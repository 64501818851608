import { Action } from '@ngrx/store';

import { LeaseDetail, LogRequest } from '../index.d';

export enum LeaseViewActionTypes {
  LoadLeaseView = '[LeaseView] Load LeaseViews',
  LoadLeaseViewWithLog = '[LeaseView] Load LeaseViews With Log',
  LoadLeaseViewSuccess = '[LeaseView] Load LeaseView Success',
  LoadLeaseViewFailed = '[LeaseView] Load LeaseView Failed',
}

export class LoadLeaseView implements Action {
  readonly type = LeaseViewActionTypes.LoadLeaseView;
  constructor(public payload: string) {}
}

export class LoadLeaseViewWithLog implements Action {
  readonly type = LeaseViewActionTypes.LoadLeaseViewWithLog;
  constructor(public payload: LogRequest) {}
}

export class LoadLeaseViewSuccess implements Action {
  readonly type = LeaseViewActionTypes.LoadLeaseViewSuccess;
  constructor(public payload: LeaseDetail) { }
}

export class LoadLeaseViewFailed implements Action {
  readonly type = LeaseViewActionTypes.LoadLeaseViewFailed;
  constructor(public payload: string) { }
}

export type LeaseViewActions = LoadLeaseView
  |LoadLeaseViewWithLog
  | LoadLeaseViewSuccess
  | LoadLeaseViewFailed;
