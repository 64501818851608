import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError } from 'rxjs/operators';

import {
  DeleteAgentFailed,
  AgentsTableTypes,
  GetAgentRelationshipsForSuccess,
  GetAgentRelationshipsForFailed,
  DeleteAgentRelationshipSuccess,
  GetAgentRelationshipsFor,
} from './agents-table.actions';
import { AquaDirectAgentService } from '../../manage-agents/aqua-direct-agent.service';
import { Agent } from '../../home/CustomerView';
import { DeleteAgentSuccess } from '../../home/home.actions';

@Injectable()
export class AgentsTableEffects {

  @Effect()
  getAgentRelationshipsFor$: Observable<GetAgentRelationshipsForSuccess | GetAgentRelationshipsForFailed> =
    this.actions$
      .pipe(
        ofType(AgentsTableTypes.GetAgentRelationshipsFor),
        switchMap((action: GetAgentRelationshipsFor) => {
          const activeAgentID = action.payload;
          return this.aquaDirectAgentService.getAgentRelationshipsFor(activeAgentID)
            .pipe(
              map((agentRelationships: Agent[]) => new GetAgentRelationshipsForSuccess(agentRelationships)),
              catchError(error => of(new GetAgentRelationshipsForFailed(error)))
            );
        })
      );

  @Effect()
  deleteAgent$: Observable<DeleteAgentRelationshipSuccess | DeleteAgentFailed> =
    this.actions$
      .pipe(
        ofType(AgentsTableTypes.DeleteAgent),
        switchMap(({payload: {agentRelationshipID, loggedInUserID, activeAgentCustomerID, customerID}}) => {
          return this.aquaDirectAgentService.delete(agentRelationshipID, loggedInUserID, activeAgentCustomerID, customerID)
            .pipe(
              map(result => new DeleteAgentRelationshipSuccess(result.agentID)),
              catchError(error => of(new DeleteAgentFailed(error)))
            );
          })
      );

  @Effect()
  deleteMyAgent$: Observable<DeleteAgentSuccess> =
    this.actions$
      .pipe(
        ofType(AgentsTableTypes.DeleteAgentRelationshipSuccess),
        map(({payload: agentID}) => {
          return new DeleteAgentSuccess(agentID);
        })
      );

  constructor(
    private actions$: Actions,
    private aquaDirectAgentService: AquaDirectAgentService,
  ) {}
}
