import { Injectable } from '@angular/core';
import { filter } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';

import * as fromAuth from '../reducers/auth.reducers';



@Injectable()
export class LoginStateSelector {

  constructor(private store: Store<fromAuth.State>) {
  }

  error(): Observable<string> {
    return this.store.pipe(
      select(fromAuth.getLoginError),
      filter(error => !!error),
    );
  }

  pending(): Observable<boolean> {
    return this.store.pipe(
      select(fromAuth.getLoginPending)
    );
  }

}
