
import { Injectable } from '@angular/core';
import { FormGroup, FormArray, FormBuilder } from '@angular/forms';

import { ProductionReturnService } from './production-return.service';
import { isNilOrEmpty, propEq, pathEq, path } from '@aquaonline/ramda';
import { FieldTypes, FieldNames, Section, IInputData, ISection } from '@aquaonline/ng-models';
import { columns } from './production-return-columns';
import { NgProductionReturnModule } from './ng-production-return.module';


@Injectable({
  providedIn: NgProductionReturnModule
})
export class ProductionReturnSectionFourService {
  columns = columns;
  constructor(
    protected productionReturnService: ProductionReturnService,
    protected formBuilder: FormBuilder
  ) { }

  addFromControlsForInput = (input: IInputData, selectedSpeciesSectionFour, transactionForm) => {
    const newColumns = {...this.columns};
    (transactionForm.get('sectionFourForm') as FormArray).push(this.formBuilder.group(this.getFormGroupItemsFromColumnEdit(newColumns.sectionFour, input)));
    selectedSpeciesSectionFour.push(input);
  }

  getFormGroupItemsFromColumnEdit(columns, receivedEntry) {
    return columns.reduce((formGroupOb, column) => {
      if (propEq('name', FieldNames.SpeciesName, column)) {
        column.defaultValue = receivedEntry.speciesName;
      }
      if (propEq('name', FieldNames.SpeciesID, column)) {
        column.defaultValue = +receivedEntry.speciesID;
      }
      if (propEq('name', FieldNames.RowNumber, column)) {
        column.defaultValue = receivedEntry.rowNumber;
      }
      if (propEq('type', FieldTypes.Input, column) || propEq('type', FieldTypes.DropDown, column)) {
        column.defaultValue = receivedEntry[column.name];
      }
      formGroupOb[column.name] = [column.defaultValue, column.validators];
      return  formGroupOb;
    }, {});
  }

  getFormGroupItemsFromColumn(columns, receivedEntry) {
    return columns.reduce((formGroupOb, column) => {
      if (propEq('name', FieldNames.SpeciesName, column)) {
        column.defaultValue = receivedEntry.speciesName;
      }
      if (propEq('name', FieldNames.SpeciesID, column)) {
        column.defaultValue = +receivedEntry.speciesID;
      }
      if (propEq('name', FieldNames.RowNumber, column)) {
        column.defaultValue = receivedEntry.rowNumber;
      }
      if (propEq('type', FieldTypes.Input, column)) {
        column.defaultValue = null;
      }
      if (propEq('type', FieldTypes.DropDown, column)) {
        column.defaultValue = null;
      }
      formGroupOb[column.name] = [column.defaultValue, column.validators];
      return  formGroupOb;
    }, {});
  }

  createControls = (receivedEntry: IInputData, selectedSpeciesSectionFour, transactionForm: FormGroup) => {
    const newColumns = {...this.columns};
    (transactionForm.get('sectionFourForm') as FormArray).push(this.formBuilder.group(this.getFormGroupItemsFromColumn(newColumns.sectionFour, receivedEntry)));
    selectedSpeciesSectionFour.push(receivedEntry);
  }

  sectionValidator = (formGroup: FormGroup) => {
    if (isNilOrEmpty(formGroup)) {
      return;
    }
    let sectionFourEntriesArray = [];
    formGroup.value.forEach(value => {
      sectionFourEntriesArray.push(value.speciesID + value.origin + value.size);
    });
    let sectionFourEntriesSet = new Set(sectionFourEntriesArray);
    return sectionFourEntriesArray.length === sectionFourEntriesSet.size ? null : {duplicateOriginSize: true};
  }

  createInputs(sectionFourForm, inputs) {
    if (isNilOrEmpty(sectionFourForm)) { return; }
    
    sectionFourForm.forEach(entry => {
      inputs.push({secID: Section.sectionFour, speciesID: entry.speciesID, origin: entry.origin, size: entry.size, 
        quantity: entry.quantity, value: entry.value, speciesName: entry.speciesName, rowNumber: entry.rowNumber, average: +entry.average
      });
    });
  }

  public removeRow = (value, sectionFourFormControl: FormArray, selectedSpeciesSectionFour) => {
    this.removeEntry(sectionFourFormControl, value, selectedSpeciesSectionFour);
    sectionFourFormControl.updateValueAndValidity();
    return path(['controls', 'length'], sectionFourFormControl) ? true : false;
  }

  private removeEntry(sectionFourControl: FormArray, value: any, selectedSpeciesSectionFour) {
    sectionFourControl.controls = sectionFourControl.controls
      .filter(formGroup => !pathEq(['value', 'rowNumber'], value.rowNumber, formGroup));
    selectedSpeciesSectionFour = selectedSpeciesSectionFour
      .filter(aSpecies => !propEq('rowNumber', value.rowNumber, aSpecies));
    selectedSpeciesSectionFour.forEach((value, index) => value.rowNumber = index);
  }

  public openSpeciesModal(modalRef, section: ISection, selectedSpeciesSectionFour) {
    modalRef.componentInstance.species = section.species;
    modalRef.componentInstance.selectedSpeciesSectionFour = selectedSpeciesSectionFour;
    return modalRef.componentInstance.passEntry;
  }
}
