import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { handleHttpErrorMessage } from '../shared/handleHttpErrorMessage';

@Injectable({
  providedIn: 'root',
})
export class LeaseService {

  constructor(
    private http: HttpClient,
  ) { }

  get = (leaseID): Observable<any> => {
    return this.http.get<any>(`${environment.apiUrl}/lease/${encodeURI(leaseID)}`)
      .pipe(
        catchError((error: HttpErrorResponse) =>
          throwError(handleHttpErrorMessage(error, `Unable to find user detail`))
        )
      );
  }
}
