<ng-container [formGroup]="currentFormGroupName" *ngFor="let column of columns">
    <td *ngIf="column.type !== 'info'">
        <div [ngSwitch]="column.type" class="inputs-container">
            <input onClick="this.select();" *ngSwitchCase="'input'"
                    type="number"
                    class="form-control input"
                    min="0"
                    [formControlName]="column.name">
            <select *ngSwitchCase="'dropDown'"
                    class="form-control drop-down"
                    [formControlName]="column.name">
                <option
                    [value]="null" disabled>Select one
                </option>
                <option *ngFor="let value of column.dropDownValues | order"
                    [value]="value">{{value}}
                </option>
            </select>
            <label *ngSwitchCase="'text'" 
                type="text"
                class="form-control text"
                >
                <ng-container *ngIf="currentFormGroupName.value[column.name]; else defaultValue">
                    {{currentFormGroupName?.value[column.name]}}</ng-container>
                </label>
                <ng-template #defaultValue>
                    <span>{{column?.defaultValue}}</span>
                </ng-template>
            <span 
                *ngSwitchCase="'remove'"
                class="add-button"
                (click)="remove(currentFormGroupName.value)"
                >
                <p class="float-right button-text">Remove</p><fa-icon class="float-right button-text" icon="trash" ></fa-icon>
            </span>
            <span 
                *ngSwitchCase="'placeHolder'"
                class="add-button">
            </span>
        </div>
        <span class="form-control-error" 
        *ngIf="showErrors && (column.type ==='input' || column.type ==='dropDown') && currentFormGroupName.get(column.name).hasError('notWholeNumber')">
            Entry must be a whole number.
        </span>
        <span class="form-control-error" 
        *ngIf="showErrors && (column.type ==='input') && currentFormGroupName.get(column.name).hasError('tooBigNumber')">
            Entry must not exceed the limit.
        </span>
        <span class="form-control-error" 
            *ngIf="showErrors && (column.type ==='input' || column.type ==='dropDown') && currentFormGroupName.get(column.name).hasError('required')">
            This field is required.
        </span>
        <span class="form-control-error" 
            *ngIf="showErrors && (column.type ==='input' || column.type ==='dropDown') && currentFormGroupName.get(column.name).hasError('notTwoDecimalPlaces')">
            Entry must have up to two decimal places.
        </span>
        <span class="form-control-error" 
            *ngIf="showErrors && (column.type ==='input') && currentFormGroupName.get(column.name).hasError('notPositiveNumber')">
                Entry must be greater than zero.
        </span>
    </td>
</ng-container>

