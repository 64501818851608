import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { withLatestFrom, filter, map } from 'rxjs/operators';

import * as fromPermitView from './permit-view.reducer';
import { ViewStatus } from '../../enum';
import { equals } from '../../shared/ramda-functions';
import { PermitStatuses } from '../../lease/enum';

@Injectable()
export class PermitViewStateSelector {
  constructor(
    private store: Store<fromPermitView.State>,
  ) { }

  viewStatus = () => this.store.select(fromPermitView.getPermitViewStatus);

  permitDetail = () => this.store.select(fromPermitView.getPermitDetail)
    .pipe(
      withLatestFrom(this.viewStatus()),
      filter(([_, viewStatus]) => equals(viewStatus, ViewStatus.LoadSuccess)),
      map(([permitDetail]) => permitDetail)
    )

  error = () => this.store.select(fromPermitView.getPermitViewErrorMessage);

  // isPermitStatus = (permitStatuses: Array<PermitStatuses>) =>
  //     this.permitDetail()
  //       .pipe(
  //         map(({summary}) => permitStatuses.includes(summary.permitStatusID))
  //       )
}
