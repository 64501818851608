import { HttpErrorResponse } from '@angular/common/http';

export const handleHttpErrorMessage = (error: HttpErrorResponse, messageFor404 = 'Unable to find the resource') => {
  let errorMessage = '';
  if (error.error instanceof ErrorEvent) {
    // A client-side or network error occurred. Handle it accordingly.
    errorMessage = `An error occurred: ${error.error.message}.`;
  } else {
    // The backend returned an unsuccessful response code.
    // The response body may contain clues as to what went wrong,
    if (error.status === 404) {
      errorMessage = messageFor404;
    }

    if (error.status === 409) {
      errorMessage = error.error.message;
    }
  }

  return errorMessage || 'Something failed. Please try again later';
};
