import { Component, OnInit, ChangeDetectorRef, OnDestroy, HostListener, ViewChild, ElementRef } from '@angular/core';

import { Location } from '@angular/common';
import { Observable } from 'rxjs';
import { Router, ActivationEnd } from '@angular/router';
import { filter, map, distinctUntilChanged } from 'rxjs/operators';
import { MediaMatcher } from '@angular/cdk/layout';

import { RootDispatcher } from './actions/dispatcher';
import { AuthStateSelector } from './auth/auth.selector';
import { pathOr, isNotNilAndEmpty } from './shared/ramda-functions';
import { LoggedInStatus } from './auth/auth.reducer';
import { UserProfile } from './user-profile/UserProfile';
import { UserProfileStateSelector } from './user-profile/user-profile.selector';
import { DeviceSize } from './enum';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  constructor(
    private rootDispatcher: RootDispatcher,
    private authStateSelector: AuthStateSelector,
    private router: Router,
    private userProfileStateSelector: UserProfileStateSelector,
    private location: Location,
    public changeDetectorRef: ChangeDetectorRef,
    public media: MediaMatcher
  ) {
    router.events.subscribe(val => {
      this.isLoginRoute = this.location.path() === '/login';
    });

  }
  loggedIn$: Observable<LoggedInStatus>;
  isPrintPage$: Observable<boolean>;
  userProfile$: Observable<UserProfile>;
  lastLoggedIn$: Observable<Date>;
  loggedInStatus = LoggedInStatus;
  loggedIn = LoggedInStatus.initial;
  mobileDevice = false;
  isLoginRoute: boolean;
  mobileQuery: MediaQueryList;
  doNotToggle: boolean;
  isPrintPage: boolean;

  fillerNav = Array.from({length: 50}, (_, i) => `Nav Item ${i + 1}`);

  private _mobileQueryListener: () => void;
  ngOnDestroy(): void {
    //this.mobileQuery.removeEventListener('change', this._mobileQueryListener);
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.checkMedia();
    this.changeDetectorRef.detectChanges();
    this.checkMediaWidth(event.target);
  }

  private checkMediaWidth(event: any) {
    this.mobileDevice = event.innerWidth < DeviceSize.largeMobileMaxWidth;
  }

  ngOnInit() {
    this.checkMediaWidth(window);
    this.checkMedia();
    this.rootDispatcher.bootstrapApp();

    this.authStateSelector.loggedIn().subscribe(status => {
      this.loggedIn = status;
    });

    this.isPrintPage$ = this.router.events
      .pipe(
        filter(event => event instanceof ActivationEnd),
        filter((event: ActivationEnd) => isNotNilAndEmpty(event.snapshot.component)),
        map(({ snapshot }: ActivationEnd): boolean => pathOr(false, ['data', 'isPrintPage'], snapshot)),
        distinctUntilChanged(),
      );

    this.userProfile$ = this.userProfileStateSelector.userProfile();
    this.lastLoggedIn$ =  this.userProfileStateSelector.lastLoggedIn();
  }
  checkMedia = () => {
    this.mobileQuery = this.media.matchMedia(`(max-width: ${DeviceSize.largeMobileMaxWidth}px)`);
    this._mobileQueryListener = () => this.changeDetectorRef.detectChanges();
    //this.mobileQuery.addEventListener('change', this._mobileQueryListener);
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  buttonListener = (snavElement) => {
    snavElement.toggle();
  }

  menuListener = (snavElement) => {
    this.doNotToggle = true;
  }

  snavToggle = (snavElement) => {
    if (this.doNotToggle) {
      this.doNotToggle = false;
    } else {
      snavElement.toggle();
    }
  }
}





