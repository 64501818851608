import { Pipe, PipeTransform } from '@angular/core';

/**
 * Usages:
 *  - {{ isPrimary | yesNo }}
 *  - {{ isPrimary | yesNo: 'Yes' : '' }}
 */
@Pipe({
  name: 'yesNo'
})
export class YesNoPipe implements PipeTransform {
  transform(condition: boolean, yesString = 'Yes', noString = 'No'): string {
    return condition ? yesString : noString;
  }
}
