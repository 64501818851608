import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';

import { CustomerDetail } from './CustomerView';
import { environment } from '../../environments/environment';
import { handleHttpErrorMessage } from '../shared/handleHttpErrorMessage';
import { ProductionReturn } from '../production-return-list';


@Injectable({
  providedIn: 'root',
})
export class CustomerViewService {

  constructor(
    private http: HttpClient,
  ) { }

  get = (): Observable<CustomerDetail> =>
    this.http.get<CustomerDetail>(`${environment.apiUrl}/user`)
      .pipe(
        catchError((error: HttpErrorResponse) =>
          throwError(handleHttpErrorMessage(error, `Unable to find user detail`))
        )
      )
}
