import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, publish, refCount } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { ProductionReturn } from '.';
import { handleHttpErrorMessage } from '../shared/handleHttpErrorMessage';

@Injectable({
  providedIn: 'root'
})
export class ProductionReturnListService {

  constructor(private http: HttpClient) { }

  getProductionReturnList = (): Observable<ProductionReturn[]> => {
    return this.http.get<any>(`${environment.apiUrl}/production-return`)
    .pipe(
      catchError((error: HttpErrorResponse) =>
        throwError(handleHttpErrorMessage(error, `Unable to get production return`))
      ),
      publish(),
      refCount(),
    );
  }
}
