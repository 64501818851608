import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as fromPermitView from './permit-view.reducer';
import { LoadPermitView, LoadPermitViewWithLog } from './permit-view.actions';

@Injectable()
export class PermitViewActionDispatcher {

  constructor(private store: Store<fromPermitView.State>) {
  }

  loadPermitView = (permitId: string) => this.store.dispatch(new LoadPermitView(permitId));
  loadPermitViewWithLog = (permitId: string) => this.store.dispatch(new LoadPermitViewWithLog({id: permitId, log: true}));
}
