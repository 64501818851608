import { AuthActions, AuthActionTypes } from '../auth.actions';

export interface State {
  error?: string;
  pending: boolean;
}

export const initialState: State = {
  pending: false,
};

export function reducer(state = initialState, action: AuthActions): State {
  switch (action.type) {
    case AuthActionTypes.Login:
      return {
        ...state,
        error: undefined,
        pending: true,
      };
    case AuthActionTypes.LoginSuccess:
      return {
        ...state,
        error: undefined,
        pending: false,
      };
    case AuthActionTypes.LoginFailure: {
      const errorMsg = action.payload;

      return {
        ...state,
        error: errorMsg,
        pending: false,
      };
    }

    default:
      return state;
  }
}

export const getError = (state: State) => state.error;
export const getPending = (state: State) => state.pending;
