import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ManageAgentsComponent } from './manage-agents.component';
import { CreateAgentComponent } from './create-agent/create-agent.component';
import { EditAgentComponent } from './edit-agent/edit-agent.component';

const routes: Routes = [{
  path: '',
  component: ManageAgentsComponent,
  data: {
    breadcrumb: {
      label: 'Manage Agents',
      reset: true,
    }
  }
},
{
  path: 'new/:customerID',
  component: CreateAgentComponent,
  data: {
    breadcrumb: {
      label: 'Create New Agent'
    }
  }
},
{
  path: 'view/:agentRelationshipID',
  component: EditAgentComponent,
  data: {
    breadcrumb: {
      label: 'Edit Agent Permissions'
    }
  }
},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ManageAgentsRoutingModule { }
