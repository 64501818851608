import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { Router } from '@angular/router';
import { catchError, map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { handleHttpErrorMessage } from '../shared/handleHttpErrorMessage';
import { Agent } from '../home/CustomerView';

@Injectable({
  providedIn: 'root'
})
export class AquaDirectAgentService {

  constructor(
    private http: HttpClient,
  ) {}

  create = (agentPermission: Agent) => {
    return this.http.post<any>(`${environment.apiUrl}/agent`, agentPermission)
    .pipe(
      catchError((error: HttpErrorResponse) =>
        throwError(handleHttpErrorMessage(error, `Unable to save the Agent permission`))
      )
    );
  }

  delete = (agentRelationshipID: string, loggedInUserID?: string, activeAgentCustomerID?: string, customerID?: string) => {
    return this.http.delete<any>(`${environment.apiUrl}/agent/relationship/${agentRelationshipID}/${loggedInUserID}/${activeAgentCustomerID}/${customerID}`)
    .pipe(
      catchError((error: HttpErrorResponse) =>
        throwError(handleHttpErrorMessage(error, `Unable to delete the Agent relationship`))
      )
    );
  }

  getAgentRelationshipsFor = (customerID: string): Observable<Agent[]> => {
    return this.http.get<Array<Agent>>(`${environment.apiUrl}/agent/for/${customerID}`)
    .pipe(
      map(agents => agents.map(agent => ({...agent, roleType: 'Customer Agent'})))
    );
  }

  getAgentRelationships = (customerID: string): Observable<Agent[]> => {
    return this.http.get<Array<Agent>>(`${environment.apiUrl}/agent/${customerID}`);
  }

  getAgentRelationship = (agentRelationshipID: string): Observable<Agent> => {
    return this.http.get<Agent>(`${environment.apiUrl}/agent/relationship/${agentRelationshipID}`).pipe(
      map(agent => ({...agent, roleType: 'Customer Agent'}))
    );
  }

  update = (agentPermission: any) => {
    return this.http.put<any>(`${environment.apiUrl}/agent`, agentPermission)
    .pipe(
      catchError((error: HttpErrorResponse) =>
        throwError(handleHttpErrorMessage(error, `Unable to update the Agent permission`))
      )
    );
  }

}
