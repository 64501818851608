<div class="header-panel">
  <button *ngIf="loggedIn === loggedInStatus.loggedIn && mobileQuery.matches" class="menu-button" (click)="toggle()"><fa-icon class="icons fa-2x" icon="bars"></fa-icon></button>
  <div class="application-branding">
    <img src="./assets/images/DPI-logo.png" class="first-logo" alt="">
  </div>
  <div *ngIf="mobileQueryMinMax.matches" class="second-logo">
    <img src="./assets/images/fishonline-logo.png">
  </div>
</div>
  
