import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { MatButtonModule } from '@angular/material/button';

import { PermitScheduleComponent } from './permit-schedule.component';
import { PageHeaderModule } from '../page-header/page-header.module';
import { PermitScheduleService } from './permit-schedule.service';
import { PermitScheduleRoutingModule } from './permit-schedule-routing.module';
import { AgentSelectorModule } from '../agent-selector/agent-selector.module';
import { PermitScheduleEffects } from './permit-schedule.effects';
import * as fromPermitSchedule from './permit-schedule.reducer';
import { PermitScheduleStateSelector } from './permit-schedule.selector';
import { PermitScheduleActionDispatcher } from './permit-schedule.dispatcher';
import { SharedModule } from '../shared/shared.module';
import { PrintFrameModule } from '../print-frame/print-frame.module';

@NgModule({
  declarations: [PermitScheduleComponent],
  imports: [
    CommonModule,
    PageHeaderModule,
    ReactiveFormsModule,
    EffectsModule.forFeature([PermitScheduleEffects]),
    StoreModule.forFeature('permit-schedule', fromPermitSchedule.reducer),
    PermitScheduleRoutingModule,
    NgbModule,
    AgentSelectorModule,
    MatButtonModule,
    SharedModule,
    PrintFrameModule
  ],
  providers: [
    PermitScheduleService,
    PermitScheduleActionDispatcher,
    PermitScheduleStateSelector,
  ]
})
export class PermitScheduleModule { }
