<div class="modal-header">
  <h4 class="modal-title">Nil Production Confirmation</h4>
  <button type="button" class="close" aria-label="Close" (click)="modal.close('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  Please confirm you have had <b>"Nil Production"</b> for the production year you are submitting a return. This will discard any production data you have entered.
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-light" (click)="cancel()" ngbAutofocus>Cancel</button>
  <button type="button" class="btn btn-primary" (click)="confirm()">Confirm</button>
</div>
