<ng-container *ngIf="agentSelectorControl.value && isAgentForAnyone$ | async">
  <div class="agent-selector row">
    <div class="label col-12">
      <h3>
        {{ label }}
      </h3>
      <h5 *ngIf="subLabel">{{ subLabel }}</h5>
    </div>
    <label class="col-2 font-weight-bold" for="agents">Select Customer:</label>
    <select class="col-10" id="agents" name="agents" [formControl]="agentSelectorControl" class="form-control">
      <option *ngFor="let agent of agents$ | async" [ngValue]="agent.agentFor">
        {{ agent.agentFor }} - {{ agent.agentForName }}
      </option>
    </select>
  </div>
</ng-container>
