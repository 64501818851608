import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { ProductionReturnSectionsComponent } from './production-return-sections.component';
import { SharedModule } from '../../shared/shared.module';


@NgModule({
  declarations: [ProductionReturnSectionsComponent],
  imports: [
    CommonModule,
    NgbModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    SharedModule,
  ],
  exports: [ProductionReturnSectionsComponent],
})
export class ProductionReturnSectionsModule {}
