import { Action, createFeatureSelector, createSelector } from '@ngrx/store';

import { ViewStatus } from '../../enum';
import {
  EditAccountProfileActions,
  EditAccountProfileActionTypes,
  LoadCustomerSuccess,
  LoadCustomerFailed } from './edit-account-profile.actions';
import { CustomerDetail } from '../../home/CustomerView';



export interface State {
  customerDetail?: CustomerDetail;
  customerViewStatus: ViewStatus;
  customerErrorMessage?: string;
}

export const initialState: State = {
  customerViewStatus: ViewStatus.Initial
};

const loadCustomer = (state: State): State => {
  const { customerDetail, customerErrorMessage, ...rest} = state;

  return {
    ...rest,
    customerViewStatus: ViewStatus.Loading,
  };
};

const loadCustomerSuccess = (state: State, action: LoadCustomerSuccess): State => ({
  ...state,
  customerViewStatus: ViewStatus.LoadSuccess,
  customerDetail: action.payload,
});

const loadCustomerFailed = (state: State, action: LoadCustomerFailed): State => ({
  ...state,
  customerViewStatus: ViewStatus.LoadFailed,
  customerErrorMessage: action.payload,
});

export function reducer(state = initialState, action: EditAccountProfileActions): State {
  switch (action.type) {
    case EditAccountProfileActionTypes.LoadCustomer:
      return loadCustomer(state);
    case EditAccountProfileActionTypes.LoadCustomerSuccess:
      return loadCustomerSuccess(state, action);
    case EditAccountProfileActionTypes.LoadCustomerFailed:
      return loadCustomerFailed(state, action);
    default:
      return state;
  }
}

const getEditAccountProfileState = createFeatureSelector<State>('edit-account-profile');


export const getCustomerDetail = createSelector(getEditAccountProfileState, state => state.customerDetail);
export const getCustomerViewStatus = createSelector(getEditAccountProfileState, state => state.customerViewStatus);
export const getCustomerViewErrorMessage = createSelector(getEditAccountProfileState, state => state.customerErrorMessage);






