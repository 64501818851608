
  <div class="navigation-menu">
    <div class="title">
        <p>AquaDirect</p>
    </div>
    <div class="menu-items">
      <ul class="top-level-menu">
        <li id="MenuItem-{{ i }}" [className]="MenuItem.isActive ? 'active' : 'inactive'" *ngFor="let MenuItem of items; let i = index">
          <fa-icon *ngIf="defineClass(MenuItem.label)" icon="caret-right" class="caret-right"></fa-icon>
          <a (click)="openLink(MenuItem)">
            {{ MenuItem.label }}
          </a>
        </li>
      </ul>

      <ngb-accordion [class]="defineClass('My Account')" #acc="ngbAccordion" activeIds="ngb-panel-0">
        <ngb-panel id="myAccount">
            <ng-template ngbPanelTitle>
              <fa-icon *ngIf="defineClass('My Account')" icon="caret-right" class="caret-right"></fa-icon>
              <span (click)="toggleIcon()">
                My Account Profile
                <fa-icon *ngIf="!openSubMenu" icon="angle-down" class="fa-angle-down"></fa-icon>
                <fa-icon *ngIf="openSubMenu" icon="angle-up" class="fa-angle-up"></fa-icon>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              <a [class]="defineDropDownClass('Registration Details')" [routerLink]="'/account-profile'" ><fa-icon icon="angle-right" class="fa-angle-right"></fa-icon>Registration Details</a>
              <a [class]="defineDropDownClass('Manage Agents')" [routerLink]="'/manage-agents'"><fa-icon icon="angle-right" class="fa-angle-right"></fa-icon>Manage Agents</a>
              <a [class]="defineDropDownClass('Change Password')" [routerLink]="'/change-password'"><fa-icon icon="angle-right" class="fa-angle-right"></fa-icon>Change Password</a>
            </ng-template>
        </ngb-panel>
      </ngb-accordion>
    </div>
    <div class="logout">
      <a style="padding-left: 0px" (click)="logout()">Logout</a>
    </div>
  </div>







