import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { handleHttpErrorMessage } from '../shared/handleHttpErrorMessage';
import { PermitGroup } from './enum';
import { PermitStatuses } from '../permit/enum';
import { PermitStatus } from './enum';
import { ConditionType } from '../lease/enum';
import { PermitPrintData } from '.';
import { isLeaseCurrentOrPendingRenewal } from './is-lease-current-or-pending-renewal';
import { prop, equals } from '@aquaonline/ramda';

@Injectable()
export class PermitScheduleService {
  permitPrintData: PermitPrintData = {
    permitHolders: undefined,
    customerNo: undefined,
    addressSections: undefined,
    permits: {
      leaseBased: [],
      farmBased: []
    }
  };
  constructor(
    private http: HttpClient,
  ) {}

  getPermitSchedule = (customerID: string): Observable<any> => {
    return this.http.get<any>(`${environment.apiUrl}/permit-schedule`, {
      params: {
        customerID,
      }
    }).pipe(
        catchError((error: HttpErrorResponse) =>
          throwError(handleHttpErrorMessage(error, `Unable to find permit schedule detail`))
        )
      );
  }

  createPermitPrintData = (permitScheduleDetail, customerID) => {
    this.resetPrintPermitData();
    this.setCustomerDetails(permitScheduleDetail);
    this.setPermits(permitScheduleDetail.permits.filter(permit => equals(permit.customerID, customerID)));
    return this.permitPrintData;
  }

  resetPrintPermitData = () => {
    this.permitPrintData = {
      permitHolders: undefined,
      customerNo: undefined,
      addressSections: undefined,
      permits: {
        leaseBased: [],
        farmBased: []
      }
    };
  }

  private setCustomerDetails(permitScheduleDetail) {
    const { myDetail, ...rest} = permitScheduleDetail;
    this.permitPrintData.customerNo = myDetail.customerID;
    this.permitPrintData.permitHolders = myDetail.customerName.split(';');
    this.permitPrintData.addressSections = [
      myDetail.physicalStreetAddress1,
      `${myDetail.physicalTownSuburb} ${myDetail.physicalState} ${myDetail.physicalPostcode}`,
    ];
  }

  private setPermits(permits) {
    permits.filter(permit => equals(permit.permitStatusID, PermitStatuses.Current))
    .map(permit => {
      if (equals(permit.permitGroup, PermitGroup.Lease)) {
        this.permitPrintData.permits.leaseBased.push(this.setLease(permit));
      }
      if (equals(permit.permitGroup, PermitGroup.Farm)) {
        this.permitPrintData.permits.farmBased.push(this.setFarm(permit));
      }
    });
  }

  private setLease(permit) {
    return {
      name: `${permit.permitID} Class ${permit.permitClassCode} ${permit.permitClass}`,
      totalPermitArea: prop('totalAuthorisedArea', permit),
      scheduleAbyPermit: {
        name: 'Schedule A: Area Authorised by Permit',
        tables: this.setAreaAuthorised(permit)
      },
      scheduleAtypeOfAquaculture: {
        name: 'Schedule A: Type of Aquaculture',
        leases: this.setTypeOfAquaculture(permit)
      },
      scheduleBpermitConditions: {
        name: 'Schedule B: Standard & Specific Permit Conditions',
        link: 'https://www.dpi.nsw.gov.au/fishing/aquaculture/permit-holder-information/aquaculture-permit-conditions',
        conditions: this.setConditions(permit)
      },
      scheduleBspecialConditions: {
        name: 'Schedule B: Special Permit Conditions',
        conditions: this.setSpecialConditions(permit)
      }
    };
  }

  private setFarm(permit) {
    return {
      name: `${permit.permitID} Class ${permit.permitClassCode} ${permit.permitClass}`,
      wspa: permit.WSPA,
      scheduleAbyPermitandType: {
        name: 'Schedule A: Area Authorised by Permit and Type of Aquaculture',
        farms: this.setFarmDetails(permit)
      },
      scheduleBpermitConditions: {
        name: 'Schedule B: Standard & Specific Permit Conditions',
        link: 'https://www.dpi.nsw.gov.au/fishing/aquaculture/permit-holder-information/aquaculture-permit-conditions',
        conditions: this.setConditions(permit)
      },
      scheduleBspecialConditions: {
        name: 'Schedule B: Special Permit Conditions',
        conditions: this.setSpecialConditions(permit)
      }
    };
  }

  private setAreaAuthorised(permit) {
    const currentOrPendingRenewalLeases = permit.leases
      .filter(isLeaseCurrentOrPendingRenewal);
    const uniqueEstuaries = Array.from(new Set(currentOrPendingRenewalLeases.map((item: any) => item.estuary)));
    return uniqueEstuaries.map((estuary) => {
      return {
        estuary,
        leases: this.filterByArea(currentOrPendingRenewalLeases, estuary)
      };
    });
  }

  private filterByArea(currentOrPendingRenewalLeases, area) {
    return currentOrPendingRenewalLeases.filter((lease) => lease.estuary === area)
      .map((currentOrPendingRenewalLease) => {
        return {
          leaseNo: currentOrPendingRenewalLease.leaseID,
          percentageAuthorised: currentOrPendingRenewalLease.percentOfLeaseAuthorised,
          leaseArea: currentOrPendingRenewalLease.leaseArea,
          expiryDate: currentOrPendingRenewalLease.expiryDate,
        };
    });
  }

  private setTypeOfAquaculture(permit) {
    return permit.leases.filter(isLeaseCurrentOrPendingRenewal)
    .map((currentOrPendingRenewalLease) => {
      return {
        leaseNo: currentOrPendingRenewalLease.leaseID,
        typeOfAquaculture: currentOrPendingRenewalLease.species,
        leaseEstuary: currentOrPendingRenewalLease.estuary,
      };
    });
  }

  private setConditions(permit) {
    return permit.conditions.filter(condition => equals(condition.conditionType, ConditionType.Specific))
    .map(condition => {
      return {
        name: condition.conditionLabelShort,
        description: condition.conditionLabel,
      };
    });
  }

  private setSpecialConditions(permit) {
    return permit.conditions.filter(condition => equals(condition.conditionType, ConditionType.Special))
    .map(condition => {
      return {
        name: condition.permitConditionID,
        description: condition.condition, // .replace(/<[^>]+>/g, ''),
      };
    });
  }

  private setFarmDetails(permit) {
    return permit.farms.filter(farm => equals(farm.farmStatus, PermitStatus.Current))
    .map(farm => {
      return {
        farmNo: farm.farmID,
        address: farm.location,
        lot: farm.lotDP,
        typeOfAquaculture: farm.species,
      };
    });
  }
}
