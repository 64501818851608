import { Pipe, PipeTransform } from '@angular/core';

import { isNilOrEmpty } from './ramda-functions';

@Pipe({
  name: 'mapArrayToProp'
})
export class MapArrayToPropPipe implements PipeTransform {
  transform = (items: Array<any>, prop: string) => {
    if (isNilOrEmpty(items)) {
      return [];
    }

    return items.map(item => item[prop]);
  }
}
