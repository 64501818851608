<div class="row">
  <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3" *ngFor="let contact of contacts">
    <div class="card">
      <div class="card-header">
        {{ contact.preferredContact | yesNo : 'Preferred' : ''}}
      </div>
      <div class="card-body">
        <div class="card-text row">
          <div class="col-12"><span class="label">Type:</span> {{ contact.contactType }}</div>
          <div class="col-12"><span class="label">Contact:</span> {{ contact.contact }}</div>
          <div class="col-12"><span class="label">Name:</span> {{ contact.contactName }}</div>
          <div class="col-12"><span class="label">SMS:</span> {{ contact.sms | yesNo }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
  
