import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { environment } from '../../environments/environment';
import { AuthStateSelector } from '../auth/auth.selector';
import { LoggedInStatus } from '../auth/auth.reducer';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {

  @Input() isMobile: boolean;
  authSubsription$$: Subscription;
  version: string;
  versionLabel: string;
  timestamp: string;
  loggedInStatus = LoggedInStatus;
  loggedIn = LoggedInStatus.initial;
  constructor(
    private authStateSelector: AuthStateSelector
  ) { }

  ngOnInit() {
    this.authSubsription$$ = this.authStateSelector.loggedIn().subscribe(loggedIn => {
      this.loggedIn = loggedIn;
    });
    this.version = `v${environment.version}`;
  }

  ngOnDestroy(): void {
    if (this.authSubsription$$) {
      this.authSubsription$$.unsubscribe();
    }
  }
}
