import { FormControl, Validators, FormGroup } from '@angular/forms';

import { prop, equals, gte, lt } from '@aquaonline/ramda';
import { twoDecimalRegex } from './two-decimal-regex';

export const positiveNumberValidator = (control: FormControl) => {
    if (Validators.required(control)) { return null; }

    return control.value > 0 ? null : { notPositiveNumber: true };
}

export const maxLengthValidator = (control: FormControl) => {
    if (Validators.required(control)) { return null; }

    return control.value <= 999999999 ? null : { tooBigNumber: true };
}

export const twoDecimalPlacesValidator = (control: FormControl) => {
    if (Validators.required(control)) { return null; }

    return twoDecimalRegex.test(prop('value', control)) ? null : { notTwoDecimalPlaces: true };
}

export const gstValidator = (control: FormControl) => {
    if (Validators.required(control)) { return null; }

    return gte(control.value, 0) && lt(control.value, 1) ? null : { invalidGSTValue: true };
}

export const totalPercentageValidator = (formGroup: FormGroup) => {
    if (Validators.required(formGroup)) { return null; }

    const values = formGroup.value.length ? formGroup.value[0] : formGroup.value;
    const sum = values.wildCaught + values.hatchery + values.import;
    return (equals(sum, 100) || equals(sum, 0)) ? null : { InvalidTotalPercentage: true };
  }