import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ViewStatus } from '../enum';
import { CustomerDetail } from './CustomerView';
import { HomeActions,
  HomeActionTypes,
  LoadCustomerSuccess,
  LoadCustomerFailed,
  DeleteAgentSuccess,
  CreateAgentFailed,
  CreateAgentSuccess,
  CreateAgent,
  UpdateAgent,
  UpdateAgentSuccess,
  UpdateAgentFailed,
} from './home.actions';

export interface State {
  customerDetail?: CustomerDetail;
  customerViewStatus: ViewStatus;
  customerErrorMessage?: string;
}

export const initialState: State = {
  customerViewStatus: ViewStatus.Initial
};

const clearErrors = (state: State): State => {
  const { customerErrorMessage, ...rest} = state;

  return {
    ...rest,
  };
};

const loadCustomer = (state: State): State => {
  const { customerErrorMessage, ...rest} = state;
  return {
    ...rest,
    customerViewStatus: ViewStatus.Loading,
  };
};

const loadCustomerSuccess = (state: State, action: LoadCustomerSuccess): State => {
  return {
    ...state,
    customerViewStatus: ViewStatus.LoadSuccess,
    customerDetail: action.payload,
  };
};

const loadCustomerFailed = (state: State, action: LoadCustomerFailed): State => ({
  ...state,
  customerViewStatus: ViewStatus.LoadFailed,
  customerErrorMessage: action.payload,
});

const deleteAgentSuccess = (state: State, action: DeleteAgentSuccess): State => {
  return {
    ...state,
    customerDetail: {
      ...state.customerDetail,
      myAgents: state.customerDetail.myAgents.filter((agent) => agent.agentID !== action.payload),
    },
    customerViewStatus: ViewStatus.LoadSuccess,
  };
};

const createAgent = (state: State, action: CreateAgent): State => {
  const { customerErrorMessage, ...rest} = state;
  return {
    ...rest,
    customerViewStatus: ViewStatus.Loading,
  };
};

const createAgentSuccess = (state: State, action: CreateAgentSuccess): State => {
  return {
    ...state,
    customerViewStatus: ViewStatus.LoadSuccess,
  };
};

const createAgentFailed = (state: State, action: CreateAgentFailed): State => ({
  ...state,
  customerViewStatus: ViewStatus.LoadFailed,
  customerErrorMessage: action.payload,
});

const updateAgent = (state: State, action: UpdateAgent): State => {
  const { customerErrorMessage, ...rest} = state;
  return {
    ...rest,
    customerViewStatus: ViewStatus.Loading,
  };
};

const updateAgentSuccess = (state: State, action: UpdateAgentSuccess): State => {
  return {
    ...state,
    customerDetail: {
      ...state.customerDetail,
      agentFor: state.customerDetail.myAgents.map((agent) => {
        if (agent.agentID === action.payload.agentID) {
          agent.agentManagement = action.payload.agentManagement;
          agent.inquiries = action.payload.inquiries;
          agent.productionReports = action.payload.productionReports;
        }
        return agent;
      }),
    },
    customerViewStatus: ViewStatus.LoadSuccess,
  };
};

const updateAgentFailed = (state: State, action: UpdateAgentFailed): State => ({
  ...state,
  customerViewStatus: ViewStatus.LoadFailed,
  customerErrorMessage: action.payload,
});

export function reducer(state = initialState, action: HomeActions): State {
  switch (action.type) {
    case HomeActionTypes.ClearErrors:
      return clearErrors(state);
    case HomeActionTypes.LoadCustomer:
      return loadCustomer(state);
    case HomeActionTypes.LoadCustomerSuccess:
      return loadCustomerSuccess(state, action);
    case HomeActionTypes.LoadCustomerFailed:
      return loadCustomerFailed(state, action);
    case HomeActionTypes.DeleteAgentSuccess:
      return deleteAgentSuccess(state, action);
    case HomeActionTypes.CreateAgent:
      return createAgent(state, action);
    case HomeActionTypes.CreateAgentSuccess:
      return createAgentSuccess(state, action);
    case HomeActionTypes.CreateAgentFailed:
      return createAgentFailed(state, action);
    case HomeActionTypes.UpdateAgent:
      return updateAgent(state, action);
    case HomeActionTypes.UpdateAgentSuccess:
      return updateAgentSuccess(state, action);
    case HomeActionTypes.UpdateAgentFailed:
      return updateAgentFailed(state, action);
    default:
      return state;
  }
}

const getHomeState = createFeatureSelector<State>('home');

export const getCustomerDetail = createSelector(getHomeState, state => state.customerDetail);
export const getCustomerViewStatus = createSelector(getHomeState, state => state.customerViewStatus);
export const getCustomerViewErrorMessage = createSelector(getHomeState, state => state.customerErrorMessage);
