import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';

import * as fromBreadcrumb from './breadcrumb.reducer';

@Injectable()
export class BreadcrumbStateSelector {

  constructor(
    private store: Store<fromBreadcrumb.State>,
  ) { }

  items = () => this.store.select(fromBreadcrumb.getBreadcrumbItems);
}
