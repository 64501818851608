import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { withLatestFrom, filter, map } from 'rxjs/operators';

import * as fromHome from './home.reducer';
import { ViewStatus } from '../enum';
import { equals } from '../shared/ramda-functions';
import { CustomerDetail } from './CustomerView';
import { isNotNilAndEmpty } from '@aquaonline/ramda';

@Injectable()
export class HomeStateSelector {
  constructor(
    private store: Store<fromHome.State>,
  ) {}

  viewStatus = () => this.store.select(fromHome.getCustomerViewStatus);

  customerDetail = (): Observable<CustomerDetail> => this.store.select(fromHome.getCustomerDetail)
    .pipe(
      withLatestFrom(this.viewStatus()),
      filter(([_, viewStatus]) => equals(viewStatus, ViewStatus.LoadSuccess)),
      map(([customerDetail]) => customerDetail)
    )

  getLoggedInUserID = () => this.customerDetail().pipe(
    filter(customerDetail => !!customerDetail),
    map((customerDetail) => {
      return customerDetail.myDetail.customerID;
    }),
  )

  getLoggedInUser = () => this.customerDetail().pipe(
    filter(customerDetail => isNotNilAndEmpty(customerDetail)),
    map(customerDetail => customerDetail.myDetail),
  )

  error = () => this.store.select(fromHome.getCustomerViewErrorMessage);
}
