import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import * as fromAuth from './reducers/auth.reducers';
import { AuthService } from './auth.service';
import { LoginStateSelector } from './login/login.selector';
import { AuthEffects } from './auth.effects';
import { AuthActionDispatcher } from './auth.dispatcher';
import { AuthStateSelector } from './auth.selector';
import { AuthTokenInterceptor } from './token.interceptor';
import { SharedModule } from '../shared/shared.module';
import { JwtInterceptor } from './jwt.interceptor';
import { LoginComponent } from './login/login.component';
import { AuthGuardService } from './auth-guard.service';
import { PageHeaderModule } from '../page-header/page-header.module';


@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('auth', fromAuth.reducers),
    EffectsModule.forFeature([AuthEffects]),
    HttpClientModule,
    SharedModule,
    ReactiveFormsModule,
    PageHeaderModule,
  ],
  providers: [
    AuthService,
    LoginStateSelector,
    AuthActionDispatcher,
    AuthStateSelector,
    AuthGuardService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthTokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
  ],
  declarations: [LoginComponent],
})
export class AuthModule { }
