import { NgModule } from '@angular/core';

import { PluralizePipe } from './pluralize.pipe';
import { YesNoPipe } from './yesNo.pipe';
import { JoinArrayPipe } from './join-array.pipe';
import { ApplyFnPipe } from './apply-fn.pipe';
import { JoinTextPipe } from './join-text.pipe';
import { DefaultIfNilPipe } from './default-if-nil.pipe';
import { DefaultIfZeroPipe } from './default-if-zero.pipe';
import { ReplaceNewLinePipe } from './replace-new-line.pipe';
import { ToFixedPipe } from './to-fixed.pipe';
import { MapArrayToPropPipe } from './map-array-to-prop.pipe';
import { NullNonePipe } from './nullNone.pipe';
import { DatePipe } from './date.pipe';
import { LinkPipe } from './link.pipe';
import { DefaultIfNilOrZeroPipe } from './default-if-zero or nil.pipe';
import { OrderPipe } from './order.pipe';

const PIPES = [
  PluralizePipe,
  YesNoPipe,
  NullNonePipe,
  JoinArrayPipe,
  ApplyFnPipe,
  JoinTextPipe,
  DefaultIfNilPipe,
  DefaultIfZeroPipe,
  ReplaceNewLinePipe,
  ToFixedPipe,
  MapArrayToPropPipe,
  DatePipe,
  LinkPipe,
  DefaultIfNilOrZeroPipe,
  OrderPipe,
];
@NgModule({
  declarations: PIPES,
  exports: PIPES,
})
export class SharedModule {

}
