import { createFeatureSelector, createSelector } from '@ngrx/store';

import { Agent, CustomerSummary, ActiveAgent } from '../home/CustomerView';
import { HomeActionTypes, HomeActions } from '../home/home.actions';
import {
  AgentSelectorActions,
  AgentSelectorActionTypes,
  GetAgentRelationships,
  GetAgentRelationshipsForSuccess,
  GetAgentRelationshipsForFailed
} from './agent-selector.actions';
import { ViewStatus } from '../enum';
import { propEq, assoc } from '@aquaonline/ramda';


export interface AgentSelectorState {
  activeAgent?: ActiveAgent;
  agentsFor?: Array<Agent>;
  myDetail: CustomerSummary;
  customerViewStatus: ViewStatus;
  customerErrorMessage?: string;
}

export const initialState: AgentSelectorState = {
  activeAgent: null,
  agentsFor: null,
  myDetail: null,
  customerViewStatus: ViewStatus.Initial,
};

const includeLoggedInUser = (customerSummary: CustomerSummary, agents: Agent[], state: AgentSelectorState) => {
  return customerSummary ?
  [
    { agentFor: customerSummary.customerID,
      agentForName: customerSummary.customerName,
      inquiries: true,
      productionReports: true,
      agentManagement: true,
    } as Agent,
    ...agents
  ] : agents;
};

const setActiveAgent = (customerID: string, name: string) => {
  return {
    agentFor: customerID,
    agentForName: name,
  };
};

const getAgentRelationships = (state: AgentSelectorState, action: GetAgentRelationships): AgentSelectorState => {
  const { customerErrorMessage, ...rest} = state;
  const activeAgent = state.activeAgent ? state.activeAgent :
    (state.myDetail ? setActiveAgent(state.myDetail.customerID, state.myDetail.customerName) : null);
  return {
    ...rest,
    customerViewStatus: ViewStatus.Loading,
    activeAgent: activeAgent,
  };
};

const getAgentRelationshipsForSuccess = (state: AgentSelectorState, action: GetAgentRelationshipsForSuccess): AgentSelectorState => {
  const newAgentRelationships = includeLoggedInUser(state.myDetail, action.payload, state);
  return {
    ...state,
    customerViewStatus: ViewStatus.LoadSuccess,
    agentsFor: newAgentRelationships,
     activeAgent: state.activeAgent ?
       setActiveAgent(state.activeAgent.agentFor, state.activeAgent.agentForName) :
       setActiveAgent(state.myDetail.customerID, state.myDetail.customerName),
  };
};


const getAgentRelationshipsForFailed = (state: AgentSelectorState, action: GetAgentRelationshipsForFailed): AgentSelectorState => ({
  ...state,
  customerViewStatus: ViewStatus.LoadFailed,
  customerErrorMessage: action.payload,
});

export function reducer(state = initialState, action: HomeActions | AgentSelectorActions): AgentSelectorState {
  switch (action.type) {
    case HomeActionTypes.LoadCustomerSuccess:
      const defaultActiveAgent = state.myDetail ? setActiveAgent(state.myDetail.customerID, state.myDetail.customerName) : undefined;

      return {
        activeAgent: defaultActiveAgent,
        agentsFor: action.payload.agentFor,
        myDetail: action.payload.myDetail,
        customerViewStatus: ViewStatus.LoadSuccess,
      };
    case AgentSelectorActionTypes.SetActiveAgent:
      return propEq('payload', state.activeAgent, action) ? state : (assoc('activeAgent', action.payload, state));
    case AgentSelectorActionTypes.GetAgentRelationships:
      return getAgentRelationships(state, action);
    case AgentSelectorActionTypes.GetAgentRelationshipsForSuccess:
      return getAgentRelationshipsForSuccess(state, action);
    case AgentSelectorActionTypes.GetAgentRelationshipsForFailed:
      return getAgentRelationshipsForFailed(state, action);
    case HomeActionTypes.Logout:
      return initialState;
    default:
      return state;
  }
}

const getAgentSelectorState = createFeatureSelector<AgentSelectorState>('agent-selector');

export const getAgentForState = createSelector(getAgentSelectorState, state => state.agentsFor);
export const getMyDetailState = createSelector(getAgentSelectorState, state => state.myDetail);
export const getActiveAgentState = createSelector(getAgentSelectorState, state => state.activeAgent);

