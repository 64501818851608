import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FarmsComponent } from './farms.component';
import { PageHeaderModule } from '../page-header/page-header.module';

@NgModule({
  declarations: [FarmsComponent],
  imports: [
    CommonModule,
    PageHeaderModule,
  ]
})
export class FarmsModule { }
