import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

// import * as fromList from './lease-list/lease-list.reducer';
import * as fromView from './lease-view/lease-view.reducer';

export interface LeaseState {
  // list: fromList.State;
  view: fromView.State;
}

export const reducers: ActionReducerMap<LeaseState> = {
  // list: fromList.reducer,
  view: fromView.reducer,
};

export const getLeaseState = createFeatureSelector<LeaseState>('lease');
