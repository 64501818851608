<div class="container-fluid">
  <ngb-alert *ngIf="error$ | async as error" type="danger" [dismissible]="false">{{ error }}</ngb-alert>
  <form [formGroup]="agentPermissionCreateForm" (submit)="createAgentPermission()">

    <div class="row">
      <div class="col-10">
        <h3>Create New Agent</h3>
        <h5>Appoint a New Agent for Customer {{ selectedAgentName }}</h5>
      </div>
      <div class="col-2">
        <div class="button-container">
          <button type="submit" mat-stroked-button color="primary">Save</button>
        </div>
      </div>
      <label class="col-2 font-weight-bold select-label">Select Customer:</label>
      <div class="col-8">
        <input
          type="text"
          class="form-control"
          formControlName="agentCustomerID"
          [ngbTypeahead]="lookupCustomer"
          [resultFormatter]="formatter"
          [inputFormatter]="inputFormatter"
          (selectItem)="loadCustomer($event)"
          placeholder="Search for customer"
        />
        <span class="form-control-error" *ngIf="showErrors && agentPermissionCreateForm.get('agentCustomerID').hasError('invalidCustomer')">
          Select a customer using typeahead.
        </span>
      </div>
    </div>

    <div class="table-container" *ngIf="agent$ | async as customerSummary">
      <table class="table table-striped">
        <thead>
          <tr>
            <th class="headings">
              <label class="col-4 font-weight-bold">Agent</label>
            </th>
            <th>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="titles">
              <label class="col-4 font-weight-bold">Customer name:</label>
              <label class="col-4">{{ customerSummary.customerName }} </label>
            </td>
            <td>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="table-container" *ngIf="agent$ | async as customerSummary">
      <table class="table table-striped">
        <thead>
          <tr>
            <th class="headings">
              <label class="col-4 font-weight-bold">Assign Permissions</label>
              <span class="col-4">
                <input id="selectAll" type="checkbox" formControlName="selectAll">
              </span>
            </th>
            <th>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="titles">
              <label class="col-4 font-weight-bold">View details:</label>
              <span class="col-4">
                <input id="inquiries" type="checkbox" formControlName="inquiries">
              </span>
            </td>
            <td>
            </td>
          </tr>
          <tr>
            <td class="titles">
              <label class="col-4 font-weight-bold">Submit production returns:</label>
              <span class="col-4">
                <input id="productionReports" type="checkbox" formControlName="productionReports">
              </span>
            </td>
            <td>
            </td>
          </tr>
          <tr>
            <td class="titles">
              <label class="col-4 font-weight-bold">Manage agents (*):</label>
              <span class="col-4">
                <input id="agentManagement" type="checkbox" formControlName="agentManagement">
              </span>
            </td>
            <td>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="col-12" *ngIf="agent$ | async as customerSummary">
      <p class="foot-note">(*) Caution: Permission allows agent to self-manage your delegated functions including sub-delegation to others.</p>
    </div>
  </form>

</div>
