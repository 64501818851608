import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { withLatestFrom, filter, map, tap } from 'rxjs/operators';

import * as fromAccountProfile from './account-profile.reducer';
import { ViewStatus } from '../../enum';
import { equals } from '../../shared/ramda-functions';



@Injectable()
export class AccountProfileStateSelector {
  constructor(
    private store: Store<fromAccountProfile.State>,
  ) {}

  viewStatus = () => this.store.select(fromAccountProfile.getCustomerViewStatus);

  registrationDetails = () => this.store.select(fromAccountProfile.getCustomerDetail)
    .pipe(
      withLatestFrom(this.viewStatus()),
      filter(([_, viewStatus]) => equals(viewStatus, ViewStatus.LoadSuccess)),
      map(([customerDetail]) => customerDetail)
    )

  error = () => this.store.select(fromAccountProfile.getCustomerViewErrorMessage);
}
