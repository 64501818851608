import { TransactionPayment, TransactionPaymentOption } from './transaction-payment';
import { IProductionReturnData } from './production-return-interfaces';

export enum TransactionType {
    AmendCustomer = 'AMEND_CUSTOMER',
    CreateCustomer = 'CREATE_CUSTOMER',
    ManageCustomerFlags = 'MANAGE_CUSTOMER_FLAGS',
    RegisterLeaseMortgageInterests = 'REGISTER_MORTGAGE_INTERESTS',
    DischargeLeaseMortgageInterests = 'DISCHARGE_MORTGAGE_INTERESTS',
    AmendFarm = 'AMEND_FARM',
    CreateFarm = 'CREATE_FARM',
    TransferLease = 'TRANSFER_LEASE',
    AmendLease = 'AMEND_LEASE',
    VaryLease = 'VARY_LEASE',
    ManageLeaseFlags = 'MANAGE_LEASE_FLAGS',
    CreateLease = 'CREATE_LEASE',
    AmendPermit = 'AMEND_PERMIT',
    GrantLease = 'GRANT_LEASE',
    ManagePermitFlags = 'MANAGE_PERMIT_FLAGS',
    CreatePermit = 'CREATE_PERMIT',
    GrantPermit = 'GRANT_PERMIT',
    AssociateLeasePermit = 'ASSOCIATE_LEASE_PERMIT',
    ReissueLease = 'REISSUE_LEASE',
    AssociateFarmPermit = 'ASSOCIATE_FARM_PERMIT',
    ConsolidateLease = 'CONSOLIDATE_LEASE',
    SubdivideLease = 'SUBDIVIDE_LEASE',
    GrantConsolidateLease = 'GRANT_CONSOLIDATE_LEASE',
    RenewLease = 'RENEW_LEASE',
    RenewLeaseBulk = 'BULK_RENEW_LEASE',
    SurrenderLease = 'SURRENDER_LEASE',
    GrantSubdivideLease = 'GRANT_SUBDIVIDE_LEASE',
    GrantRenewLease = 'GRANT_RENEW_LEASE',
    CancelLease = 'CANCEL_LEASE',
    SurrenderPartLease = 'SURRENDER_PART_LEASE',
    GrantSurrenderPartLease = 'GRANT_SURRENDER_PART_LEASE',
    VaryFarmSpeciesPermit = 'VARY_FARM_SPECIES_PERMIT',
    VaryLeaseSpeciesPermit = 'VARY_LEASE_SPECIES_PERMIT',
    VaryFarmAreaPermit = 'VARY_FARM_AREA_PERMIT',
    VaryLeaseAreaPermit = 'VARY_LEASE_AREA_PERMIT',
    BulkExpireLease = 'BULK_EXPIRE_LEASE',
    CancelPermitByHolder = 'CANCEL_PERMIT_BY_HOLDER',
    CancelPermitByDepartment = 'CANCEL_PERMIT_BY_DEPARTMENT',
    ExpireLease = 'EXPIRE_LEASE',
    CleanupSuccessLease = 'CLEANUP_SUCCESS_LEASE',
    CleanupUnsuccessfulLease = 'CLEANUP_UNSUCCESSFUL_LEASE',
    SubLetLease = 'SUBLET_LEASE',
    TransmitLease = 'TRANSMIT_LEASE',
    ChangeLeaseStatus = 'CHANGE_STATUS_LEASE',
    CreateProductionReturn = 'CREATE_PRODUCTION_RETURN',
    AmendProductionReturn = 'AMEND_PRODUCTION_RETURN',
    CreatePermitStandardCondition = 'CREATE_PERMIT_STANDARD_CONDITION',
    CreateLeaseStandardCondition = 'CREATE_LEASE_STANDARD_CONDITION',
    CreateConditionOnPermit = 'CREATE_CONDITION_ON_PERMIT',
    CreateConditionOnLease = 'CREATE_CONDITION_ON_LEASE',
    CreateLeaseSpecificCondition = 'CREATE_LEASE_SPECIFIC_CONDITION',
    CreatePermitSpecificCondition = 'CREATE_PERMIT_SPECIFIC_CONDITION',
    AmendPermitStandardCondition = 'AMEND_PERMIT_STANDARD_CONDITION',
    AmendPermitSpecificCondition = 'AMEND_PERMIT_SPECIFIC_CONDITION',
    AmendConditionOnPermit = 'AMEND_CONDITION_ON_PERMIT',
    AmendLeaseStandardCondition = 'AMEND_LEASE_STANDARD_CONDITION',
    AmendLeaseSpecificCondition = 'AMEND_LEASE_SPECIFIC_CONDITION',
    AmendConditionOnLease = 'AMEND_CONDITION_ON_LEASE',
    DeactivatePermitStandardCondition = 'DEACTIVATE_PERMIT_STANDARD_CONDITION',
    DeactivatePermitSpecificCondition = 'DEACTIVATE_PERMIT_SPECIFIC_CONDITION',
    DeactivateConditionFromPermit = 'DEACTIVATE_CONDITION_FROM_PERMIT',
    DeactivateLeaseStandardCondition = 'DEACTIVATE_LEASE_STANDARD_CONDITION',
    DeactivateLeaseSpecificCondition = 'DEACTIVATE_LEASE_SPECIFIC_CONDITION',
    DeactivateConditionFromLease = 'DEACTIVATE_CONDITION_FROM_LEASE',
    ReprintPermitCertificateCustomer = 'REPRINT_PERMIT_CERTIFICATE_CUSTOMER',
}

export enum TransactionStatus {
    Pending = 'PENDING',
    Approved = 'APPROVED',
    NotApproved = 'NOT_APPROVED',
    Deleted = 'DELETED',
    Granted = 'GRANTED',
    NotGranted = 'NOT GRANTED',
    Accepted = 'ACCEPTED'
}

export enum TransactionInitiatingChannel {
    AquaAssist = 'AquaAssist'
}

export enum TransactionActionType {
    Save = 'save',
    Approve = 'approve',
    NotApprove = 'not-approve',
    Delete = 'delete'
}

export enum TransactionErrorType {
    Preventing = 'PREVENTING',
    Warning = 'WARNING',
}

export enum GrantedStatus {
    Yes = 'YES',
    No = 'NO',
    Both = 'BOTH',
}

export interface TransactionHeader {
    transactionID?: number;
    transactionTypeID: TransactionType;
    transactionStatus: TransactionStatus;
    createdByUsername?: string;
    createdDateTime?: Date;
    lastModifiedByUsername?: string;
    lastModifiedDateTime?: Date;
    initiatingChannel: TransactionInitiatingChannel;
    initiatingTransactionID?: number;
}

export interface TransactionComment {
    comments: string;
    createdByUsername: string;
    createdDateTime?: Date;
}

export interface TransactionError {
    errorType: TransactionErrorType;
    errorDetail: string;
}

export interface TransactionDetailBase {
    transactionID?: number;
    transactionComponentID?: number;
    commentNew?: string;
    component?: any | string;
    payments?: Array<TransactionPayment>;
    paymentOptions?: Array<TransactionPaymentOption>;
    granted?: boolean;
  }

export interface ProductionReturnTransaction extends TransactionDetailBase {
    productionReturnData?: IProductionReturnData;
    detail?: any;
  }

export enum InitiatingGrantTransactions {
    CreatePermit = 'CREATE_PERMIT',
    CreateLease = 'CREATE_LEASE',
    SubdivideLease = 'SUBDIVIDE_LEASE',
    ConsolidateLease = 'CONSOLIDATE_LEASE',
    ReissueLease = 'REISSUE_LEASE',
    SurrenderPartLease = 'SURRENDER_PART_LEASE',
    RenewLease = 'RENEW_LEASE',
}