import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-agent-edit-delete-modal',
  templateUrl: './agent-edit-delete-modal.component.html',
  styleUrls: ['./agent-edit-delete-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AgentEditDeleteModalComponent implements OnInit {

  constructor(
    public modal: NgbActiveModal,
  ) { }

  ngOnInit() {
  }

}
