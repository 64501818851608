import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

import { Species, Estuary, SectionInterface as SectionType, Permit } from '../index';
import { SubSection, Section } from '../enum';
import { isNotNilAndEmpty, isNil, sortBy } from '../../shared/ramda-functions';


@Component({
  selector: 'app-species-modal',
  templateUrl: './species-modal.component.html',
  styleUrls: ['./species-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SpeciesModalComponent implements OnInit, OnDestroy {

  private _species: Array<Species>;
  set species(species: Species[]) {
    this._species = sortBy(x => x.speciesName)(species);
  }
  get species(): Species[] {
    return this._species;
  }

  speciesForm: FormGroup;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @Input() estuary: Estuary;
  @Input() section: SectionType;
  @Input() subSection: SubSection;
  @Input() permit: Permit;
  @Input() selectedSpeciesSectionThree: any;
  @Input() selectedSpeciesSectionFour: any;
  @Input() selectedSpeciesByEstuarySectionThree: any;
  @Input() selectedSpeciesByEstuarySectionFive: any;
  @Input() selectedSpeciesByEstuarySectionSix: any;
  @Input() selectedSpeciesByPermitSectionSeven: any;
  @Input() selectedSpeciesByPermitSectionEight: any;
  speciesIDSubscription$$: Subscription;
  duplicateSpecies: any;
  Section = Section;
  disabled = true;

  constructor(
    public modal: NgbActiveModal,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit() {
    this.removeAlreadySelectedSpeciesFromDropDown();
    this.createForm();
    this.speciesIDSubscription$$ = this.speciesForm.get('speciesID').valueChanges.subscribe(value => {
      if (isNotNilAndEmpty(value)) {
        this.disabled = false;
      }
    });
  }

  private removeAlreadySelectedSpeciesFromDropDown() {
    switch (this.section.secID) {
      case Section.sectionThree: {
        if (Object.keys(this.selectedSpeciesByEstuarySectionThree).length > 0
        && this.selectedSpeciesByEstuarySectionThree[this.estuary.estuaryID]) {
        this.selectedSpeciesByEstuarySectionThree[this.estuary.estuaryID].forEach(value => {
          this.species = this.species.filter(Aspecies => Aspecies.speciesID !== +(value.speciesID));
          });
        }
        break;
      }
      case Section.sectionSix: {
        if (Object.keys(this.selectedSpeciesByEstuarySectionSix).length > 0
        && this.selectedSpeciesByEstuarySectionSix[this.estuary.estuaryID]) {
        this.selectedSpeciesByEstuarySectionSix[this.estuary.estuaryID].forEach(value => {
          this.species = this.species.filter(Aspecies => Aspecies.speciesID !== +(value.speciesID));
        });
        }
        break;
      }
    }
  }

  private createForm() {
    this.speciesForm = this.formBuilder.group({
      speciesID: [],
    });
  }

  findSpeciesName = (speciesID:  string) => {
    return this.species.find(Aspecies => Aspecies.speciesID.toString() === speciesID).speciesName;
  }

  addSpecies = () => {
    if (!this.speciesForm.get('speciesID').value) {
      this.disabled = true;
      return;
    }

    switch (this.section.secID) {
      case this.Section.sectionThree: {
        this.passEntry.emit({
          speciesID: +this.speciesForm.get('speciesID').value,
          speciesName: this.findSpeciesName(this.speciesForm.get('speciesID').value),
          estuaryName: this.estuary.name,
          estuaryID: this.estuary.estuaryID,
          sectionID: this.section.secID,
          rowNumber: this.selectedSpeciesByEstuarySectionThree[this.estuary.estuaryID] ?
            this.selectedSpeciesByEstuarySectionThree[this.estuary.estuaryID].length :
            0,
        });
        this.species = this.species.filter(Aspecies => Aspecies.speciesID !== +this.speciesForm.get('speciesID').value);
        this.modal.close();
        break;
      }
      case this.Section.sectionFour: {
        this.passEntry.emit({
          speciesID: +this.speciesForm.get('speciesID').value,
          speciesName: this.findSpeciesName(this.speciesForm.get('speciesID').value),
          sectionID: this.section.secID,
          rowNumber: this.selectedSpeciesSectionFour.length,
        });
        this.species = this.species.filter(Aspecies => Aspecies.speciesID !== +this.speciesForm.get('speciesID').value);
        this.modal.close();
        break;
      }
      case this.Section.sectionFive: {
        this.passEntry.emit({
          speciesID: +this.speciesForm.get('speciesID').value,
          speciesName: this.findSpeciesName(this.speciesForm.get('speciesID').value),
          estuaryName: this.estuary.name,
          estuaryID: this.estuary.estuaryID,
          sectionID: this.section.secID,
          rowNumber: this.selectedSpeciesByEstuarySectionFive[this.estuary.estuaryID] ?
            this.selectedSpeciesByEstuarySectionFive[this.estuary.estuaryID].length :
            0,
        });
        this.species = this.species.filter(Aspecies => Aspecies.speciesID !== +this.speciesForm.get('speciesID').value);
        this.modal.close();
        break;
      }
      case this.Section.sectionSix: {
        this.passEntry.emit({
          speciesID: +this.speciesForm.get('speciesID').value,
          speciesName: this.findSpeciesName(this.speciesForm.get('speciesID').value),
          estuaryName: this.estuary.name,
          estuaryID: this.estuary.estuaryID,
          sectionID: this.section.secID,
          rowNumber: this.selectedSpeciesByEstuarySectionSix[this.estuary.estuaryID] ?
            this.selectedSpeciesByEstuarySectionSix[this.estuary.estuaryID].length :
            0,
        });
        this.species = this.species.filter(Aspecies => Aspecies.speciesID !== +this.speciesForm.get('speciesID').value);
        this.modal.close();
        break;
      }
      case this.Section.sectionSeven: {
        this.passEntry.emit({
          speciesID: +this.speciesForm.get('speciesID').value,
          speciesName: this.findSpeciesName(this.speciesForm.get('speciesID').value),
          permitClassCode: this.permit.permitClassCode,
          permitID: this.permit.permitID,
          sectionID: this.section.secID,
          rowNumber: this.selectedSpeciesByPermitSectionSeven[this.permit.permitID] ? this.selectedSpeciesByPermitSectionSeven[this.permit.permitID].length : 0,
        });
        this.species = this.species.filter(Aspecies => Aspecies.speciesID !== +this.speciesForm.get('speciesID').value);
        this.modal.close();
        break;
      }
      case this.Section.sectionEight: {
        this.passEntry.emit({
          speciesID: +this.speciesForm.get('speciesID').value,
          speciesName: this.findSpeciesName(this.speciesForm.get('speciesID').value),
          permitClassCode: this.permit.permitClassCode,
          permitID: this.permit.permitID,
          sectionID: this.section.secID,
          rowNumber: this.selectedSpeciesByPermitSectionEight[this.permit.permitID] ? this.selectedSpeciesByPermitSectionEight[this.permit.permitID].length : 0,
        });
        this.species = this.species.filter(Aspecies => Aspecies.speciesID !== +this.speciesForm.get('speciesID').value);
        this.modal.close();
        break;
      }
    }
  }

  ngOnDestroy(): void {
    if (!isNil(this.speciesIDSubscription$$)) {
      this.speciesIDSubscription$$.unsubscribe();
    }
  }

}
