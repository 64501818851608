import { createFeatureSelector, createSelector } from '@ngrx/store';

import { Breadcrumb } from './Breadcrumb';
import { BreadcrumbActions, BreadcrumbActionTypes } from './breadcrumb.actions';
import { equals } from '../shared/ramda-functions';

export interface State {
  items: Array<Breadcrumb>;
}

export const initialState: State = {
  items: [{
    label: 'Home',
    url: '/home'
  }]
};

const insertIfNotExists = (state: State, breadcrumb: Breadcrumb) => {
  const index = state.items.findIndex(item => equals(item.label, breadcrumb.label));
  return {
    items:  [
      ...(equals(index, -1) ? state.items : state.items.slice(0, index)),
      breadcrumb
    ],
  };
};

export function reducer(state = initialState, action: BreadcrumbActions): State {
  switch (action.type) {

    case BreadcrumbActionTypes.Add:
      return insertIfNotExists(state, action.payload);

    case BreadcrumbActionTypes.Clear:
      return initialState;

    default:
      return state;
  }
}

export const getBreadcrumbState = createFeatureSelector<State>('breadcrumb');

export const getBreadcrumbItems = createSelector(getBreadcrumbState, state => state.items);


