<div class="table-container" *ngIf="customerDetail$ | async as customerDetail">
  <table class="table table-striped">
    <thead>
      <tr>
        <th class="headings" colspan="2">
          My Account Summary
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td class="titles">
          Customer No
        </td>
        <td>
          <a [routerLink]="['/account-profile']">
            {{customerDetail.myDetail.customerName}}
            ({{customerDetail.myDetail.customerID}})
          </a>
        </td>
      </tr>
      <tr>
        <td class="titles">
          Permits ({{permits?.length | defaultIfNil: 0}})
        </td>
        <td>
          <app-collapsible-horizontal [items]="{id:'permit', items:permits}"></app-collapsible-horizontal>
        </td>
      </tr>
      <tr>
        <td class="titles">
          Leases ({{leases?.length | defaultIfNil: 0}})
        </td>
        <td>
          <app-collapsible-horizontal [items]="{id:'lease', items:leases}"></app-collapsible-horizontal>
        </td>
      </tr>
      <tr>
        <td class="titles">
          Agent for ({{agents?.length | defaultIfNil: 0}})
        </td>
        <td>
          <app-collapsible-horizontal [showAsLink]="false" [items]="{id:'agents', items:agents}" [allShown] = "true"></app-collapsible-horizontal>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<div class="col-12">
  <p class="foot-note">
    Any leases and/or permits listed on this page relate to the user and/or a customer the user is an agent for.
  </p>
</div>
<h3 class="title">Pending Production Returns</h3>
<div class="pendig-container" *ngIf="pendingProductionReturns$ | async as pendingProductionReturns">
  <table class="table table-striped">
    <thead>
      <tr>
        <th>
          Year
        </th>
        <th>
          Permit no
        </th>
        <th>
          Customer no
        </th>
        <th>
          Customer name
        </th>
        <th>
          Action
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let pr of pendingProductionReturns">
        <td>
          {{ pr.financialYear }}
        </td>
        <td>
          <span *ngFor="let permitID of pr.permits; let i = index;">
            <span *ngIf="i < pr.permits.length - 1">
              {{permitID}},
            </span>
            <span *ngIf="i === pr.permits.length - 1">
              {{permitID}}
            </span>
          </span>
        </td>
        <td>
          {{ pr.customerID }}
        </td>
        <td>
          {{ pr.customerName }}
        </td>
        <td>
            <a class="link" (click) = "editProductionReturn(pr.customerID, pr.transactionID)">
            Complete Return
          </a>
        </td>
      </tr>
      <tr *ngIf="pendingProductionReturns.length === 0">
        <td [colSpan]="8">
            No pending production returns available.
        </td>
      </tr>
    </tbody>
  </table>
</div>


