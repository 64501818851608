import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'order'
})
export class OrderPipe implements PipeTransform {
  transform = (item: any, fn) => {
    const collator = new Intl.Collator(undefined, {numeric: true, sensitivity: 'base'});
    const compare = ( a, b ) => {
      if ( a[fn] < b[fn] ) {
        return -1;
      }
      if ( a[fn] > b[fn] ) {
        return 1;
      }
      return 0;
    };
    return fn ? item.sort(compare) : item.sort(collator.compare);
  }
}
