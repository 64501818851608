import * as R from 'ramda';

export const {
  includes,
  isNil,
  equals,
  has,
  isEmpty,
  both,
  either,
  lensProp,
  lensPath,
  view,
  set,
  over,
  add,
  multiply,
  toPairs,
  type,
  gt,
  ifElse,
  ascend,
  descend,
  sortWith,
  sortBy,
  prop,
  propOr,
  path,
  pathOr,
  complement,
  reject,
  uniq,
  or,
  and,
  __,
  is,
  pipe,
  filter,
  indexBy,
} = R;

export const createLensProp = (val: string): any => {
  return lensProp(val);
};

const isStringNullOrNil = (val: string): boolean => equals('null', val) || equals('undefined', val)  || isNil(val);
export const isNilOrEmpty = either(isStringNullOrNil, isEmpty);
export const isNotNilAndEmpty = complement(isNilOrEmpty);

export const toNumber = (val: string): number => {
  console.log('toNumber: %s, %s', val, isNilOrEmpty(val));
  return isNilOrEmpty(val) ? null : +val;
};

export const removeNilsFromObject = (obj: any) => reject(isNil, obj);


