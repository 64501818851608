import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { NavigationExtras, Router } from '@angular/router';

import { UserProfileStateSelector } from '../user-profile/user-profile.selector';
import { UserProfile } from '../user-profile/UserProfile';
import { HomeActionDispatcher } from './home.dispatcher';
import { HomeStateSelector } from './home.selector';
import { CustomerDetail } from './CustomerView';
import { TransactionStatuses } from '../production-return-list/enum';
import { PermitStatuses } from '../permit/enum';
import { ProductionReturnListService } from '../production-return-list/production-return-list.service';
import { ProductionReturn } from '../production-return-list';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  userProfile$: Observable<UserProfile>;
  lastLoggedIn$: Observable<Date>;
  customerDetail$: Observable<CustomerDetail>;
  pendingProductionReturns$: Observable<ProductionReturn[]>;
  TransactionStatuses = TransactionStatuses;
  morePermits = false;
  moreFarms = false;
  moreLeases = false;
  leases = [];
  permits = [];
  farms = [];
  agents = [];

  constructor(
    private userProfileStateSelector: UserProfileStateSelector,
    private homeActionDispatcher: HomeActionDispatcher,
    private homeStateSelector: HomeStateSelector,
    private productionReturnListService: ProductionReturnListService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.userProfile$ = this.userProfileStateSelector.userProfile();

    this.lastLoggedIn$ =  this.userProfileStateSelector.lastLoggedIn();

    this.homeActionDispatcher.loadCustomer();

    this.receiveProductionReturns();

    this.customerDetail$ = this.homeStateSelector.customerDetail().pipe(
      tap(customerDetail => {
        customerDetail.permits.forEach(permit => {
          if (permit.permitStatusID === PermitStatuses.Current) {
            this.permits.push(permit.permitID);
          }
        });
        customerDetail.farms.forEach(farm => {
          this.farms.push(farm.farmID);
        });
        customerDetail.leases.forEach(lease => {
          this.leases.push(lease.leaseID);
        });
        customerDetail.agentFor.forEach(agent => {
          this.agents.push(`${agent.agentFor} - ${agent.agentForName}`);
        });
      })
    );
  }

  private receiveProductionReturns() {
    this.pendingProductionReturns$ = this.productionReturnListService.getProductionReturnList().pipe(
      map(prs => prs.filter(pr => pr.pending))
    );

    this.pendingProductionReturns$ .subscribe(x =>  console.log(x));
  }

  editProductionReturn = (customerID: string, transactionID: number) => {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        customerID: customerID,
        transactionID: transactionID,
      }
    };
    this.router.navigate(['/production-return/edit'], navigationExtras);
  }
}
