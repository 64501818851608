<div class="modal-header">
  <h4 class="modal-title">Submit Confirmation</h4>
  <button type="button" class="close" aria-label="Close" (click)="modal.close('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  By pressing Confirm you certify the information you recorded is a true and accurate record. If you need to correct this information, please click Cancel and review the report content.<br> 
  Information collected is subject to the Privacy and Personal Information Protection Act 1998. It may be stored and used in accordance with this 
  <a href="https://www.dpi.nsw.gov.au/fishing/aquaculture/forms/privacy-collection-notices" target="blank">Privacy Collection Notice</a>.
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-light" (click)="cancel()" ngbAutofocus>Cancel</button>
  <button type="button" class="btn btn-primary" (click)="confirm()">Confirm</button>
</div>
