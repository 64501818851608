import { Pipe, PipeTransform } from '@angular/core';

/**
 * Usages:
 *  - {{ isPrimary | yesNo }}
 *  - {{ isPrimary | yesNo: 'Yes' : '' }}
 */
@Pipe({
  name: 'nullNone'
})
export class NullNonePipe implements PipeTransform {
  transform(condition: boolean, noneString = 'None'): string | boolean {
    return condition ? condition : noneString;
  }
}
