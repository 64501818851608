export enum SortOrder {
  Ascending = 'ASC',
  Descending = 'DESC',
}

export enum ColumnLinkType {
  Link = 'LINK',
  TransactionLink = 'TRANSACTION_LINK',
  InitiatingTransactionLink = 'INITIATING_TRANSACTION_LINK',
  LinkWithFn = 'LINK_WITH_FN',
  OutputRow = 'OUTPUT_ROW',
  Edit = 'EDIT',
  Delete = 'DELETE',
  Print = 'PRINT',
  Checkbox = 'CHECKBOX',
  CompositeLink = 'COMPOSITE_LINK',
  Download = 'DOWNLOAD',
  ConditionalLink = 'CONDITIONAL_LINK'
}

export interface SortMeta {
  column: string;
  order: SortOrder;
}

export interface ColumnMeta {
  id?: string;
  label?: string;
  visible: boolean;
  sortable: boolean;
  resultProperty?: string;
  link?: ColumnLink;
  sortColumnName?: string;
  displayFn?: (item: any) => string;
  isLinkActiveFn?: (item: any) => boolean;
  clickFn?: any;
  columnLinkType?: ColumnLinkType;
  styleClass?: string;
  icon?: {
    name: string,
    condition: string,
  };
  defaultDisplayColumn?: boolean;
  linkColumn?: string;
  textColumn?: string;
  linkToView?: string;
  order?: number;
  fieldPermittedRoles?: Array<string>;
  conditionReferenceColumn?: string;
  conditionReferenceValue?: PrintTypeID;
}

export interface ServiceSideColumn {
  id: string;
  label: string;
  defaultDisplayColumn: boolean;
}

export interface ColumnLink {
  url: string;
  variableName?: string;
}

export interface SearchResult<T> {
  totalCount: number;
  results: T[];
}

export interface SearchReference {
  columns: Array<ColumnMeta>;
}

export interface Field {
  name: string;
  isRequired?: boolean;
  isReadonly?: boolean;
  isTypeahead?: boolean;
}

export enum PrintTypeID {
  LeaseCondition = 'LEASE_CONDITION',
  LeaseDeedDocument = 'LEASE_DEED_DOCUMENT',
  PermitCertificate = 'PERMIT_CERTIFICATE',
  ProductionReturn = 'PRODUCTION_RETURN'
}