
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';

import { isIE } from './shared/is-ie';
import { equals } from './shared/ramda-functions';


@Injectable()
export class IECacheInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (!isIE() || !equals(req.method, 'GET')) {
      return next.handle(req);
    }

    const params = req.params.append('date', `${new Date().getTime()}`);
    const httpRequest = req.clone({
      params,
    });
    return next.handle(httpRequest);
  }
}
