<div class="modal-header">
  <h4 class="modal-title">Confirm Clear</h4>
  <button type="button" class="close" aria-label="Close" (click)="modal.close('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <h4>Are you sure you want to clear the production return form? All data will be discarded.</h4>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-light" (click)="modal.close('Close click')" ngbAutofocus>Cancel</button>
  <button type="button" class="btn btn-primary" (click)="confirm()">Confirm</button>
</div>