import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ChangePasswordComponent } from './change-password.component';

const routes: Routes = [{
  path: '',
  component: ChangePasswordComponent,
  data: {
    breadcrumb: {
      label: 'Change Password',
      reset: true,
    }
  }
},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ChangePasswordRoutingModule { }
