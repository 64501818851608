import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { handleHttpErrorMessage } from '../../shared/handleHttpErrorMessage';


@Injectable({
  providedIn: 'root',
})
export class AccountProfileService {

  constructor(
    private http: HttpClient,
  ) { }

  getUser = (customerID: string): Observable<any> => {
    return this.http.get<any>(`${environment.apiUrl}/user/profile/${customerID}`)
    .pipe(
      catchError((error: HttpErrorResponse) =>
        throwError(handleHttpErrorMessage(error, `Unable to find the customer`))
      )
    );
  }

}
