import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { environment } from '../../environments/environment';
import { handleHttpErrorMessage } from '../shared/handleHttpErrorMessage';
import { ProductionReturnTransaction, TransactionDetailWithType } from '.';
import { SubSection } from './enum';
import { NilProductionModalComponent } from './nil-production-modal/nil-production-modal.component';
import { ExitModalComponent } from './exit-modal/exit-modal.component';
import { Transaction } from '../search-results';
import { transformPayload } from './transform-payload';
import { Action } from './enum';

@Injectable({
  providedIn: 'root'
})
export class ProductionReturnWebappDirectService {
  SubSection = SubSection;
  removeSubject = new Subject<any>();
  constructor(
    private http: HttpClient,
    private modalService: NgbModal,
    ) { }

  getProductionReturn = (transactionID: number, agentForID?: string): Observable<ProductionReturnTransaction> => {
    return this.http.get<ProductionReturnTransaction>(`${environment.apiUrl}/production-return/${transactionID}/${agentForID}`)
    .pipe(
      catchError((error: HttpErrorResponse) =>
        throwError(handleHttpErrorMessage(error, `Unable to get production return`))
      )
    );
  }

  viewProductionReturn = (productionReturnID: number, agentForID?: string): Observable<ProductionReturnTransaction> => {
    return this.http.get<ProductionReturnTransaction>(`${environment.apiUrl}/production-return/view/${productionReturnID}/${agentForID}`)
    .pipe(
      catchError((error: HttpErrorResponse) =>
        throwError(handleHttpErrorMessage(error, `Unable to get production return`))
      )
    );
  }

  openNilProductionReturnModal = (action: Action) => {
    const modalRef = this.modalService.open(NilProductionModalComponent,
      { size: 'lg', windowClass: 'production-return-modal', backdrop: 'static' });
    modalRef.componentInstance.action = action;
    return modalRef.componentInstance.passEntry;
  }

  manageExit = (): Observable<any> => {
    const modalRef = this.modalService.open(ExitModalComponent, { size: 'lg', windowClass: 'exit-modal', backdrop: 'static' });
    modalRef.componentInstance.passEntry = {exit: false};
    return modalRef.componentInstance.passEntry;
  }

  save = (transactionDetailWithType: TransactionDetailWithType): Observable<Transaction> => {
    const { transactionType, transactionDetail } = transactionDetailWithType;
    const payload = transformPayload(transactionDetail);
    return this.http.put<Transaction>(`${environment.apiUrl}/production-return/save/${transactionDetail.transactionID}`
    , payload)
    .pipe(
      catchError((error: HttpErrorResponse) => {
          return throwError(handleHttpErrorMessage(error, `Unable to save production return`));
        }
      )
    );
  }

  submit = (transactionDetailWithType: TransactionDetailWithType): Observable<Transaction> => {
    const { transactionType, transactionDetail } = transactionDetailWithType;
    const payload = transformPayload(transactionDetail);
    return this.http.put<Transaction>(`${environment.apiUrl}/production-return/submit/${transactionDetail.transactionID}`
    , payload)
    .pipe(
      catchError((error: HttpErrorResponse) => {
          return throwError(handleHttpErrorMessage(error, `Unable to submit production return`));
        }
      )
    );
  }
}
