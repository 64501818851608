import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { PasswordPayload, NewPasswordResult } from '../auth/auth';
import { LoginMessages } from '../auth/login/loginMessages.enum';

@Injectable()
export class ChangePasswordService {
  constructor(
    private http: HttpClient
  ) {}

  changePassword = (password: PasswordPayload): Observable<NewPasswordResult> => {
    return this.http.post<NewPasswordResult>(`${environment.apiUrl}/password/change-password`, password)
      .pipe(
        catchError(error => {
          switch (error.status) {
            case 401:
                throw new Error(LoginMessages.invalidOldPasswordError);
            case 412:
                throw new Error(LoginMessages.invalidOldPasswordError);
            case 409:
                throw new Error(LoginMessages.forgotPasswordUsedPasswordError);
            default:
                throw new Error(LoginMessages.unknownError);
          }
        })
    );
  }
}
