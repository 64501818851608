import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';

import * as fromAgentsTable from './agents-table.reducer';

@Injectable()
export class AgentTableStateSelector {
  constructor(
    private store: Store<fromAgentsTable.State>,
  ) {}

  getAgentRelationshipsFor = () => this.store.select(fromAgentsTable.getAgentRelationshipsFor).pipe(
    filter(agentRelationships => !!agentRelationships),
  )

  error = () => this.store.select(fromAgentsTable.getCustomerViewErrorMessage);
}
