import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ProductionReturnViewComponent } from './production-return-view.component';
import { SharedModule } from '../shared/shared.module';
import { ProductionReturnViewRoutingModule } from './production-return-view-routing.module';
import { ProductionReturnExitModule } from './production-return-exit/production-return-exit.module';

@NgModule({
  declarations: [ProductionReturnViewComponent],
  exports: [ProductionReturnViewComponent],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    NgbModule,
    ProductionReturnViewRoutingModule,
    ProductionReturnExitModule,
  ],
})
export class ProductionReturnViewModule { }
