import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

import { AuthStateSelector } from './auth.selector';
import { isNil } from '../shared/ramda-functions';
import { environment } from '../../environments/environment';

@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {

  static addAuthHeader(request: HttpRequest<any>, token: string): HttpRequest<any> {
    if (isNil(token)) {
      return request;
    }

    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  intercept = (request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>  =>
    (
      [ environment.apiUrl ]
        .some(url => request.url.includes(url)) ?
        this.addTokenToRequest(request) :
        of(request)
    )
    .pipe(
      switchMap(updatedRequest => next.handle(updatedRequest)),
    )

  constructor(
    public authStateSelector: AuthStateSelector,
  ) {}

  private addTokenToRequest = (request: HttpRequest<any>): Observable<HttpRequest<any>> =>
    this.authStateSelector.token()
      .pipe(
        take(1),
        map(token => AuthTokenInterceptor.addAuthHeader(request, token))
      )
}
