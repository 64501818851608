<div class="modal-header">
  <h4 class="modal-title">Species</h4>
  <button type="button" class="close" aria-label="Close" (click)="modal.close('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="speciesForm" class="row" novalidate>
      <div class="col-sm-6">
        <select class="form-control" id="species" formControlName="speciesID">
          <option value="null" disabled>Select one</option>
          <option *ngFor="let Aspecies of species" [value]="Aspecies.speciesID">{{ Aspecies.speciesName }}</option>
        </select>
      </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-light" (click)="modal.close('Close click')" ngbAutofocus>Close</button>
  <button type="submit" class="btn btn-primary" (click)="addSpecies()" [disabled] = "disabled">Add</button>
</div>
