interface Field {
  name: string;
  isRequired?: boolean;
  isReadonly?: boolean;
  isTypeahead?: boolean;
}
export const ProductionReturnFields: Array<Field> = [
  {
    name: 'questionOne',
  },
  {
    name: 'questionTwo',
  },
  {
    name: 'questionThree',
  },
  {
    name: 'nilProduction',
    isRequired: true,
  }
];
