import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { OptionMeta } from '../search-results';
import { environment } from '../../environments/environment';
import { SearchResult } from '../search-results/index';
import { equals } from './ramda-functions';

@Injectable({
  providedIn: 'root',
})
export class TypeaheadService {
  constructor(
    private http: HttpClient,
  ) {}

  lookup = (endpoint: string, searchString: string, exclude?: string): Observable<Array<OptionMeta>> =>
    this.http.get<SearchResult<OptionMeta>>(`${environment.apiUrl}${endpoint}`, {
      params: {
        keyword: searchString,
      }
    })
    .pipe(
      map(searchResult => searchResult.results),
      map((results) => {
        if (exclude) {
          results = results.filter((result) => result.id !== exclude);
        }
        return equals(results.length, 0) ? [{ id: null, label: 'No items found' }] : results;
      }),
      catchError(error => {
        console.error('error: ' + error);

        return of([{id: null, label: 'Unable to retrieve list of items'}]);
      }),
    )
}
