import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { tap, map, switchMap } from 'rxjs/operators';

import { BasePageComponent } from '../shared/base-page.component';
import { Permissions } from '../enum';
import { Condition } from '../permit-schedule/enum';
import { PermitScheduleStateSelector } from '../permit-schedule/permit-schedule.selector';
import { PermitScheduleService } from '../permit-schedule/permit-schedule.service';
import { PermitPrintData } from '../permit-schedule';
import { PermitScheduleActionDispatcher } from '../permit-schedule/permit-schedule.dispatcher';

@Component({
  selector: 'app-permit-schedule-print',
  templateUrl: './permit-schedule-print.component.html',
  styleUrls: ['./permit-schedule-print.component.scss']
})
export class PermitSchedulePrintComponent extends BasePageComponent implements OnInit, OnDestroy {
  loggedInUserSubscription$$: Subscription;
  getPermitSubscription$$: Subscription;
  permitStandardConditionsSubscription$$: Subscription;
  steams$$: Subscription;
  queryParamsSubscription$$: Subscription;
  permitSchedulePrintDetails$$: Subscription;

  error$: Observable<string>;
  isAgentForAnyone$: Observable<Boolean>;
  permitPrintData$: Observable<PermitPrintData>;

  permitStandardConditions: Array<Condition>;
  agentSelectorPermissionRequired = Permissions.inquiries;
  error: string;
  showError = false;
  loggedInUsername: string;
  customerID: string;
  captionText = '';
  currentDate = new Date();

  permitPrintData = {
    permitHolders: undefined,
    customerNo: undefined,
    addressSections: undefined,
    permits: {
      leaseBased: [],
      farmBased: []
    }
  };

  constructor(
    private permitScheduleStateSelector: PermitScheduleStateSelector,
    private permitScheduleActionDispatcher: PermitScheduleActionDispatcher,
    private permitScheduleService: PermitScheduleService,
    private route: ActivatedRoute,
  ) {
    super();
  }

  ngOnInit() {
    this.selectPermitScheduleDetails();
  }

  selectPermitScheduleDetails = () => {
    this.permitPrintData$ = this.route.queryParams.pipe(
      tap(params => {
        this.customerID = params.customerID;
        this.permitScheduleActionDispatcher.loadPermitSchedule(this.customerID);
      }),
      switchMap(() => this.permitScheduleStateSelector.permitScheduleDetail()),
      map(permitScheduleDetail => this.permitScheduleService.createPermitPrintData(permitScheduleDetail, this.customerID))
    );
  }

  ngOnDestroy() {}
}
