
export enum Section {
    sectionThree = 'OYSTER_PRODUCTION',
    sectionFour = 'SPAT_PRODUCTION',
    sectionFive = 'PEARL_OYSTER_PRODUCTION',
    sectionSix = 'NON_OYSTER_PRODUCTION',
    sectionSeven = 'PERMIT_NON_H_PRODUCTION',
    sectionEight = 'PERMIT_H_PRODUCTION',
  }
  
  export enum SubSection {
      oysterSold = 'OYSTER_SOLD',
      farmGatePriceAvg = 'FARM_GATE_PRICE_AVERAGE',
      farmStockSrc = 'FARM_STOCK_SOURCE',
  }
  
  export enum SpeciesGroup {
      Finfish = 'FINFISH',
      Other = 'OTHER',
      Molluscs = 'MOLLUSCS',
      Crustaceans = 'CRUSTACEANS',
  }
  
  export enum SpeciesProductionGroup {
      Ornamental = 'ORNAMENTAL',
      Lease = 'LEASE',
      Pearl = 'PEARL',
      Oyster = 'OYSTER',
  }
  
  export enum Habitat {
      Freshwater = 'FRESHWATER',
      Marine = 'MARINE',
  }
  
  export enum Endemic {
      NonIndigenous = 'NONINDIGENOUS',
      Exotic = 'EXOTIC',
      Indigenous = 'INDIGENOUS',
  }
  
  
  export enum SpeciesType {
      SydneyRockOyster = 'SYDNEY_ROCK_OYSTER',
      PacificOyster = 'PACIFIC_OYSTER',
      NativeOyster = 'NATIVE_OYSTER',
      OysterSpat = 'OYSTER_SPAT'
  }
  
  export enum PermitClassCodes {
      A = 'A',
      B = 'B',
      C = 'C',
      D = 'D',
      E = 'E',
      F = 'F',
      G = 'G',
      H = 'H'
  }

  export enum ViewTypes {
      Entity = 'Entity',
      Transaction = 'Transaction'
  }
  
  export const FinancialYearStartMonth: number = 7;
  