import { AuthActions, AuthActionTypes } from './auth.actions';

export enum LoggedInStatus {
  loggedIn = 'LOGGEDIN',
  setPassword = 'SETPASSWORD',
  initial = 'INITIAL',
}

export interface State {
  loggedIn: LoggedInStatus;
  token?: string;
  temporaryPassword?: string;
  username?: string;
}

export const initialState: State = {
  loggedIn: LoggedInStatus.initial,

};

export function reducer(state = initialState, action: AuthActions): State {
  switch (action.type) {
    case AuthActionTypes.LoginSuccess:
      return {
        ...state,
        loggedIn: action.payload.temporaryPassword ? LoggedInStatus.setPassword : LoggedInStatus.loggedIn,
        token: action.payload.token,
        temporaryPassword: action.payload.temporaryPassword,
        username: action.payload.username,
      };
    case AuthActionTypes.Logout:
      return initialState;

    default:
      return state;
  }
}

export const getAuthStatus = (state: State) => state;
