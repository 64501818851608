import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter, distinctUntilChanged, take } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';

import { isNotNilAndEmpty, isNil, isNilOrEmpty, indexBy, prop } from '../shared/ramda-functions';
import { InputStructure, InputData, Species } from '../production-return';
import { Section, SubSection, PermitClassCodes } from '../production-return/enum';
import { ProductionReturnWebappDirectService } from '../production-return/production-return-webapp-direct.service';
import { IProductionReturnData } from '@aquaonline/ng-models';
import { path } from '@aquaonline/ramda';
import { addSpeciesAndEstuaryNameToInput, addPermitClassCodeToInput } from '@aquaonline/ng-shared';

@Component({
  selector: 'app-production-return-view',
  templateUrl: './production-return-view.component.html',
  styleUrls: ['./production-return-view.component.scss']
})
export class ProductionReturnViewComponent implements OnInit, OnDestroy {
  transactionSubscription$$: Subscription;
  routeSubscription$$: Subscription;
  inputs;
  containsLeaseBasedPermit: boolean;
  isNotNilProduction = true;
  estuariesObject = {};
  transactionDetail: any;
  Section = Section;
  SubSection = SubSection;
  sectionFourInputs: Array<InputData> = [];
  financialYear: string;
  productionReturnData: Array<InputStructure> = [{
    secID: Section.sectionThree,
    subSections: [{
      subSection: SubSection.oysterSold,
      estuaries: []
    }, {
      subSection: SubSection.farmGatePriceAvg,
      species: [],
    },
    {
      subSection: SubSection.farmStockSrc,
      species: [],
    }]
  },
  {
    secID: Section.sectionFour,
    inputs: [],
  },
  {
    secID: Section.sectionFive,
    estuaries: [],
  },
  {
    secID: Section.sectionSix,
    estuaries: [],
  },
  {
    secID: Section.sectionSeven,
    permits: [],
  },
  {
    secID: Section.sectionEight,
    permits: [],
  }
];

  speciesByID: {[ speciesID: number ]: Species } = {};

  constructor(
    private productionReturnWebappDirectService: ProductionReturnWebappDirectService,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit() {
    this.routeSubscription$$ = this.route.queryParams.subscribe((queryParams: any) => {
      const {productionReturnID, customerID} = queryParams;

      this.transactionSubscription$$ = this.productionReturnWebappDirectService.viewProductionReturn(productionReturnID, customerID).pipe(
        filter(transactionDetail => isNotNilAndEmpty(transactionDetail)),
        take(1),
        distinctUntilChanged(),
        ).subscribe(transactionDetail => {
          this.inputs = transactionDetail.productionReturnData.inputs;
          this.transactionDetail = transactionDetail;
          this.speciesByID = indexBy(prop('speciesID'), transactionDetail.productionReturnData.species);
          this.checkPermitsClasses(this.transactionDetail.productionReturnData);
          this.inputsFormatter(this.inputs, this.transactionDetail.productionReturnData);
          this.financialYearCalc(transactionDetail.detail);
      });
    });
  }

  financialYearCalc = (transactionDetails) => {
    this.financialYear = (transactionDetails.year - 1) + '-' + (transactionDetails.year % 100);
  }

  checkPermitsClasses = (productionReturnData: IProductionReturnData) => {
    this.containsLeaseBasedPermit = path(['permits', 'length'], productionReturnData) ? 
        productionReturnData.permits.some(permit => [PermitClassCodes.A, PermitClassCodes.B].includes(prop('permitClassCode', permit))) : false;
  }

  inputsFormatter = (inputs: InputData[], productionReturnData: IProductionReturnData) => {
    if (isNilOrEmpty(inputs)) {
      return;
    }
    const { species, sections, permits } = productionReturnData;
    inputs.forEach(input => {
      switch (input.secID) {
        case this.Section.sectionThree: {
          const detailedInput = addSpeciesAndEstuaryNameToInput(input, species, sections, Section.sectionThree);
          this.manageSectionThree(detailedInput);
          break;
        }
        case this.Section.sectionFour: {
          this.manageSectionFour(input);
          break;
        }
        case this.Section.sectionFive: {
          const detailedInput = addSpeciesAndEstuaryNameToInput(input, species, sections, Section.sectionFive);
          this.manageSectionFive(detailedInput);
          break;
        }
        case this.Section.sectionSix: {
          const detailedInput = addSpeciesAndEstuaryNameToInput(input, species, sections, Section.sectionSix);
          this.manageSectionSix(detailedInput);
          break;
        }
        case this.Section.sectionSeven: {
          const detailedInput = addSpeciesAndEstuaryNameToInput(input, species, sections, Section.sectionSeven);
          const detailedInputWithPermitClassCode = addPermitClassCodeToInput(detailedInput, permits);
          this.manageSectionSeven(detailedInputWithPermitClassCode);
          break;
        }
        case this.Section.sectionEight: {
          const detailedInput = addSpeciesAndEstuaryNameToInput(input, species, sections, Section.sectionEight);
          const detailedInputWithPermitClassCode = addPermitClassCodeToInput(detailedInput, permits);
          this.manageSectionEight(detailedInputWithPermitClassCode);
          break;
        }
      }
    });
  }

  private manageSectionThree = (input) => {
    switch (input.subsection) {
      case SubSection.oysterSold: {
        const result = this.productionReturnData[0].subSections[0].estuaries.find(value => value.estuaryName === input.estuaryName);
        if (isNilOrEmpty(result)) {
          this.productionReturnData[0].subSections[0].estuaries.push({'estuaryName': input.estuaryName, inputs: [input]});
        } else {
          result.inputs.push(input);
        }
        break;
      }
      case SubSection.farmGatePriceAvg: {
        this.productionReturnData[0].subSections[1].species.push(input);
        break;
      }
      case SubSection.farmStockSrc: {
        this.productionReturnData[0].subSections[2].species.push(input);
        break;
      }
    }
  }

  private manageSectionFour = (input) => {
    this.productionReturnData[1].inputs.push(input);
  }

  private manageSectionFive = (input) => {
  const result = this.productionReturnData[2].estuaries.find(value => value.estuaryName === input.estuaryName);
   if (isNilOrEmpty(result)) {
    this.productionReturnData[2].estuaries.push({'estuaryName': input.estuaryName, inputs: [input]});
   } else {
    result.inputs.push(input);
   }
  }

  private manageSectionSix = (input) => {
    const result = this.productionReturnData[3].estuaries.find(value => value.estuaryName === input.estuaryName);
    if (isNilOrEmpty(result)) {
      this.productionReturnData[3].estuaries.push({'estuaryName': input.estuaryName, inputs: [input]});
    } else {
      result.inputs.push(input);
    }
  }

  private manageSectionSeven = (input) => {
    const result = this.productionReturnData[4].permits.find(value => value.permitID === input.permitID);
      if (isNilOrEmpty(result)) {
      this.productionReturnData[4].permits.push({'permitID': input.permitID, 'permitClassCode': input.permitClassCode, inputs: [input]});
      } else {
      result.inputs.push(input);
      }
    }

  private manageSectionEight = (input) => {
    const result = this.productionReturnData[5].permits.find(value => value.permitID === input.permitID);
      if (isNilOrEmpty(result)) {
      this.productionReturnData[5].permits.push({'permitID': input.permitID, 'permitClassCode': input.permitClassCode, inputs: [input]});
      } else {
      result.inputs.push(input);
      }
    }

  exit = () => {
    this.router.navigate(['./production-return']);
  }

  ngOnDestroy(): void {

    if (!isNil(this.routeSubscription$$)) {
      this.routeSubscription$$.unsubscribe();
    }

    if (!isNil(this.transactionSubscription$$)) {
      this.transactionSubscription$$.unsubscribe();
    }
  }
}
