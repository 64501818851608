import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { TypeaheadService } from '../shared/typeahead.service';

export const lookup = (text$: Observable<string>, endpoint: string, typeaheadService: TypeaheadService, exclude?: string) =>
  text$.pipe(
    debounceTime(300),
    distinctUntilChanged(),
    switchMap(text =>
      text.length >= 3 ?
        typeaheadService.lookup(endpoint, text, exclude) : of([])
    )
  );
