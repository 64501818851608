<nav aria-label="breadcrumb" *ngIf="items$ | async as items">
  <ol class="breadcrumb">
    <li class="breadcrumb-item" *ngFor="let item of items; let last = last" [class.active]="last">
      <ng-container *ngIf="last; else link">
          {{ item.label }}
      </ng-container>
      <ng-template #link>
          <a [routerLink]="item.url" [queryParams]="item.params">{{ item.label }}</a>
      </ng-template>
    </li>
  </ol>
</nav>
