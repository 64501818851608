import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Observable } from 'rxjs';

import { HomeActionDispatcher } from '../home/home.dispatcher';
import { HomeStateSelector } from '../home/home.selector';
import { CustomerDetail } from '../home/CustomerView';
import { UserProfile } from '../user-profile/UserProfile';
import { UserProfileStateSelector } from '../user-profile/user-profile.selector';
import { LoggedInStatus } from '../auth/auth.reducer';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageHeaderComponent implements OnInit, OnChanges {
  @Input() pageTitle = '';
  @Input() capitalise = false;
  @Input() hideExit = false;
  @Input() hideBreadcrumbs = false;
  @Input() loggedIn: LoggedInStatus;
  customerDetail$: Observable<CustomerDetail>;
  userProfile$: Observable<UserProfile>;
  lastLoggedIn$: Observable<Date>;

  constructor(
    private homeActionDispatcher: HomeActionDispatcher,
    private homeStateSelector: HomeStateSelector,
    private userProfileStateSelector: UserProfileStateSelector,
    ) {}

  ngOnInit() {
    this.userProfile$ = this.userProfileStateSelector.userProfile();
    this.lastLoggedIn$ =  this.userProfileStateSelector.lastLoggedIn();
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes);
    if (changes.loggedIn && (changes.loggedIn.currentValue === LoggedInStatus.loggedIn)) {
      this.homeActionDispatcher.loadCustomer();
      this.customerDetail$ = this.homeStateSelector.customerDetail();
    }
  }

}
