import { Action } from '@ngrx/store';

export enum PermitScheduleActionTypes {
  LoadPermitSchedule = '[PermitSchedule] Load Permit Schedule',
  LoadPermitScheduleSuccess = '[PermitSchedule] Load Permit Schedule Success',
  LoadPermitScheduleFailed = '[PermitSchedule] Load Permit Schedule Failed',
}

export class LoadPermitSchedule implements Action {
  readonly type = PermitScheduleActionTypes.LoadPermitSchedule;
  constructor(public payload: string) {}
}

export class LoadPermitScheduleSuccess implements Action {
  readonly type = PermitScheduleActionTypes.LoadPermitScheduleSuccess;
  constructor(public payload: any) {}
}

export class LoadPermitScheduleFailed implements Action {
  readonly type = PermitScheduleActionTypes.LoadPermitScheduleFailed;
  constructor(public payload: string) {}
}

export type PermitScheduleActions = LoadPermitSchedule
| LoadPermitScheduleSuccess
| LoadPermitScheduleFailed;
