import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { RouterModule } from '@angular/router';

import { ProductionReturnListComponent } from './production-return-list.component';
import { PageHeaderModule } from '../page-header/page-header.module';
import { AgentSelectorModule } from '../agent-selector/agent-selector.module';
import { PrTableComponent } from './pr-table/pr-table.component';
import * as fromProductionReturnList from './production-return-list.reducer';
import { ProductionReturnListEffects } from './production-return-list.effects';
import { ProductionReturnListRoutingModule } from './production-return-list-routing.module';

@NgModule({
  declarations: [ProductionReturnListComponent, PrTableComponent],
  imports: [
    CommonModule,
    PageHeaderModule,
    AgentSelectorModule,
    StoreModule.forFeature('production-return-list', fromProductionReturnList.reducer),
    EffectsModule.forFeature([ProductionReturnListEffects]),
    RouterModule,
    ProductionReturnListRoutingModule
  ],
  providers: []
})
export class ProductionReturnListModule { }
