<div class="modal-header">
  <h4 class="modal-title">Information</h4>
  <button type="button" class="close" aria-label="Close" (click)="modal.close('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="table-container">
      <table class="table table-striped">
        <thead>
          <th>Species</th>
          <th>Large</th>
          <th>Medium</th>
          <th>Small</th>
        </thead>
        <tbody>
          <tr>
            <td>
              <label class="font-weight-bold">Sydney Rock Oyster</label>
            </td>
            <td>
              <p>
                &gt; 70mm total length or &gt; 50g whole weight
              </p>
            </td>
            <td>
              <p>
                55 - 70mm total length or 30 - 50g whole weight
              </p>
            </td>
            <td>
              <p>
                &lt; 55m total length or &lt; 30g whole weight
              </p>
          </td> 
          </tr>
          <tr>
            <td>
              <label class="font-weight-bold">Pacific Oyster</label>
            </td>
            <td>
              <p>
                &gt; 85mm total length or &gt; 70g whole weight
              </p>
            </td>
            <td>
              <p>
                70 - 85mm total length or 35 - 70g whole weight
              </p>
            </td>
            <td>
              <p>
                &lt; 70m total length or &lt; 35g whole weight
              </p>
            </td> 
          </tr>
          <tr>
            <td>
              <label class="font-weight-bold">Native Oyster</label>
            </td>
            <td>
              <p>
                &gt; 100mm total length or >100g whole weight
              </p>
            </td>
            <td>
              <p>
                80 - 100mm total length or 70 - 100g whole weight
              </p>
          </td>
            <td>
              <p>
                &lt; 80m total length or &lt; 70g whole weight
              </p>
            </td> 
          </tr>
          <tr>
            <td>
              <label class="font-weight-bold">Oyster Spat</label>
            </td>
            <td>
              <p>
                &gt; 20mm
              </p>
            </td>
            <td>
              <p>
                10 - 20mm
              </p> 
            </td>
            <td>
              <p>
                &lt; 10mm
              </p>
          </td> 
          </tr>
        </tbody>
      </table>
    </div>
  
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="modal.close('Close click')" ngbAutofocus>Ok</button>
</div>
