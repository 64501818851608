import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ReactiveFormsModule } from '@angular/forms';

import { EditAccountProfileComponent } from './edit-account-profile.component';
import { PageHeaderModule } from '../../page-header/page-header.module';
import { EditAccountProfileEffects } from './edit-account-profile.effects';
import * as fromEditAccountProfile from './edit-account-profile.reducer';
import { CustomerViewContactsModule } from '../../customer-view-contacts/customer-view-contacts.module';
import { EditAccountProfileActionDispatcher } from './edit-account-profile.dispatcher';
import { EditAccountProfileStateSelector } from './edit-account-profile.selector';
import { EditAccountProfileService } from './edit-account-profile.service';
import { SharedModule } from '../../shared/shared.module';


@NgModule({
  declarations: [EditAccountProfileComponent],
  imports: [
    CommonModule,
    PageHeaderModule,
    EffectsModule.forFeature([EditAccountProfileEffects]),
    StoreModule.forFeature('edit-account-profile', fromEditAccountProfile.reducer),
    NgbModule,
    CustomerViewContactsModule,
    SharedModule,
    FontAwesomeModule,
    ReactiveFormsModule,
  ],
  providers: [
    EditAccountProfileActionDispatcher,
    EditAccountProfileStateSelector,
    EditAccountProfileService,
  ]
})
export class EditAccountProfileModule { }
