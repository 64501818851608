import {
  ActionReducer,
  ActionReducerMap,
  MetaReducer
} from '@ngrx/store';

import { localStorageSync } from 'ngrx-store-localstorage';

import { environment } from '../../environments/environment';

// tslint:disable-next-line:no-empty-interface
export interface State {

}

// logger - console.log all actions
export function logger(reducer: ActionReducer<State>): ActionReducer<State> {
  return (state: State, action: any): State => {
    const newState = reducer(state, action);
    // tslint:disable-next-line:no-console
    console.debug('Action: %o, state (%d kB) %o', action, Math.trunc(JSON.stringify(newState || {}).length / 1024), newState);

    return newState;
  };
}

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({
    keys: [
      { auth: ['status'] },
      'breadcrumb',
      'admin-reference-tables',
      'user-profile',
    ],
    rehydrate: true,
  })(reducer);
}


export const reducers: ActionReducerMap<State> = {};


export const metaReducers: MetaReducer<State>[] = !environment.production ?
  [logger, localStorageSyncReducer] : [localStorageSyncReducer];
