import { Action } from '@ngrx/store';

import { Agent, ActiveAgent } from '../home/CustomerView';

export enum AgentSelectorActionTypes {
  SetActiveAgent = '[AgentSelector] SetActiveAgent',
  GetAgentRelationships = '[AgentSelector] GetAgentRelationshipsFor',
  GetAgentRelationshipsForSuccess = '[AgentSelector] GetAgentRelationshipsForSuccess',
  GetAgentRelationshipsForFailed = '[AgentSelector] GetAgentRelationshipsForFailed',
}

export class SetActiveAgent implements Action {
  readonly type = AgentSelectorActionTypes.SetActiveAgent;
  constructor(public payload: ActiveAgent) {}
}

export class GetAgentRelationships implements Action {
  readonly type = AgentSelectorActionTypes.GetAgentRelationships;
  constructor() {}
}

export class GetAgentRelationshipsForSuccess implements Action {
  readonly type = AgentSelectorActionTypes.GetAgentRelationshipsForSuccess;
  constructor(public payload: Agent[]) {}
}

export class GetAgentRelationshipsForFailed implements Action {
  readonly type = AgentSelectorActionTypes.GetAgentRelationshipsForFailed;
  constructor(public payload: any) {}
}

export type AgentSelectorActions = SetActiveAgent
 | GetAgentRelationships
 | GetAgentRelationshipsForSuccess
 | GetAgentRelationshipsForFailed;
