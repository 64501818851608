<div class="container-fluid" *ngIf="agentRelationship">
  <ngb-alert *ngIf="error$ | async as error" type="danger" [dismissible]="false">{{ error }}</ngb-alert>
  <form [formGroup]="agentEditForm" (submit)="showUpdatePermissionsModal()">

    <div class="row">
      <div class="col-10">
        <h3>Edit Agent Permission</h3>
        <h5>Permissions For Customer {{ agentRelationship.agentName }}</h5>
      </div>
      <div class="col-2">
        <div class="button-container">
          <button type="submit" mat-stroked-button color="primary">Save</button>
        </div>
      </div>
    </div>

    <div class="table-container">
      <table class="table table-striped">
        <thead>
          <tr>
            <th class="headings">
              <label class="col-4 font-weight-bold">Agent</label>
            </th>
            <th>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="titles">
              <label class="col-4 font-weight-bold">Customer no:</label>
              <label class="col-4">{{ agentRelationship.customerID }} </label>
            </td>
            <td>

            </td>
          </tr>
          <tr>
            <td class="titles">
              <label class="col-4 font-weight-bold">Customer name:</label>
              <label class="col-4">{{ agentRelationship.agentName }} </label>
            </td>
            <td>
            </td>
          </tr>
          <!-- <tr>
            <td class="titles">
              <label class="col-4 font-weight-bold">Preferred contact:</label>
              <label class="col-4">{{ agentRelationship.agentPreferredContactNumber }}</label>
            </td>
            <td>
            </td>
          </tr> -->
        </tbody>
      </table>
    </div>

    <div class="table-container">
      <table class="table table-striped">
        <thead>
          <tr>
            <th class="headings">
              <label class="col-4 font-weight-bold">Permissions</label>
              <span class="col-4">
                <input id="selectAll" type="checkbox" formControlName="selectAll">
              </span>
            </th>
            <th>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="titles">
              <label class="col-4 font-weight-bold">View details:</label>
              <span class="col-4">
                <input id="inquiries" type="checkbox" formControlName="inquiries">
              </span>
            </td>
            <td>
            </td>
          </tr>
          <tr>
            <td class="titles">
              <label class="col-4 font-weight-bold">Submit production returns:</label>
              <span class="col-4">
                <input id="productionReports" type="checkbox" formControlName="productionReports">
              </span>
            </td>
            <td>
            </td>
          </tr>
          <tr>
            <td class="titles">
              <label class="col-4 font-weight-bold">Manage agents (*):</label>
              <span class="col-4">
                <input id="agentManagement" type="checkbox" formControlName="agentManagement">
              </span>
            </td>
            <td>
            </td>
          </tr>
        </tbody>
      </table>
    </div>


    <div class="col-12">
      <p class="foot-note">(*) Caution: Permission allows agent to self-manage your delegated functions including sub-delegation to others.</p>
    </div>
  </form>

</div>
