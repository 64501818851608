import { Pipe, PipeTransform } from '@angular/core';

import { ColumnLink } from '../search-results';
import { isNil } from '../shared/ramda-functions';
@Pipe({
  name: 'link'
})
export class LinkPipe implements PipeTransform {
  transform = (result, link: ColumnLink) => {
    if (isNil(link)) {
      return '';
    }

    return link.url.replace('{variableName}', encodeURIComponent(result[link.variableName]));
  }
}
