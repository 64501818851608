import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-information-modal',
  templateUrl: './information-modal.component.html',
  styleUrls: ['./information-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class InformationModalComponent implements OnInit {
  informationForm: FormGroup;
  duplicateInformation: any;

  constructor(
    public modal: NgbActiveModal,
  ) { }

  ngOnInit() {

  }

}
