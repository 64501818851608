<div class="modal-header">
  <h4 class="modal-title">Confirm Permissions</h4>
  <button type="button" class="close" aria-label="Close" (click)="modal.close('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p>Please confirm the following permissions are correct for {{previousPermissions.agentName}}.</p>
  <div class="col-12">
    <h4>Assigned Permissions</h4>
    <div class="row">
      <ul>
        <li *ngIf="newPermissions.inquiries">View details</li>
        <li *ngIf="newPermissions.productionReports">Submit production returns</li>
        <li *ngIf="newPermissions.agentManagement">Manage agents (*)</li>
        <li class="no-decoration" *ngIf="noPermissionsAssigned">none</li>
      </ul>
    </div>
    <h4>Not Assigned Permissions</h4>
    <div class="row">
      <div>
        <ul>
          <li *ngIf="!newPermissions.inquiries">View details</li>
          <li *ngIf="!newPermissions.productionReports">Submit production returns</li>
          <li *ngIf="!newPermissions.agentManagement">Manage agents (*)</li>
          <li class="no-decoration" *ngIf="allPermissionsAssigned">none</li>
        </ul>
      </div>
    </div>

    <div class="row foot-note">(*) Caution: Permission allows agent to sub-delegate to others.</div>
  </div>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Cancel</button>
  <button type="button" class="btn btn-primary" (click)="modal.close('update')" ngbAutofocus>
    Confirm
  </button>
</div>
