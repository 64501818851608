import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as fromAgentsTable from './agents-table.reducer';
import { GetAgentRelationshipsFor, DeleteAgent } from './agents-table.actions';

@Injectable()
export class AgentsTableDispatcher {

  constructor(private store: Store<fromAgentsTable.State>) {
  }

  getAgentRelationshipsFor = (activeAgentID) => this.store.dispatch(new GetAgentRelationshipsFor(activeAgentID));
  deleteAgent = (agentRelationshipID: number, loggedInUserID: string, activeAgentCustomerID: string, customerID: string) =>
    this.store.dispatch(new DeleteAgent({agentRelationshipID, loggedInUserID, activeAgentCustomerID, customerID}))
}
