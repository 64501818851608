import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ProductionReturn } from '.';
import {
  ProductionReturnListActionTypes,
  LoadProductionReturnList,
  ProductionReturnListActions,
  LoadProductionReturnListSuccess,
  LoadProductionReturnListFailed
} from './production-return-list.actions';
import { ViewStatus } from '../enum';

export interface State {
  productionReturns?: ProductionReturn[];
  viewStatus: ViewStatus;
  errorMessage?: string;
}

export const initialState: State = {
  viewStatus: ViewStatus.Initial
};

const loadProductionReturnList = (state: State, action: LoadProductionReturnList): State => {
  const { errorMessage, ...rest} = state;
  return {
    ...rest,
    viewStatus: ViewStatus.Loading,
  };
};

const loadProductionReturnListSuccess = (state: State, action: LoadProductionReturnListSuccess): State => {
  return {
    ...state,
    viewStatus: ViewStatus.LoadSuccess,
    productionReturns: action.payload,
  };
};

const loadProductionReturnListFailed = (state: State, action: LoadProductionReturnListFailed): State => ({
  ...state,
  viewStatus: ViewStatus.LoadFailed,
  errorMessage: action.payload,
});

export function reducer(state = initialState, action: ProductionReturnListActions): State {
  switch (action.type) {
    case ProductionReturnListActionTypes.LoadProductionReturnList:
      return loadProductionReturnList(state, action);
    case ProductionReturnListActionTypes.LoadProductionReturnListSuccess:
      return loadProductionReturnListSuccess(state, action);
    case ProductionReturnListActionTypes.LoadProductionReturnListFailed:
      return loadProductionReturnListFailed(state, action);
    default:
      return state;
  }
}

const getAgentsTableState = createFeatureSelector<State>('production-return-list');
export const getProductionReturnList = createSelector(getAgentsTableState, state => state.productionReturns);
export const getViewStatus = createSelector(getAgentsTableState, state => state.viewStatus);
export const getErrorMessage = createSelector(getAgentsTableState, state => state.errorMessage);
