<ng-container *ngIf="transactionDetail">

<app-production-return-exit (clickListener) = "exit()"></app-production-return-exit>

<p class="title">Production return year {{financialYear}}</p>
<ngb-alert *ngIf="transactionDetail?.header.transactionTypeID === 'AMEND_PRODUCTION_RETURN'" type="info" [dismissible]="false">
  Production data has been amended. Contact Aquaculture Administration for further information.
</ngb-alert>

<!-- Section 1 -->

  <div class="row section">
    <div class="table-containers">
      <table class="table table-striped">
        <thead>
          <tr>
            <th [colSpan]="4" class="headings">
              Permit Holder Details
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="titles">
              <p class="table-data">Permit holder</p>
            </td>
            <td>
              <p class="table-data">{{transactionDetail.productionReturnData.customerName}} - {{transactionDetail.productionReturnData.customerID}}</p>
            </td>
            <td class="titles">
              <p class="table-data">Postal address</p>
            </td>
            <td>
              <p class="table-data">{{transactionDetail.productionReturnData.customerContact}}</p>
            </td>
          </tr>
          <tr>
            <td class="titles">
              <p class="table-data">Permits</p>
            </td>
            <td [colSpan]="4">
              <div class="table-data">
                <span *ngFor="let permit of transactionDetail.productionReturnData.permits; let i = index;">
                  <span *ngIf="i !== transactionDetail.productionReturnData.permits.length - 1">
                    {{ permit.permitID}} - Class: {{permit.permitClassCode}},
                  </span>
                  <span *ngIf="i === transactionDetail.productionReturnData.permits.length - 1">
                    {{ permit.permitID}} - Class: {{permit.permitClassCode}}
                  </span> 
                </span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>  

<!-- section two -->

  <div class="row section">
    <div class="table-containers">
      <table class="table table-striped">
        <thead>
          <tr>
            <th style = "text-align: left" colspan="4">
              Permit holder declaration
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="containsLeaseBasedPermit">
            <td [colSpan]="3"
            class="titles">
                <p class="required text">1. Do you as the permit holder have current Public Liability Insurance (PLI) 
                    as required by your Deed of Undertaking and Acknowledgement?</p>
            </td>
            <td>
              <div class="question-container">
                <div class="question-holder">
                  <div class="question">
                    <label class="col-2">{{transactionDetail.detail.questionOne | yesNo}}</label>
                  </div>
                </div>
              </div>
            </td>
          </tr>
          <tr *ngIf="containsLeaseBasedPermit">
            <td [colSpan]="3"
            class="titles">
              <p class="required text">2. Do you understand that as the permit holder, 
                  you are responsible for ensuring that all leases under your permit 
                  are maintained in a tidy condition and marked in accordance with the 
                  standards described in Chapters 7 & 8 of the NSW Oyster Industry 
                  Sustainable Aquaculture Strategy (OISAS)?</p>
            </td>
            <td>
              <div class="question-container">
                <div class="question-holder">
                  <div class="question">
                    <label class="col-2">{{transactionDetail.detail.questionTwo | yesNo}}</label>
                  </div>
                </div>
              </div>
            </td>
          </tr>
          <tr *ngIf="containsLeaseBasedPermit">
            <td [colSpan]="3"
            class="titles">
              <p class="required text">3. Do you understand that as the permit holder, 
                  the leases under your permit will be regularly inspected by Fisheries 
                  Officers and if any area is found to be non-compliant with OISAS, 
                  a Notice to Comply will be issued under S.162 of the Fisheries 
                  Management Act 1994 and/or clause 55 of the Fisheries 
                  Management (Aquaculture) Regulation 2017, and you will 
                  be responsible for ensuring the lease is brought back to a 
                  compliant state as per the time indicated in that Notice?</p>
            </td>
            <td>
              <div class="question-container">
                <div class="question-holder">
                  <div class="question">
                    <label class="col-2">{{transactionDetail.detail.questionThree | yesNo}}</label>
                  </div>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td [colSpan]="3"
            class="titles">
              <p *ngIf="containsLeaseBasedPermit" class="table-data required">4. Did you have a Nil Production?</p>
              <p *ngIf="!containsLeaseBasedPermit" class="table-data required">Did you have a Nil Production?</p>
            </td>
            <td>
              <div class="question-container">
                <div class="question-holder">
                  <div class="question">
                    <label class="col-2">{{transactionDetail.detail.nilProduction | yesNo}}</label>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>


  <ng-container *ngIf="!transactionDetail.detail.nilProduction">
    <ng-container *ngFor="let section of productionReturnData">
      <div [ngSwitch]="section.secID">
  
        <!--Section Three-->
        <ng-container *ngSwitchCase="Section.sectionThree">
          <div class="row section" *ngIf="section.subSections[0].estuaries.length">
            <div class="col-12">
                <h3>Oyster Production</h3>
            </div>
            <ng-container *ngFor="let subSection of section.subSections">
    
              <!--Section Three, Subsection: OYSTER_SOLD-->
    
              <div *ngIf="subSection.subSection === SubSection.oysterSold">
                <div class="col-12">
                  <h4>Oyster Sold (In DOZENS)</h4>
    
                  <div *ngFor="let estuary of subSection.estuaries" class="estuary-field">
                    <h5>
                      <label class="col-7 font-weight-bold">{{estuary.estuaryName}}</label>
                    </h5>
                    <div class="table-container">
                      <table class="table table-striped">
                        <thead>
                          <th>Species</th> 
                          <th>Large</th>
                          <th>Medium</th>
                          <th>Small</th>
                          <th></th>
                      </thead>
                      <tbody>
                        <ng-container *ngFor="let Aspecies of estuary.inputs">
                          <tr>
                              <td>
                                <p>{{speciesByID[Aspecies.speciesID]?.speciesName}}</p>
                              </td>
                              <td>
                                <p>{{Aspecies.large}}</p>
                              </td>
                              <td>
                                <p>{{Aspecies.medium}}</p>
                              </td>
                              <td>
                                <p>{{Aspecies.small}}</p> 
                              </td>
                              <td></td>
                            </tr> 
                          </ng-container>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
    
              <!--Section Three, Subsection: FARM_GATE_PRICE_AVERAGE-->
    
              <div *ngIf="subSection.subSection === SubSection.farmGatePriceAvg">
                <div class="col-12">
                  <h4>Average Farm Gate Price (In dollars ($))</h4>
    
                  <div class="table-container">
                    <table class="table table-striped percentage">
                      <thead>
                        <th>Species</th>
                        <th>Large</th>
                        <th>Medium</th>
                        <th>Small</th>
                        <th></th>
                    </thead>
                    <tbody>
                      <ng-container *ngFor="let Aspecies of subSection.species">
                        <tr>
                            <td>
                              <p>{{speciesByID[Aspecies.speciesID]?.speciesName}}</p>
                            </td>
                            <td>
                              <p>{{Aspecies.large | number}}</p>
                            </td>
                            <td>
                              <p>{{Aspecies.medium | number}}</p>
                            </td>
                            <td>
                              <p>{{Aspecies.small | number}}</p> 
                            </td>
                            <td></td>
                          </tr> 
                        </ng-container>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
    
              <!--Section Three, Subsection: FARM_STOCK_SOURCE-->
    
              <div *ngIf="subSection.subSection === SubSection.farmStockSrc">
                  <div class="col-12">
                    <h4>Source Of Farm Stock (By percentage (%))</h4>
    
                    <div class="table-container">
                      <table class="table table-striped percentage">
                        <thead>
                          <th>Species</th>
                          <th>Wild caught</th>
                          <th>NSW hatchery / Nursery stock</th>
                          <th>Import from interstate hatchery</th>
                          <th></th>
                      </thead>
                      <tbody>
                        <ng-container *ngFor="let Aspecies of subSection.species">
                          <tr>
                              <td>
                                <p>{{speciesByID[Aspecies.speciesID]?.speciesName}}</p>
                              </td>
                              <td>
                                <p>{{Aspecies.wildCaught}}</p>
                              </td>
                              <td>
                                <p>{{Aspecies.hatchery}}</p>
                              </td>
                              <td>
                                <p>{{Aspecies.import}}</p> 
                              </td>
                              <td></td>
                            </tr> 
                          </ng-container>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
            </ng-container>
          </div>
        </ng-container>

        <!-- Section 4 -->

        <ng-container *ngSwitchCase="Section.sectionFour">
          <div class="row section" *ngIf="section.inputs.length">
            <div class="col-12">
                <h3>Spat Production for Molluscs</h3>
            </div>
            <div class="col-12">
              <div class="table-container">
                <table class="table table-striped">
                  <thead>
                      <th>Species</th>
                      <th>
                        Origin
                      </th>
                      <th>
                        Size
                      </th>
                      <th>
                        Total Quantity (Ind)
                      </th>
                      <th>
                        Total Value ($)
                      </th>
                  </thead>
                  <tbody>
                    <tr *ngFor="let input of section.inputs"> 
                      <td>
                        {{speciesByID[input.speciesID]?.speciesName}}
                      </td>
                      <td>
                        {{input.origin}}
                      </td>
                      <td>
                        {{input.size}}
                      </td>
                      <td>
                        {{input.quantity | number}}
                      </td>
                      <td>
                        {{input.value | number}}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </ng-container>



         <!-- Section 5 -->

        <ng-container  *ngSwitchCase="Section.sectionFive">
          <div class="row section" *ngIf="section.estuaries.length">
            <div class="col-12">
              <h3>Pearl Oyster Production</h3>
            </div>
            <div class="col-12">
              <div class="estuary-field" *ngFor="let estuary of section.estuaries">
                <h5>
                    <label class="col-7 font-weight-bold">{{estuary.estuaryName}}</label>
                </h5>
                <div class="table-container">
                  <table class="table table-striped">
                    <thead>
                      <th>Species</th>
                      <th>
                          Product Type
                      </th>
                      <th>
                          Total Quantity (Ind)
                      </th>
                      <th>
                          Total Value ($)
                      </th>
                    </thead>
                    <tbody>
                      <tr *ngFor="let input of estuary.inputs"> 
                        <td>
                          {{speciesByID[input.speciesID]?.speciesName}}
                        </td>
                        <td>
                          {{input.productType}}
                        </td>
                        <td>
                          {{input.quantity | number}}
                        </td>
                        <td>
                          {{input.value | number}}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>    
            </div>
          </div>
        </ng-container>


        <!-- Section 6 -->

        <ng-container  *ngSwitchCase="Section.sectionSix">
          <div class="row section" *ngIf="section.estuaries.length">
            <div class="col-12 mb-2">
              <h3 class="mb-0">Non Oyster Production</h3>
              <p class="comment">Any other lease-based production excluding oysters.</p>
            </div>
            <div class="col-12">
              <div class="estuary-field" *ngFor="let estuary of section.estuaries">
                <h5>
                  <label class="col-7 font-weight-bold">{{estuary.estuaryName}}</label>
                </h5>
                <div class="table-container">
                  <table class="table table-striped">
                    <thead>
                      <th>Species</th>
                      <th>
                          Total Quantity (Ind)
                      </th>
                      <th>
                          Total Value ($)
                      </th>
                    </thead>
                    <tbody>
                      <tr *ngFor="let input of estuary.inputs"> 
                        <td>
                          {{speciesByID[input.speciesID]?.speciesName}}
                        </td>
                        <td>
                          {{input.quantity | number}}
                        </td>
                        <td>
                          {{input.value | number}}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>    
            </div>
          </div>
        </ng-container>


        <!-- Section 7 -->

        <ng-container  *ngSwitchCase="Section.sectionSeven">
          <div class="row section" *ngIf="section.permits.length">
            <div class="col-12">
              <h3>Production under Class C, D, E and / or F Permits</h3>
            </div>
            <div class="col-12">
              <div class="estuary-field" *ngFor="let permit of section.permits">
                <h5>
                  <label class="col-7 font-weight-bold">{{permit.permitID}} Class {{permit.permitClassCode}}</label>
                </h5>
                <div class="table-container">
                  <table class="table table-striped">
                    <thead>
                        <th>Species</th>
                        <th>
                            Product Type
                        </th>
                        <th>
                            Total Quantity (Kg)
                        </th>
                        <th>
                            Total Value ($)
                        </th>
                    </thead>
                    <tbody>
                      <tr *ngFor="let input of permit.inputs"> 
                        <td>
                          {{speciesByID[input.speciesID]?.speciesName}}
                        </td>
                        <td>
                          {{input.productType}}
                        </td>
                        <td>
                          {{input.quantity | number}}
                        </td>
                        <td>
                          {{input.value | number}}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>    
            </div>
          </div>
        </ng-container>


        <!-- Section 8 -->

        <ng-container  *ngSwitchCase="Section.sectionEight">
          <div class="row section" *ngIf="section.permits.length">
            <div class="col-12">
              <h3>Production under H Permit</h3>
            </div>
            <div class="col-12">
              <div class="estuary-field" *ngFor="let permit of section.permits">
                <h5>
                  <label class="col-7 font-weight-bold">{{permit.permitID}} Class {{permit.permitClassCode}}</label>
                </h5>
                <div class="table-container">
                  <table class="table table-striped">
                    <thead>
                        <th>Species</th>
                        <th>
                            Product Type
                        </th>
                        <th>
                            Total Quantity (Ind)
                        </th>
                        <th>
                            Total Value ($)
                        </th>
                    </thead>
                    <tbody>
                      <tr *ngFor="let input of permit.inputs"> 
                        <td>
                          {{speciesByID[input.speciesID]?.speciesName}}
                        </td>
                        <td>
                          {{input.productType}}
                        </td>
                        <td>
                          {{input.quantity | number}}
                        </td>
                        <td>
                          {{input.value | number}}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>    
            </div>
          </div>
        </ng-container>


        <div *ngSwitchDefault>
          Not implemented
        </div>
      </div>
    </ng-container>
  </ng-container>
  <div class="buttons">
      <app-production-return-exit (clickListener) = "exit()"></app-production-return-exit>
  </div>
 </ng-container>




