import { Component, OnInit, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-submit-modal',
  templateUrl: './submit-modal.component.html',
  styleUrls: ['./submit-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SubmitModalComponent implements OnInit {

  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  constructor(
    public modal: NgbActiveModal,
  ) { }

  ngOnInit() {}

  confirm = () => {
    this.passEntry.emit({
      submitProduction: true
    });
    this.modal.close();
  }

  cancel = () => {
    this.passEntry.emit({
      submitProduction: false
    });
    this.modal.close();
  }

}
