export enum Permissions {
  inquiries = 'inquiries',
  productionReports = 'productionReports',
  agentManagement = 'agentManagement',
}

export enum SearchStatus {
  Initial = 'Initial',
  Searching = 'Searching',
  SearchSuccess = 'Search Success',
  SearchFailed = 'Search Failed'
}

export enum ViewStatus {
  Initial = 'Initial',
  Loading = 'Loading',
  LoadSuccess = 'Load Success',
  LoadFailed = 'Load Failed'
}

export enum SortOrder {
  Ascending = 'ASC',
  Descending = 'DESC',
}

export enum ColumnLinkType {
  Link = 'LINK',
  TransactionLink = 'TRANSACTION_LINK',
  InitiatingTransactionLink = 'INITIATING_TRANSACTION_LINK',
  LinkWithFn = 'LINK_WITH_FN',
  OutputRow = 'OUTPUT_ROW',
}

export enum GrantTransactionTypes {
  GrantLease = 'GRANT_LEASE',
  GrantPermit = 'GRANT_PERMIT',
  GrantConsolidateLease = 'GRANT_CONSOLIDATE_LEASE',
  GrantSubdivideLease = 'GRANT_SUBDIVIDE_LEASE',
  GrantRenewLease = 'GRANT_RENEW_LEASE',
  GrantSurrenderPartLease = 'GRANT_SURRENDER_PART_LEASE',
}

export enum DeviceSize {
  smallMobileWidth = 600,
  largeMobileMaxWidth = 1030,
  largeMobileMinWidth = 600,
}