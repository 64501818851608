import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { AgentSelectorComponent } from './agent-selector.component';
import * as fromAgentSelector from './agent-selector.reducers';
import { AgentSelectorStateSelector } from './agent-selector.selector';
import { AgentsSelectorEffects } from './agent-selector.effects';

@NgModule({
  declarations: [AgentSelectorComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    StoreModule.forFeature('agent-selector', fromAgentSelector.reducer),
    EffectsModule.forFeature([AgentsSelectorEffects]),
  ],
  providers: [
    AgentSelectorStateSelector,
    AgentsSelectorEffects,
  ],
  exports: [
    AgentSelectorComponent
  ]
})
export class AgentSelectorModule { }
