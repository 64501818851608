import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { EditPermissionsModalComponent } from './edit-permissions-modal.component';

@NgModule({
  declarations: [EditPermissionsModalComponent],
  exports: [EditPermissionsModalComponent],
  entryComponents: [EditPermissionsModalComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
  ]
})
export class EditPermissionsModalModule { }
