import { Component, OnInit, Input, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable, Subscription } from 'rxjs';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

import { isNotNilAndEmpty } from '../shared/ramda-functions';

@Component({
  selector: 'app-print-frame',
  templateUrl: './print-frame.component.html',
  styleUrls: ['./print-frame.component.scss']
})
export class PrintFrameComponent implements OnInit, OnDestroy {
  @Input() routePath: string;
  @Input() paramKey: string;
  @Input() paramValue: string;
  @ViewChild('iframe', { static: true }) iframeView: ElementRef;
  @Input() print: Observable<boolean>;

  printSubscription$$: Subscription;

  src: SafeUrl;

  constructor(
    private sanitizer: DomSanitizer,
    private ngxLoader: NgxUiLoaderService,
  ) { }

  ngOnInit() {
    const uri1 = `#/${this.routePath}?${this.paramKey}=${this.paramValue}`;
    this.src = this.sanitizer.bypassSecurityTrustResourceUrl(uri1);
    this.listenForPrintEvent();
  }

  ngOnDestroy() {
    if (isNotNilAndEmpty(this.printSubscription$$)) {
      this.printSubscription$$.unsubscribe();
    }
  }

  private listenForPrintEvent() {
    this.printSubscription$$ = this.print.subscribe(() => this.printIframeContent());
  }

  private printIframeContent = () => {
    this.ngxLoader.start();
    setTimeout(() => {
      this.iframeView.nativeElement.contentWindow.print();
      this.ngxLoader.stop();
    }, 500);
  }
}
