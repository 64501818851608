import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  Input,
  OnDestroy,
  ViewChild,
  ChangeDetectorRef,
  EventEmitter,
  Output,
} from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { NgbAccordion } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

import { MenuItem } from './Menu';
import { AuthActionDispatcher } from '../auth/auth.dispatcher';
import { BreadcrumbActionDispatcher } from '../breadcrumb/breadcrumb.dispatcher';
import { equals, isNotNilAndEmpty } from '../shared/ramda-functions';
import { AuthService } from '../auth/auth.service';
import { HomeStateSelector } from '../home/home.selector';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent implements OnInit, OnDestroy {
  @Output() accordianMenuClicked: EventEmitter<string> = new EventEmitter();
  @Input() sidenav;
  routerSubscription$$: Subscription;
  homeSubscription$$: Subscription;
  authSubscription$$: Subscription;
  customerID: string;
  activatedRoute = undefined;
  visible = false;
  panelOpenState = false;
  public isCollapsed = false;
  openSubMenu = false;
  className = '';
  dropDownMenuItem: string;
  @ViewChild('acc', { static: true }) accordionComponent: NgbAccordion;

  items: MenuItem[] = [{label: 'Home', path: '/home', isActive: false},
    {label: 'Permits', path: '/permit', isActive: false} ,
    {label: 'Leases', path: '/lease', isActive: false},
    {label: 'Production Returns', path: '/production-return', isActive: false},
    {label: 'Permit Holder Summary', path: '/permit-holder-summary', isActive: false},
  ];



  constructor(
    private router: Router,
    private authActionDispatcher: AuthActionDispatcher,
    private breadcrumbActionDispatcher: BreadcrumbActionDispatcher,
    private homeStateSelector: HomeStateSelector,
    private route: ActivatedRoute,
    private ref: ChangeDetectorRef,
    private authService: AuthService,
  ) {}

  ngOnInit() {
    this.homeSubscription$$ = this.homeStateSelector.customerDetail().subscribe(detail => {
      this.customerID = detail.myDetail.customerID;
    });
    if (isNotNilAndEmpty(this.router) && this.router.url === '/home') {
      this.activatedRoute = 'Home';
      this.items.find(item => item.label === 'Home').isActive = true;
    }
    this.routerSubscription$$ = this.router
        .events
        .pipe(
          filter(event => event instanceof NavigationEnd),
          map(() => {
            let child = this.route.firstChild;
            while (child) {
              if (child.firstChild) {
                child = child.firstChild;
              } else if (child.snapshot.data && child.snapshot.data['breadcrumb']) {
                return child.snapshot.data['breadcrumb'];
              } else {
                return null;
              }
            }
            return null;
          }),
          filter(activatedRoute => isNotNilAndEmpty(activatedRoute))
        )
        .subscribe( activatedRoute => {
          switch (activatedRoute.label) {
            case 'Manage Agents':
              this.activatedRoute = 'My Account';
              this.dropDownMenuItem = 'Manage Agents';
            break;
            case 'Create New Agent':
              this.activatedRoute = 'My Account';
              this.dropDownMenuItem = 'My Account';
            break;
            case 'Registration Details':
              this.activatedRoute = 'My Account';
              this.dropDownMenuItem = 'Registration Details';
              this.accordionComponent.expandAll();
            break;
            case 'Change Password':
              this.activatedRoute = 'My Account';
              this.dropDownMenuItem = 'Change Password';
              this.accordionComponent.expandAll();
            break;
            case 'Production Return Detail':
              this.activatedRoute = 'Production Returns';
            break;
            case 'Production Return View':
              this.activatedRoute = 'Production Returns';
            break;
            default:
              this.activatedRoute = activatedRoute.label;
            break;
          }

          this.items = this.items.map(item => {
            if (item.label === this.activatedRoute) {
              item.isActive = true;
            } else {
              item.isActive = false;
            }
            return {
              ...item
            };
          });
          this.ref.markForCheck();
       });
   }

   toggleIcon() {
     this.openSubMenu = !this.openSubMenu;
     this.accordianMenuClicked.emit('clicked');
   }

   defineClass(menuItemLabel) {
     return menuItemLabel === this.activatedRoute ? 'active' : null;
   }

   defineDropDownClass(menuItemLabel) {
    return menuItemLabel === this.dropDownMenuItem ? 'active' : null;
   }

  openLink(item: MenuItem) {
    if (!item.path) {
      return;
    }
    this.accordionComponent.collapseAll();
    this.openSubMenu = false;
    this.dropDownMenuItem = '';
    if (!equals(this.router.url, item.path)) {
      this.breadcrumbActionDispatcher.clear();
    }
    this.router.navigate([item.path]);
  }

  logout = () => {
    this.authSubscription$$ = this.authService.logout(this.customerID).subscribe();
    if (isNotNilAndEmpty(this.sidenav)) {
      this.sidenav.toggle();
    }
    this.authActionDispatcher.logout();
    this.router.navigate(['/login']);
  }

  ngOnDestroy() {
    if (isNotNilAndEmpty(this.homeSubscription$$)) {
      this.homeSubscription$$.unsubscribe();
    }
    if (isNotNilAndEmpty(this.routerSubscription$$)) {
      this.routerSubscription$$.unsubscribe();
    }
    if (isNotNilAndEmpty(this.authSubscription$$)) {
      this.authSubscription$$.unsubscribe();
    }
  }

}
