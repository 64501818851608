import * as R from 'ramda';
import * as RB from 'rambda';

export function ramda(): string {
  return 'ramda';
}

export const {
  contains,
  isNil,
  equals,
  has,
  isEmpty,
  both,
  either,
  lensProp,
  lensPath,
  view,
  set,
  over,
  add,
  multiply,
  toPairs,
  type,
  gt,
  ifElse,
  ascend,
  sortWith,
  sortBy,
  prop,
  propOr,
  path,
  pathOr,
  complement,
  reject,
  uniq,
  or,
  and,
  __,
  propEq,
  curry,
  map,
  when,
  assoc,
  any,
  flatten,
  adjust,
  pluck,
  props,
  filter,
  uniqBy,
  find,
  defaultTo,
  pipe,
  compose,
  pathEq,
  pick,
  dissoc,
  take,
  descend,
  sort,
  omit,
  identity,
  init,
  tail,
  head,
  last,
  length,
  toLower,
  join,
  toUpper,
  concat,
  converge,
  split,
  lt,
  lte,
  gte,
  append,
  values,
  divide,
  keys,
  zipObj,
  propSatisfies,
  assocPath,
  splitEvery,
  forEach,
  includes,
  innerJoin,
  apply,
  indexOf,
  applySpec,
  groupWith,
  slice
} = R;

export const {
  is,
  fromPairs,
  range,
  groupBy,
  sum,
  reduce,
  replace,
  flip,
  negate,
  match,
  always,
  startsWith,
  allPass,
  takeLast,
  merge,
  drop,
  dropLast,
  endsWith,
  max
} = RB;

export const createLensProp = (val: string): any => {
  return lensProp(val);
};

const isStringNullOrNil = (val: string): boolean => equals('null', val) || equals('undefined', val)  || isNil(val);
export const isNilOrEmpty = either(isStringNullOrNil, isEmpty);
export const isNotNilAndEmpty = complement(isNilOrEmpty);

export const toNumber = (val: string): number => {
  console.log('toNumber: %s, %s', val, isNilOrEmpty(val));
  return isNilOrEmpty(val) ? null : +val;
};

export const removeNilsFromObject = (obj: any) => reject(isNil, obj);

export const propOrFalse = propOr(false);

export const propOrNull = propOr(null);

export const propOrZero = propOr(0);

export const joinOuter = R.curry((f1, f2, t1, t2) => {
  const o1 = R.indexBy(f1, t1);
  const o2 = R.indexBy(f2, t2);
  return R.values(R.mergeWith(R.merge, o1, o2));
});

export const joinInner = R.curry((f1, f2, t1, t2) => {
  const indexed = R.indexBy(f1, t1);
  return R.chain((t2row) => {
    const corresponding = indexed[f2(t2row)];
    return corresponding ? [R.merge(t2row, corresponding)] : [];
  }, t2);
});

export const renameKey = curry((oldKey, newKey, obj) =>
  assoc(newKey, prop(oldKey, obj), dissoc(oldKey, obj)));

// https://gist.github.com/mrwithersea/267600718c627ab936001078e3a2a808
export const capitalize =
  converge(
    concat(), [
      compose(
        toUpper,
        head
      ),
      tail,
    ]
  );

export const titleCase =
  compose(
    join(' '),
    map(capitalize),
    split(' '),
    toLower,
  );

  export const isStrInteger = (val: string): boolean => {
    return /^\d+$/.test(val);
  };

  export const uniqBySpeciesId = uniqBy(prop('speciesId'));

  // tslint:disable-next-line: max-func-args
  export const joinRight = R.curry((mapper1, mapper2, t1, t2) => {
    const indexed = R.indexBy(mapper1, t1);

    // @ts-ignore
    return t2.map((t2row) => merge(t2row, indexed[mapper2(t2row)]));
  });

  // tslint:disable-next-line: max-func-args
  export const joinLeft = R.curry((f1, f2, t1, t2) => joinRight(f2, f1, t2, t1));

  // https://github.com/ramda/ramda/wiki/Cookbook#sort-a-list-by-array-of-props-if-first-prop-equivalent-sort-by-second-etc
  const firstTruthy = ([h, ...tail]) => reduce(either, h, tail);
  // @ts-ignore
  const makeComparator = (propName: string) => comparator((a, b) => lt(prop(propName, a), prop(propName, b)));
  // @ts-ignore
  export const sortByProps = curry((args: string[], list) => sort(firstTruthy(map(makeComparator, args)), list));

  export const concatFlipped = flip(concat);

  const exitWithErrorMsg = curry((msg, value) => {
    throw Error(msg);
  })
  const isNotZero = complement(equals(0));
  /**
   * Evaluate a state of a value (the last arg) by calling a function to evaluate (1st arg);
   * present the message (2nd arg) if the evaluation fails and exit with shelljs.exit(1)
   * @type {any}
   */
  const failWhen = curry((toEvaluate, msg, value) => when(toEvaluate, exitWithErrorMsg(msg))(value));
  /**
   * Exit if a value is Nil
   */
  export const failIfNil = failWhen(isNil);
  /**
   * Exit if a value is not 0
   */
  export const failIfNotZero = failWhen(isNotZero);
