import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { switchMapTo, filter, distinctUntilChanged, map, withLatestFrom, switchMap, tap, debounceTime } from 'rxjs/operators';
import { Router, ActivatedRoute, NavigationEnd, ActivationEnd } from '@angular/router';

import { RootActionType, BootstrapApp } from '../actions';
import { isNil } from '../shared/ramda-functions';
import { Breadcrumb } from './Breadcrumb';
import { ClearBreadcrumb, AddBreadcrumb } from './breadcrumb.actions';
import { getCurrentPathWithoutParams } from '../shared/getCurrentPathWithoutParams';
import { getBreadCrumbForActivatedRoute } from './getBreadCrumbForActivatedRoute';

@Injectable()
export class BreadcrumbEffects {
  @Effect()
  routeChange$ = this.actions$
    .pipe(
      ofType<BootstrapApp>(RootActionType.BootstrapApp),
      switchMapTo(this.router.events),
      filter(event => event instanceof ActivationEnd),
      debounceTime(200),
      distinctUntilChanged(),
      map(() => getBreadCrumbForActivatedRoute(this.route.root)),
      filter(breadcrumb => !isNil(breadcrumb)),
      withLatestFrom(this.route.queryParams),
      map(([breadcrumb, params]): Breadcrumb => ({
        ...breadcrumb,
        url: getCurrentPathWithoutParams(this.router),
        params,
      })),
      switchMap(breadcrumb =>
        breadcrumb.reset ? [ new ClearBreadcrumb(), new AddBreadcrumb(breadcrumb) ] : [ new AddBreadcrumb(breadcrumb) ]
      )
    );


  constructor(
    private actions$: Actions,
    private router: Router,
    private route: ActivatedRoute,
  ) {}
}
