
<div class="col-12" *ngIf="hasPermitSchedule$ | async">
  <div class="button-container">
    <button type="button" mat-stroked-button (click)="print.next(true)" color="primary">Print</button> 
    <app-print-frame [routePath]="'permit-holder-summary-print'" [paramKey]="'customerID'" [paramValue]="customerID" [print]="print"></app-print-frame>
  </div>
  <p class="contextual">{{ contextualMessage }}</p>
</div>
<div class="container-fluid">
    <app-agent-selector
      [filterForPermission]="agentSelectorPermissionRequired"
      [label]="'Permit Holder Summary'">
    </app-agent-selector>
</div>
<div *ngIf="permitScheduleDetails$ | async" class="container-fluid">

  <ng-container *ngIf="hasPermitSchedule$ | async; else noCurrentPermits">
     <div *ngIf="captionText">{{ captionText }}</div>
    
    <!-- Permit holder details -->

    <div class="table-container">
      <table class="table table-striped">
        <thead>
          <tr>
            <th class="headings" colspan="2">
              Permit holder details
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="titles">
              Permit holder
            </td>
            <td>
              <span *ngFor="let holder of permitPrintData.permitHolders; index as i">{{ holder }}<br> </span>
            </td>
          </tr>
          <tr>
            <td class="titles">
              Customer No
            </td>
            <td>
              {{ permitPrintData.customerNo }}
            </td>
          </tr>
          <tr>
            <td class="titles">
              Street Address
            </td>
            <td>
              <span *ngFor="let section of permitPrintData.addressSections; index as i">{{ section }}<br> </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </ng-container>

  <ng-template #noCurrentPermits>
    <p class="text-content">Selected customer does not hold any current permits.</p>
  </ng-template>

  <!-- Leased Based -->
  <div *ngFor="let permit of permitPrintData.permits.leaseBased; index as i">
    <br><br>
    <h2>{{ permit.name }}</h2>
    <h3 *ngIf="permit.totalPermitArea">Total Permit Area: {{ permit.totalPermitArea }}</h3>
    <h3>{{ permit.scheduleAbyPermit.name }}</h3>
    <div class="table-container" *ngFor="let table of permit.scheduleAbyPermit.tables">
      <table class="table table-striped" >
        <caption *ngIf="table.estuary" class="estuary-caption">
          Estuary: {{ table.estuary}}
        </caption>
        <thead>
          <tr>
            <th>Lease No</th>
            <th>% of Lease Authorised</th>
            <th>Lease Area (ha)</th>
            <th>Expiry Date</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let lease of table.leases">
            <td>{{ lease.leaseNo }}</td>
            <td>{{ lease.percentageAuthorised }}</td>
            <td>{{ lease.leaseArea }}</td>
            <td>{{ lease.expiryDate | date: 'dd MMM yyyy' }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <h3>{{ permit.scheduleAtypeOfAquaculture.name }}</h3>
    <div class="table-container" *ngFor="let table of permit.scheduleAbyPermit.tables">
      <table class="table table-striped">
        <caption *ngIf="table.estuary" class="estuary-caption">
          Estuary: {{table.estuary}}
        </caption>
        <thead>
          <tr>
            <th class="first-column">Lease No</th>
            <th>Type of Aquaculture</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let lease of permit.scheduleAtypeOfAquaculture.leases">
            <td *ngIf="lease.leaseEstuary === table.estuary">{{ lease.leaseNo }}</td>
            <td *ngIf="lease.leaseEstuary === table.estuary">
              <span *ngFor="let type of lease.typeOfAquaculture">{{ type }}<br></span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <h3>{{ permit.scheduleBpermitConditions.name }}</h3>
     (refer to <a [href]="permit.scheduleBpermitConditions.link" target="_blank">{{ permit.scheduleBpermitConditions.link }}</a>)
    <div class="table-container">
      <table class="table table-striped">
        <thead>
          <tr>
            <th class="first-column">Condition No</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let condition of permitStandardConditions">
            <td>{{ condition.conditionLabelShort }}</td>
            <td>{{ condition.conditionLabel }}</td>
          </tr>
          <tr *ngFor="let condition of permit.scheduleBpermitConditions.conditions">
            <td>{{ condition.name }}</td>
            <td>{{ condition.description }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <h3>{{ permit.scheduleBspecialConditions.name }}</h3>
    <ng-container *ngIf="permit.scheduleBspecialConditions.conditions.length; else noSpecialCondition">
      <table class="table table-striped">
        <thead>
          <tr>
            <th>Special Condition(s)</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let condition of permit.scheduleBspecialConditions.conditions">
            <td><span [innerHTML]="condition.description | replaceNewLine"></span></td>
          </tr>
        </tbody>
      </table>
    </ng-container>
    <ng-template #noSpecialCondition>
      <div class="text-content">No special conditions apply to this permit.</div>
    </ng-template>
  </div>

  <!-- Farm Based -->
  <div *ngFor="let permit of permitPrintData.permits.farmBased; index as i">
    <br><br>
    <h2>{{ permit.name }}</h2>
    <h3 *ngIf="permit.wspa">Authorised WSPA (ha): {{ permit.wspa }}</h3>
    <h3>{{ permit.scheduleAbyPermitandType.name }}</h3>
    <div class="table-container" *ngFor="let farm of permit.scheduleAbyPermitandType.farms; index as i">
      <table class="table table-striped">
        <thead>
          <tr>
            <th>Farm</th>
            <th>{{ farm.farmNo }}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Address</td>
            <td>{{ farm.address }}</td>
          </tr>
          <tr>
            <td>Lot/DP</td>
            <td>{{ farm.lot }}</td>
          </tr>
          <tr *ngFor="let type of farm.typeOfAquaculture; let j = index;" >
            <td *ngIf="!j" class="first-heading">Type of Aquaculture</td>
            <td *ngIf="j" class="no-border"></td>
            <td *ngIf="!j" class="first-heading"><span>{{ type }}<br></span></td>
            <td *ngIf="j" class="side-border"><span>{{ type }}<br></span></td>
          </tr>
        </tbody>
      </table>
    </div>

    <h3>{{ permit.scheduleBpermitConditions.name }}</h3>
      (refer to <a [href]="permit.scheduleBpermitConditions.link" target="_blank">{{ permit.scheduleBpermitConditions.link }}</a>)
    <div class="table-container">
      <table class="table table-striped">
        <thead>
          <tr>
            <th>Condition No</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let condition of permitStandardConditions">
            <td>{{ condition.conditionLabelShort }}</td>
            <td>{{ condition.conditionLabel }}</td>
          </tr>
          <tr *ngFor="let condition of permit.scheduleBpermitConditions.conditions">
            <td>{{ condition.name }}</td>
            <td>{{ condition.description }}</td>
          </tr>
        </tbody>
      </table>
    </div>

      <h3>{{ permit.scheduleBspecialConditions.name }}</h3>
      <ng-container *ngIf="permit.scheduleBspecialConditions.conditions.length; else noSpecialCondition">
        <table>
          <thead>
            <tr>
              <th>Special Condition(s)</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let condition of permit.scheduleBspecialConditions.conditions">
              <td><span [innerHTML]="condition.description | replaceNewLine"></span></td>
            </tr>
          </tbody>
        </table>
      </ng-container>
      <ng-template #noSpecialCondition>
        <p class="text-content">No special conditions apply to this permit.</p>
      </ng-template>
  </div>
</div>
<div class="col-12" *ngIf="hasPermitSchedule$ | async">
  <div class="button-container">
    <button type="button" mat-stroked-button (click)="print.next(true)" color="primary">Print</button> 
  </div>
</div>

