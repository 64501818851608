<div class="container-fluid">
  <ngb-alert *ngIf="passwordChangedMessage" type="success" [dismissible]="false">{{ passwordChangedMessage }}</ngb-alert>
  <div class="container">
    <p class="text">Your password can be a sequence of words or other text and can also contain symbols.</p>
    <ul>
      <li>
        Your new password must contain 16 characters or more.
      </li>
      <li>
        Your password will be case sensitive
      </li>
    </ul>
    <p>If you need help, use a <a href="https://www.useapassphrase.com/">passphrase generator</a>.</p>
  </div>
  <form [formGroup]="passwordForm" (ngSubmit)="onSubmit()">
    <div class="container">
      <div class="form-group">
        <label class="col-form-label required">Old password:</label>
        <input class="form-control" type="password" formControlName="password">
      </div>
      <span class="form-control-error"
        *ngIf="showError && passwordForm.get('password').hasError('required')">
        Old password is required.
      </span>
      <div class="form-group">
        <label class="col-form-label required">Enter new password:</label>
        <input class="form-control" type="password" formControlName="newPassword">
      </div>
      <span class="form-control-error"
        *ngIf="showError && passwordForm.get('newPassword').hasError('minlength')">
        Password must be at least 16 characters long.
      </span>
      <span class="form-control-error"
        *ngIf="showError && passwordForm.get('newPassword').hasError('required')">
        Password is required.
      </span>
      <div class="form-group">
        <label class="col-form-label required">Re-enter new password:</label>
        <input class="form-control" type="password" formControlName="reNewPassword">
      </div>
      <span class="form-control-error"
        *ngIf="showError && passwordForm.hasError('passwordsNotMatch')">
        Passwords do not match.
      </span>
      <span class="form-control-error"
        *ngIf="showError && passwordForm.get('reNewPassword').hasError('required')">
        Password is required.
      </span>
      <span class="form-control-error"
        *ngIf="showError && passwordForm.get('newPassword').hasError('minlength')">
        Password must be at least 16 characters long.
      </span>
      <div class="error" *ngIf="error">{{ error }}</div>
      <div class="button-container">
        <div class="button-container--inner">
          <button type="button" (click)="goToHomePage()" class="btn btn-secondary">Cancel</button>
        </div>
        <div class="button-container--inner">
          <button type="submit" class="btn btn-primary">Submit</button>
        </div>
      </div>
    </div>
  </form>
</div>

