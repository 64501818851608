import { FormControl } from '@angular/forms';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { take, map, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { Agent } from '../home/CustomerView';
import { AgentSelectorStateSelector } from './agent-selector.selector';
import { GetAgentRelationships, SetActiveAgent } from './agent-selector.actions';
import { AgentSelectorState } from './agent-selector.reducers';

@Component({
  selector: 'app-agent-selector',
  templateUrl: './agent-selector.component.html',
  styleUrls: ['./agent-selector.component.scss']
})
export class AgentSelectorComponent implements OnInit, OnDestroy {
  @Input() label: string;
  @Input() subLabel: string;
  @Input() showMySelf = true;
  @Input() filterForPermission;

  isAgentForAnyone$: Observable<boolean>;
  agents$: Observable<Agent[]>;
  valueChanges$$: Subscription;

  agentSelectorControl = new FormControl();

  constructor(
    private agentSelectorStateSelector: AgentSelectorStateSelector,
    private agentSelectorStore: Store<AgentSelectorState>
  ) {}

  ngOnInit() {
    this.agentSelectorStore.dispatch(new GetAgentRelationships());

    this.isAgentForAnyone$ = this.agentSelectorStateSelector.isAgentForAnyone(this.filterForPermission);
    this.agents$ = this.agentSelectorStateSelector.agentsFor(this.filterForPermission);

    this.agentSelectorStateSelector.activeAgent().pipe(take(1)).subscribe(activeAgent => {
      this.agentSelectorControl.setValue(activeAgent.agentFor, { emitEvent: false });
    });

    this.valueChanges$$ = this.agentSelectorControl.valueChanges.pipe(
      switchMap(agentFor => this.agents$.pipe(
        take(1),
        map((agents) => agents.find(agent => agent.agentFor === agentFor))
      ))
    ).subscribe(selectedAgent => this.agentSelectorStore.dispatch(new SetActiveAgent(selectedAgent)));
  }

  ngOnDestroy() {
    this.valueChanges$$.unsubscribe();
  }
}
