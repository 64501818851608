
<ng-container *ngIf="(permitPrintData$ | async) as permitPrintData">
  <div class="page-footer">
    <span class="date">Printed: {{currentDate | date: 'dd/MM/yyyy hh:mm:ss a'}}</span>
  </div>

  <table class='no-border'>
    <thead>
      <tr>
        <td class='no-border'>
          <!--place holder for the fixed-position header-->
          <div class="page-header-space"></div>
        </td>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td class='no-border'>
          <div class="note-text">
            This document is a printed copy of the Permit Holder Summary and is not an authorised copy of the permit.
          </div>

          <div class="row customer-details text">
            <span class="col-4 font-weight-bold">Permit holder:</span>
            <span class="col-6">
              <span *ngFor="let holder of permitPrintData.permitHolders; index as i">{{ holder }}<br> </span>
            </span>
            <span class="col-4 font-weight-bold fields">Customer No:</span>
            <span class="col-6 fields">{{ permitPrintData.customerNo }}</span>
            <span class="col-4 font-weight-bold fields">Street Address:</span>
            <span class="col-6 fields">
              <span *ngFor="let section of permitPrintData.addressSections; index as i">{{ section }}<br> </span>
            </span>
          </div>
          <!-- Leased Based -->
          <div class="permit-section" *ngFor="let permit of permitPrintData.permits.leaseBased; index as i">
            <div >
              <h2>{{ permit.name }}</h2>
              <h3 *ngIf="permit.totalPermitArea">Total Permit Area: {{ permit.totalPermitArea }}</h3>
              <h3>{{ permit.scheduleAbyPermit.name }}</h3>
              <table *ngFor="let table of permit.scheduleAbyPermit.tables">
                <caption *ngIf="table.estuary" class="estuary-caption">
                  Estuary: {{ table.estuary}}
                </caption>
                <thead>
                  <tr>
                    <th>Lease No</th>
                    <th>% of Lease Authorised</th>
                    <th>Lease Area (ha)</th>
                    <th>Expiry Date</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let lease of table.leases">
                    <td>{{ lease.leaseNo }}</td>
                    <td>{{ lease.percentageAuthorised }}</td>
                    <td>{{ lease.leaseArea }}</td>
                    <td>{{ lease.expiryDate | date: 'dd MMM yyyy' }}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div>
              <h3>{{ permit.scheduleAtypeOfAquaculture.name }}</h3>

              <table *ngFor="let table of permit.scheduleAbyPermit.tables">
                <caption *ngIf="table.estuary" class="estuary-caption">
                  Estuary: {{table.estuary}}
                </caption>
                <thead>
                  <tr>
                    <th class="first-column">Lease No</th>
                    <th>Type of Aquaculture</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let lease of permit.scheduleAtypeOfAquaculture.leases">
                    <td *ngIf="lease.leaseEstuary === table.estuary">{{ lease.leaseNo }}</td>
                    <td *ngIf="lease.leaseEstuary === table.estuary">
                      <span *ngFor="let type of lease.typeOfAquaculture">{{ type }}<br></span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div>
              <h3>{{ permit.scheduleBpermitConditions.name }}</h3>
                (refer to <a [href]="permit.scheduleBpermitConditions.link" target="_blank">{{ permit.scheduleBpermitConditions.link }}</a>)
              <table>
                <thead>
                  <tr>
                    <th class="first-column">Condition No</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let condition of permitStandardConditions">
                    <td>{{ condition.conditionLabelShort }}</td>
                    <td>{{ condition.conditionLabel }}</td>
                  </tr>
                  <tr *ngFor="let condition of permit.scheduleBpermitConditions.conditions">
                    <td>{{ condition.name }}</td>
                    <td>{{ condition.description }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div>
              <h3>{{ permit.scheduleBspecialConditions.name }}</h3>
              <ng-container *ngIf="permit.scheduleBspecialConditions.conditions.length; else noSpecialCondition">
                <table>
                  <thead>
                    <tr>
                      <th>Special Condition(s)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let condition of permit.scheduleBspecialConditions.conditions">
                      <td><span [innerHTML]="condition.description | replaceNewLine"></span></td>
                    </tr>
                  </tbody>
                </table>
              </ng-container>
              <ng-template #noSpecialCondition>
                <p class="text-content">No special conditions apply to this permit.</p>
              </ng-template>
            </div>
          </div>

          <!-- Farm Based -->
          <div class="permit-section" *ngFor="let permit of permitPrintData.permits.farmBased; index as i">
            <div>
              <h2>{{ permit.name }}</h2>
              <h3 *ngIf="permit.wspa">Authorised WSPA (ha): {{ permit.wspa }}</h3>
              <h3>{{ permit.scheduleAbyPermitandType.name }}</h3>

              <table *ngFor="let farm of permit.scheduleAbyPermitandType.farms; index as i">
                <thead>
                  <tr>
                    <th class="first-column">Farm</th>
                    <th>{{ farm.farmNo }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>Address</th>
                    <td>{{ farm.address }}</td>
                  </tr>
                  <tr>
                    <th>Lot/DP</th>
                    <td>{{ farm.lot}}</td>
                  </tr>
                  <tr *ngFor="let type of farm.typeOfAquaculture; let j = index;" >
                    <th *ngIf="j===0" class="first-heading">Type of Aquaculture</th>
                    <th *ngIf="j!==0" class="no-border"></th>
                    <td class="half-border"><span>{{ type }}<br></span></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div>
              <br>
              <h3>{{ permit.scheduleBpermitConditions.name }}</h3>
              (refer to <a [href]="permit.scheduleBpermitConditions.link" target="_blank">{{ permit.scheduleBpermitConditions.link }}</a>)
              <table>
                <thead>
                  <tr>
                    <th class="first-column">Condition No<br></th>
                    <th>Description<br></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let condition of permitStandardConditions">
                    <td>{{ condition.conditionLabelShort }}</td>
                    <td>{{ condition.conditionLabel }}</td>
                  </tr>
                  <tr  *ngFor="let condition of permit.scheduleBpermitConditions.conditions">
                    <td>{{ condition.name }}</td>
                    <td>{{ condition.description }}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div>
              <h3>{{ permit.scheduleBspecialConditions.name }}</h3>
              <ng-container *ngIf="permit.scheduleBspecialConditions.conditions.length; else noSpecialCondition">
                <table>
                  <thead>
                    <tr>
                      <th>Special Condition(s)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let condition of permit.scheduleBspecialConditions.conditions">
                      <td><span [innerHTML]="condition.description | replaceNewLine"></span></td>
                    </tr>
                  </tbody>
                </table>
              </ng-container>
              <ng-template #noSpecialCondition>
                <p class="text-content">No special conditions apply to this permit.</p>
              </ng-template>
            </div>
          </div>
        </td>
      </tr>
    </tbody>

    <tfoot>
      <tr>
        <td class='no-border'>
          <!--place holder for the fixed-position footer-->
          <div class="page-footer-space"></div>
        </td>
      </tr>
    </tfoot>
  </table>
</ng-container>
