import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ChangePasswordComponent } from './change-password.component';
import { PageHeaderModule } from '../page-header/page-header.module';
import { ChangePasswordService } from './change-password.service';
import { ChangePasswordRoutingModule } from './change-password-routing.module';


@NgModule({
  declarations: [ChangePasswordComponent],
  imports: [
    CommonModule,
    PageHeaderModule,
    ReactiveFormsModule,
    ChangePasswordRoutingModule,
    NgbModule
  ],
  providers: [ChangePasswordService]
})
export class ChangePasswordModule { }
