import { TransactionHeader, TransactionComment, TransactionError } from './transaction';
import { TransactionPaymentOption, TransactionPayment } from './transaction-payment';

export interface IProductionReturnAmend {
  transactionID?: number;
  error?: string;
  productionReturnData?: any;
}

export interface OptionMeta {
  id: string | number;
  label: string;
}

export interface IField {
    name: string;
    isRequired?: boolean;
    isReadonly?: boolean;
    isTypeahead?: boolean;
}

export const productionReturnAmendFields: Array<IField> = [
  {
    name: 'customerID',
    isRequired: true,
    isTypeahead: true,
  },
];

export interface IProductionReturnData {
  customerID?: string;
  customerName?: string;
  custmerContact?: string;
  permits?: Array<IPermitDetails>;
  sections?: Array<ISection>;
  inputs?: Array<IInputData>;
  customerContact: string;
  species: Array<ISpecies>;
}

export interface IInputData {
  secID: string;
  subSection: string;
  estuaryID?: number;
  speciesID: number;
  permitID?: string;
  field: string;
  value: number;
  speciesName?: string;
  permitClassCode?: string;
  productType?: string;
  quantity?: number;
  rowNumber?: number;
  subsection?: string;
  estuaryName?: string;
  origin?: string;
  size?: string;
  large?: number;
  medium?: number;
  small?: number;
  sectionID?: string;
}

export interface IPermitDetails {
  permitID: string;
  permitClassCode: string;
}

export interface IInputStructure {
  secID: string;
  subSections?: Array<ISubSection>;
  inputs?: any;
  estuaries?: any;
  permits?: Array<IPermit>;
}

export interface ISection {
  secID?: string;
  secDesc?: string;
  estuaries?: Array<IEstuary>;
  inputs?: Array<any>;
  subSections?: Array<ISubSection>;
  species?: Array<ISpecies>;
  permits?: Array<IPermit>;
}

export interface ISubSection {
  subSection: string;
  estuaries?: any;
  species?: any;
}

export interface IEstuary {
  name?: string;
  estuaryID: number;
  estuaryName?: string;
  species: Array<ISpecies>;
  inputs?: any;
}

export interface ISpecies {
  speciesID: number;
  speciesName?: string;
  speciesGroup?: string;
  productionGroup?: string;
  small?: number;
  medium?: number;
  large?: number;
}

export interface IInputField {
  fieldName?: string;
  fieldValue?: number;
}

export interface IPermit {
  permitID?: string;
  permitClassCode?: string;
  species?: Array<ISpecies>;
  inputs?: any;
}

export interface ISpeciesRow {
  large?: number;
  medium?: number;
  small?: number;
  speciesName?: string;
}

export interface ISpeciesBySection {
  [key: string]: Array<ISpecies>
}

export interface IProductionReturn {
  commentNew?: TransactionComment;
  header: TransactionHeader;
  detail: IProductionReturnDetail;
  errors: Array<TransactionError>;
  comments?: Array<TransactionComment>;
  contacts?: Array<any>;
  paymentOptions?: Array<TransactionPaymentOption>;
  payments?: Array<TransactionPayment>;
  productionReturnData: IProductionReturnData
}

export interface IProductionReturnDetail {
  component: any;
  customerID: string;
  nilProduction: boolean;
  notSubmitted: boolean;
  productionReturnID: number;
  questionOne: boolean;
  questionThree: boolean;
  questionTwo: boolean;
  transactionID: number;
  year: number;
}