import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { map, tap, distinctUntilChanged } from 'rxjs/operators';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { SubSink } from 'subsink';

import { HomeStateSelector } from '../home/home.selector';
import { equals } from '../shared/ramda-functions';
import { ViewStatus } from '../enum';
import { AgentSelectorStateSelector } from '../agent-selector/agent-selector.selector';
import { Permissions } from '../enum';
import { HomeActionDispatcher } from '../home/home.dispatcher';
import { ActiveAgent } from '../home/CustomerView';

@Component({
  selector: 'app-manage-agents',
  templateUrl: './manage-agents.component.html',
  styleUrls: ['./manage-agents.component.scss']
})
export class ManageAgentsComponent implements OnInit, OnDestroy {
  public subs = new SubSink();
  activeAgent$: Observable<ActiveAgent>;
  error$: Observable<string>;
  activeAgentID: string;
  loading$$: Subscription;
  agentSelectorPermissionRequired = Permissions.agentManagement;

  constructor(
    protected router: Router,
    private homeStateSelector: HomeStateSelector,
    private homeActionDispatcher: HomeActionDispatcher,
    protected ngxLoader: NgxUiLoaderService,
    private agentSelectorStateSelector: AgentSelectorStateSelector
  ) { }

  ngOnInit() {
    this.homeActionDispatcher.clearErrors();
    this.error$ = this.homeStateSelector.error();
    this.listenToViewStatus();
    this.listenToActiveAgent();
  }

  newAgentRelationship() {
    this.router.navigate(['manage-agents', 'new', this.activeAgentID]);
  }

  private listenToActiveAgent() {
    this.activeAgent$ = this.agentSelectorStateSelector.activeAgent().pipe(
      distinctUntilChanged(),
      tap((activeAgent: ActiveAgent) => {
        console.log('listenToActiveAgent: ', activeAgent);
        this.activeAgentID = activeAgent.agentFor;
      }),
    );
  }

  private listenToViewStatus() {
    this.subs.sink = this.homeStateSelector.viewStatus()
      .pipe(
        map(searchStatus => equals(searchStatus, ViewStatus.Loading))
      ).subscribe(isLoading => isLoading ? this.ngxLoader.start() : this.ngxLoader.stop());
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

}
