import { Component, OnInit, OnDestroy, HostListener, ChangeDetectorRef } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { take, filter, switchMap, tap, debounceTime } from 'rxjs/operators';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FormBuilder, FormArray, FormGroup, Validators, FormControl } from '@angular/forms';
import { SubSink } from 'subsink';
import { MediaMatcher } from '@angular/cdk/layout';

import { Permissions, DeviceSize } from '../enum';
import { isNotNilAndEmpty, isNil, isNilOrEmpty } from '../shared/ramda-functions';
import { ProductionReturnWebappDirectService  } from './production-return-webapp-direct.service';
import { ProductionReturnTransaction, InputStructure, Estuary,
  Permit, TransactionDetail, CONTEXTUAL_MESSAGE } from '.';
import { SubSection, Section, SpeciesType, Action, TransactionType, PermitClassCodes } from './enum';
import { validateTypeaheadFormat } from '../shared/validate-typeahead';
import { ProductionReturnFields } from './production-return-fields';
import { OptionMeta } from '../search-results';
import { InformationModalComponent } from './information-modal/information-modal.component';
import { SpeciesModalComponent } from './species-modal/species-modal.component';
import { getAllFormErrors } from '../shared/get-all-form-errors';
import { SubmitModalComponent } from './submit-modal/submit-modal.component';
import { ProductionReturnSectionThreeService, ProductionReturnSectionFourService, ProductionReturnSectionFiveService, ProductionReturnSectionSixService,
   ProductionReturnSectionSevenService, ProductionReturnSectionEightService, columns, ProductionReturnService } from '@aquaonline/ng-production-return';
import { path, prop, values, propOrNull, reject, equals } from '@aquaonline/ramda';
import { IProductionReturnData, IInputData, IEstuary, ISection, IPermit } from '@aquaonline/ng-models';
import { createArrayWithOrAppendValue, disableFormControls, enableFormControls } from '@aquaonline/ng-shared';
import { ClearModalComponent } from './clear-modal/clear-modal.component';

@Component({
  selector: 'app-production-return',
  templateUrl: './production-return.component.html',
  styleUrls: ['./production-return.component.scss'],
})
export class ProductionReturnComponent implements OnInit, OnDestroy {
  public subs = new SubSink();
  transactionForm = new FormGroup({});
  transaction: ProductionReturnTransaction;
  submitModalRef: NgbModalRef;
  showError$ = new Subject<boolean>();
  saved$ = new Subject<any>();
  agentSelectorPermissionRequired = Permissions.productionReports;
  Action = Action;
  TransactionType = TransactionType;
  preventing = [];
  sectionThreeForms: any = {};
  showErrors = false;
  containsLeaseBasedPermit: boolean;
  transactionDetail$: Observable<ProductionReturnTransaction>;
  transactionDetail: IProductionReturnData;
  sectionThreeSpeciesFrequency = {};
  selectedSpeciesByEstuarySectionThree = {};
  selectedSpeciesByEstuarySectionFive = {};
  selectedSpeciesByEstuarySectionSix = {};
  selectedSpeciesByPermitSectionSeven = {};
  selectedSpeciesByPermitSectionEight = {};
  selectedSpeciesSectionThree = [];
  selectedSpeciesSectionFour = [];
  speciesNameSectionFour;
  SubSection = SubSection;
  Section = Section;
  SpeciesType = SpeciesType;
  showSectionThreeEntries = {};
  showSectionThreeSubsectionsEntries: boolean;
  showSectionFourEntries: boolean;
  showSectionFiveEntries = {};
  showSectionSixEntries = {};
  showSectionSevenEntries = {};
  showSectionEightEntries = {};
  inputs = [];
  payload: Array<InputStructure>;
  columns = columns;
  transactionID: number;
  speciesForm: FormGroup;
  speciesError: string;
  isEdit = true;
  showFlag = false;
  subSectionThreeInput = [];
  subSectionThreeArrays = {};
  saved: string;
  financialYear: string;
  showFields: boolean;
  mobileQuery: MediaQueryList;
  smallMobileDevice: boolean;
  contextualMessage = CONTEXTUAL_MESSAGE;

  get sectionThreeControls () {
    return this.transactionForm.get('sectionThreeFormGroup') as FormGroup;
  }

  get sectionThreeAFormControl () {
    return this.sectionThreeControls.get('sectionThreeAForm') as FormArray;
  }

  get sectionThreeBFormControl () {
    return this.sectionThreeControls.get('sectionThreeBForm') as FormArray;
  }

  get sectionThreeCFormControl () {
    return this.sectionThreeControls.get('sectionThreeCForm') as FormArray;
  }

  get sectionFourFormControl () {
    return this.transactionForm.get('sectionFourForm') as FormArray;
  }

  get sectionFiveFormControl () {
    return this.transactionForm.get('sectionFiveForm') as FormArray;
  }

  get sectionSixFormControl () {
    return this.transactionForm.get('sectionSixForm') as FormArray;
  }

  get sectionSevenFormControl () {
    return this.transactionForm.get('sectionSevenForm') as FormArray;
  }

  get sectionEightFormControl () {
    return this.transactionForm.get('sectionEightForm') as FormArray;
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private ngxLoader: NgxUiLoaderService,
    private modalService: NgbModal,
    private productionReturnWebappDirectService: ProductionReturnWebappDirectService,
    private productionReturnSectionThreeService: ProductionReturnSectionThreeService,
    private productionReturnSectionFourService: ProductionReturnSectionFourService,
    private productionReturnSectionFiveService: ProductionReturnSectionFiveService,
    private productionReturnSectionSixService: ProductionReturnSectionSixService,
    private productionReturnSectionSevenService: ProductionReturnSectionSevenService,
    private productionReturnSectionEightService: ProductionReturnSectionEightService,
    private productionReturnService: ProductionReturnService,
    private changeDetectorRef: ChangeDetectorRef,
    private media: MediaMatcher
  ) { }

  private _mobileQueryListener: () => void;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.checkMedia();
    this.changeDetectorRef.detectChanges();
    this.checkMediaWidth(event.target);
  }

  private checkMediaWidth(event: any) {
    this.smallMobileDevice = event.innerWidth < DeviceSize.smallMobileWidth;
  }

  ngOnInit() {
    this.checkMediaWidth(window);
    this.checkMedia();
    this.ngxLoader.start();
    this.createForm();
    this.listenToNilProductionChanges();
    this.subs.sink = this.route.queryParams
      .pipe(
        filter(isNotNilAndEmpty),
        switchMap(queryParams => this.productionReturnWebappDirectService.getProductionReturn(queryParams.transactionID, queryParams.customerID)),
        tap(transactionDetail => {
          this.transaction = transactionDetail;
          this.ngxLoader.stop();
          this.transactionDetail = transactionDetail.productionReturnData;
          this.checkPermitsClasses(this.transactionDetail);
          this.transactionForm.patchValue(transactionDetail.detail);
          const inputs = isNotNilAndEmpty(path(['detail', 'component'], transactionDetail)) ? JSON.parse(path(['detail', 'component'], transactionDetail)).inputs : 
            prop('inputs', this.transactionDetail);
          this.createFormControls(this.transactionDetail, inputs);
          this.financialYearCalc(this.transaction.detail);
        })
      ).subscribe();
    this.subs.sink = this.productionReturnService.removeSubject.subscribe(value => this.removeSectionRow(value));
  }

  checkMedia = () => {
    this.mobileQuery = this.media.matchMedia(`(max-width: ${DeviceSize.largeMobileMaxWidth}px)`);
    this._mobileQueryListener = () => this.changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  financialYearCalc = (transactionDetail: TransactionDetail) => {
    this.financialYear = (transactionDetail.year - 1) + '-' + (transactionDetail.year % 100);
  }

  checkPermitsClasses = (productionReturnData: IProductionReturnData) => {
    if (!path(['permits', 'length'], productionReturnData)) { return; }

    this.containsLeaseBasedPermit = productionReturnData.permits.some(permit => [PermitClassCodes.A, PermitClassCodes.B].includes(prop('permitClassCode', permit)));

    if (!this.containsLeaseBasedPermit) { return; }

    this.setFieldsValidators(Validators.required, 'questionOne', 'questionTwo', 'questionThree');
  }

  setFieldsValidators = (validator: any, ...controlNames: string[]) => {
    controlNames.map(controlName => this.transactionForm.get(controlName) as FormControl)
    .filter(isNotNilAndEmpty)
    .forEach(control => {
        control.setValidators(validator);
        control.updateValueAndValidity();
    });
  }

  createForm = () => {
    ProductionReturnFields
      .forEach(field => {
        const validators = [];
        if (field.isRequired) {
          validators.push(Validators.required);
        }

        if (field.isTypeahead) {
          validators.push(validateTypeaheadFormat);
        }

        this.transactionForm.addControl(field.name, this.formBuilder.control(
          { disabled: field.isReadonly, value: null }, validators
        ));
      });
      this.transactionForm.addControl('sectionThreeFormGroup', this.formBuilder.group(
        {}, {validators: [this.productionReturnService.sectionThreeValidator, this.productionReturnService.sectionThreeRequiredFieldsValidator]}
      ));
      this.subs.sink = this.transactionForm.valueChanges.pipe(debounceTime(3000)).subscribe(() => this.autoSaveProductionReturnData());
  }

  formatter = (option: OptionMeta | string) => typeof option === 'object' ? `${option.label}` : option;

  inputFormatter = (option: OptionMeta) => option.id;

  createFormControls = (transactionDetail: IProductionReturnData, inputs: Array<IInputData>) => {
    this.addSectionFormControls(transactionDetail);
    inputs.forEach((input, index) => {
      switch(input.secID){
        case this.Section.sectionThree:{
          this.showSectionThreeEntries[+input.estuaryID] = input.estuaryID ? true : false;
          this.showSectionThreeSubsectionsEntries = true;
          this.productionReturnSectionThreeService.addFromControlsForInput(input, index, this.sectionThreeForms, 
            this.selectedSpeciesByEstuarySectionThree, this.sectionThreeControls, this.sectionThreeSpeciesFrequency);
          break;
        }
        case this.Section.sectionFour:{
          this.showSectionFourEntries = true;
          this.productionReturnSectionFourService.addFromControlsForInput(input, this.selectedSpeciesSectionFour, this.transactionForm);
          break;
        } 
        case this.Section.sectionFive:{
          this.showSectionFiveEntries[+input.estuaryID] = true;
          this.productionReturnSectionFiveService.addFromControlsForInput(input, this.transactionForm, this.selectedSpeciesByEstuarySectionFive);
          break;
        } 
        case this.Section.sectionSix:{
          this.showSectionSixEntries[+input.estuaryID] = true;
          this.productionReturnSectionSixService.addFromControlsForInput(input, this.transactionForm, this.selectedSpeciesByEstuarySectionSix);
          break;
        }
        case this.Section.sectionSeven:{
          this.showSectionSevenEntries[input.permitID] = true;
          this.productionReturnSectionSevenService.addFromControlsForInput(input, this.transactionForm, this.selectedSpeciesByPermitSectionSeven);
          break;
        }
        case this.Section.sectionEight:{
          this.showSectionEightEntries[input.permitID] = true;
          this.productionReturnSectionEightService.addFromControlsForInput(input, this.transactionForm, this.selectedSpeciesByPermitSectionEight);
          break;
        }
      }
    });
  }

  private addSectionFormControls(transactionDetail: IProductionReturnData) {
    transactionDetail.sections.forEach(section => {
      switch (section.secID) {
        case this.Section.sectionThree: {
          this.addSectionThreeSubSectionControls();
          break;
        }
        case this.Section.sectionFour: {
          if (isNilOrEmpty(this.transactionForm.get('sectionFourForm'))) {
            this.transactionForm.addControl('sectionFourForm', this.formBuilder.array([], [this.productionReturnSectionFourService.sectionValidator]));
          }
          break;
        }
        case this.Section.sectionFive: {
          if (isNilOrEmpty(this.transactionForm.get('sectionFiveForm'))) {
            this.transactionForm.addControl('sectionFiveForm', this.formBuilder.array([], [this.productionReturnSectionFiveService.sectionValidator]));
          }
          break;
        }
        case this.Section.sectionSix: {
          if (isNilOrEmpty(this.transactionForm.get('sectionSixForm'))) {
            this.transactionForm.addControl('sectionSixForm', this.formBuilder.array([]));
          }
          break;
        }
        case this.Section.sectionSeven: {
          if (isNilOrEmpty(this.transactionForm.get('sectionSevenForm'))) {
            this.transactionForm.addControl('sectionSevenForm', this.formBuilder.array([], [this.productionReturnSectionSevenService.sectionValidator]));
          }
          break;
        }
        case this.Section.sectionEight: {
          if (isNilOrEmpty(this.transactionForm.get('sectionEightForm'))) {
            this.transactionForm.addControl('sectionEightForm', this.formBuilder.array([], [this.productionReturnSectionEightService.sectionValidator]));
          }
          break;
        }
      }
    });
  }

  private addSectionThreeSubSectionControls() {
    if (isNilOrEmpty(this.sectionThreeControls.get('sectionThreeAForm'))) {
      this.sectionThreeControls.addControl('sectionThreeAForm', this.formBuilder.array([]));
    }
    if (isNilOrEmpty(this.sectionThreeControls.get('sectionThreeBForm'))) {
      this.sectionThreeControls.addControl('sectionThreeBForm', this.formBuilder.array([]));
    }
    if (isNilOrEmpty(this.sectionThreeControls.get('sectionThreeCForm'))) {
      this.sectionThreeControls.addControl('sectionThreeCForm', this.formBuilder.array([]));
    }
  }

  openSpeciesModal = (estuary?: IEstuary, section?: ISection, subSection?: string, permit?: IPermit) => {
    const modalRef = this.modalService.open(SpeciesModalComponent, { size: 'lg', windowClass: 'species-modal', backdrop: 'static' });
    modalRef.componentInstance.section = section;
    switch(section.secID){
      case this.Section.sectionThree: {
        this.subs.sink = this.productionReturnSectionThreeService.openSpeciesModal(modalRef, estuary, this.selectedSpeciesByEstuarySectionThree)
          .subscribe((receivedEntry) => {
            this.showSectionThreeEntries[receivedEntry.estuaryID] = receivedEntry.estuaryID ? true : false;
            this.showSectionThreeSubsectionsEntries = true;
            this.sectionThreeSpeciesFrequency = createArrayWithOrAppendValue([receivedEntry.speciesID], receivedEntry, this.sectionThreeSpeciesFrequency);
            this.constructFormControls(receivedEntry);
          });
        break;
      }
      case this.Section.sectionFour: {
        this.subs.sink = this.productionReturnSectionFourService.openSpeciesModal(modalRef, section, this.selectedSpeciesSectionFour)
          .subscribe(receivedEntry => this.constructFormControls(receivedEntry));
        break;
      }
      case this.Section.sectionFive: {
        this.subs.sink = this.productionReturnSectionFiveService.openSpeciesModal(modalRef, estuary, this.selectedSpeciesByEstuarySectionFive)
          .subscribe(receivedEntry => {
            this.showSectionFiveEntries[+estuary.estuaryID] = true;
            this.constructFormControls(receivedEntry)
          });
        break;
      }
      case this.Section.sectionSix: {
        this.subs.sink = this.productionReturnSectionSixService.openSpeciesModal(modalRef, estuary, this.selectedSpeciesByEstuarySectionSix)
          .subscribe((receivedEntry) => {
            this.showSectionSixEntries[+estuary.estuaryID] = true;
            this.constructFormControls(receivedEntry);
          });
        break;
      }
      case this.Section.sectionSeven: {
        this.subs.sink = this.productionReturnSectionSevenService.openSpeciesModal(modalRef, permit, this.selectedSpeciesByPermitSectionSeven)
        .subscribe((receivedEntry) => {
          this.showSectionSevenEntries[receivedEntry.permitID] = true;
          this.constructFormControls(receivedEntry);
        });
        break;
      }
      case this.Section.sectionEight: {
        this.subs.sink = this.productionReturnSectionEightService.openSpeciesModal(modalRef, permit, this.selectedSpeciesByPermitSectionEight)
          .subscribe((receivedEntry) => {
            this.showSectionEightEntries[receivedEntry.permitID] = true;
            this.constructFormControls(receivedEntry);
          });
        break;
      }
    }
  }

  openInformationModal = () => {
    const modalRef = this.modalService.open(InformationModalComponent, { size: 'lg', windowClass: 'information-modal', backdrop: 'static' });
  }

  constructFormControls = (receivedEntry: any) => {
    switch(receivedEntry.sectionID){
      case this.Section.sectionThree: {
        this.selectedSpeciesByEstuarySectionThree = this.productionReturnSectionThreeService.createControls(receivedEntry, this.sectionThreeControls,
          this.selectedSpeciesByEstuarySectionThree, this.selectedSpeciesSectionThree, this.sectionThreeSpeciesFrequency);
        break;
      }
      case this.Section.sectionFour: {
        this.showSectionFourEntries = true;
        this.productionReturnSectionFourService.createControls(receivedEntry, this.selectedSpeciesSectionFour, this.transactionForm);
        break;
      }
      case this.Section.sectionFive: {
        this.selectedSpeciesByEstuarySectionFive = this.productionReturnSectionFiveService.createControls(receivedEntry, 
          this.transactionForm, this.selectedSpeciesByEstuarySectionFive);
        break;
      }
      case this.Section.sectionSix: {
        this.selectedSpeciesByEstuarySectionSix = this.productionReturnSectionSixService.createControls(receivedEntry, 
          this.transactionForm, this.selectedSpeciesByEstuarySectionSix);
        break;
      }
      case this.Section.sectionSeven: {
        this.selectedSpeciesByPermitSectionSeven = this.productionReturnSectionSevenService.createControls(receivedEntry, 
          this.transactionForm, this.selectedSpeciesByPermitSectionSeven);
        break;
      }
      case this.Section.sectionEight: {
        this.selectedSpeciesByPermitSectionEight = this.productionReturnSectionEightService.createControls(receivedEntry, 
          this.transactionForm, this.selectedSpeciesByPermitSectionEight);
      }
    }
  }

  private addControlsToSections(transactionDetail: IProductionReturnData) {
    transactionDetail.sections.forEach(section => {
      switch (section.secID) {
        case this.Section.sectionThree: {
          if (isNilOrEmpty(this.sectionThreeControls)) {
            this.transactionForm.addControl('sectionThreeFormGroup', this.formBuilder.group(
              {}, {validators: [this.productionReturnService.sectionThreeValidator, this.productionReturnService.sectionThreeRequiredFieldsValidator]}
            ));
          }
          if (isNilOrEmpty(this.sectionThreeControls.get('sectionThreeAForm'))) {
            this.sectionThreeControls.addControl('sectionThreeAForm', this.formBuilder.array([]));
          }
          if (isNilOrEmpty(this.sectionThreeControls.get('sectionThreeBForm'))) {
            this.sectionThreeControls.addControl('sectionThreeBForm', this.formBuilder.array([]));
          }
          if (isNilOrEmpty(this.sectionThreeControls.get('sectionThreeCForm'))) {
            this.sectionThreeControls.addControl('sectionThreeCForm', this.formBuilder.array([]));
          }
          break;
        }
        case this.Section.sectionFour: {
          if (isNilOrEmpty(this.transactionForm.get('sectionFourForm'))) {
            this.transactionForm.addControl('sectionFourForm', this.formBuilder.array([], [this.productionReturnSectionFourService.sectionValidator]));
          }
          break;
        }
        case this.Section.sectionFive: {
          if (isNilOrEmpty(this.transactionForm.get('sectionFiveForm'))) {
            this.transactionForm.addControl('sectionFiveForm', this.formBuilder.array([], [this.productionReturnSectionFiveService.sectionValidator]));
          }
          break;
        }
        case this.Section.sectionSix: {
          if (isNilOrEmpty(this.transactionForm.get('sectionSixForm'))) {
            this.transactionForm.addControl('sectionSixForm', this.formBuilder.array([]));
          }
          break;
        }
        case this.Section.sectionSeven: {
          if (isNilOrEmpty(this.transactionForm.get('sectionSevenForm'))) {
            this.transactionForm.addControl('sectionSevenForm', this.formBuilder.array([], [this.productionReturnSectionSevenService.sectionValidator]));
          }
          break;
        }
        case this.Section.sectionEight: {
          if (isNilOrEmpty(this.transactionForm.get('sectionEightForm'))) {
            this.transactionForm.addControl('sectionEightForm', this.formBuilder.array([], [this.productionReturnSectionEightService.sectionValidator]));
          }
          break;
        }
      }
    });
  }

  removeSectionRow = (value) => {
    switch(value.secID){
      case this.Section.sectionThree:
        this.showSectionThreeEntries[value.estuaryID] = this.productionReturnSectionThreeService.removeRow(value, this.sectionThreeAFormControl, 
          this.sectionThreeBFormControl, this.sectionThreeCFormControl, this.selectedSpeciesByEstuarySectionThree, this.sectionThreeSpeciesFrequency, 
          this.selectedSpeciesSectionThree, this.showSectionThreeEntries);
          this.showSectionThreeSubsectionsEntries = values(this.showSectionThreeEntries).some(value => value);
        break;
      case this.Section.sectionFour:
        this.showSectionFourEntries = this.productionReturnSectionFourService.removeRow(value, this.sectionFourFormControl, this.selectedSpeciesSectionFour);
        break;
      case this.Section.sectionFive:
        this.showSectionFiveEntries[value.estuaryID] = this.productionReturnSectionFiveService.removeRow(value, 
          this.sectionFiveFormControl, this.selectedSpeciesByEstuarySectionFive);
        break;
      case this.Section.sectionSix:
        this.showSectionSixEntries[value.estuaryID] = this.productionReturnSectionSixService.removeRow(value, 
          this.sectionSixFormControl, this.selectedSpeciesByEstuarySectionSix);
        break;
      case this.Section.sectionSeven:
        this.showSectionSevenEntries[value.permitID] = this.productionReturnSectionSevenService.removeRow(value, 
          this.sectionSevenFormControl, this.selectedSpeciesByPermitSectionSeven);
        break;
      case this.Section.sectionEight:
        this.showSectionEightEntries[value.permitID] = this.productionReturnSectionEightService.removeRow(value, 
          this.sectionEightFormControl, this.selectedSpeciesByPermitSectionEight);
        break;
    }
    this.transactionForm.updateValueAndValidity();
  }

  displayHeading(estuary?: Estuary, section?, permit?: Permit ) {
    switch (section.secID) {
      case Section.sectionThree: {
        return Object.keys(this.selectedSpeciesByEstuarySectionThree).includes(estuary.estuaryID.toString());
      }
      case Section.sectionFive: {
        return Object.keys(this.selectedSpeciesByEstuarySectionFive).includes(estuary.estuaryID.toString());
      }
      case Section.sectionSix: {
        return Object.keys(this.selectedSpeciesByEstuarySectionSix).includes(estuary.estuaryID.toString());
      }
      case Section.sectionSeven: {
        return Object.keys(this.selectedSpeciesByPermitSectionSeven).includes(permit.permitID);
      }
      case Section.sectionEight: {
        return Object.keys(this.selectedSpeciesByPermitSectionEight).includes(permit.permitID);
      }
    }
  }

  actionHandler = (event) => {
    switch (event) {
      case Action.Exit:
        this.router.navigate(['./production-return']);
      break;
      case Action.Clear:
        this.openClearModal();
      break;
      case Action.Save:
        this.saveProductionReturn(Action.Save);
      break;
      case Action.Submit:
        this.submitProductionReturn();
      break;
    }
  }

  saveProductionReturn = (action: Action) => {
    if (this.transactionForm.invalid) {
      console.log('dispatchSave: Form Errors', getAllFormErrors(this.transactionForm));
      this.showError$.next(true);
      this.showErrors = true;
      return;
    }
    this.showErrors = false;
    this.constructInputs();
    this.transactionForm.get('nilProduction').value ? this.submitNilProductionReturn(action) : this.submitProductionRetrunData(action);
  }

  submitProductionRetrunData = (action: Action) => {
    const { updatedTransction } = this.constructTransactionPayload(this.transaction);
    this.ngxLoader.start();
    this.subs.sink = this.productionReturnWebappDirectService.save({
      transactionDetail: {
        ...this.transaction.detail,
        questionOne: this.transactionForm.get('questionOne') ? this.transactionForm.get('questionOne').value : false,
        questionTwo: this.transactionForm.get('questionTwo') ? this.transactionForm.get('questionTwo').value : false,
        questionThree: this.transactionForm.get('questionThree') ? this.transactionForm.get('questionThree').value : false,
        nilProduction: this.transactionForm.get('nilProduction').value,
        productionReturnData: updatedTransction.productionReturnData,
        component: {
          species: this.selectedSpeciesSectionThree,
          sections: updatedTransction.productionReturnData.sections,
          inputs: this.inputs
        },
      } as ProductionReturnTransaction,
      transactionType: TransactionType.AquaDirectProductionReturn,
    }).pipe(
      switchMap(response => this.productionReturnWebappDirectService.getProductionReturn(path(['detail', 'transactionID'], response), path(['detail', 'customerID'], response)))
    ).subscribe(transactionDetail => {
      this.ngxLoader.stop();
      this.preventing = transactionDetail.errors;
      if (equals(action, Action.Submit)) {
        this.saved$.next({saved: true, preventingError: !!this.preventing.length});
      }
      this.saved = 'Production details have been saved successfully.';
    });
  }

  submitProductionReturn = () => {
    this.saveProductionReturn(Action.Submit);
    const { updatedTransction } = this.constructTransactionPayload(this.transaction);
    this.subs.sink = this.saved$.subscribe(response => {
      if (response.saved && !response.preventingError && isNilOrEmpty(this.submitModalRef)) {
        this.submitModalRef = this.modalService.open(SubmitModalComponent, { size: 'lg', windowClass: 'submit-modal', backdrop: 'static' });
        this.subs.sink = this.submitModalRef.componentInstance.passEntry.pipe(
          take(1),
          tap(passEntry => {
            if (!prop('submitProduction', passEntry)) { this.submitModalRef = null; }
          }),
          filter(passEntry => prop('submitProduction', passEntry)),
          tap(() => this.ngxLoader.start()),
          switchMap(() => this.productionReturnWebappDirectService.submit({
            transactionDetail: {
              ...this.transaction.detail,
              questionOne: this.transactionForm.get('questionOne') ? this.transactionForm.get('questionOne').value : false,
              questionTwo: this.transactionForm.get('questionTwo') ? this.transactionForm.get('questionTwo').value : false,
              questionThree: this.transactionForm.get('questionThree') ? this.transactionForm.get('questionThree').value : false,
              nilProduction: this.transactionForm.get('nilProduction').value,
              productionReturnData: updatedTransction.productionReturnData,
              component: {
                species: this.selectedSpeciesSectionThree,
                sections: updatedTransction.productionReturnData.sections,
                inputs: this.inputs
              },
            } as ProductionReturnTransaction,
            transactionType: TransactionType.AquaDirectProductionReturn,
          }))
        ).subscribe(result => {
          this.ngxLoader.stop();
          const navigationExtras: NavigationExtras = {
            queryParams: {
              customerID: result.detail.customerID,
              productionReturnID: result.detail.productionReturnID,
            }
          };
          this.router.navigate(['/production-return/view'], navigationExtras);
        });
      }
    });
  }

  autoSaveProductionReturnData = () => {
    this.constructInputs();
    const { updatedTransction } = this.constructTransactionPayload(this.transaction);
    this.subs.sink = this.productionReturnWebappDirectService.save({
      transactionDetail: {
        ...this.transaction.detail,
        questionOne: this.transactionForm.get('questionOne') ? this.transactionForm.get('questionOne').value : false,
        questionTwo: this.transactionForm.get('questionTwo') ? this.transactionForm.get('questionTwo').value : false,
        questionThree: this.transactionForm.get('questionThree') ? this.transactionForm.get('questionThree').value : false,
        nilProduction: this.transactionForm.get('nilProduction').value,
        productionReturnData: updatedTransction.productionReturnData,
        component: {
          species: this.selectedSpeciesSectionThree,
          sections: updatedTransction.productionReturnData.sections,
          inputs: this.inputs
        },
      } as ProductionReturnTransaction,
      transactionType: TransactionType.AquaDirectProductionReturn,
    }).subscribe();
  }

  constructInputs = () => {
    this.inputs = [];
    const {commentNew, paymentOptions, payments, transactionID,
      questionOne, questionTwo, questionThree, nilProduction, 
      sectionFourForm, sectionThreeFormGroup,
      sectionFiveForm, sectionSixForm, sectionSevenForm, sectionEightForm,
      ...formDetails} = this.transactionForm.getRawValue();
    this.productionReturnSectionThreeService.createInputs(sectionThreeFormGroup, this.inputs);
    this.productionReturnSectionFourService.createInputs(sectionFourForm, this.inputs);
    this.productionReturnSectionFiveService.createInputs(sectionFiveForm, this.inputs);
    this.productionReturnSectionSixService.createInputs(sectionSixForm, this.inputs);
    this.productionReturnSectionSevenService.createInputs(sectionSevenForm, this.inputs);
    this.productionReturnSectionEightService.createInputs(sectionEightForm, this.inputs);
    this.inputs = this.inputs.filter(input => isNotNilAndEmpty(input));
  }

  private submitNilProductionReturn(action: Action) {
    this.subs.sink = this.productionReturnWebappDirectService.openNilProductionReturnModal(action)
      .pipe(
        take(1),
        filter(receivedEntry => prop('nilProduction', receivedEntry)),
        tap(() => this.ngxLoader.start()),
        switchMap(() => this.productionReturnWebappDirectService.save({
          transactionDetail: {
            ...this.transaction.detail,
            questionOne: this.transactionForm.get('questionOne') ? this.transactionForm.get('questionOne').value : false,
            questionTwo: this.transactionForm.get('questionTwo') ? this.transactionForm.get('questionTwo').value : false,
            questionThree: this.transactionForm.get('questionThree') ? this.transactionForm.get('questionThree').value : false,
            nilProduction: this.transactionForm.get('nilProduction').value,
            productionReturnData: this.getUpdatedProductionReturnData(),
            component: null,
          } as ProductionReturnTransaction,
          transactionType: TransactionType.AquaDirectProductionReturn,
        }))
      ).subscribe(() => {
        this.ngxLoader.stop();
        if (equals(action, Action.Submit)) {
          this.saved$.next({ saved: true });
        }
        this.saved = 'Nil Production has been saved successfully.';
      });
  }

  private getUpdatedProductionReturnData() {
    const { updatedTransction, transactionID } = this.constructNilTransactionPayload(this.transaction);
    return updatedTransction.productionReturnData;
  }

  private openClearModal() {
    const modalRef = this.modalService.open(ClearModalComponent, { size: 'lg', windowClass: 'clear-modal', backdrop: 'static' });
    this.subs.sink = modalRef.componentInstance.passEntry.pipe(filter(isNotNilAndEmpty)).subscribe(() => this.clearProductionReturnForm());
  }

  private clearProductionReturnForm() {
    this.showErrors = false;
    this.showFields = false;
    this.transactionForm.reset();
    const formObject = this.transactionForm.controls;
    Object.keys(formObject).forEach(control => {
      this.transactionForm.removeControl(control);
    });
    this.clearQuestionControls();
    this.addControlsToSections(this.transactionDetail);
    this.transactionForm.updateValueAndValidity();
    this.resetSectionsVariables();
  }

  private resetSectionsVariables = () => {
    this.selectedSpeciesByEstuarySectionThree = {};
    this.selectedSpeciesSectionThree = [];
    this.selectedSpeciesSectionFour = [];
    this.selectedSpeciesByEstuarySectionFive = {};
    this.selectedSpeciesByEstuarySectionSix = {};
    this.selectedSpeciesByPermitSectionSeven = {};
    this.selectedSpeciesByPermitSectionEight = {};
    this.showSectionThreeSubsectionsEntries = false;
    this.showSectionFourEntries = false;
    this.showSectionFiveEntries = {};
    this.showSectionSixEntries = {};
    this.showSectionSevenEntries = {};
    this.showSectionEightEntries = {};
    this.subSectionThreeInput = [];
    this.subSectionThreeArrays = {};
    this.inputs = [];
    this.preventing = [];
    this.sectionThreeSpeciesFrequency = {};
    this.showSectionThreeEntries = {};
  }

  private clearQuestionControls() {
    if (this.containsLeaseBasedPermit) {
      this.transactionForm.addControl('questionOne', this.formBuilder.control(null, [Validators.required]));
      this.transactionForm.addControl('questionTwo', this.formBuilder.control(null, [Validators.required]));
      this.transactionForm.addControl('questionThree', this.formBuilder.control(null, [Validators.required]));
    }
    else {
      this.transactionForm.addControl('questionOne', this.formBuilder.control(false));
      this.transactionForm.addControl('questionTwo', this.formBuilder.control(false));
      this.transactionForm.addControl('questionThree', this.formBuilder.control(false));
    }
    this.transactionForm.addControl('nilProduction', this.formBuilder.control(null, [Validators.required]));
    this.listenToNilProductionChanges();
  }

  private constructTransactionPayload(transaction: ProductionReturnTransaction) {
    const transactionID = propOrNull('transactionID', transaction.detail);
    const { productionReturnData, ...details } = transaction;
    productionReturnData.inputs = this.inputs;
    const updatedTransction = { productionReturnData, ...details };
    return { updatedTransction, transactionID };
  }

  private constructNilTransactionPayload(transaction: ProductionReturnTransaction) {
    const transactionID = propOrNull('transactionID', transaction.detail);
    const { productionReturnData, ...details } = transaction;
    productionReturnData.inputs = [];
    const updatedTransction = { productionReturnData, ...details };
    return { updatedTransction, transactionID };
  }

  public listenToNilProductionChanges() {
    const formControlsArray = [this.sectionThreeControls, this.sectionFourFormControl, this.sectionFiveFormControl, 
      this.sectionSixFormControl, this.sectionSevenFormControl, this.sectionEightFormControl];
    this.subs.sink = this.transactionForm.get('nilProduction').valueChanges
      .pipe(filter(isNotNilAndEmpty)).subscribe(value => this.hideSectionControlsForNilProduction(value, formControlsArray));
  }

  private hideSectionControlsForNilProduction(value: any, formControlsArray: (FormGroup | FormArray)[]) {
    this.showFields = !value;
    value ? disableFormControls(reject(isNil)(formControlsArray)) : enableFormControls(reject(isNil)(formControlsArray));
    this.transactionForm.updateValueAndValidity();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }
}
