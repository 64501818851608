import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { SetPasswordComponent } from './update-password.component';
import { PageHeaderModule } from '../../page-header/page-header.module';
import { SetPasswordService } from './update-password.service';


@NgModule({
  declarations: [SetPasswordComponent],
  imports: [
    CommonModule,
    PageHeaderModule,
    ReactiveFormsModule,
  ],
  providers: [SetPasswordService]
})
export class SetPasswordModule { }
