import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';

import { ActionText } from '../../production-return/enum';
import { ProductionReturn } from '..';

@Component({
  selector: 'app-pr-table',
  templateUrl: './pr-table.component.html',
  styleUrls: ['./pr-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrTableComponent implements OnInit {
  @Input() heading;
  @Input() actionText;
  @Input() productionReturns: ProductionReturn[];
  ActionText = ActionText;

  constructor(private router: Router) { }

  ngOnInit() {}

  viewProductionReturn = (customerID: string, productionReturnID: number) => {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        customerID: customerID,
        productionReturnID: productionReturnID,
      }
    };
    this.router.navigate(['/production-return/view'], navigationExtras);
  }
  editProductionReturn = (customerID: string, transactionID: number) => {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        customerID: customerID,
        transactionID: transactionID,
      }
    };
    this.router.navigate(['/production-return/edit'], navigationExtras);
  }
}
