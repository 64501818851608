import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';

import { PageHeaderModule } from '../page-header/page-header.module';
import { LeaseService } from './lease.service';
import { LeaseRoutingModule } from './lease-routing.module';
import { LeaseViewActionDispatcher } from './lease-view/lease-view.dispatcher';
import { LeaseViewStateSelector } from './lease-view/lease-view.selector';
import * as fromLease from './lease.reducers';

@NgModule({
  imports: [
    CommonModule,
    LeaseRoutingModule,
    PageHeaderModule,
    StoreModule.forFeature('lease', fromLease.reducers),
  ],
  providers: [
    LeaseService,
    LeaseViewActionDispatcher,
    LeaseViewStateSelector,
  ]
})
export class LeaseModule { }
