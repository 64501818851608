import { Action } from '@ngrx/store';

import { CustomerDetail } from '../../home/CustomerView';

export enum EditAccountProfileActionTypes {
  LoadCustomer = '[EditAccountProfile] Load Customer',
  LoadCustomerSuccess = '[EditAccountProfile] Load Customer Success',
  LoadCustomerFailed = '[EditAccountProfile] Load Customer Failed',
}

export class LoadCustomer implements Action {
  readonly type = EditAccountProfileActionTypes.LoadCustomer;
  constructor() {}
}

export class LoadCustomerSuccess implements Action {
  readonly type = EditAccountProfileActionTypes.LoadCustomerSuccess;
  constructor(public payload: CustomerDetail) {}
}

export class LoadCustomerFailed implements Action {
  readonly type = EditAccountProfileActionTypes.LoadCustomerFailed;
  constructor(public payload: string) {}
}

export type EditAccountProfileActions = LoadCustomer
| LoadCustomerSuccess
| LoadCustomerFailed;
