import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromLogin from '../login/login.reducer';
import * as fromAuth from '../auth.reducer';

export interface State {
  login: fromLogin.State;
  status: fromAuth.State;
}

export const initialState: State = {
  login: fromLogin.initialState,
  status: fromAuth.initialState,
};

export const reducers: ActionReducerMap<State> = {
  login: fromLogin.reducer,
  status: fromAuth.reducer,
};

// selectors
export const selectAuthState = createFeatureSelector<State>('auth');

export const selectLoginState = createSelector(
  selectAuthState,
  (state: State) => state.login,
);

export const getLoginError = createSelector(
  selectLoginState,
  fromLogin.getError,
);

export const getLoginPending = createSelector(
  selectLoginState,
  fromLogin.getPending,
);

export const selectAuthStatusState = createSelector(
  selectAuthState,
  (state: State) => state.status,
);

export const getAuthStatus = createSelector(
  selectAuthStatusState,
  fromAuth.getAuthStatus,
);
