import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  DeleteAgent,
  DeleteAgentFailed,
  AgentsTableTypes,
  AgentsTableActions,
  DeleteAgentRelationshipSuccess,
  GetAgentRelationshipsFor,
  GetAgentRelationshipsForSuccess,
  GetAgentRelationshipsForFailed,
} from './agents-table.actions';
import { Agent } from '../../home/CustomerView';
import { ViewStatus } from '../../enum';

export interface State {
  agentRelationships?: Agent[];
  customerViewStatus: ViewStatus;
  customerErrorMessage?: string;
}

export const initialState: State = {
  customerViewStatus: ViewStatus.Initial
};

const getAgentRelationships = (state: State, action: GetAgentRelationshipsFor): State => {
  const { customerErrorMessage, ...rest} = state;
  return {
    ...rest,
    customerViewStatus: ViewStatus.Loading,
  };
};

const getAgentRelationshipsForSuccess = (state: State, action: GetAgentRelationshipsForSuccess): State => {
  return {
    ...state,
    customerViewStatus: ViewStatus.LoadSuccess,
    agentRelationships: action.payload,
  };
};

const getAgentRelationshipsForFailed = (state: State, action: GetAgentRelationshipsForFailed): State => ({
  ...state,
  customerViewStatus: ViewStatus.LoadFailed,
  customerErrorMessage: action.payload,
});

const deleteAgent = (state: State, action: DeleteAgent): State => {
  const { customerErrorMessage, ...rest} = state;
  return {
    ...rest,
    customerViewStatus: ViewStatus.Loading,
  };
};

const deleteAgentSuccess = (state: State, action: DeleteAgentRelationshipSuccess): State => {
  return {
    agentRelationships: state.agentRelationships.filter((agent) => agent.agentID !== action.payload),
    customerViewStatus: ViewStatus.LoadSuccess,
  };
};

const deleteAgentFailed = (state: State, action: DeleteAgentFailed): State => ({
  ...state,
  customerViewStatus: ViewStatus.LoadFailed,
  customerErrorMessage: action.payload,
});

export function reducer(state = initialState, action: AgentsTableActions): State {
  switch (action.type) {
    case AgentsTableTypes.GetAgentRelationshipsFor:
      return getAgentRelationships(state, action);
    case AgentsTableTypes.GetAgentRelationshipsForSuccess:
      return getAgentRelationshipsForSuccess(state, action);
    case AgentsTableTypes.GetAgentRelationshipsForFailed:
      return getAgentRelationshipsForFailed(state, action);
    case AgentsTableTypes.DeleteAgent:
      return deleteAgent(state, action);
    case AgentsTableTypes.DeleteAgentRelationshipSuccess:
      return deleteAgentSuccess(state, action);
    case AgentsTableTypes.DeleteAgentFailed:
      return deleteAgentFailed(state, action);
    default:
      return state;
  }
}

const getAgentsTableState = createFeatureSelector<State>('agents-table');


export const getAgentRelationshipsFor = createSelector(getAgentsTableState, state => state.agentRelationships);
export const getCustomerViewStatus = createSelector(getAgentsTableState, state => state.customerViewStatus);
export const getCustomerViewErrorMessage = createSelector(getAgentsTableState, state => state.customerErrorMessage);






