<div class="row">
  <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
    <div class="card">
      <div class="card-header">
        Physical address
      </div>
      <div class="card-body">
        <div class="card-text row">
          <div class="col-12"><span class="label">Street address 1:</span> {{ addresses.physicalStreetAddress1 }}</div>
          <div class="col-12"><span class="label">Street address 2:</span> {{ addresses.physicalStreetAddress2 }}</div>
          <div class="col-12"><span class="label">Town/Suburb:</span> {{ addresses.physicalTownSuburb }}</div>
          <div class="col-12"><span class="label">Postcode:</span> {{ addresses.physicalPostcode }}</div>
          <div class="col-12"><span class="label">State:</span> {{ addresses.physicalState }}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
    <div class="card">
      <div class="card-header">
        Mailing address
      </div>
      <div class="card-body">
        <div class="card-text row">
          <div class="col-12"><span class="label">Street address 1:</span> {{ addresses.mailingStreetAddress1 }}</div>
          <div class="col-12"><span class="label">Street address 2:</span> {{ addresses.mailingStreetAddress2 }}</div>
          <div class="col-12"><span class="label">Town/Suburb:</span> {{ addresses.mailingTownSuburb }}</div>
          <div class="col-12"><span class="label">Postcode:</span> {{ addresses.mailingPostcode }}</div>
          <div class="col-12"><span class="label">State:</span> {{ addresses.mailingState }}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
    <div class="card">
      <div class="card-header">
        Billing details
      </div>
      <div class="card-body">
        <div class="card-text row">
          <div class="col-12"><span class="label">Street address:</span> {{ addresses.billingStreetAddress }}</div>
          <div class="col-12"><span class="label">Town/Suburb:</span> {{ addresses.billingTownSuburb }}</div>
          <div class="col-12"><span class="label">Postcode:</span> {{ addresses.billingPostcode }}</div>
          <div class="col-12"><span class="label">State:</span> {{ addresses.billingState }}</div>
          <div class="col-12"><span class="label">Billing name:</span> {{ addresses.billingName }}</div>
          <div class="col-12"><span class="label">Billing name ext:</span> {{ addresses.billingNameExt }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
  
