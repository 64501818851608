<app-page-header pageTitle="Login" [hideExit]="true" [hideBreadcrumbs]="true"></app-page-header>
<form [formGroup]="loginForm" (ngSubmit)="login()">
  <div class="container login-container">
    <div class="name">AquaDirect</div>
    <div class="form-group">
      <label class="col-form-label">Username:</label>
      <input class="form-control" formControlName="username" autocomplete="username">
    </div>
    <div class="form-group">
      <label class="col-form-label">Password:</label>
      <input class="form-control" type="password" formControlName="password" autocomplete="off">
    </div>
    <div class="error" *ngIf="loginError$ | async as loginError">{{ loginError }}</div>
    <div class="alert alert-danger" *ngIf="forgotPasswordError">{{ forgotPasswordError }}</div>
    <div class="alert alert-success" *ngIf="forgotPasswordSuccessMessage">{{ forgotPasswordSuccessMessage }}</div>
    <div class="error" *ngIf="forgotPasswordSuspensionErrorMessage">{{ forgotPasswordSuspensionErrorMessage }}</div>
    <button type="button" class="forgotPasswordButton" (click)="forgotPassword()">Forgot password</button>
    <div class="button-container">
      <div class="button-container--inner">
        <button type="submit" class="btn btn-primary" [disabled]="loginForm.invalid">Login</button>
      </div>
    </div>
  </div>
</form>
