<div class="container-fluid">
  <ngb-alert *ngIf="error$ | async as error" type="danger" [dismissible]="false">{{ error }}</ngb-alert>

  <app-agent-selector
    [filterForPermission]="agentSelectorPermissionRequired"
    label="Manage Agents"
    subLabel="Manage existing agents or appoint new agents for the selected customer, using the options below">
  </app-agent-selector>

  <div class="row">
    <div class="col">
        <button class="btn btn-link btn-lg float-right" (click)="newAgentRelationship()">
          Create new agent
          <fa-icon icon="plus" class="ml-2"></fa-icon>
        </button>
    </div>
  </div>

  <app-agents-table [activeAgent$]="activeAgent$"></app-agents-table>

</div>
