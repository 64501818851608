import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ClearModalComponent } from './clear-modal.component';

@NgModule({
  declarations: [ClearModalComponent],
  exports: [ClearModalComponent],
  entryComponents: [ClearModalComponent],
  imports: [
    CommonModule,
    NgbModule,
    ReactiveFormsModule
  ]
})
export class ClearModalModule {}
