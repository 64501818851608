import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { AuthenticatePayload, AuthenticateResult } from './auth';
import { LoginMessages } from './login/loginMessages.enum';
import { ForgotPasswordResult } from './auth.d';
import { handleHttpErrorMessage } from '../shared/handleHttpErrorMessage';


@Injectable()
export class AuthService {
  constructor(
    private http: HttpClient
  ) {}

  login = (authenticate: AuthenticatePayload): Observable<AuthenticateResult> => {
    return this.http.post<AuthenticateResult>(`${environment.apiUrl}/authenticate`, authenticate)
      .pipe(
        catchError(error => {
          switch (error.status) {
            case 401:
              throw new Error(error.error.message);
            case 423:
              throw new Error(error.error.message);
              // throw new Error(LoginMessages.invalidCredentialsError);
            default:
              throw new Error(LoginMessages.unknownError);
          }
        })
      );
  }

  logout = (customerID: string): Observable<any> => {
    return this.http.post<AuthenticateResult>(`${environment.apiUrl}/authenticate/logout/${customerID}`, customerID)
    .pipe(
      catchError((error: HttpErrorResponse) =>
        throwError(handleHttpErrorMessage(error, `Unable to logout`))
      )
    );
  }

  forgotPassword = (forgotPasswordPayload: AuthenticatePayload): Observable<ForgotPasswordResult> => {
    return this.http.post<ForgotPasswordResult>(`${environment.apiUrl}/password/forgot-password`, forgotPasswordPayload)
    .pipe(
      catchError(error => {
        // WARNING: forgot password should not return any clues about which emails exist in the database
        switch (error.status) {
          default:
            throw new Error(LoginMessages.unknownError);
        }
      })
    );
  }

}
