export { LeaseStatuses, LeaseStatus, LeaseRelatedTransactions, PermitStatus as PermitStatuses, TransactionStatus } from '@aquaonline/ng-models';

export enum ConditionType {
  Standard = 'STANDARD',
  Specific = 'SPECIFIC',
  Special = 'SPECIAL',
}

export enum PermitClass {
  Repository = 'Repository (leases)',
}

export enum TransactionType {
  AmendCustomer = 'AMEND_CUSTOMER',
  CreateCustomer = 'CREATE_CUSTOMER',
  ManageCustomerFlags = 'MANAGE_CUSTOMER_FLAGS',
  RegisterLeaseMortgageInterests = 'REGISTER_MORTGAGE_INTERESTS',
  DischargeLeaseMortgageInterests = 'DISCHARGE_MORTGAGE_INTERESTS',
  AmendFarm = 'AMEND_FARM',
  CreateFarm = 'CREATE_FARM',
  TransferLease = 'TRANSFER_LEASE',
  AmendLease = 'AMEND_LEASE',
  VaryLease = 'VARY_LEASE',
  ManageLeaseFlags = 'MANAGE_LEASE_FLAGS',
  CreateLease = 'CREATE_LEASE',
  AmendPermit = 'AMEND_PERMIT',
  GrantLease = 'GRANT_LEASE',
  ManagePermitFlags = 'MANAGE_PERMIT_FLAGS',
  CreatePermit = 'CREATE_PERMIT',
  GrantPermit = 'GRANT_PERMIT',
  AssociateLeasePermit = 'ASSOCIATE_LEASE_PERMIT',
  ReissueLease = 'REISSUE_LEASE',
  AssociateFarmPermit = 'ASSOCIATE_FARM_PERMIT',
  ConsolidateLease = 'CONSOLIDATE_LEASE',
  SubdivideLease = 'SUBDIVIDE_LEASE',
  GrantConsolidateLease = 'GRANT_CONSOLIDATE_LEASE',
  RenewLease = 'RENEW_LEASE',
  RenewLeaseBulk = 'BULK_RENEW_LEASE',
  SurrenderLease = 'SURRENDER_LEASE',
  GrantSubdivideLease = 'GRANT_SUBDIVIDE_LEASE',
  GrantRenewLease = 'GRANT_RENEW_LEASE',
  CancelLease = 'CANCEL_LEASE',
  SurrenderPartLease = 'SURRENDER_PART_LEASE',
  GrantSurrenderPartLease = 'GRANT_SURRENDER_PART_LEASE',
  VaryFarmSpeciesPermit = 'VARY_FARM_SPECIES_PERMIT',
  VaryLeaseSpeciesPermit = 'VARY_LEASE_SPECIES_PERMIT',
  VaryFarmAreaPermit = 'VARY_FARM_AREA_PERMIT',
  VaryLeaseAreaPermit = 'VARY_LEASE_AREA_PERMIT',
  BulkExpireLease = 'BULK_EXPIRE_LEASE',
  CancelPermitByHolder = 'CANCEL_PERMIT_BY_HOLDER',
  CancelPermitByDepartment = 'CANCEL_PERMIT_BY_DEPARTMENT',
  ExpireLease = 'EXPIRE_LEASE',
  CleanupSuccessLease = 'CLEANUP_SUCCESS_LEASE',
  CleanupUnsuccessfulLease = 'CLEANUP_UNSUCCESSFUL_LEASE',
  SubLetLease = 'SUBLET_LEASE',
  TransmitLease = 'TRANSMIT_LEASE',
  ChangeLeaseStatus = 'CHANGE_STATUS_LEASE',
}

export enum ADTransactionTypes {
  GrantRenewalLease = 'Grant Renewal',
  GrantSubdivideLease = 'Grant Subdivision',
  GrantConsolidateLease = 'Grant Consolidation',
  GrantSurrenderPartLease = 'Grant Part Surrender',
}
