import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { UserProfileStateSelector } from '../user-profile/user-profile.selector';
import { UserProfile } from '../user-profile/UserProfile';


@Component({
  selector: 'app-farms',
  templateUrl: './farms.component.html',
  styleUrls: ['./farms.component.scss']
})
export class FarmsComponent implements OnInit {

  constructor(
  ) { }

  ngOnInit() {

  }

}
