import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-collapsible-horizontal',
  templateUrl: './collapsible-horizontal.component.html',
  styleUrls: ['./collapsible-horizontal.component.scss']
})
export class CollapsibleHorizontalComponent implements OnInit {
  displayButtons = false;
  @Input() items: any;
  @Input() hideOverLength = 10;
  @Input() showAsLink = true;
  @Input() allShown: boolean;

  entity: string;
  itemsToShow: Array<string>;
  constructor(private router: Router) { }

  ngOnInit() {
    const collator = new Intl.Collator(undefined, {numeric: true, sensitivity: 'base'});
    this.itemsToShow = this.items.items.sort(collator.compare);
    this.entity = this.items.id;
  }

  showMore() {
    this.router.navigate([`/${this.items.id}/list`]);
  }

  encodeURI(item) {
    return encodeURIComponent(item);
  }
}
