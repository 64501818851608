import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ViewStatus } from '../enum';
import { LoadPermitScheduleSuccess, LoadPermitScheduleFailed, PermitScheduleActions, PermitScheduleActionTypes } from './permit-schedule.actions';

export interface State {
  permitScheduleDetail?: any;
  permitScheduleViewStatus: ViewStatus;
  permitScheduleErrorMessage?: string;
}

export const initialState: State = {
  permitScheduleViewStatus: ViewStatus.Initial
};

const loadPermitSchedule = (state: State): State => {
  return {
    ...state,
    permitScheduleViewStatus: ViewStatus.Loading,
  };
};

const loadPermitScheduleSuccess = (state: State, action: LoadPermitScheduleSuccess): State => {
  return {
    ...state,
    permitScheduleViewStatus: ViewStatus.LoadSuccess,
    permitScheduleDetail: action.payload,
  };
};

const loadPermitScheduleFailed = (state: State, action: LoadPermitScheduleFailed): State => ({
  ...state,
  permitScheduleViewStatus: ViewStatus.LoadFailed,
  permitScheduleErrorMessage: action.payload,
});

export function reducer(state = initialState, action: PermitScheduleActions): State {
  switch (action.type) {
    case PermitScheduleActionTypes.LoadPermitSchedule:
      return loadPermitSchedule(state);
    case PermitScheduleActionTypes.LoadPermitScheduleSuccess:
      return loadPermitScheduleSuccess(state, action);
    case PermitScheduleActionTypes.LoadPermitScheduleFailed:
      return loadPermitScheduleFailed(state, action);
    default:
      return state;
  }
}

const getPermitScheduleState = createFeatureSelector<State>('permit-schedule');

export const getPermitScheduleDetail = createSelector(getPermitScheduleState, state => state.permitScheduleDetail);
export const getPermitScheduleViewStatus = createSelector(getPermitScheduleState, state => state.permitScheduleViewStatus);
export const getPermitScheduleViewErrorMessage = createSelector(getPermitScheduleState, state => state.permitScheduleErrorMessage);
