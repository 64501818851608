<div class="welcome" *ngIf="customerDetail$ | async as customerDetail">
    <span>
      Welcome <a [routerLink]="['/account-profile']">{{ customerDetail.myDetail.customerName }}</a>
    </span>
      <span class="items">
        <a href="https://www.dpi.nsw.gov.au/fishing/aquaculture/forms" target="_blank">Application Forms</a> |
        <a href="https://www.dpi.nsw.gov.au/fishing/commercial/services-for-fishers/fishonline/fishonline-aquaculture/fishonline-aquadirect" target="_blank">Help</a> | 
        <a [routerLink]="['/additional-links']">Additional Resource Links</a> | 
        <a href="https://www.dpi.nsw.gov.au/fishing/commercial/services-for-fishers/fishonline/fishonline-contacts" target="_blank">Contact Us</a> | 
        <a href="https://www.dpi.nsw.gov.au/fishing/commercial/services-for-fishers/fishonline/terms-and-conditions" target="_blank">Terms & Conditions</a>
      </span>
  </div>
  