import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PrintFrameComponent } from './print-frame.component';

@NgModule({
  declarations: [PrintFrameComponent],
  exports: [PrintFrameComponent],
  imports: [
    CommonModule
  ]
})
export class PrintFrameModule { }
