import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';

import { AuthActionDispatcher } from '../auth.dispatcher';
import { BasePageComponent } from '../../shared/base-page.component';
import { AuthStateSelector } from '../auth.selector';
import { AuthenticateResult } from '../auth';
import { SetPasswordService } from './update-password.service';
import { isNil } from '../../shared/ramda-functions';

@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.scss']
})
export class SetPasswordComponent extends BasePageComponent implements OnInit, OnDestroy {
  passwordForm: FormGroup;
  error$: Observable<string>;
  userName: string;
  temporaryPassword: string;
  error: string;
  showError = false;
  loggedInUserSubscription$$: Subscription;
  setPasswordSubscription$$: Subscription;

  constructor(
    private setPasswordService: SetPasswordService,
    private authActionDispatcher: AuthActionDispatcher,
    private formBuilder: FormBuilder,
    private authStateSelector: AuthStateSelector,
    private ngxLoader: NgxUiLoaderService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    super();
  }

  ngOnInit() {
    this.createForm();
    this.loggedInUserSubscription$$ = this.authStateSelector.loggedInUser().subscribe((user: AuthenticateResult) => {
      this.passwordForm.get('username').setValue(user.username);
      this.passwordForm.get('password').setValue(user.temporaryPassword);
    });
  }

  createForm = () => {
    this.passwordForm = this.formBuilder.group({
      username: [undefined],
      password: [undefined],
      newPassword: [undefined, [Validators.required, Validators.minLength(16)]],
      reNewPassword: [undefined, [Validators.required, Validators.minLength(16)]],
    });
    this.passwordForm.setValidators(this.validatePasswords);
  }

  validatePasswords = (group: FormGroup) => {
    return group.controls.newPassword.value === group.controls.reNewPassword.value ? null : {passwordsNotMatch: true};
  }

  onSubmit = () => {
    this.showError = true;
    if (this.passwordForm.valid) {
      const { reNewPassword, ...rest } = this.passwordForm.value;
      this.setPasswordSubscription$$ = this.setPasswordService.setPassword({
        username: this.passwordForm.get('username').value,
        password: this.passwordForm.get('password').value,
        newPassword: this.passwordForm.get('newPassword').value,
      }).subscribe((result) => {
        this.authActionDispatcher.loginSuccess(result);
        this.router.navigate(['/home']);
      },
      (error) => {
        this.error = error.message;
      });
    }
  }

  ngOnDestroy() {
    if (!isNil(this.loggedInUserSubscription$$)) {
      this.loggedInUserSubscription$$.unsubscribe();
    }
    if (!isNil(this.setPasswordSubscription$$)) {
      this.setPasswordSubscription$$.unsubscribe();
    }
  }
}
