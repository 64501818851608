export enum BillingFeeType {
  PermitFee = 'PERMIT_FEE',
  ResearchLease = 'RESEARCH_LEASE',
  ResearchLeaseMin = 'RESEARCH_LEASE_MIN',
  ResearchLeasePerHa = 'RESEARCH_LEASE_PER_HA',
  LeaseSecurity = 'LEASE_SECURITY',
  LeaseRentMin = 'LEASE_RENT_MIN',
  LeaseRentPerHa = 'LEASE_RENT_PER_HA',
}

export interface BillingFee {
  billingFeeID?: number;
  feeType: string;
  projectTask: string;
  glCode: string;
  gstValue: number;
  taxCode: string;
  uom: string;
  fee: number;
  text: string;
  updatedDate?: string;
}

export interface BillingDefault {
  billingDefaultID?: number;
  recordTypeHolder: string;
  recordTypeDetail: string;
  postingKeyCustomerLine: string;
  postingKeyGLLine: string;
  calculateTax: string;
  dunningArea: string;
  paymentTerm: string;
  headerText: string;
  docDate?: Date;
  updatedDate?: string;
}

export interface IExcludedCustomer {
  id?: string;
  excludedCustomerID?: number;
  customerID: string;
  customerName: string;
  customerType: string;
  updatedDate: string;
}