const globalEnvironment = (window as any).QUOKKA;

if (!globalEnvironment) {
  throw new Error('Generate config.js in assets and include in index.html');
}

export const environment = {
  production: globalEnvironment.production,
  version: globalEnvironment.version,
  timestamp: globalEnvironment.timestamp,
  apiUrl: globalEnvironment.apiUrl, // Direct
};

