import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { ProductionReturnSaveSubmitComponent } from './production-return-save-submit.component';

@NgModule({
  declarations: [ProductionReturnSaveSubmitComponent],
  imports: [
    CommonModule,
    NgbModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    MatButtonModule,
  ],
  exports: [ProductionReturnSaveSubmitComponent],
})
export class ProductionReturnSaveSubmitModule {}
