export enum FieldTypes {
    Text = 'text',
    Input = 'input',
    Info = 'info',
    Remove = 'remove',
    PlaceHolder = 'placeHolder',
    DropDown = 'dropDown',
}

export enum FieldNames {
    SpeciesName = 'speciesName',
    Large = 'large',
    Medium = 'medium',
    Small = 'small',
    SpeciesID = 'speciesID',
    EstuaryID = 'estuaryID',
    EstuaryName = 'estuaryName',
    SecID = 'secID',
    RowNumber = 'rowNumber',
    Remove = 'remove',
    WildCaught = 'wildCaught',
    Hatchery = 'hatchery',
    Import = 'import',
    Origin = 'origin',
    Size = 'size',
    Quantity = 'quantity',
    Value = 'value',
    ProductType = 'productType',
    PermitID = 'permitID',
    PermitClassCode = 'permitClassCode',
    Average = 'average'
}