
import { Injectable } from '@angular/core';

import { isNilOrEmpty, pipe, map, props, adjust, defaultTo, pluck, flatten, filter, complement, uniqBy, prop } from '@aquaonline/ramda';
import { ISection } from '@aquaonline/ng-models';
import { NgProductionReturnModule } from './ng-production-return.module';

@Injectable({
  providedIn: NgProductionReturnModule
})
export class ProductionReturnSpeciesService {
  constructor() { }

  extractSpeciesFromSections = (sections: Array<ISection>, ) => {
    return pipe(
      map(
        pipe(
          props(['estuaries', 'species']),
          adjust(0,
                 pipe(
                  defaultTo({}),
                        pluck('species')
                  )
                )
        )
      ),
      flatten,
      filter(complement(isNilOrEmpty)),
      uniqBy(prop('speciesID')),
    )(sections);
  }
}
