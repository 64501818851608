import { Action } from '@ngrx/store';

import { CustomerDetail, CreateAgentPayload, Agent, UpdateAgentPayload } from './CustomerView';

export enum HomeActionTypes {
  ClearErrors = '[Home] Clear Errors',
  LoadCustomer = '[Home] Load Customer',
  LoadCustomerSuccess = '[Home] Load Customer Success',
  LoadCustomerFailed = '[Home] Load Customer Failed',
  DeleteAgentSuccess = '[Home] Delete Agent Success',
  DeleteAgentFailed = '[Home] Delete Agent Failed',
  DeleteAgent = '[Home] Delete Agent',
  CreateAgentSuccess = '[Home] CreateAgent Success',
  CreateAgentFailed = '[Home] CreateAgent Failed',
  CreateAgent = '[Home] CreateAgent',
  UpdateAgentSuccess = '[Home] UpdateAgent Success',
  UpdateAgentFailed = '[Home] UpdateAgent Failed',
  UpdateAgent = '[Home] UpdateAgent',
  Logout = '[Logout] Logout',
}

export class ClearErrors implements Action {
  readonly type = HomeActionTypes.ClearErrors;
  constructor() {}
}

export class LoadCustomer implements Action {
  readonly type = HomeActionTypes.LoadCustomer;
  constructor() {}
}

export class Logout implements Action {
  readonly type = HomeActionTypes.Logout;
}

export class CreateAgent implements Action {
  readonly type = HomeActionTypes.CreateAgent;
  constructor(public payload: CreateAgentPayload) {}
}

export class CreateAgentSuccess implements Action {
  readonly type = HomeActionTypes.CreateAgentSuccess;
  constructor(public payload: Agent) {}
}

export class CreateAgentFailed implements Action {
  readonly type = HomeActionTypes.CreateAgentFailed;
  constructor(public payload: any) {}
}

export class UpdateAgent implements Action {
  readonly type = HomeActionTypes.UpdateAgent;
  constructor(public payload: UpdateAgentPayload) {}
}

export class UpdateAgentSuccess implements Action {
  readonly type = HomeActionTypes.UpdateAgentSuccess;
  constructor(public payload: Agent) {}
}

export class UpdateAgentFailed implements Action {
  readonly type = HomeActionTypes.UpdateAgentFailed;
  constructor(public payload: any) {}
}

export class DeleteAgent implements Action {
  readonly type = HomeActionTypes.DeleteAgent;
  constructor(public payload: number) {}
}

export class DeleteAgentSuccess implements Action {
  readonly type = HomeActionTypes.DeleteAgentSuccess;
  constructor(public payload: number) {}
}

export class DeleteAgentFailed implements Action {
  readonly type = HomeActionTypes.DeleteAgentFailed;
  constructor(public payload: any) {}
}

export class LoadCustomerSuccess implements Action {
  readonly type = HomeActionTypes.LoadCustomerSuccess;
  constructor(public payload: CustomerDetail) {}
}

export class LoadCustomerFailed implements Action {
  readonly type = HomeActionTypes.LoadCustomerFailed;
  constructor(public payload: string) {}
}

export type HomeActions = ClearErrors
| LoadCustomer
| LoadCustomerSuccess
| LoadCustomerFailed
| DeleteAgent
| DeleteAgentSuccess
| DeleteAgentFailed
| CreateAgent
| CreateAgentSuccess
| CreateAgentFailed
| UpdateAgent
| UpdateAgentSuccess
| UpdateAgentFailed
| Logout
;
