import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError } from 'rxjs/operators';

import { LoadCustomerSuccess, LoadCustomerFailed, EditAccountProfileActionTypes } from './edit-account-profile.actions';
import { EditAccountProfileService } from './edit-account-profile.service';



@Injectable()
export class EditAccountProfileEffects {

  @Effect()
  loadCustomer$: Observable<LoadCustomerSuccess | LoadCustomerFailed> =
    this.actions$
      .pipe(
        ofType(EditAccountProfileActionTypes.LoadCustomer),
        switchMap(({payload}) =>
          this.EditAccountProfileService.getUser()
            .pipe(
              map(references => new LoadCustomerSuccess(references)),
              catchError(error => of(new LoadCustomerFailed(error)))
            )
        )
      );


  constructor(
    private actions$: Actions,
    // tslint:disable-next-line:no-shadowed-variable
    private EditAccountProfileService: EditAccountProfileService,
  ) {}
}
