<div class="container-fluid">
    <div class="row">
        <div class="button-container">
            <button type="button" mat-stroked-button color="warn" (click) = "exit()"><fa-icon icon="times"></fa-icon> Exit</button>
            <button type="button" mat-stroked-button color="warn" (click) = "clear()"><fa-icon icon="eraser"></fa-icon> Clear</button>
            <button type="button" mat-stroked-button color="primary" (click) = "save()"><fa-icon icon="save"></fa-icon> Save</button>
            <button type="button" mat-stroked-button color="primary" (click) = "submit()"><span class="btn-span"><fa-icon icon="thumbs-up"></fa-icon> Save & Submit</span></button>
        </div>
    </div>
</div>

