import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { BreadcrumbStateSelector } from './breadcrumb.selector';
import { Breadcrumb } from './Breadcrumb';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
  items$: Observable<Array<Breadcrumb>>;
  constructor(
    private breadcrumbStateSelector: BreadcrumbStateSelector,
  ) { }

  ngOnInit() {
    this.items$ = this.breadcrumbStateSelector.items();
  }

}
