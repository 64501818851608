export enum LeaseStatuses {
  Available = 'AVAILABLE',
  Current = 'CURRENT',
  Inactive = 'INACTIVE',
  Processing = 'PROCESSING',
  TerminatedPendingCleanup = 'TERMINATED_PENDING_CLEANUP',
  All = '',
}

export enum LeaseStatus {
  Available = 'Available',
  Current = 'Current',
  Inactive = 'Inactive',
  Processing = 'Processing',
  TerminatedPendingCleanup = 'Terminated_pending_cleanup',
  All = '',
}

export enum LeaseRelatedTransactions {
  RegisterLeaseMortgageInterests = 'REGISTER_MORTGAGE_INTERESTS',
  DischargeLeaseMortgageInterests = 'DISCHARGE_MORTGAGE_INTERESTS',
  TransferLease = 'TRANSFER_LEASE',
  AmendLease = 'AMEND_LEASE',
  VaryLease = 'VARY_LEASE',
  CreateLease = 'CREATE_LEASE',
  GrantLease = 'GRANT_LEASE',
  ReissueLease = 'REISSUE_LEASE',
  ConsolidateLease = 'CONSOLIDATE_LEASE',
  SubdivideLease = 'SUBDIVIDE_LEASE',
  GrantConsolidateLease = 'GRANT_CONSOLIDATE_LEASE',
  RenewLease = 'RENEW_LEASE',
  RenewLeaseBulk = 'BULK_RENEW_LEASE',
  SurrenderLease = 'SURRENDER_LEASE',
  GrantSubdivideLease = 'GRANT_SUBDIVIDE_LEASE',
  GrantRenewLease = 'GRANT_RENEW_LEASE',
  CancelLease = 'CANCEL_LEASE',
  SurrenderPartLease = 'SURRENDER_PART_LEASE',
  GrantSurrenderPartLease = 'GRANT_SURRENDER_PART_LEASE',
  BulkExpireLease = 'BULK_EXPIRE_LEASE',
  ExpireLease = 'EXPIRE_LEASE',
  CleanupSuccessLease = 'CLEANUP_SUCCESS_LEASE',
  CleanupUnsuccessfulLease = 'CLEANUP_UNSUCCESSFUL_LEASE',
  SubLetLease = 'SUBLET_LEASE',
  TransmitLease = 'TRANSMIT_LEASE',
  ChangeLeaseStatus = 'CHANGE_STATUS_LEASE',
  AmendConditionOnLease = 'AMEND_CONDITION_ON_LEASE',
  CreateConditionOnLease = 'CREATE_CONDITION_ON_LEASE',
  DeactivateConditionFromLease = 'DEACTIVATE_CONDITION_FROM_LEASE',
}

export interface Lease {
  leaseID: string;
  customerID: string;
  leaseClassID: number;
  leaseStatusID: string;
  leaseExpiryDate: Date;
  trueLeaseArea: number;
  term: number;
  tenancy: string;
  location: string;
  estuaryID: number;
  fisheriesOfficeID: number;
  LGAID: number;
  OISASTypeID: number;
  cleanupProgramID: number;
  cleanupContinueAction: number;
  bondExempt: number;
  noOfLeaseParts: number;
  cleanupResponsibleCustomerID: string;
  pendingRenewal: number;
  S171NoticeExpiryDate: Date;
  transactionID: number;
}
