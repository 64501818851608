<ng-container *ngIf="(isPrintPage$ | async)">
  <router-outlet></router-outlet>
</ng-container>
<ng-container *ngIf="!(isPrintPage$ | async)">
  <div class="container" *ngIf="!mobileDevice; else mobileTemplate" [ngClass]="{ 'app-container': !isLoginRoute }">
    <app-header></app-header>
    <app-page-header *ngIf="loggedIn === loggedInStatus.loggedIn" [loggedIn]="loggedIn"></app-page-header>
    <mat-drawer-container class="main-container">
      <mat-drawer class="drawer" mode="side" *ngIf="loggedIn === loggedInStatus.loggedIn" opened>
        <app-menu class="menu"></app-menu>
      </mat-drawer>
      <mat-drawer class="drawer" mode="side" *ngIf="loggedIn !== loggedInStatus.loggedIn" closed>
        </mat-drawer>
      <mat-drawer-content>
        <div class="breadcrumb" *ngIf="loggedIn === loggedInStatus.loggedIn">
          <app-breadcrumb></app-breadcrumb>
          <div class="time-stamp" *ngIf="userProfile$ | async as userProfile">
            Your last login: {{ lastLoggedIn$ | async | date: 'EE, MMM d, y, h:mm a' }}
          </div>
        </div>
        <router-outlet></router-outlet>
      </mat-drawer-content>
    </mat-drawer-container>
    <app-footer [isMobile]="false"></app-footer>
    <ngx-ui-loader></ngx-ui-loader>
  </div>
  <ng-template #mobileTemplate>
    <mat-sidenav-container class="ad-sidenav-container">
      <mat-sidenav #snav [mode]="mobileDevice ? 'over' : 'side'"
        [fixedInViewport]="mobileDevice" >
          <mat-nav-list>
            <app-menu [sidenav] = "snav" (accordianMenuClicked) = "menuListener(snav)" class="menu" *ngIf="loggedIn === loggedInStatus.loggedIn" (click)="snavToggle(snav)"></app-menu>
          </mat-nav-list>
      </mat-sidenav>
      <mat-sidenav-content>
          <app-header (buttonListener)="buttonListener(snav)"></app-header>
          <div class="mobile-view">
            <app-page-header *ngIf="loggedIn === loggedInStatus.loggedIn" [loggedIn]="loggedIn"></app-page-header>
          </div>
        <div class="breadcrumb-container">
          <div class="breadcrumb" *ngIf="loggedIn === loggedInStatus.loggedIn">
            <app-breadcrumb></app-breadcrumb>
            <div class="time-stamp" *ngIf="userProfile$ | async as userProfile">
              Your last login: {{ lastLoggedIn$ | async | date: 'EE, MMM d, y, h:mm a' }}
            </div>
          </div>
        </div>
        <router-outlet></router-outlet>
        <app-footer [isMobile]="true"></app-footer>
      </mat-sidenav-content>
    </mat-sidenav-container>
    <ngx-ui-loader></ngx-ui-loader>
  </ng-template>
</ng-container>


