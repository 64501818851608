import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription, combineLatest, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { PermitScheduleService } from './permit-schedule.service';
import { BasePageComponent } from '../shared/base-page.component';
import { Permissions } from '../enum';
import { AgentSelectorStateSelector } from '../agent-selector/agent-selector.selector';
import { HomeStateSelector } from '../home/home.selector';
import { isNotNilAndEmpty } from '../shared/ramda-functions';
import { CONTEXTUAL_MESSAGE } from './enum';
import { PermitScheduleActionDispatcher } from './permit-schedule.dispatcher';
import { PermitScheduleStateSelector } from './permit-schedule.selector';
import { PermitPrintData } from '.';

@Component({
  selector: 'app-permit-schedule',
  templateUrl: './permit-schedule.component.html',
  styleUrls: ['./permit-schedule.component.scss']
})
export class PermitScheduleComponent extends BasePageComponent implements OnInit, OnDestroy {
  steams$$: Subscription;

  isAgentForAnyone$: Observable<Boolean>;
  permitScheduleDetails$: Observable<any>;
  hasPermitSchedule$: Observable<any>;
  print = new Subject<boolean>();

  agentSelectorPermissionRequired = Permissions.inquiries;
  captionText = '';
  customerID: string;
  permitPrintData: PermitPrintData;
  hasPermitSchedule;
  contextualMessage = CONTEXTUAL_MESSAGE;

  constructor(
    private permitScheduleService: PermitScheduleService,
    private permitScheduleActionDispatcher: PermitScheduleActionDispatcher,
    private permitScheduleStateSelector: PermitScheduleStateSelector,
    private agentSelectorStateSelector: AgentSelectorStateSelector,
    private homeStateSelector: HomeStateSelector,
    private route: ActivatedRoute,
  ) {
    super();
  }

  ngOnInit() {
    this.combineListeners();
  }

  combineListeners = () => {
    this.steams$$ = combineLatest([
      this.agentSelectorStateSelector.activeAgent(),
      this.agentSelectorStateSelector.isAgentForAnyone(this.agentSelectorPermissionRequired),
      this.route.queryParams,
      this.homeStateSelector.customerDetail()
    ]).subscribe(([activeAgent, isAgentForAnyone, params, customerDetail]) => {
      this.customerID = activeAgent.agentFor;
      this.permitScheduleActionDispatcher.loadPermitSchedule(activeAgent.agentFor);
      this.permitScheduleDetails$ = this.permitScheduleStateSelector.permitScheduleDetail().pipe(
        tap(permitScheduleDetail => {
          this.permitPrintData = this.permitScheduleService.createPermitPrintData(permitScheduleDetail, activeAgent.agentFor);
        })
      );
      this.hasPermitSchedule$ = this.permitScheduleStateSelector.hasPermitSchedule();
    });
  }

  ngOnDestroy() {
    if (isNotNilAndEmpty(this.steams$$)) {
      this.steams$$.unsubscribe();
    }
  }
}
