import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { FormArray, FormGroup } from '@angular/forms';

import { SubSection, IInputData, ISection, ISpeciesRow } from '@aquaonline/ng-models';
import { propEq, isNilOrEmpty } from '@aquaonline/ramda';
import { NgProductionReturnModule } from './ng-production-return.module';

@Injectable({
  providedIn: NgProductionReturnModule
})
export class ProductionReturnService {
  SubSection = SubSection;
  removeSubject = new Subject<any>();
  constructor() { }

  findSpeciesName = (input: IInputData, sections: Array<ISection>) => {
    if (!propEq('subSection', SubSection.oysterSold, input)) { return null; }

    return sections.find(section => propEq('secID', input.secID, section))
      .estuaries.find(estuary => propEq('estuaryID', +input.estuaryID, estuary))
      .species.find(Aspecies => propEq('speciesID', +input.speciesID, Aspecies)).speciesName;
  }

  sectionThreeValidator = (transactionForm: FormGroup) => {
    if (isNilOrEmpty(transactionForm.get('sectionThreeAForm')) ||
     isNilOrEmpty(transactionForm.get('sectionThreeBForm')) || isNilOrEmpty(transactionForm.get('sectionThreeCForm'))) {
      return null;
    }

    const aControl = transactionForm.get('sectionThreeAForm') as FormArray;
    const bControl = transactionForm.get('sectionThreeBForm') as FormArray;
    const cControl = transactionForm.get('sectionThreeCForm') as FormArray;
    const aSum = {};
    aControl.value.forEach(value => {
      if (aSum[value.speciesName]) {
        aSum[value.speciesName] += value.large + value.medium + value.small;
      } else {
        aSum[value.speciesName] = value.large + value.medium + value.small;
      }
    });
    const bSum: number[] = bControl.value.map(e => e.large + e.medium + e.small);
    const cSum: number[] = cControl.value.map(e => e.wildCaught + e.hatchery + e.import);
    let emptyFields = {};
    cSum.forEach((value, index) => {
      if (value) {
        if (!bSum[index] && !aSum[cControl.value[index].speciesName]) {
          emptyFields[index] = cControl.value[index].speciesName;
        }
      }
    });
    const errorMsg = `Please enter values for ${Object.values(emptyFields).join(', ')}.`;
    return Object.keys(emptyFields).length ? { requiredRow: true, errorMsg} : null;
  }

  sectionThreeRequiredFieldsValidator = (transactionForm: FormGroup) => {
    if (isNilOrEmpty(transactionForm.get('sectionThreeAForm')) || isNilOrEmpty(transactionForm.get('sectionThreeBForm'))) {
      return null;
    }
    if (!transactionForm.get('sectionThreeAForm').value.length || !transactionForm.get('sectionThreeBForm').value.length) {
      return null;
    }
    const aControl = transactionForm.get('sectionThreeAForm') as FormArray;
    const bControl = transactionForm.get('sectionThreeBForm') as FormArray;
    const sectionARows: ISpeciesRow = {};
    aControl.value.forEach(value => {
      if (sectionARows[value.speciesName]) {
        if (value.large) {
          sectionARows[value.speciesName]['large'] += value.large;
        }
        if (value.medium) {
          sectionARows[value.speciesName]['medium'] += value.medium;
        }
        if (value.small) {
          sectionARows[value.speciesName]['small'] += value.small;
        }
      } else {
        sectionARows[value.speciesName] = {'large': value.large, 'medium': value.medium,
         'small': value.small, 'speciesName': value.speciesName};
      }
    });
    let emptyFields = {};
    Object.values(sectionARows).forEach((aValue, index) => {
      if (bControl.value[index]) {
        if (aValue.large) {
          if (bControl.value[index].large === null) {
              if (emptyFields[aValue.speciesName]) {
                  emptyFields[aValue.speciesName].push('large');
              } else {
                  emptyFields[aValue.speciesName] = ['large'];
              }
          }
      } else {
          if (bControl.value[index].large) {
              if (emptyFields[aValue.speciesName]) {
                  emptyFields[aValue.speciesName].push('large');
              } else {
                  emptyFields[aValue.speciesName] = ['large'];
              }
          }
      }
      if (aValue.medium) {
          if (bControl.value[index].medium === null) {
              if (emptyFields[aValue.speciesName]) {
                  emptyFields[aValue.speciesName].push('medium');
              } else {
                  emptyFields[aValue.speciesName] = ['medium'];
              }
          }
      } else {
          if (bControl.value[index].medium) {
              if (emptyFields[aValue.speciesName]) {
                  emptyFields[aValue.speciesName].push('medium');
              } else {
                  emptyFields[aValue.speciesName] = ['medium'];
              }
          }
      }
      if (aValue.small) {
          if (bControl.value[index].small === null) {
              if (emptyFields[aValue.speciesName]) {
                  emptyFields[aValue.speciesName].push('small');
              } else {
                  emptyFields[aValue.speciesName] = ['small'];
              }
          }
      } else {
          if (bControl.value[index].small) {
              if (emptyFields[aValue.speciesName]) {
                  emptyFields[aValue.speciesName].push('small');
              } else {
                  emptyFields[aValue.speciesName] = ['small'];
              }
          }
        }
      }
    });
    let errorMsg = [];
    Object.keys(emptyFields).forEach(key => {
      errorMsg.push('No data provided for ' + emptyFields[key].join(', ') + ' ' + key + '. ');
    });
    return Object.keys(emptyFields).length ? { requiredFields: true, errorMsg } : null;
  }
}
