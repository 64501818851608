import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  OnDestroy
} from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { Subscription } from 'rxjs';

import { LoggedInStatus } from '../auth/auth.reducer';
import { AuthStateSelector } from '../auth/auth.selector';
import { DeviceSize } from '../enum';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Output() buttonListener: EventEmitter<string> = new EventEmitter();
  mobileQuery: MediaQueryList;
  mobileQueryMinMax: MediaQueryList;
  fillerNav = Array.from({length: 50}, (_, i) => `Nav Item ${i + 1}`);
  authSubsription$$: Subscription;
  loggedInStatus = LoggedInStatus;
  loggedIn = LoggedInStatus.initial;
  private _mobileQueryListener: () => void;
  constructor(
    media: MediaMatcher,
    public changeDetectorRef: ChangeDetectorRef,
    private authStateSelector: AuthStateSelector,
  ) {
    this.mobileQuery = media.matchMedia(`(max-width: ${DeviceSize.largeMobileMaxWidth}px)`);
    this.mobileQueryMinMax = media.matchMedia(`(min-width: ${DeviceSize.largeMobileMinWidth}px)`);
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    //this.mobileQuery.addEventListener('change', this._mobileQueryListener);
    this.mobileQuery.addListener(this._mobileQueryListener);
    this.mobileQueryMinMax.addListener(this._mobileQueryListener);
  }

  ngOnInit() {
    this.authSubsription$$ = this.authStateSelector.loggedIn().subscribe(loggedIn => {
      this.loggedIn = loggedIn;
      this.changeDetectorRef.detectChanges();
    });
  }
  // tslint:disable-next-line:use-life-cycle-interface
  ngOnDestroy(): void {
    //this.mobileQuery.removeEventListener('change', this._mobileQueryListener);
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.mobileQueryMinMax.removeListener(this._mobileQueryListener);
    if (this.authSubsription$$) {
      this.authSubsription$$.unsubscribe();
    }
  }

  toggle = () => {
    this.buttonListener.emit('clicked');
  }
}
