import { Action } from '@ngrx/store';

import { Breadcrumb } from './Breadcrumb';

export enum BreadcrumbActionTypes {
  Add = '[Breadcrumb] Add',
  Clear = '[Breadcrumb] Clear'
}

export class AddBreadcrumb implements Action {
  readonly type = BreadcrumbActionTypes.Add;
  constructor(public payload: Breadcrumb) {}
}

export class ClearBreadcrumb implements Action {
  readonly type = BreadcrumbActionTypes.Clear;
}


export type BreadcrumbActions = AddBreadcrumb | ClearBreadcrumb;
