import { TransactionType, TransactionStatus } from './transaction';

// TODO: Should we rename this to PermitClasses Text or something?
export enum PermitClasses {
  A = 'Extensive (leases)',
  B = 'Intensive (leases)',
  C = 'Extensive Land Based',
  D = 'Intensive Land Based',
  E = 'Multiple Land Based',
  F = 'Fishout',
  G = 'Experimental',
  H = 'Hatchery',
  RF = 'Repository (farm)',
  RL = 'Repository (leases)',
}

export enum PermitClassesID {
  A = 3,
  B = 7,
  C = 4,
  D = 8,
  E = 9,
  F = 5,
  G = 2,
  H = 6,
  RF = 11,
  RL = 10,
}

export enum PermitStatus {
  Current = 'CURRENT',
  Cancelled = 'CANCELLED',
  Inactive = 'INACTIVE',
  Processing = 'PROCESSING',
  Suspended = 'SUSPENDED',
}

export enum PermitGroup {
  Farm = 'FARM',
  Lease = 'LEASE',
}

export enum PermitRelatedTransactions {
  AmendPermit = 'AMEND_PERMIT',
  CreatePermit = 'CREATE_PERMIT',
  GrantPermit = 'GRANT_PERMIT',
  VaryFarmSpeciesPermit = 'VARY_FARM_SPECIES_PERMIT',
  VaryLeaseSpeciesPermit = 'VARY_LEASE_SPECIES_PERMIT',
  VaryFarmAreaPermit = 'VARY_FARM_AREA_PERMIT',
  VaryLeaseAreaPermit = 'VARY_LEASE_AREA_PERMIT',
  CancelPermitByHolder = 'CANCEL_PERMIT_BY_HOLDER',
  CancelPermitByDepartment = 'CANCEL_PERMIT_BY_DEPARTMENT',
  AmendConditionOnPermit = 'AMEND_CONDITION_ON_PERMIT',
  CreateConditionOnPermit = 'CREATE_CONDITION_ON_PERMIT',
  DeactivateConditionFromPermit = 'DEACTIVATE_CONDITION_FROM_PERMIT',
}

export interface PermitTransaction {
  transactionID: string;
  transactionType: TransactionType;
  transactionStatus: TransactionStatus;
  createdDateTime: Date;
  finalisedDateTime: Date;
  transactionTypeID?: string;
  grantPermitGranted?: number;
}

export interface Permit {
  permitID: string;
  customerID: string;
  permitClassID: number;
  issueDate: Date;
  permitStatusID: string;
  broodstockPermit: string;
  uandaReceived: number;
  publicLiabilityPolicyNo: string;
  publicLiabilityExpiry: Date;
  publicLiabilityAmount: string;
  publicLiabilityCompany: string;
  publicLiabilityAuditDate: Date;
  HQASAccreditationNo: string;
  WSPA: number;
  transactionID: number;
}

export interface PermitSnapshot {
  permitSnapshotID?: number;
  invoiceRequestID: number;
  permitHolderCustomerID: string;
  permitID: string;
  permitStatusID: string;
  permitClassID: number;
  totalAuthorisedArea: number;
  leaseSecurityArea: number;
  WSPA: number;
  fee: number;
}

export interface PermitDetails {
  permitID: string;
  permitHolderCustomerID: string;
  permitHolderCustomerName: string;
  preferredContact: string;
  folderNo: string;
  issueDate: Date | string;
  permitClass: string;
  permitGroup: string;
  permitStatus: string;
  broodstockPermit: string;
  hqasAccreditationNo: string;
  totalAuthorisedArea: number;
  uandaReceived: number;
  policyNumber: string;
  insuranceCompany: string;
  expiryDate: Date | string;
  leaseSecurityArea: number;
  WSPA: number;
}
