<div class="container-fluid">
  <app-agent-selector
    [filterForPermission]="agentSelectorPermissionRequired"
    [label]="'Registration Details'">
  </app-agent-selector>

  <div *ngIf="registrationDetails$ | async as registrationDetails">
    <h5 style="text-align: justify">{{ message }}</h5>
    <div class="row">
      <div class="table-container">
        <table class="table table-striped">
          <caption>Current Registration Details for {{ activeAgent?.agentForName }}</caption>
          <thead>
            <tr>
              <th class="headings" colspan="4">
                Account summary
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="titles">
                Customer No
              </td>
              <td>
                {{registrationDetails.myDetail.customerID}}
              </td>
              <td class="titles">
                Trading as
              </td>
              <td>
                {{registrationDetails.myDetail.tradingAs}}
              </td>
            </tr>
            <tr>
              <td class="titles">
                Customer name
              </td>
              <td>
                {{registrationDetails.myDetail.customerName}}
              </td>
              <td class="titles">
                ACN
              </td>
              <td>
                {{registrationDetails.myDetail.ACN}}
              </td>
            </tr>
            <tr>
              <td class="titles">
              Status
              </td>
              <td>
                {{registrationDetails.myDetail.customerStatus}}
              </td>
              <td class="titles">
                ABN
                </td>
                <td>
                  {{registrationDetails.myDetail.ABN}}
                </td>
            </tr>
            <tr>
              <td class="titles">
                Customer type
              </td>
              <td>
                {{registrationDetails.myDetail.customerType}}
              </td>
              <td class="titles">
              </td>
              <td>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <ngb-tabset class="row cards" [destroyOnHide]="false">
      <ngb-tab>
        <ng-template ngbTabTitle>Address</ng-template>
        <ng-template ngbTabContent>
          <app-customer-view-address [addresses]="registrationDetails.myDetail"></app-customer-view-address>
        </ng-template>
      </ngb-tab>
      <ngb-tab>
        <ng-template ngbTabTitle>Contacts ({{ registrationDetails.contacts?.length | defaultIfNil: 0 }})</ng-template>
        <ng-template ngbTabContent>
          <app-customer-view-contacts [contacts]="registrationDetails.contacts"></app-customer-view-contacts>
        </ng-template>
      </ngb-tab>
      <ngb-tab *ngIf="registrationDetails?.myDetail?.customerTypeID !== CustomerTypes.Individual">
        <ng-template ngbTabTitle>{{ componentCustomersTabTitle }}</ng-template>
        <ng-template ngbTabContent>
          <app-component-customer-tab [registrationDetails]="registrationDetails" (tabTitle)="componentCustomersTabTitle = $event"></app-component-customer-tab>
        </ng-template>
      </ngb-tab>
    </ngb-tabset>
  </div>
</div>
