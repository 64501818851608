import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { withLatestFrom, distinctUntilChanged } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { SubSink } from 'subsink';

import { Agent, ActiveAgent, CustomerSummary } from '../../home/CustomerView';
import { AgentEditDeleteModalComponent } from '../delete-modal/agent-edit-delete-modal.component';
import { equals } from '../../shared/ramda-functions';
import { AgentsTableDispatcher } from './agents-table.dispatcher';
import { AgentTableStateSelector } from './agents-table.selector';
import { HomeStateSelector } from '../../home/home.selector';
import { getAgentForState, AgentSelectorState } from '../../agent-selector/agent-selector.reducers';
import { propEq, prop } from '@aquaonline/ramda';
import { SetActiveAgent } from '../../agent-selector/agent-selector.actions';

@Component({
  selector: 'app-agents-table',
  templateUrl: './agents-table.component.html',
  styleUrls: ['./agents-table.component.scss']
})
export class AgentsTableComponent implements OnInit, OnDestroy {
  public subs = new SubSink();
  @Input() activeAgent$: Observable<ActiveAgent>;
  captionText: string;
  agents$: Observable<Agent[]>;
  activeAgent$$: Subscription;
  loggedInUserID: string;
  loggedInUserIDSubscription$$: Subscription;
  activeAgentCustomerID: string;
  constructor(
    private agentTableStateSelector: AgentTableStateSelector,
    private agentsTableDispatcher: AgentsTableDispatcher,
    private modalService: NgbModal,
    protected router: Router,
    private homeStateSelector: HomeStateSelector,
    private agentSelectorStore: Store<AgentSelectorState>,
  ) { }

  ngOnInit() {
    this.agents$ = this.agentTableStateSelector.getAgentRelationshipsFor();
    this.subs.sink = this.homeStateSelector.getLoggedInUserID().subscribe(customerID => this.loggedInUserID = customerID);

    this.subs.sink = this.activeAgent$.pipe(
      distinctUntilChanged(),
      withLatestFrom(this.agentSelectorStore.select(getAgentForState), this.homeStateSelector.getLoggedInUser())
    ).subscribe(([activeAgent, agentRelationships, loggedInUser]) => {
      const agentRelationsForActiveAgent = agentRelationships.find(agentRelationship => propEq('agentFor', activeAgent.agentFor, agentRelationship));
      prop('agentManagement', agentRelationsForActiveAgent) ? this.getAgentRelationShips(activeAgent) : this.setLoggedInUserAsActiveAgent(loggedInUser);
    });
  }

  getAgentRelationShips = (activeAgent: ActiveAgent) => {
    this.captionText = activeAgent.agentForName;
    if (!this.activeAgentCustomerID || this.activeAgentCustomerID !== activeAgent.agentFor) {
      this.activeAgentCustomerID = activeAgent.agentFor;
      this.agentsTableDispatcher.getAgentRelationshipsFor(activeAgent.agentFor);
    }
  }

  setLoggedInUserAsActiveAgent = (loggedInUser: CustomerSummary) => {
    this.captionText = loggedInUser.customerName;
    this.activeAgentCustomerID = loggedInUser.customerID;
    this.agentSelectorStore.dispatch(new SetActiveAgent({
      agentFor: loggedInUser.customerID,
      agentForName: loggedInUser.customerName
    }));
  }

  edit(agentRelationshipID, customerID) {
    this.router.navigate(['manage-agents', 'view', agentRelationshipID,
      {loggedInUserID: this.loggedInUserID, activeAgentCustomerID: this.activeAgentCustomerID, customerID}]);
  }

  showDeleteModal(agentRelationshipID: string, customerID: string) {
    const modalRef = this.modalService.open(AgentEditDeleteModalComponent, {
      windowClass: 'delete-modal',
      backdrop: 'static',
    });

    modalRef.result.then(result => equals(result, 'delete') ? this.delete(agentRelationshipID, this.loggedInUserID,
      this.activeAgentCustomerID, customerID) : null);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  private delete(agentRelationshipID, loggedInUserID, activeAgentCustomerID, customerID) {
    this.agentsTableDispatcher.deleteAgent(agentRelationshipID, loggedInUserID, activeAgentCustomerID, customerID);
  }

}
