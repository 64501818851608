import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';

import { CustomerTypes, RegistrationDetails, Partner, Director, Delegate } from '../../home/CustomerView';
import { ComponentCustomer } from '../../home/CustomerView';

@Component({
  selector: 'app-component-customer-tab',
  templateUrl: './component-customer-tab.component.html',
})
export class ComponentCustomerTabComponent implements OnChanges {

  @Input() registrationDetails: RegistrationDetails;
  @Output() tabTitle = new EventEmitter<string>();

  customerTypeConfigMap = {
    [CustomerTypes.Company]: {
      title: 'Directors',
      customerNumberResolver: (x: Director) => x.directorCustomerId,
      componentCustomersResolver: (x: RegistrationDetails) => x.directors,
    },
    [CustomerTypes.Entity]: {
      title: 'Delegates',
      customerNumberResolver: (x: Delegate) => x.delegateCustomerId,
      componentCustomersResolver: (x: RegistrationDetails) => x.delegates,
    },
    [CustomerTypes.Partnership]: {
      title: 'Partners',
      customerNumberResolver: (x: Partner) => x.partnerCustomerId,
      componentCustomersResolver: (x: RegistrationDetails) => x.partners,
    },
  };

  title: string;
  componentCustomers: ComponentCustomer[];
  customerNumberResolver: (ComponentCustomer) => string;
  CustomerTypes = CustomerTypes;

  constructor() { }

  ngOnChanges() {
    if (!this.registrationDetails) {
      this.title = null;
      this.componentCustomers = null;
      return;
    }

    const config = this.customerTypeConfigMap[this.registrationDetails.myDetail.customerTypeID];
    this.title = config.title;
    this.componentCustomers = config.componentCustomersResolver(this.registrationDetails);
    this.customerNumberResolver = config.customerNumberResolver;
    this.tabTitle.next(`${this.title} (${this.componentCustomers.length})`);
  }
}
