import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { PasswordPayload, NewPasswordResult } from '../auth';
import { LoginMessages } from '../login/loginMessages.enum';


@Injectable()
export class SetPasswordService {
  constructor(
    private http: HttpClient
  ) {}

  setPassword = (password: PasswordPayload): Observable<NewPasswordResult> => {
    return this.http.post<NewPasswordResult>(`${environment.apiUrl}/password`, password)
      .pipe(
      catchError(error => {
        switch (error.status) {
          case 401:
              throw new Error(LoginMessages.invalidCredentialsError);
          case 409:
              throw new Error(LoginMessages.forgotPasswordUsedPasswordError);
          default:
              throw new Error(LoginMessages.unknownError);
        }
      })
    );
  }
}
