import { Field } from '..';

export const editAccountProfileFields: Array<Field> = [
  {
    name: 'customerID',
    isReadonly: true,
  },
  {
    name: 'customerType',
    isReadonly: true,
  },
  {
    name: 'customerStatus',
    isReadonly: true,
  },
  {
    name: 'customerName',
    isRequired: true,
  },
  {
    name: 'givenName',
  },
  {
    name: 'surname',
  },
  {
    name: 'customerTradingNames',
  },
  {
    name: 'customerTypeID',
  },
  {
    name: 'acn'
  },
  {
    name: 'abn'
  },
  {
    name: 'documentFolderNumber'
  },
  {
    name: 'debtorNumber'
  },
  {
    name: 'physicalStreetAddress1',
  },
  {
    name: 'physicalStreetAddress2',
  },
  {
    name: 'physicalTownSuburb',
  },
  {
    name: 'physicalState',
  },
  {
    name: 'physicalPostcode',
  },
  {
    name: 'mailingStreetAddress1',
  },
  {
    name: 'mailingStreetAddress2',
  },
  {
    name: 'mailingTownSuburb',
  },
  {
    name: 'mailingState',
  },
  {
    name: 'mailingPostcode',
  },
  {
    name: 'billingStreetAddress',
  },
  {
    name: 'billingTownSuburb',
  },
  {
    name: 'billingState',
  },
  {
    name: 'billingPostcode',
  },
  {
    name: 'billingCustomerName',
  },
  {
    name: 'billingCustomerNameExtension',
  },
];
