<h3>{{heading}}</h3>
<div class="row">
  <div class="table-container">
    <table class="table table-striped">
      <thead>
        <tr>
          <th>
            Year
          </th>
          <th>
            Permit no
          </th>
          <th>
            Customer no
          </th>
          <th>
            Customer name
          </th>
          <th>
            Action
          </th>
        </tr>
      </thead>
      <tbody *ngIf="productionReturns">
        <tr *ngFor="let pr of productionReturns">
          <td>
            {{ pr.financialYear }}
          </td>
          <td>
            <span *ngFor="let permitID of pr.permits; let i = index;">
              <span *ngIf="i < pr.permits.length - 1">
                {{permitID}},
              </span>
              <span *ngIf="i === pr.permits.length - 1">
                {{permitID}}
              </span>
            </span>
          </td>
          <td>
            {{ pr.customerID }}
          </td>
          <td>
            {{ pr.customerName }}
          </td>
          <td>
             <a *ngIf="actionText === ActionText.Complete" class="link" (click) = "editProductionReturn(pr.customerID, pr.transactionID)">
              {{actionText}}
            </a>
            <a *ngIf="actionText === ActionText.View" class="link" (click) = "viewProductionReturn(pr.customerID, pr.productionReturnID)">
              {{actionText}}
            </a>
          </td>
        </tr>
        <tr *ngIf="productionReturns.length === 0">
          <td [colSpan]="8">
              No production returns available.
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
