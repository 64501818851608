import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError } from 'rxjs/operators';

import { LoadCustomerSuccess,
  LoadCustomerFailed,
  HomeActionTypes,
  DeleteAgentSuccess,
  DeleteAgentFailed,
  CreateAgentFailed,
  CreateAgentSuccess,
  UpdateAgentSuccess,
  UpdateAgentFailed,
} from './home.actions';
import { CustomerViewService } from './customer-view.service';
import { AquaDirectAgentService } from '../manage-agents/aqua-direct-agent.service';
import { AgentsTableTypes, DeleteAgentRelationshipSuccess } from '../manage-agents/agents-table/agents-table.actions';


@Injectable()
export class HomeEffects {

  @Effect()
  loadCustomer$: Observable<LoadCustomerSuccess | LoadCustomerFailed> =
    this.actions$
      .pipe(
        ofType(HomeActionTypes.LoadCustomer),
        switchMap(() =>
          this.customerViewService.get()
            .pipe(
              map(references => new LoadCustomerSuccess(references)),
              catchError(error => of(new LoadCustomerFailed(error)))
            )
        )
      );

  // @Effect()
  // deleteAgent$: Observable<DeleteAgentSuccess | DeleteAgentFailed> =
  //   this.actions$
  //     .pipe(
  //       ofType(HomeActionTypes.DeleteAgent),
  //       switchMap(({payload: agentID}) => {
  //         return this.aquaDirectAgentService.delete(agentID)
  //           .pipe(
  //             map(result => new DeleteAgentSuccess(result.agentID)),
  //             catchError(error => of(new DeleteAgentFailed(error)))
  //           );
  //         })
  //     );

  // @Effect()
  // deleteMyAgent$: Observable<DeleteAgentSuccess> =
  //   this.actions$
  //     .pipe(
  //       ofType(AgentsTableTypes.DeleteAgentRelationshipSuccess),
  //       map(({payload: agentID}) => {
  //         return new DeleteAgentSuccess(agentID);
  //       })
  //     );

  @Effect()
  createAgent$: Observable<CreateAgentSuccess | CreateAgentFailed> =
    this.actions$
      .pipe(
        ofType(HomeActionTypes.CreateAgent),
        switchMap(({payload: agentPermissionDetail}) => {
          return this.aquaDirectAgentService.create(agentPermissionDetail)
            .pipe(
              map(result => new CreateAgentSuccess(result)),
              catchError(error => of(new CreateAgentFailed(error)))
            );
          })
      );

  @Effect()
  updateAgent$: Observable<UpdateAgentSuccess | UpdateAgentFailed> =
    this.actions$
      .pipe(
        ofType(HomeActionTypes.UpdateAgent),
        switchMap(({payload}) => {
          return this.aquaDirectAgentService.update(payload)
            .pipe(
              map(result => new UpdateAgentSuccess(payload)),
              catchError(error => of(new UpdateAgentFailed(error)))
            );
          })
      );


  constructor(
    private actions$: Actions,
    private customerViewService: CustomerViewService,
    private aquaDirectAgentService: AquaDirectAgentService,
  ) {}
}
