import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  ProductionReturnActionTypes,
  LoadProductionReturn,
  ProductionReturnActions,
  LoadProductionReturnSuccess,
  LoadProductionReturnFailed
} from './production-return.actions';
import { ViewStatus } from '../enum';

export interface State {
  productionReturns?: any;
  viewStatus: ViewStatus;
  errorMessage?: string;
}

export const initialState: State = {
  viewStatus: ViewStatus.Initial
};

const loadProductionReturn = (state: State, action: LoadProductionReturn): State => {
  const { errorMessage, ...rest} = state;
  return {
    ...rest,
    viewStatus: ViewStatus.Loading,
  };
};

const loadProductionReturnSuccess = (state: State, action: LoadProductionReturnSuccess): State => {
  return {
    ...state,
    viewStatus: ViewStatus.LoadSuccess,
    productionReturns: action.payload,
  };
};

const loadProductionReturnFailed = (state: State, action: LoadProductionReturnFailed): State => ({
  ...state,
  viewStatus: ViewStatus.LoadFailed,
  errorMessage: action.payload,
});

export function reducer(state = initialState, action: ProductionReturnActions): State {
  switch (action.type) {
    case ProductionReturnActionTypes.LoadProductionReturn:
      return loadProductionReturn(state, action);
    case ProductionReturnActionTypes.LoadProductionReturnSuccess:
      return loadProductionReturnSuccess(state, action);
    case ProductionReturnActionTypes.LoadProductionReturnFailed:
      return loadProductionReturnFailed(state, action);
    default:
      return state;
  }
}

const getAgentsTableState = createFeatureSelector<State>('production-return');
export const getProductionReturn = createSelector(getAgentsTableState, state => state.productionReturns);
export const getViewStatus = createSelector(getAgentsTableState, state => state.viewStatus);
export const getErrorMessage = createSelector(getAgentsTableState, state => state.errorMessage);
