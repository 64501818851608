import { Action } from '@ngrx/store';

import { Agent } from '../../home/CustomerView';

export enum AgentsTableTypes {
  GetAgentRelationshipsFor = '[AgentsTable] GetAgentRelationshipsFor',
  GetAgentRelationshipsForSuccess = '[AgentsTable] GetAgentRelationshipsForSuccess',
  GetAgentRelationshipsForFailed = '[AgentsTable] GetAgentRelationshipsForFailed',
  DeleteAgent = '[AgentsTable] DeleteAgent',
  DeleteAgentRelationshipSuccess = '[AgentsTable] DeleteAgentRelationshipSuccess',
  DeleteAgentFailed = '[AgentsTable] DeleteAgentFailed',
}

export class GetAgentRelationshipsFor implements Action {
  readonly type = AgentsTableTypes.GetAgentRelationshipsFor;
  constructor(public payload: string) {}
}

export class GetAgentRelationshipsForSuccess implements Action {
  readonly type = AgentsTableTypes.GetAgentRelationshipsForSuccess;
  constructor(public payload: Agent[]) {}
}

export class GetAgentRelationshipsForFailed implements Action {
  readonly type = AgentsTableTypes.GetAgentRelationshipsForFailed;
  constructor(public payload: any) {}
}

export class DeleteAgent implements Action {
  readonly type = AgentsTableTypes.DeleteAgent;
  constructor(public payload: {agentRelationshipID: number, loggedInUserID: string, activeAgentCustomerID: string, customerID: string}) {}
}

export class DeleteAgentRelationshipSuccess implements Action {
  readonly type = AgentsTableTypes.DeleteAgentRelationshipSuccess;
  constructor(public payload: number) {}
}

export class DeleteAgentFailed implements Action {
  readonly type = AgentsTableTypes.DeleteAgentFailed;
  constructor(public payload: any) {}
}

export type AgentsTableActions = GetAgentRelationshipsFor
| GetAgentRelationshipsForSuccess
| GetAgentRelationshipsForFailed
| DeleteAgent
| DeleteAgentRelationshipSuccess
| DeleteAgentFailed;
