import { TransactionType } from './enum';
import { IProductionReturnData } from '@aquaonline/ng-models';


export interface InputData {
  secID: string;
  subSection: string;
  estuaryID?: number;
  speciesID: number;
  permitID?: string;
  field: string;
  value: number;
}

export interface PermitDetails {
  permitID: string;
  permitClassCode: string;
}

export interface InputStructure {
  secID: string;
  subSections?: Array<SubSection>;
  inputs?: any;
  estuaries?: any;
  permits?: Array<Permit>;
}

export interface SectionInterface {
  secID?: string;
  secDesc?: string;
  estuaries?: Array<Estuary>;
  inputs?: Array<any>;
  subSections?: Array<SubSection>;
  species?: Array<Species>;
  permits?: Array<Permit>;
}

export interface SubSection {
  subSection: string;
  estuaries?: any;
  species?: any;
}

export interface Estuary {
  name?: string;
  estuaryID: number;
  estuaryName?: string;
  species: Array<Species>;
  inputs?: any;
}

export interface Species {
  speciesID: number;
  speciesName?: string;
  speciesGroup?: string;
  productionGroup?: string;
  small?: number;
  medium?: number;
  large?: number;
}

export interface InputField {
  fieldName?: string;
  fieldValue?: number;
}

export interface Permit {
  permitID?: string;
  permitClassCode?: string;
  species?: Array<Species>;
  inputs?: any;
}

export interface ProductionReturnTransaction {
  year: number;
  errors?: any[];
  productionReturnData?: IProductionReturnData;
  detail?: any;
  component?: any;
  transactionID?: number;
}

export interface TransactionDetailWithType {
  transactionDetail: TransactionDetail;
  transactionType: TransactionType;
}

export interface SpeciesRow {
  large?: number;
  medium?: number;
  small?: number;
  speciesName?: string;
}

export type TransactionDetail = ProductionReturnTransaction;

export const CONTEXTUAL_MESSAGE = 'Production return data entered will be saved upon field entry.';


