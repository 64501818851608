import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthStateSelector } from './auth.selector';
import { AuthActionDispatcher } from './auth.dispatcher';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        catchError((error: any) => {
          this.logoutIfUnauthorized(error);

          return throwError(error);
        })
      );
  }

  constructor(
    public authStateSelector: AuthStateSelector,
    public authActionDispatcher: AuthActionDispatcher,
    public router: Router,
  ) {}

  private logoutIfUnauthorized = (err: HttpErrorResponse) => {
    if (err instanceof HttpErrorResponse) {
      console.log('error interceptor status: ', err.status);
      switch (err.status) {
        case 401:
          this.authActionDispatcher.logout();
          this.router.navigate(['/login']);
          break;
        case 403:
          this.authActionDispatcher.logout();
          this.router.navigate(['/login']);
          break;
      }
      // if (err.status === 401) {
      //   this.authActionDispatcher.logout();
      //   this.router.navigate(['/login']);
      // }
    }
  }
}
