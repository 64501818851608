
import { Injectable } from '@angular/core';
import { FormGroup, FormArray, FormBuilder } from '@angular/forms';

import { ProductionReturnService } from './production-return.service';
import { isNilOrEmpty, isNotNilAndEmpty, propEq, pathEq } from '@aquaonline/ramda';
import { FieldTypes, FieldNames, Section, IPermit } from '@aquaonline/ng-models';
import { createArrayWithOrAppendValue } from '@aquaonline/ng-shared';
import { columns } from './production-return-columns';
import { NgProductionReturnModule } from './ng-production-return.module';

@Injectable({
  providedIn: NgProductionReturnModule
})
export class ProductionReturnSectionSevenService {
  columns = columns;
  constructor(
    protected productionReturnService: ProductionReturnService,
    protected formBuilder: FormBuilder,
  ) { }

  addFromControlsForInput = (input, transactionForm, selectedSpeciesByPermitSectionSeven) => {
    const newColumns = {...this.columns};
    (transactionForm.get('sectionSevenForm') as FormArray)
    .push(this.formBuilder.group(this.getFormGroupItemsFromColumnEdit(newColumns.sectionSeven, input)));
    if (isNotNilAndEmpty(input.permitID)) {
      this.addInputIfNotExist(selectedSpeciesByPermitSectionSeven, input);
    }
  }

  private addInputIfNotExist(selectedSpeciesByPermitSectionSeven: any, input: any) {
    const newValue = { speciesName: input.speciesName, speciesID: +input.speciesID };
    if (selectedSpeciesByPermitSectionSeven[input.permitID]) {
      const isValueExist = selectedSpeciesByPermitSectionSeven[input.permitID].find(element => propEq('speciesID', +input.speciesID, element));
      if (isNilOrEmpty(isValueExist)) {
        selectedSpeciesByPermitSectionSeven[input.permitID].push(newValue);
      }
    } else {
      selectedSpeciesByPermitSectionSeven[input.permitID] = [newValue];
    }
  }

  getFormGroupItemsFromColumnEdit(columns, receivedEntry) {
    return columns.reduce((formGroupOb, column) => {
      switch (column.name) {
        case FieldNames.SpeciesName: {
          column.defaultValue = receivedEntry.speciesName;
          break;
        }
        case FieldNames.SpeciesID: {
          column.defaultValue = +receivedEntry.speciesID;
          break;
        }
        case FieldNames.PermitClassCode: {
          column.defaultValue = receivedEntry.permitClassCode;
          break;
        }
        case FieldNames.PermitID: {
          column.defaultValue = receivedEntry.permitID;
          break;
        }
        case FieldNames.RowNumber: {
          column.defaultValue = receivedEntry.rowNumber;
          break;
        }
      }
      if (propEq('type', FieldTypes.Input, column) || propEq('type', FieldTypes.DropDown, column)) {
        column.defaultValue = receivedEntry[column.name];
      }
      formGroupOb[column.name] = [column.defaultValue, column.validators];
      return  formGroupOb;
    }, {});
  }

  getFormGroupItemsFromColumn(columns, receivedEntry) {
    return columns.reduce((formGroupOb, column) => {
      switch (column.name) {
        case FieldNames.SpeciesName: {
          column.defaultValue = receivedEntry.speciesName;
          break;
        }
        case FieldNames.SpeciesID: {
          column.defaultValue = +receivedEntry.speciesID;
          break;
        }
        case FieldNames.PermitClassCode: {
          column.defaultValue = receivedEntry.permitClassCode;
          break;
        }
        case FieldNames.PermitID: {
          column.defaultValue = receivedEntry.permitID;
          break;
        }
        case FieldNames.RowNumber: {
          column.defaultValue = receivedEntry.rowNumber;
          break;
        }
      }
      if (propEq('type', FieldTypes.Input, column)) {
        column.defaultValue = null;
      }
      if (propEq('type', FieldTypes.DropDown, column)) {
        column.defaultValue = null;
      }
      formGroupOb[column.name] = [column.defaultValue, column.validators];
      return  formGroupOb;
    }, {});
  }

  createControls = (receivedEntry, transactionForm: FormGroup, selectedSpeciesByPermitSectionSeven) => {
    const newColumns = {...this.columns};
    (transactionForm.get('sectionSevenForm') as FormArray)
    .push(this.formBuilder.group(this.getFormGroupItemsFromColumn(newColumns.sectionSeven, receivedEntry)));
    return createArrayWithOrAppendValue([receivedEntry.permitID], {speciesName: receivedEntry.speciesName, speciesID: receivedEntry.speciesID}, 
      selectedSpeciesByPermitSectionSeven);
  }

  sectionValidator = (formGroup: FormGroup) => {
    if (isNilOrEmpty(formGroup)) {
      return;
    }
    let sectionSevenEntriesArray = [];
    formGroup.value.forEach(value => {
      sectionSevenEntriesArray.push(value.permitID + value.speciesID + value.productType);
    });
    let sectionSevenEntriesSet = new Set(sectionSevenEntriesArray);
    return sectionSevenEntriesArray.length === sectionSevenEntriesSet.size ? null : {duplicateProductType: true};
  }

  public createInputs(sectionSevenForm, inputs) {
    if (isNilOrEmpty(sectionSevenForm)) { return; }

    sectionSevenForm.forEach(entry => {
      inputs.push({secID: Section.sectionSeven, speciesID: entry.speciesID, productType: entry.productType,
        permitID: entry.permitID, permitClassCode: entry.permitClassCode, average: +entry.average,
        quantity: entry.quantity, value: entry.value, speciesName: entry.speciesName, rowNumber: entry.rowNumber,
      });
    });
  }

  public openSpeciesModal(modalRef, permit: IPermit, selectedSpeciesByPermitSectionSeven) {
    modalRef.componentInstance.species = permit.species;
    modalRef.componentInstance.permit = permit;
    modalRef.componentInstance.selectedSpeciesByPermitSectionSeven = selectedSpeciesByPermitSectionSeven;
    return modalRef.componentInstance.passEntry;
  }

  public removeRow = (value, sectionSevenFormControl: FormArray, selectedSpeciesByPermitSectionSeven) => {
    this.removeSectionSevenEntry(sectionSevenFormControl, value, selectedSpeciesByPermitSectionSeven);
    const entryFroPermit = sectionSevenFormControl.controls.find(formGroup => pathEq(['value', 'permitID'], value.permitID, formGroup));
    sectionSevenFormControl.updateValueAndValidity();
    return isNilOrEmpty(entryFroPermit) ? false : true;
  }

  private removeSectionSevenEntry(sectionSevenControl: FormArray, value: any, selectedSpeciesByPermitSectionSeven) {
    sectionSevenControl.controls = sectionSevenControl.controls
      .filter(formGroup => !(pathEq(['value', 'rowNumber'], value.rowNumber, formGroup) && pathEq(['value', 'permitID'], value.permitID, formGroup)));
    selectedSpeciesByPermitSectionSeven[value.permitID] = selectedSpeciesByPermitSectionSeven[value.permitID]
      .filter(aSpecies => !propEq('speciesID', value.speciesID, aSpecies));
  }
}
