import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { AccountProfileComponent } from './account-profile.component';
import { PageHeaderModule } from '../../page-header/page-header.module';
import { AccountProfileEffects } from './account-profile.effects';
import * as fromAccountProfile from './account-profile.reducer';
import { CustomerViewContactsModule } from '../../customer-view-contacts/customer-view-contacts.module';
import { AccountProfileActionDispatcher } from './account-profile.dispatcher';
import { AccountProfileStateSelector } from './account-profile.selector';
import { AccountProfileService } from './account-profile.service';
import { SharedModule } from '../../shared/shared.module';
import { CustomerViewAddressModule } from '../../customer-view-address/customer-view-address.module';
import { AgentSelectorModule } from '../../agent-selector/agent-selector.module';
import { ComponentCustomerTabComponent } from '../component-customer-tab/component-customer-tab.component';

@NgModule({
  declarations: [AccountProfileComponent, ComponentCustomerTabComponent],
  imports: [
    CommonModule,
    PageHeaderModule,
    EffectsModule.forFeature([AccountProfileEffects]),
    StoreModule.forFeature('account-profile', fromAccountProfile.reducer),
    NgbModule,
    CustomerViewContactsModule,
    SharedModule,
    FontAwesomeModule,
    CustomerViewAddressModule,
    AgentSelectorModule,
  ],
  providers: [
    AccountProfileActionDispatcher,
    AccountProfileStateSelector,
    AccountProfileService,
  ]
})
export class AccountProfileModule { }
