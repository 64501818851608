import { Component, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';

import { Action } from '../enum';


@Component({
  selector: 'app-production-return-save-submit',
  templateUrl: './production-return-save-submit.component.html',
  styleUrls: ['./production-return-save-submit.component.scss'],
})
export class ProductionReturnSaveSubmitComponent implements OnInit, OnDestroy {
  @Output() clickListener: EventEmitter<string> = new EventEmitter();
  Action = Action;
  constructor() { }

  ngOnInit() {
  }

  save = () => {
    this.clickListener.emit(Action.Save);
  }

  submit = () => {
    this.clickListener.emit(Action.Submit);
  }

  clear = () => {
    this.clickListener.emit(Action.Clear);
  }

  exit = () => {
    this.clickListener.emit(Action.Exit);
  }

  ngOnDestroy(): void {}
}
