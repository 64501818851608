import { equals } from 'ramda';
import { filter, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

import * as fromUserProfile from './user-profile.reducer';
import { UserRole, UserProfile } from './UserProfile';

@Injectable()
export class UserProfileStateSelector {

  constructor(private store: Store<fromUserProfile.State>) {

  }

  userProfile = (): Observable<UserProfile> =>
    this.store.select(fromUserProfile.selectUserProfile)

  isUser = (userRole: UserRole): Observable<boolean> =>
    this.userProfile()
      .pipe(
        filter(state => !!state),
        select(state => state.role),
        map(role => equals(role, userRole)),
      )

  ready = (): Observable<boolean> =>
    this.store.select(fromUserProfile.selectUserProfileReady)

  lastLoggedIn = (): Observable<Date> =>
    this.store.select(fromUserProfile.selectUserLastLoggedIn)
}
