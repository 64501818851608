import { Pipe, PipeTransform } from '@angular/core';

import { isNilOrEmpty } from './ramda-functions';

/**
 * Use this pipe as a substitute for using OR operator || in template.
 * Change implementation if it does not cover all scenarios covered
 * by 'OR' operator
 */
@Pipe({
  name: 'defaultIfNil'
})
export class DefaultIfNilPipe implements PipeTransform {
  transform = (obj: any, defaultValue: string | number) => isNilOrEmpty(obj) ? defaultValue : obj;
}
