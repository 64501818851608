import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';

import { ChangePasswordService } from './change-password.service';
import { isNil, equals } from '../shared/ramda-functions';
import { BasePageComponent } from '../shared/base-page.component';
import { AuthStateSelector } from '../auth/auth.selector';
import { AuthenticateResult } from '../auth/auth';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent extends BasePageComponent implements OnInit, OnDestroy {
  passwordForm: FormGroup;
  error$: Observable<string>;
  temporaryPassword: string;
  error: string;
  showError = false;
  loggedInUserSubscription$$: Subscription;
  changePasswordSubscription$$: Subscription;
  loggedInUsername: string;
  passwordChangedMessage: string;

  constructor(
    private changePasswordService: ChangePasswordService,
    private formBuilder: FormBuilder,
    private authStateSelector: AuthStateSelector,
    private ngxLoader: NgxUiLoaderService,
    private router: Router,
  ) {
    super();
  }

  ngOnInit() {
    this.createForm();
    this.loggedInUserSubscription$$ = this.authStateSelector.loggedInUser()
      .subscribe((user: AuthenticateResult) => {
        this.loggedInUsername = user.username;
      });
  }

  createForm = () => {
    this.passwordForm = this.formBuilder.group({
      password: [null, [Validators.required]],
      newPassword: [null, [Validators.required, Validators.minLength(16)]],
      reNewPassword: [null, [Validators.required, Validators.minLength(16)]],
    });
    this.passwordForm.setValidators(this.validatePasswords);
  }

  validatePasswords = (group: FormGroup) => {
    return equals(group.controls.newPassword.value, group.controls.reNewPassword.value) ? null : {passwordsNotMatch: true};
  }

  onSubmit = () => {
    this.passwordChangedMessage = null;
    if (this.passwordForm.invalid) {
      this.showError = true;
      return;
    }
    this.showError = false;
    this.ngxLoader.start();
    this.changePasswordSubscription$$ = this.changePasswordService.changePassword({
      username: this.loggedInUsername,
      password: this.passwordForm.get('password').value,
      newPassword: this.passwordForm.get('newPassword').value,
    }).subscribe(() => {
      this.ngxLoader.stop();
      this.passwordForm.reset();
      this.error = null;
      this.passwordChangedMessage = 'Your password has been successfully changed.';
    },
    (error) => {
      this.ngxLoader.stop();
      this.error = error.message;
    });
  }

  goToHomePage = () => {
    this.router.navigate(['/']);
  }

  ngOnDestroy() {
    if (!isNil(this.loggedInUserSubscription$$)) {
      this.loggedInUserSubscription$$.unsubscribe();
    }
    if (!isNil(this.changePasswordSubscription$$)) {
      this.changePasswordSubscription$$.unsubscribe();
    }
  }
}
