import { Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ProductionReturnComponent } from './production-return/production-return.component';
import { ExitModalComponent } from './production-return/exit-modal/exit-modal.component';


@Injectable({
    providedIn: 'root'
})
export class CanDeactivateGuard implements CanDeactivate<ProductionReturnComponent> {
    exitModalSubscription$$: Subscription;
    constructor(
        private modalService: NgbModal,
    ) {}
    canDeactivate(
        component: ProductionReturnComponent,
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
        nextState: RouterStateSnapshot,
        )
        : Observable<boolean> | boolean {

        if (nextState.url.includes('/production-return/view')) {
            return true;
        } else {
            const modalRef = this.modalService.open(ExitModalComponent, { size: 'lg', windowClass: 'exit-modal', backdrop: 'static' });
            return modalRef.componentInstance.passEntry;
        }
    }
}
