import { Pipe, PipeTransform } from '@angular/core';

/**
 * Usages:
 *  - result{{ items | pluralize }}
 *  - {{ items | pluralize: 'results' : 'result' }}
 */
@Pipe({
  name: 'pluralize'
})
export class PluralizePipe implements PipeTransform {
  transform(items: Array<any>, plularForm = 's', singularForm = ''): string {
    return items.length > 1 ? plularForm : singularForm;
  }
}
