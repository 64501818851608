import { createFeatureSelector, createSelector } from '@ngrx/store';

import { UserProfileActions, UserProfileActionTypes } from './user-profile.actions';
import { UserProfile } from './UserProfile';

export interface State {
  ready: boolean;
  profile?: UserProfile;
  lastLoggedIn?: Date;
}

export const initialState: State = {
  ready: false,
};

export function reducer(state = initialState, action: UserProfileActions): State {
  switch (action.type) {
    case UserProfileActionTypes.Ready:
      return {
        ...state,
        ready: true,
        profile: action.payload,
        lastLoggedIn: action.payload.lastLogin,
      };

    case UserProfileActionTypes.Reset:
      return initialState;

    default:
      return state;
  }
}

export const selectUserProfileState = createFeatureSelector<State>('user-profile');

export const selectUserProfile = createSelector(
  selectUserProfileState,
  state => state.profile
);

export const selectUserProfileReady = createSelector(
  selectUserProfileState,
  state => state.ready
);

export const selectUserLastLoggedIn = createSelector(
  selectUserProfileState,
  state => state.lastLoggedIn,
);
