import { Action } from '@ngrx/store';

import { ProductionReturn } from '.';

export enum ProductionReturnListActionTypes {
  LoadProductionReturnList = '[ProductionReturnList] Load ProductionReturnList',
  LoadProductionReturnListSuccess = '[ProductionReturnList] Load ProductionReturnList Success',
  LoadProductionReturnListFailed = '[ProductionReturnList] Load ProductionReturnList Failed',
}

export class LoadProductionReturnList implements Action {
  readonly type = ProductionReturnListActionTypes.LoadProductionReturnList;
  constructor() {}
}
export class LoadProductionReturnListSuccess implements Action {
  readonly type = ProductionReturnListActionTypes.LoadProductionReturnListSuccess;
  constructor(public payload: ProductionReturn[]) {}
}
export class LoadProductionReturnListFailed implements Action {
  readonly type = ProductionReturnListActionTypes.LoadProductionReturnListFailed;
  constructor(public payload: string) {}
}

export type ProductionReturnListActions = LoadProductionReturnList
| LoadProductionReturnListSuccess
| LoadProductionReturnListFailed;
