import { Action, createFeatureSelector, createSelector } from '@ngrx/store';

import { ViewStatus } from './../../enum';
import { PermitViewActions, PermitViewActionTypes } from './permit-view.actions';
import { PermitState } from '../permit.reducers';
import { PermitDetail } from '../index.d';


export interface State {
  permitDetail?: PermitDetail;
  viewStatus: ViewStatus;
  errorMessage?: string;
}

export const initialState: State = {
  viewStatus: ViewStatus.Initial,
};

export function reducer(state = initialState, action: PermitViewActions): State {
  switch (action.type) {

    case PermitViewActionTypes.LoadPermitView:
    case PermitViewActionTypes.LoadPermitViewWithLog:
      return {
        viewStatus: ViewStatus.Loading,
      };
    case PermitViewActionTypes.LoadPermitViewSuccess:
      return {
        viewStatus: ViewStatus.LoadSuccess,
        permitDetail: action.payload,
      };
    case PermitViewActionTypes.LoadPermitViewFailed:
      return {
        viewStatus: ViewStatus.LoadFailed,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
}

const getPermitState = createFeatureSelector<PermitState>('permit');

export const getPermitViewState = createSelector(getPermitState, state => state.view);

export const getPermitDetail = createSelector(getPermitViewState, state => state.permitDetail);
export const getPermitViewStatus = createSelector(getPermitViewState, state => state.viewStatus);
export const getPermitViewErrorMessage = createSelector(getPermitViewState, state => state.errorMessage);
