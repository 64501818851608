import { Permit } from './permit-models';

export interface InvoiceRequestCalculation {
  invoiceRequestCalculationID?: number;
  invoiceRequestID?: number;
  createdDateTime?: Date;
  customerID?: string;
  recordType?: string;
  docDate?: string;
  reference?: string;
  headerText?: string;
  calculateTax?: string;
  pk?: string;
  account?: string;
  amount?: number;
  taxCode?: string;
  dunningArea?: string;
  paymentTerm?: string;
  quantity?: number;
  uom?: string;
  costCenter?: string;
  projectTask?: string;
  allocation?: string;
  text?: string;
}

export interface InvoiceCustomerDetails {
  customerID?: string;
  customerName?: string;
  permits?: Array<Permit>;
  leases?: Array<any>;
}

export enum InvoiceRequestStatusID {
  Pending = 'PENDING',
  Calculated = 'CALCULATED',
  Ready = 'READY',
  Done = 'DONE',
}

export class InvoiceRequestHeader {
  invoiceRequestID: number;
  createdDateTime: Date;
  invoiceRequestStatusID: string;
}

