import { FormControl, FormArray, FormGroup } from '@angular/forms';
import * as moment from 'moment';

import { isNilOrEmpty, isNotNilAndEmpty, pipe, defaultTo, append, over, 
  lensPath, divide, find, propEq, prop, set, lensProp, pluck, filter, assoc, values, length as ramdaLength, identity,
  propSatisfies, contains, uniq, flatten, keys, map, includes, __, sortBy, last, omit, reject, uniqBy, groupBy, toPairs, applySpec, join, sortWith, ascend, curry, equals, pick, groupWith } from '@aquaonline/ramda';
import { ISpecies, ISection, IInputData, InitiatingGrantTransactions, ColumnMeta, IPermit } from '@aquaonline/ng-models';

export const blankValidator = (control: FormControl) => {
    if (isNilOrEmpty(control.value)) {
      return null;
    }
    return isNotNilAndEmpty(control.value.trim()) ? null : {blankField: true};
}

export const getFiscalYear = () => {
    const year = moment().year();
    const endOfFinancialYear = moment(year + '-07-01');
    return moment().isBefore(endOfFinancialYear) ? (year - 2) + '-' + ((year - 1) % 100) : (year - 1) + '-' + (year % 100);
}

export const createArrayWithOrAppendValue = (path: Array<string | number>, value: any, object: any) => {
  const pushFunction = pipe(
    defaultTo([]),
    append(value)
  );
  return over(lensPath(path), pushFunction, object);
}

export const disableFormControls = (array: Array<FormControl | FormGroup | FormArray>) => {
  if (!array.length) { return; }

  array.forEach(formControl => formControl.disable());
}

export const enableFormControls = (array: Array<FormControl | FormGroup | FormArray>) => {
  if (!array.length) { return; }

  array.forEach(formControl => formControl.enable());
}

export const getAverage = (value: number, quantity: number): string => {
  if (!value || !quantity) { return '0.00'; }

  return  divide(value, quantity).toFixed(2);
}

export const getSpeciesName = (speciesID: number, species: Array<ISpecies>): string => {
  return pipe(
    find(propEq('speciesID', speciesID)),
    prop('speciesName')
  )(species);
}

export const getEstuaryName = (estuaryID: number, secID: string, sections: Array<ISection>): string => {
  return pipe(
    find(propEq('secID', secID)),
      prop('estuaries'),
      find(propEq('estuaryID', estuaryID)),
      prop('name')
    )(sections);
}

export const addSpeciesAndEstuaryNameToInput = (input: IInputData, species: ISpecies[], sections: ISection[], secID: string) => {
  const estuaryID = prop('estuaryID', input);
  const speciesName = getSpeciesName(prop('speciesID', input), species);
  const estuaryName = estuaryID ? getEstuaryName(estuaryID, secID, sections) : null;
  return pipe(
    set(lensProp('speciesName'), speciesName),
    set(lensProp('estuaryName'), estuaryName)
  )(input);
}

export const findPermitClassCode = (input: IInputData, permits: Array<IPermit>) => pipe(
    find(propEq('permitID', prop('permitID', input))),
    prop('permitClassCode')
  )(permits);

export const addPermitClassCodeToInput = (input: IInputData, permits: Array<IPermit>) => assoc('permitClassCode', findPermitClassCode(input, permits), input);

export const isNilOrContainsRole = (role: string) => (roles: Array<string>) => {
  return isNilOrEmpty(roles) || contains(role, roles);
}

export const removeColumnsForRoles = (role: string, columns: Array<ColumnMeta>) => {
  return pipe(
    filter(propSatisfies(isNilOrContainsRole(role), 'fieldPermittedRoles'))
  )(columns);
}

export const pluckNonNullValuesForProp = (property: string, array: Array<any>) => {
  return pipe(
      pluck(property),
      filter(isNotNilAndEmpty)
    )(array);
}

export const isCurrentStatusInitiatingGrantTransaction = (initiatingTransactionIDs: Array<number>, currentStatus:string, transaction: any) => {
  return values(InitiatingGrantTransactions).includes(prop('transactionTypeID', transaction)) && initiatingTransactionIDs.includes(prop('transactionID', transaction)) && 
    propEq('transactionStatus', currentStatus, transaction);
}

export const changeInitiatingTransactionsStatuses = (initiatingTransactionIDs: Array<number>, currentStatus: string, newStatus: string, transactions: Array<any>) => {
  return transactions.map(transaction => isCurrentStatusInitiatingGrantTransaction(initiatingTransactionIDs, currentStatus, transaction) ? assoc('transactionStatus', newStatus, transaction) : transaction);
}

export const getUniqueKeys = (object: Array<Object>) => {
  return pipe(
    map(keys),
    flatten,
    uniq
  )(object); 
} 

export const getValues = (object: Array<Object>) => {
  return pipe(
    map(values),
    flatten
  )(object); 
} 

export const convertPropWithFn = <T>(prop: string, fn: any, object: Array<T>) => {
  return pipe(
    map(over(lensProp(prop), fn))
  )(object)
}

export const findIncludesProp = <T1, T2>(property: string, includingArray: Array<T1>, items: Array<T2>) => {
  return pipe(
    filter(
      pipe(
        prop(property),
        includes(__, includingArray)
      )
    )
  )(items);
}

export const findMaxValueForProp = <T>(property: string, array: Array<T>) => {
  return pipe(
    sortBy(prop(property)),
    last,
    prop(property)
  )(array);
}

export const roundTwoDecimalPoints = (number: number) => {
  return Math.round(number * 100) / 100;
}

export const removePropsFromArrayOfObjects = <T>(properties: Array<string>, array: Array<T>) => {
  return map(omit(properties), array);
}

export const dateFormatKebab = 'YYYY-MM-DD';

export const getPropArrayLengthInArrayOfObjects = (property: string, uniqueBy: string, arrayOfObjects: Array<any>) => 
  pipe(
    pluck(property),
    reject(isNilOrEmpty),
    flatten,
    uniqBy(prop(uniqueBy)),
    ramdaLength
  )(arrayOfObjects);

const equalProps = curry((properties, item1, item2) => equals(pick(properties, item1), pick(properties, item2)));

const groupWithProps = curry(
(primaryProperties, secondaryListName) =>
  pipe(
  groupWith(equalProps(primaryProperties)),
  map(
  pipe(
  applySpec({
    primaryEntity: pipe(prop(0), pick(primaryProperties)),
    [secondaryListName]: map(omit(primaryProperties))
  }),
  x => ({
    ...x.primaryEntity,
    [secondaryListName]: x[secondaryListName],
  }))),
  )
);

export const concatPermitIDGroupBySerialID = (arrayOfObject: Array<any>) => pipe(
  groupWithProps(['serialID', 'revokedDateTime', 'printedDateTime', 'issuedDateTime', 'customerName', 'customerID'], 'permits'),
  map(({permits, ...rest}) => ({
    ...rest,
    permitID: pipe(map(prop('permitID')), sortBy(identity), join(', '))(permits)
  }))
)(arrayOfObject);