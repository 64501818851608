import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AgentEditDeleteModalComponent } from './agent-edit-delete-modal.component';

@NgModule({
  declarations: [AgentEditDeleteModalComponent],
  exports: [AgentEditDeleteModalComponent],
  entryComponents: [AgentEditDeleteModalComponent],
  imports: [
    CommonModule
  ]
})
export class AgentEditDeleteModalModule { }
