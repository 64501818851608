import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { withLatestFrom, filter, map, tap } from 'rxjs/operators';

import * as fromLeaseView from './lease-view.reducer';
import { ViewStatus } from '../../enum';
import { equals } from '../../shared/ramda-functions';
import { LeaseStatuses } from '../enum';

@Injectable()
export class LeaseViewStateSelector {
  constructor(
    private store: Store<fromLeaseView.State>,
  ) { }

  viewStatus = () => this.store.select(fromLeaseView.getLeaseViewStatus);

  leaseDetail = () => this.store.select(fromLeaseView.getLeaseDetail)
    .pipe(
      withLatestFrom(this.viewStatus()),
      filter(([_, viewStatus]) => equals(viewStatus, ViewStatus.LoadSuccess)),
      map(([leaseDetail]) => leaseDetail)
    )

  error = () => this.store.select(fromLeaseView.getLeaseViewErrorMessage);

  isLeaseStatus = (leaseStatuses: Array<LeaseStatuses>) =>
      this.leaseDetail()
        .pipe(
          map(({summary}) => leaseStatuses.includes(summary.leaseStatusID))
        )
}
