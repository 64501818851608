import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as fromLeaseView from './lease-view.reducer';
import { LoadLeaseView, LoadLeaseViewWithLog } from './lease-view.actions';

@Injectable()
export class LeaseViewActionDispatcher {

  constructor(private store: Store<fromLeaseView.State>) {
  }

  loadLeaseView = (leaseId: string) => this.store.dispatch(new LoadLeaseView(leaseId));
  loadLeaseViewWithLog = (leaseId: string) => this.store.dispatch(new LoadLeaseViewWithLog({id: leaseId, log: true}));
}
