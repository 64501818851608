import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import * as userProfile from './user-profile.reducer';
import { UserProfileStateSelector } from './user-profile.selector';
import { UserProfileEffects } from './user-profile.effects';

@NgModule({
  imports: [
    EffectsModule.forFeature([UserProfileEffects]),
    StoreModule.forFeature('user-profile', userProfile.reducer),
  ],
  providers: [UserProfileStateSelector],
})
export class UserProfileModule {}
