import { Pipe, PipeTransform } from '@angular/core';

/**
 * Use this pipe as a substitute for using OR operator || in template.
 * Change implementation if it does not cover all scenarios covered
 * by 'OR' operator
 */
@Pipe({
  name: 'defaultIfZero'
})
export class DefaultIfZeroPipe implements PipeTransform {
  transform = (obj: any, defaultValue: string | number) => obj === 0 ? defaultValue : obj;
}
