import { FormGroup, FormArray } from '@angular/forms';


/**
 * Used for debugging only
 */
export const getAllFormErrors = (form: FormGroup | FormArray): { [key: string]: any; } | null  => {
    let hasError = false;
    const result = Object.keys(form.controls).reduce((acc, key) => {
        const control = form.get(key);
        if (control instanceof FormArray && control.errors) {
          acc[key] = control.errors;
          hasError = true;
        }
        const errors = (control instanceof FormGroup || control instanceof FormArray)
            ? getAllFormErrors(control)
            : control.errors;
        if (errors) {
            acc[key] = errors;
            hasError = true;
        }
        return acc;
    }, {} as { [key: string]: any; });

    return hasError ? result : null;
  };
