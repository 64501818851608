import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, CanActivate, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { take, tap, map } from 'rxjs/operators';

import { AuthStateSelector } from './auth.selector';
import { environment } from '../../environments/environment';

@Injectable()
export class AuthGuardService implements CanActivate, CanActivateChild {

  constructor(
    private authStateSelector: AuthStateSelector,
    private router: Router,
    private http: HttpClient,
  ) {}

  canActivateChild = (): Observable<boolean> =>
    this.canActivate()

  canActivate = (): Observable<boolean> =>
    this.http.get<boolean>(`${environment.apiUrl}/authenticate/verify`)
}
