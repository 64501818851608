export { PermitGroup, PermitStatus as PermitStatuses, PermitRelatedTransactions } from '@aquaonline/ng-models';

export enum ConditionType {
  Standard = 'STANDARD',
  Specific = 'SPECIFIC',
  Special = 'SPECIAL',
}

export enum PermitClass {
  Repository = 'Repository (permits)',
}

export enum TransactionStatus {
  Pending = 'PENDING',
  Approved = 'APPROVED',
  NotApproved = 'NOT_APPROVED',
  Deleted = 'DELETED',
  Granted = 'GRANTED',
  NotGranted = 'NOT GRANTED',
}

export enum TransactionType {
  AmendCustomer = 'AMEND_CUSTOMER',
  CreateCustomer = 'CREATE_CUSTOMER',
  ManageCustomerFlags = 'MANAGE_CUSTOMER_FLAGS',
  RegisterPermitMortgageInterests = 'REGISTER_MORTGAGE_INTERESTS',
  DischargePermitMortgageInterests = 'DISCHARGE_MORTGAGE_INTERESTS',
  AmendFarm = 'AMEND_FARM',
  CreateFarm = 'CREATE_FARM',
  AmendPermit = 'AMEND_PERMIT',
  ManagePermitFlags = 'MANAGE_PERMIT_FLAGS',
  CreatePermit = 'CREATE_PERMIT',
  GrantPermit = 'GRANT_PERMIT',
  AssociateLeasePermit = 'ASSOCIATE_LEASE_PERMIT',
  AssociateFarmPermit = 'ASSOCIATE_FARM_PERMIT',
  VaryFarmSpeciesPermit = 'VARY_FARM_SPECIES_PERMIT',
  VaryLeaseSpeciesPermit = 'VARY_LEASE_SPECIES_PERMIT',
  VaryFarmAreaPermit = 'VARY_FARM_AREA_PERMIT',
  VaryPermitAreaPermit = 'VARY_LEASE_AREA_PERMIT',
  CancelPermitByHolder = 'CANCEL_PERMIT_BY_HOLDER',
  CancelPermitByDepartment = 'CANCEL_PERMIT_BY_DEPARTMENT',
}



