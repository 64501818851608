import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as fromAccountProfile from './account-profile.reducer';
import { LoadRegistrationDetails } from './account-profile.actions';

@Injectable()
export class AccountProfileActionDispatcher {

  constructor(private store: Store<fromAccountProfile.State>) {
  }

  registrationDetails = (customerID: string) => this.store.dispatch(new LoadRegistrationDetails(customerID));
}
