import { Directive, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { isNil } from './ramda-functions';


@Directive()
// tslint:disable-next-line:directive-class-suffix
export class BasePageComponent implements OnDestroy {
  loading$$: Subscription;

  ngOnDestroy() {
    if (!isNil(this.loading$$)) {
      this.loading$$.unsubscribe();
    }
  }
}
