import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { NilProductionModalComponent } from './nil-production-modal.component';

@NgModule({
  declarations: [NilProductionModalComponent],
  exports: [NilProductionModalComponent],
  entryComponents: [NilProductionModalComponent],
  imports: [
    CommonModule,
    NgbModule,
    ReactiveFormsModule
  ]
})
export class NilProductionModalModule {}
