import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { LoginComponent } from './auth/login/login.component';
import { FarmsComponent } from './farms/farms.component';
import { AccountProfileComponent } from './account-profile/view-account-profile/account-profile.component';
import { SetPasswordComponent } from './auth/update-password/update-password.component';
import { AuthGuardService } from './auth/auth-guard.service';
import { PermitSchedulePrintComponent } from './permit-schedule-print/permit-schedule-print.component';


const routes: Routes = [
  {
    path: '',
    canActivateChild: [AuthGuardService],
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
      },
      {
        path: 'home',
        component: HomeComponent,
        data: {
          breadcrumb: {
            label: 'Home',
          },
        },
      },
      {
        path: 'permit',
        loadChildren: () => import('./permit/permit.module').then(m => m.PermitModule),
        data: {
          breadcrumb: {
            label: 'Permits',
            reset: true,
          },
        },
      },
      {
        path: 'lease',
        loadChildren: () => import('./lease/lease.module').then(m => m.LeaseModule),
        data: {
          breadcrumb: {
            label: 'Leases',
            reset: true,
          },
        },
      },
      {
        path: 'farms',
        component: FarmsComponent,
        data: {
          breadcrumb: {
            label: 'Farms',
            reset: true,
          },
        },
      },
      {
        path: 'production-return',
        loadChildren: () => import('./production-return-list/production-return-list.module').then(m => m.ProductionReturnListModule),
      },
      {
        path: 'account-profile',
        component: AccountProfileComponent,
        data: {
          breadcrumb: {
            label: 'Registration Details',
            reset: true,
          },
        },
      },
      {
        path: 'manage-agents',
        loadChildren: () => import('./manage-agents/manage-agents.module').then(m => m.ManageAgentsModule),
      },
      {
        path: 'change-password',
        loadChildren: () => import('./change-password/change-password.module').then(m => m.ChangePasswordModule),
      },
      {
        path: 'permit-holder-summary',
        loadChildren: () => import('./permit-schedule/permit-schedule.module').then(m => m.PermitScheduleModule),
      },
      {
        path: 'permit-holder-summary-print',
        component: PermitSchedulePrintComponent,
        data: {
          isPrintPage: true,
        }
      },
      {
        path: 'update-password',
        component: SetPasswordComponent
      },
      {
        path: 'additional-links',
        loadChildren: () => import('./additional-links/additional-links.module').then(m => m.AdditionalLinksModule),
      },
    ]
  },
  {
    path: 'login',
    component: LoginComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
