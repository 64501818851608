import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';

const isNumber = (data: any) => !isNaN(parseFloat(data)) && isFinite(data);
const padNumber = (data: number) => data < 10 ? `0${data}` : data;

@Injectable()
export class NgbDateCustomParserFormatter extends NgbDateParserFormatter {
  parse(value: string): NgbDateStruct {
    if (!value) {
      return null;
    }
    const [ day, month, year ] = value.trim().split('/');
    const nullIfIsNotNumber = (data) => isNumber(data) ? parseFloat(data) : null;
    return { day: nullIfIsNotNumber(day), month: nullIfIsNotNumber(month), year: nullIfIsNotNumber(year) };
  }

  format(date: NgbDateStruct): string {
    return date
      ? `${isNumber(date.day) ? padNumber(date.day) : ''}/${isNumber(date.month) ? padNumber(date.month) : ''}/${date.year || ''}`
      : '';
  }
}
