import { Action } from '@ngrx/store';

export enum ProductionReturnActionTypes {
  LoadProductionReturn = '[ProductionReturn] Load ProductionReturn',
  LoadProductionReturnSuccess = '[ProductionReturn] Load ProductionReturn Success',
  LoadProductionReturnFailed = '[ProductionReturn] Load ProductionReturn Failed',
}

export class LoadProductionReturn implements Action {
  readonly type = ProductionReturnActionTypes.LoadProductionReturn;
  constructor(public payload: string) {}
}
export class LoadProductionReturnSuccess implements Action {
  readonly type = ProductionReturnActionTypes.LoadProductionReturnSuccess;
  constructor(public payload: any) {}
}
export class LoadProductionReturnFailed implements Action {
  readonly type = ProductionReturnActionTypes.LoadProductionReturnFailed;
  constructor(public payload: string) {}
}

export type ProductionReturnActions = LoadProductionReturn
| LoadProductionReturnSuccess
| LoadProductionReturnFailed;
