import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { MatCardModule } from '@angular/material/card';

import { HomeComponent } from './home.component';
import { PageHeaderModule } from '../page-header/page-header.module';
import { HomeEffects } from './home.effects';
import * as fromHome from '../home/home.reducer';
import { CustomerViewService } from './customer-view.service';
import { HomeActionDispatcher } from './home.dispatcher';
import { HomeStateSelector } from './home.selector';
import { CollapsibleHorizontalModule } from '../collapsible - horizontal/collapsible-horizontal.module';
import { SharedModule } from '../shared/shared.module';
import { PrTableComponent } from '../production-return-list/pr-table/pr-table.component';

@NgModule({
  declarations: [HomeComponent],
  imports: [
    CommonModule,
    PageHeaderModule,
    EffectsModule.forFeature([HomeEffects]),
    StoreModule.forFeature('home', fromHome.reducer),
    CollapsibleHorizontalModule,
    SharedModule,
    RouterModule,
    MatCardModule,
  ],
  providers: [
    CustomerViewService,
    HomeActionDispatcher,
    HomeStateSelector,
  ]
})
export class HomeModule { }
