import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PermitScheduleComponent } from './permit-schedule.component';

const routes: Routes = [{
  path: '',
  component: PermitScheduleComponent,
  data: {
    breadcrumb: {
      label: 'Permit Holder Summary',
      reset: true,
    }
  }
},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PermitScheduleRoutingModule { }
