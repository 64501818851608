import { ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatButtonModule } from '@angular/material/button';

import { AgentsTableComponent } from './agents-table.component';
import { AgentsTableEffects } from './agents-table.effects';
import { AgentTableStateSelector } from './agents-table.selector';
import { AgentsTableDispatcher } from './agents-table.dispatcher';
import * as fromAgentsTableSelector from './agents-table.reducer';

@NgModule({
  declarations: [AgentsTableComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgbModule,
    FontAwesomeModule,
    StoreModule.forFeature('agents-table', fromAgentsTableSelector.reducer),
    EffectsModule.forFeature([AgentsTableEffects]),
    MatButtonModule,
  ],
  exports: [AgentsTableComponent],
  providers: [
    AgentTableStateSelector,
    AgentsTableDispatcher,
  ]
})
export class AgentsTableModule { }
