import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';

import * as fromAuth from './reducers/auth.reducers';
import { State, LoggedInStatus } from './auth.reducer';
import { AuthenticateResult } from './auth.d';

@Injectable()
export class AuthStateSelector {

  constructor(private store: Store<fromAuth.State>) {
  }

  loggedIn = (): Observable<LoggedInStatus> => this.store.pipe(
    select(fromAuth.getAuthStatus),
    map((getLoggedInStatus: State) => getLoggedInStatus.loggedIn)
  )

  token = (): Observable<string> => this.store.pipe(
    select(fromAuth.getAuthStatus),
    map((authStatus: State) => authStatus.token)
  )

  loggedInUser = (): Observable<AuthenticateResult> => this.store.pipe(
    select(fromAuth.getAuthStatus),
    map((authStatus: State) => {
      const {loggedIn, ...rest} = authStatus;
      return rest;
    })
  )

}
