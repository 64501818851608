import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { Agent } from '../../home/CustomerView';

@Component({
  selector: 'app-edit-permissions-modal',
  templateUrl: './edit-permissions-modal.component.html',
  styleUrls: ['./edit-permissions-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EditPermissionsModalComponent implements OnInit {
  @Input() previousPermissions: Agent;
  @Input() newPermissions: any;
  noPermissionsAssigned: boolean;
  allPermissionsAssigned: boolean;
  constructor(
    public modal: NgbActiveModal,
  ) { }

  ngOnInit() {
    this.manageNewPermissions(this.newPermissions);
  }

  manageNewPermissions = (permissions: any) => {
    const allPermissions = permissions.inquiries && permissions.productionReports && permissions.agentManagement;
    const noPermissions = !permissions.inquiries && !permissions.productionReports && !permissions.agentManagement;
    if (noPermissions) {
      this.noPermissionsAssigned = true;
    }
    if (allPermissions) {
      this.allPermissionsAssigned = true;
    }

  }

}
