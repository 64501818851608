import { Component, OnInit, ViewEncapsulation, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-exit-modal',
  templateUrl: './exit-modal.component.html',
  styleUrls: ['./exit-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ExitModalComponent implements OnInit {
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  constructor(
    public modal: NgbActiveModal,
  ) { }

  ngOnInit() {
  }

  confirm = () => {
    this.passEntry.emit(true);
    this.modal.close();
  }
}
