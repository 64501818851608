import { Action } from '@ngrx/store';

import { RegistrationDetails } from '../../home/CustomerView';

export enum AccountProfileActionTypes {
  LoadRegistrationDetails = '[AccountProfile] Load RegistrationDetails',
  LoadRegistrationDetailsSuccess = '[AccountProfile] Load Customer Success',
  LoadRegistrationDetailsFailed = '[AccountProfile] Load Customer Failed',
}

export class LoadRegistrationDetails implements Action {
  readonly type = AccountProfileActionTypes.LoadRegistrationDetails;
  constructor(public payload: string) {}
}

export class LoadRegistrationDetailsSuccess implements Action {
  readonly type = AccountProfileActionTypes.LoadRegistrationDetailsSuccess;
  constructor(public payload: RegistrationDetails) {}
}

export class LoadRegistrationDetailsFailed implements Action {
  readonly type = AccountProfileActionTypes.LoadRegistrationDetailsFailed;
  constructor(public payload: string) {}
}

export type AccountProfileActions = LoadRegistrationDetails
| LoadRegistrationDetailsSuccess
| LoadRegistrationDetailsFailed;
