import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { FormGroup, FormBuilder,  } from '@angular/forms';
import { map, tap, withLatestFrom } from 'rxjs/operators';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NgbTypeaheadSelectItemEvent, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';

import { HomeActionDispatcher } from '../../home/home.dispatcher';
import { HomeStateSelector } from '../../home/home.selector';
import { CustomerDetail, Agent, ActiveAgent, CustomerSummary } from '../../home/CustomerView';
import { CustomerSummaryService } from '../customer-summary.service';
import { TypeaheadService } from '../../shared/typeahead.service';
import { AquaDirectAgentService } from '../aqua-direct-agent.service';
import { EditPermissionsModalComponent } from '../edit-permissions-modal/edit-permissions-modal.component';
import { AgentSelectorStateSelector } from '../../agent-selector/agent-selector.selector';
import { ViewStatus } from '../../enum';
import { OptionMeta } from '../../search-results';
import { lookup } from '../../typeahead/lookup';
import { equals } from '../../shared/ramda-functions';
import { AgentSelectorState } from '../../../app/agent-selector/agent-selector.reducers';
import { SetActiveAgent } from '../../../app/agent-selector/agent-selector.actions';

@Component({
  selector: 'app-edit-agent',
  templateUrl: './edit-agent.component.html',
  styleUrls: ['./edit-agent.component.scss']
})
export class EditAgentComponent implements OnInit, OnDestroy {
  agent$: Observable<any>;
  customerDetail$: Observable<CustomerDetail>;
  agentEditForm: FormGroup;
  showErrors = false;
  loading$$: Subscription;
  error$: Observable<string>;
  queryParam$$: Subscription;
  getAgentRelationship$$: Subscription;
  selectAllSubscription$$: Subscription;
  inquiriesSubscription$$: Subscription;
  agentManagementSubscription$$: Subscription;
  productionReportsSubscription$$: Subscription;
  activeAgent$$: Subscription;
  agentRelationship: Agent;
  inquiriesValue: boolean;
  agentManagementValue: boolean;
  productionReportsValue: boolean;
  selectedAgentName: string;
  loggedInUserID: string;
  activeAgentCustomerID: string;
  secondaryAgentID: string;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    private homeActionDispatcher: HomeActionDispatcher,
    private homeStateSelector: HomeStateSelector,
    private formBuilder: FormBuilder,
    private customerSummaryService: CustomerSummaryService,
    protected typeaheadService: TypeaheadService,
    protected ngxLoader: NgxUiLoaderService,
    private aquaDirectAgentService: AquaDirectAgentService,
    private modalService: NgbModal,
    private agentSelectorStateSelector: AgentSelectorStateSelector,
    private agentSelectorStore: Store<AgentSelectorState>,
  ) { }

  ngOnInit() {
    this.homeActionDispatcher.loadCustomer();
    this.customerDetail$ = this.homeStateSelector.customerDetail();
    this.error$ = this.homeStateSelector.error();
    this.createForm();
    this.listenToQueryParamChange();
    this.inquiriesSubscription$$ = this.agentEditForm.get('inquiries').valueChanges.subscribe(value => {
      this.manageSelectAllStatus(value);
      this.inquiriesValue = value;
      this.isAllSelected();
    });
    this.agentManagementSubscription$$ = this.agentEditForm.get('agentManagement').valueChanges.subscribe(value => {
      this.agentManagementValue = value;
      this.manageSelectAllStatus(value);
      this.isAllSelected();
    });
    this.productionReportsSubscription$$ = this.agentEditForm.get('productionReports').valueChanges.subscribe(value => {
      this.productionReportsValue = value;
      this.manageSelectAllStatus(value);
      this.isAllSelected();
    });
    this.checkBoxManagement();
    this.listenToActiveAgent();
    // TODO: you need set active agent here to see name page is reloaded, but you need to include name
    // this.agentSelectorActionDispatcher.setActiveAgent(this.route.snapshot.params.customerID);
  }

  private listenToActiveAgent() {
    this.activeAgent$$ = this.agentSelectorStateSelector.activeAgent().subscribe((activeAgent: ActiveAgent) => {
      this.selectedAgentName = activeAgent.agentForName;
    });
  }

  private manageSelectAllStatus(value: any) {
    if (!value) {
      this.selectAllSubscription$$.unsubscribe();
      this.agentEditForm.get('selectAll').setValue(value);
      this.checkBoxManagement();
    }
  }

  private listenToQueryParamChange() {
    this.queryParam$$ = this.route.params.pipe(
      tap((params) => {
        this.loggedInUserID = params.loggedInUserID;
        this.activeAgentCustomerID = params.activeAgentCustomerID;
        this.secondaryAgentID = params.customerID;
        this.getAgentRelationship(params.agentRelationshipID);
      })
    ).subscribe();
  }

  private getAgentRelationship(agentRelationshipID) {
    if (agentRelationshipID) {
      if (this.getAgentRelationship$$) {
        this.getAgentRelationship$$.unsubscribe();
      }
      this.getAgentRelationship$$ = this.aquaDirectAgentService.getAgentRelationship(agentRelationshipID)
      .subscribe((agentRelationship) => {
        this.agentRelationship = agentRelationship;
        this.patchValuesToForm(this.agentRelationship);
      });
    }
  }

  patchValuesToForm = (agentRelationship?: Agent) => {
    this.agentEditForm.get('inquiries').patchValue(agentRelationship.inquiries);
    this.agentEditForm.get('productionReports').patchValue(agentRelationship.productionReports);
    this.agentEditForm.get('agentManagement').patchValue(agentRelationship.agentManagement);
  }

  private createForm() {
      this.agentEditForm = this.formBuilder.group({
        inquiries: [null],
        productionReports: [null],
        agentManagement: [null],
        selectAll: [],
      });
  }

  checkAllSelected = () => {
    return (this.agentRelationship.inquiries && this.agentRelationship.productionReports && this.agentRelationship.agentManagement)
      ? true : false;
  }

  isAllSelected = () => {
    if (this.inquiriesValue && this.agentManagementValue && this.productionReportsValue) {
      this.selectAllSubscription$$.unsubscribe();
      this.agentEditForm.get('selectAll').setValue(true);
      this.checkBoxManagement();
    }
    if (!this.inquiriesValue && !this.agentManagementValue && !this.productionReportsValue) {
      this.selectAllSubscription$$.unsubscribe();
      this.agentEditForm.get('selectAll').setValue(false);
      this.checkBoxManagement();
    }
  }

  selectAll = () => {
    this.agentEditForm.get('inquiries').setValue(true);
    this.agentEditForm.get('agentManagement').setValue(true);
    this.agentEditForm.get('productionReports').setValue(true);
  }

  unselectAll = () => {
    this.agentEditForm.get('inquiries').setValue(false);
    this.agentEditForm.get('agentManagement').setValue(false);
    this.agentEditForm.get('productionReports').setValue(false);
  }

  checkBoxManagement = () => {
    this.selectAllSubscription$$ = this.agentEditForm.get('selectAll').valueChanges.subscribe(value => {
      if (value) {
        this.selectAll();
      } else {
        this.unselectAll();
      }
    });
  }


  lookupCustomer = (text$: Observable<string>) => lookup(text$, '/typeahead/agent', this.typeaheadService);
  formatter = (option: OptionMeta | string) => typeof option === 'object' ? `${option.label}` : option;
  inputFormatter = (option: OptionMeta) => option.id;
  loadCustomer = (event: NgbTypeaheadSelectItemEvent) => {
    this.agent$ = this.customerSummaryService.get(event.item.id);
  }

  showUpdatePermissionsModal(agentID: string) {
    const modalRef = this.modalService.open(EditPermissionsModalComponent, {
      windowClass: 'update-modal',
      backdrop: 'static',
      size: 'lg'
    });
    modalRef.componentInstance.previousPermissions = this.agentRelationship;
    modalRef.componentInstance.newPermissions = this.agentEditForm.value;

    modalRef.result.then(result => equals(result, 'update') ? this.updateAgentPermission() : null);
  }

  updateAgentPermission() {
    if (this.agentEditForm.invalid) {
      this.showErrors = true;
      return;
    }

    this.homeActionDispatcher.updateAgent({
      ...this.agentEditForm.value,
      agentID: this.route.snapshot.params.agentRelationshipID,
      loggedInUserID: this.loggedInUserID,
      activeAgentCustomerID: this.activeAgentCustomerID ,
      secondaryAgentID: this.secondaryAgentID,
    });
    this.listenToViewStatus();
  }

  ngOnDestroy(): void {
    if (this.loading$$) {
      this.loading$$.unsubscribe();
    }
    this.productionReportsSubscription$$.unsubscribe();
    this.inquiriesSubscription$$.unsubscribe();
    this.agentManagementSubscription$$.unsubscribe();
  }

  listenToViewStatus = () => {
    this.loading$$ = this.homeStateSelector.viewStatus()
    .pipe(
      withLatestFrom(this.homeStateSelector.getLoggedInUser()),
      tap(([searchStatus, loggedInUser]) => {
        if (equals(searchStatus, ViewStatus.LoadSuccess)) {
          this.setActiveAgent(loggedInUser);
          this.navigateToManageAgents();
        }
      }),
      map(([searchStatus, _]) => equals(searchStatus, ViewStatus.Loading))
    ).subscribe(isLoading => {
      isLoading ? this.ngxLoader.start() : this.ngxLoader.stop();
    });
  }

  hasPermissionToManageAgents = () => this.agentEditForm.get('agentManagement').value;

  setActiveAgent = (loggedInUser: CustomerSummary) => {
    const activeAgent = this.hasPermissionToManageAgents() ? {
      agentFor: this.agentRelationship.agentFor,
      agentForName: this.agentRelationship.agentForName
    } : {
      agentFor: loggedInUser.customerID,
      agentForName: loggedInUser.customerName
    };
    this.agentSelectorStore.dispatch(new SetActiveAgent(activeAgent));
  }

  navigateToManageAgents = () => {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        selectedAgentID: this.agentRelationship.agentFor,
        selectedAgentName: this.agentRelationship.agentForName,
      }
    };
    this.router.navigate(['manage-agents'], navigationExtras);
  }
}
