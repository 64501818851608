import { Pipe, PipeTransform } from '@angular/core';

import { complement, isNilOrEmpty } from './ramda-functions';

/**
 * Sample Usage in template {{ ' - ' | joinText: preferredContactNumber: preferredContactName}}
 */
@Pipe({
  name: 'joinText'
})
export class JoinTextPipe implements PipeTransform {
  transform = (joinString: string, ...args: Array<string>) =>
    args
      .filter(complement(isNilOrEmpty))
      .join(joinString)
}
