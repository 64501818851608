import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import * as fromView from './permit-view/permit-view.reducer';

export interface PermitState {
  view: fromView.State;
}

export const reducers: ActionReducerMap<PermitState> = {
  view: fromView.reducer,
};

export const getPermitState = createFeatureSelector<PermitState>('permit');
