import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { filter } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, Subscription, of } from 'rxjs';

import { AuthActionDispatcher } from '../auth.dispatcher';
import { LoginStateSelector } from './login.selector';
import { BasePageComponent } from '../../shared/base-page.component';
import { AuthStateSelector } from '../auth.selector';
import { AuthService } from '../auth.service';
import { isNil } from '../../shared/ramda-functions';
import { LoginMessages } from './loginMessages.enum';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends BasePageComponent implements OnInit, OnDestroy  {
  loginForm: FormGroup;
  loginError$: Observable<string>;
  forgotPasswordError: string;
  forgotPasswordSuccessMessage: string;
  forgotPasswordSuspensionErrorMessage: string;
  loggedInUserSubscription$$: Subscription;
  queryParamsSubscription$$: Subscription;
  forgotPasswordSubscription$$: Subscription;


  constructor(
    private authActionDispatcher: AuthActionDispatcher,
    private formBuilder: FormBuilder,
    private loginStateSelector: LoginStateSelector,
    private authStateSelector: AuthStateSelector,
    private ngxLoader: NgxUiLoaderService,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService
  ) {
    super();
  }

  ngOnInit() {
    this.createForm();
    this.listenToQueryParams();
    this.listenToUserLoginEvent();
    this.loading$$ = this.loginStateSelector.pending()
      .subscribe(pending => pending ? this.ngxLoader.start() : this.ngxLoader.stop());
  }

  login = () => {
    this.loginError$ = this.loginStateSelector.error();
    this.clearNotifications();
    this.authActionDispatcher.login(this.loginForm.value);
  }

  forgotPassword = () => {
    this.loginError$ = of(null);
    this.clearNotifications();
    if (this.loginForm.get('username').valid) {
      this.forgotPasswordSubscription$$ = this.authService.forgotPassword(this.loginForm.value)
      .subscribe((result: any) => {
        if (result === LoginMessages.forgotPasswordSuspensionErrorMessage) {
          this.forgotPasswordSuspensionErrorMessage = result;
          this.forgotPasswordSuccessMessage = null;
        } else {
          this.forgotPasswordSuccessMessage = LoginMessages.forgotPasswordSuccessMessage;
          this.forgotPasswordSuspensionErrorMessage = null;
        }
      }, (error: Error) => {
        this.forgotPasswordError = error.message;
      });
    } else {
      this.forgotPasswordError = LoginMessages.forgotPasswordUsernameError;
    }
  }

  ngOnDestroy() {
    if (!isNil(this.loggedInUserSubscription$$)) {
      this.loggedInUserSubscription$$.unsubscribe();
    }
    if (!isNil(this.queryParamsSubscription$$)) {
      this.queryParamsSubscription$$.unsubscribe();
    }
    if (!isNil(this.forgotPasswordSubscription$$)) {
      this.forgotPasswordSubscription$$.unsubscribe();
    }
  }

  private listenToUserLoginEvent() {
    this.loggedInUserSubscription$$ = this.authStateSelector.loggedInUser().subscribe((user) => {
      if (user.temporaryPassword) {
        this.router.navigate(['/update-password']);
      } else if (user.token) {
        this.router.navigate(['home']);
      }
    });
  }

  private listenToQueryParams(): void {
    this.queryParamsSubscription$$ = this.route.queryParams.pipe(
        filter(params => params.temp)
      )
      .subscribe(params => {
        this.loginForm.get('password').setValue(params.temp);
      });
  }

  private createForm = () => {
    this.loginForm = this.formBuilder.group({
      username: [undefined, Validators.required],
      password: [undefined, Validators.required],
    });
  }

  private clearNotifications() {
    this.forgotPasswordError = '';
    this.forgotPasswordSuccessMessage = '';
  }

}
