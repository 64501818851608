import { Component, OnInit, ViewEncapsulation, Output, EventEmitter, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { Action } from '../enum';


@Component({
  selector: 'app-nil-production-modal',
  templateUrl: './nil-production-modal.component.html',
  styleUrls: ['./nil-production-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NilProductionModalComponent implements OnInit {
  @Input() action: Action;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  constructor(
    public modal: NgbActiveModal,
  ) { }

  ngOnInit() {}

  confirm = () => {
    this.passEntry.emit({
      nilProduction: true
    });
    this.modal.close();
  }

  cancel = () => {
    this.passEntry.emit({
      nilProduction: false
    });
    this.modal.close();
  }

}
