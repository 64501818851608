<div class="row">
  <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3" *ngFor="let customer of componentCustomers">
    <div class="card">
      <div class="card-header">
        {{ title }}
      </div>
      <div class="card-body">
        <div class="card-text row">
          <div class="col-12"><span class="label">Customer no:</span>
            {{ customerNumberResolver(customer) }}
          </div>
          <div class="col-12"><span class="label">Name:</span> {{ customer.customerName }}</div>
          <div class="col-12"><span class="label">Status:</span> {{ customer.customerStatus }}</div>
          <div class="col-12"><span class="label">Type:</span> {{ customer.customerType }}</div>
          <div class="col-12"><span class="label">Preferred contact:</span> {{ customer.preferredContact }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
