import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Observable, merge, defer, of, combineLatest } from 'rxjs';
import { SubSink } from 'subsink';

import { isNil } from '../../shared/ramda-functions';
import { ProductionReturnService } from '@aquaonline/ng-production-return';
import { FieldNames } from '@aquaonline/ng-models';
import { isNotNilAndEmpty } from '@aquaonline/ramda';
import { map } from 'rxjs/operators';
import { getAverage } from '@aquaonline/ng-shared';


@Component({
  // tslint:disable-next-line:component-selector
  selector: 'tr[app-production-return-sections]',
  templateUrl: './production-return-sections.component.html',
  styleUrls: ['./production-return-sections.component.scss'],
})
export class ProductionReturnSectionsComponent implements OnInit, OnDestroy {
  public subs = new SubSink();
  @Input() currentFormGroupName: FormGroup;
  @Input() showError$: Observable<void>;
  @Input() showErrors: boolean;
  @Input() columns;

  average$: Observable<number>;
  quantityControlValueChanges$: Observable<number>;
  valueControlValueChanges$: Observable<number>;

  get averageControl() {
    return this.currentFormGroupName.get(FieldNames.Average) as FormControl;
  }

  get valueControl() {
    return this.currentFormGroupName.get(FieldNames.Value) as FormControl;
  }

  get quantityControl() {
    return this.currentFormGroupName.get(FieldNames.Quantity) as FormControl;
  }

  constructor(
    protected productionReturnService: ProductionReturnService,
  ) { }

  ngOnInit() {
    if (isNotNilAndEmpty(this.averageControl)) { this.showAverageValue(); }
    
    this.subs.sink = this.showError$.subscribe(() => this.showErrors = true);
  }

  private showAverageValue = () => {
    this.valueControlValueChanges$ = merge(this.valueControl.valueChanges, defer(() => of(this.valueControl.value)));
    this.quantityControlValueChanges$ = merge(this.quantityControl.valueChanges, defer(() => of(this.quantityControl.value)));
    this.subs.sink = combineLatest(this.valueControlValueChanges$, this.quantityControlValueChanges$)
      .pipe(
        map(([value, quantity]) => getAverage(value, quantity))
      ).subscribe(average => this.averageControl.setValue(average));
  }

  remove = (value) => {
    this.productionReturnService.removeSubject.next(value);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
