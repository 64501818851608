import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError } from 'rxjs/operators';

import {
  PermitScheduleActionTypes,
  LoadPermitScheduleSuccess,
  LoadPermitScheduleFailed
 } from './permit-schedule.actions';
import { PermitScheduleService } from './permit-schedule.service';

@Injectable()
export class PermitScheduleEffects {

  @Effect()
  loadPermitSchedule$: Observable<LoadPermitScheduleSuccess | LoadPermitScheduleFailed> =
    this.actions$
      .pipe(
        ofType(PermitScheduleActionTypes.LoadPermitSchedule),
        switchMap(({payload}) =>
          this.permitScheduleService.getPermitSchedule(payload)
            .pipe(
              map(references => new LoadPermitScheduleSuccess(references)),
              catchError(error => of(new LoadPermitScheduleFailed(error)))
            )
        )
      );

  constructor(
    private actions$: Actions,
    private permitScheduleService: PermitScheduleService,
  ) {}
}
