
import { Injectable } from '@angular/core';
import { FormGroup, FormArray, FormBuilder } from '@angular/forms';

import { ProductionReturnService } from './production-return.service';
import { isNilOrEmpty, isNotNilAndEmpty, propEq, pathEq } from '@aquaonline/ramda';
import { FieldTypes, FieldNames, Section, IEstuary } from '@aquaonline/ng-models';
import { createArrayWithOrAppendValue } from '@aquaonline/ng-shared';
import { columns } from './production-return-columns';
import { NgProductionReturnModule } from './ng-production-return.module';


@Injectable({
  providedIn: NgProductionReturnModule
})
export class ProductionReturnSectionFiveService {
  columns = columns;
  constructor(
    protected productionReturnService: ProductionReturnService,
    protected formBuilder: FormBuilder,
  ) { }

  addFromControlsForInput = (input, transactionForm, selectedSpeciesByEstuarySectionFive) => {
    const newColumns = {...this.columns};
    (transactionForm.get('sectionFiveForm') as FormArray)
    .push(this.formBuilder.group(this.getFormGroupItemsFromColumnEdit(newColumns.sectionFive, input)));
    if (isNotNilAndEmpty(+input.estuaryID)) {
      this.addInputIfNotExist(selectedSpeciesByEstuarySectionFive, input);
    }
  }

  private addInputIfNotExist(selectedSpeciesByEstuarySectionFive: any, input: any) {
    const newValue = { speciesName: input.speciesName, speciesID: +input.speciesID };
    if (selectedSpeciesByEstuarySectionFive[+input.estuaryID]) {
      const isValueExist = selectedSpeciesByEstuarySectionFive[+input.estuaryID].find(element => propEq('speciesID', +input.speciesID, element));
      if (isNilOrEmpty(isValueExist)) {
        selectedSpeciesByEstuarySectionFive[+input.estuaryID].push(newValue);
      }
    } else {
      selectedSpeciesByEstuarySectionFive[+input.estuaryID] = [newValue];
    }
  }

  getFormGroupItemsFromColumnEdit(columns, receivedEntry) {
    return columns.reduce((formGroupOb, column) => {
      switch (column.name) {
        case FieldNames.SpeciesName: {
          column.defaultValue = receivedEntry.speciesName;
          break;
        }
        case FieldNames.SpeciesID: {
          column.defaultValue = +receivedEntry.speciesID;
          break;
        }
        case FieldNames.EstuaryName: {
          column.defaultValue = receivedEntry.estuaryName;
          break;
        }
        case FieldNames.EstuaryID: {
          column.defaultValue = +receivedEntry.estuaryID;
          break;
        }
        case FieldNames.RowNumber: {
          column.defaultValue = receivedEntry.rowNumber;
          break;
        }
      }
      if (propEq('type', FieldTypes.Input, column) || propEq('type', FieldTypes.DropDown, column)) {
        column.defaultValue = receivedEntry[column.name];
      }
      formGroupOb[column.name] = [column.defaultValue, column.validators];
      return  formGroupOb;
    }, {});
  }

  getFormGroupItemsFromColumn(columns, receivedEntry) {
    return columns.reduce((formGroupOb, column) => {
      switch (column.name) {
        case FieldNames.SpeciesName: {
          column.defaultValue = receivedEntry.speciesName;
          break;
        }
        case FieldNames.SpeciesID: {
          column.defaultValue = +receivedEntry.speciesID;
          break;
        }
        case FieldNames.EstuaryName: {
          column.defaultValue = receivedEntry.estuaryName;
          break;
        }
        case FieldNames.EstuaryID: {
          column.defaultValue = +receivedEntry.estuaryID;
          break;
        }
        case FieldNames.RowNumber: {
          column.defaultValue = receivedEntry.rowNumber;
          break;
        }
      }
      if (propEq('type', FieldTypes.Input, column)) {
        column.defaultValue = null;
      }
      if (propEq('type', FieldTypes.DropDown, column)) {
        column.defaultValue = null;
      }
      formGroupOb[column.name] = [column.defaultValue, column.validators];
      return  formGroupOb;
    }, {});
  }

  createControls = (receivedEntry, transactionForm: FormGroup, selectedSpeciesByEstuarySectionFive) => {
    const newColumns = {...this.columns};
    (transactionForm.get('sectionFiveForm') as FormArray).push(this.formBuilder.group(this.getFormGroupItemsFromColumn(newColumns.sectionFive, receivedEntry)));
    return createArrayWithOrAppendValue([receivedEntry.estuaryID], 
      {speciesName: receivedEntry.speciesName, speciesID: receivedEntry.speciesID}, selectedSpeciesByEstuarySectionFive);
  }

  sectionValidator = (formGroup: FormGroup) => {
    if (isNilOrEmpty(formGroup)) {
      return;
    }
    let sectionFiveEntriesArray = [];
    formGroup.value.forEach(value => {
      sectionFiveEntriesArray.push(value.estuaryID + value.speciesID + value.productType);
    });
    let sectionFiveEntriesSet = new Set(sectionFiveEntriesArray);
    return sectionFiveEntriesArray.length === sectionFiveEntriesSet.size ? null : {duplicateProductType: true};
  }

  public createInputs(sectionFiveForm, inputs) {
    if (isNilOrEmpty(sectionFiveForm)) { return; }

    sectionFiveForm.forEach(entry => {
      inputs.push({secID: Section.sectionFive, speciesID: entry.speciesID, productType: entry.productType,
        estuaryID: entry.estuaryID, estuaryName: entry.estuaryName, average: +entry.average,
        quantity: entry.quantity, value: entry.value, speciesName: entry.speciesName, rowNumber: entry.rowNumber,
      });
    });
  }

  public openSpeciesModal(modalRef, estuary: IEstuary, selectedSpeciesByEstuarySectionFive) {
    modalRef.componentInstance.species = estuary.species;
    modalRef.componentInstance.estuary = estuary;
    modalRef.componentInstance.selectedSpeciesByEstuarySectionFive = selectedSpeciesByEstuarySectionFive;
    return modalRef.componentInstance.passEntry;
  }

  public removeRow = (value, sectionFiveFormControl: FormArray, selectedSpeciesByEstuarySectionFive) => {
    this.removeEntry(sectionFiveFormControl, value, selectedSpeciesByEstuarySectionFive);
    const entryForEstuary = sectionFiveFormControl.controls.find(formGroup => pathEq(['value', 'estuaryID'], value.estuaryID, formGroup));
    sectionFiveFormControl.updateValueAndValidity();
    return isNilOrEmpty(entryForEstuary) ? false : true
  }

  private removeEntry(sectionFiveControl: FormArray, value: any, selectedSpeciesByEstuarySectionFive) {
    sectionFiveControl.controls = sectionFiveControl.controls
      .filter(formGroup => !(pathEq(['value', 'rowNumber'], value.rowNumber, formGroup) && pathEq(['value', 'estuaryID'], value.estuaryID, formGroup)));
    selectedSpeciesByEstuarySectionFive[value.estuaryID] = selectedSpeciesByEstuarySectionFive[value.estuaryID]
      .filter(aSpecies => !propEq('speciesID', value.speciesID, aSpecies));
  }
}