import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { BreadcrumbComponent } from './breadcrumb.component';
import { reducer } from './breadcrumb.reducer';
import { BreadcrumbStateSelector } from './breadcrumb.selector';
import { BreadcrumbActionDispatcher } from './breadcrumb.dispatcher';
import { BreadcrumbEffects } from './breadcrumb.effects';


@NgModule({
  declarations: [BreadcrumbComponent],
  exports: [BreadcrumbComponent],
  imports: [
    CommonModule,
    RouterModule,
    StoreModule.forFeature('breadcrumb', reducer),
    EffectsModule.forFeature([BreadcrumbEffects]),
  ],
  providers: [
    BreadcrumbStateSelector,
    BreadcrumbActionDispatcher,
  ]
})
export class BreadcrumbModule { }
