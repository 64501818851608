import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ProductionReturnListComponent } from './production-return-list.component';
import { CanDeactivateGuard } from '../CanDeactivateGuard';

const routes: Routes = [
  {
    path: '',
    component: ProductionReturnListComponent,
    data: {
      breadcrumb: {
        label: 'Production Returns',
      },
    },
  },
  {
    path: 'edit',
    loadChildren: () => import('../production-return/production-return.module').then(m => m.ProductionReturnModule),
    data: {
      breadcrumb: {
        label: 'Production Return Detail',
      },
    },
  },
  {
    path: 'view',
    loadChildren: () => import('../production-return-view/production-return-view.module').then(m => m.ProductionReturnViewModule),
    data: {
      breadcrumb: {
        label: 'Production Return View',
      },
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProductionReturnListRoutingModule { }
