import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { InformationModalComponent } from './information-modal.component';

@NgModule({
  declarations: [InformationModalComponent],
  exports: [InformationModalComponent],
  entryComponents: [InformationModalComponent],
  imports: [
    CommonModule,
    NgbModule,
    ReactiveFormsModule
  ]
})
export class InformationModalModule {}
