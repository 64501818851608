import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatButtonModule } from '@angular/material/button';

import { PermitSchedulePrintComponent } from './permit-schedule-print.component';
import { PageHeaderModule } from '../page-header/page-header.module';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [PermitSchedulePrintComponent],
  imports: [
    CommonModule,
    PageHeaderModule,
    ReactiveFormsModule,
    NgbModule,
    MatButtonModule,
    SharedModule
  ],
  providers: []
})
export class PermitSchedulePrintModule { }
