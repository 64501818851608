import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';

import { PageHeaderModule } from '../page-header/page-header.module';
import { PermitService } from './permit.service';
import { PermitRoutingModule } from './permit-routing.module';
import { PermitViewActionDispatcher } from './permit-view/permit-view.dispatcher';
import { PermitViewStateSelector } from './permit-view/permit-view.selector';
import * as fromPermit from './permit.reducers';

@NgModule({
  imports: [
    CommonModule,
    PermitRoutingModule,
    PageHeaderModule,
    StoreModule.forFeature('permit', fromPermit.reducers),
  ],
  providers: [
    PermitService,
    PermitViewActionDispatcher,
    PermitViewStateSelector,
  ]
})
export class PermitModule { }
