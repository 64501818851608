<ng-container [formGroup]="transactionForm" *ngIf="transactionDetail">
    <ngb-alert *ngIf="saved" type="success" [dismissible]="false">{{ saved }}</ngb-alert>
   <div *ngIf="preventing?.length">
        <ngb-alert *ngFor="let error of preventing" type="danger" [dismissible]="false">
            {{ error.errorDetail }}
        </ngb-alert>
   </div>
    <ngb-alert *ngIf="showErrors" type="danger" [dismissible] = "false">
        Data entry has been saved. Please review data entry errors as this will prevent you from submitting your production return.
    </ngb-alert>
    <ngb-alert  *ngIf="smallMobileDevice" type="danger" class="small-device-alert" [dismissible] = "false">
        Completion of this form is not recommended on small screen devices.
    </ngb-alert>
    
     <!-- Save & Submit -->
     <app-production-return-save-submit (clickListener) = "actionHandler($event)"></app-production-return-save-submit>

     <p class="contextual">{{ contextualMessage }}</p>

     <p class="title">Production return year {{financialYear}}</p>

    <!-- Section 1 -->

        <div class="row section">
            <div class="table-containers">
                <table class="table table-striped">
                <thead>
                    <tr>
                        <th [colSpan]="4" class="headings">
                            Permit Holder Details
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="titles">
                            Permit holder
                        </td>
                        <td>
                            {{transactionDetail.customerName}} - {{transactionDetail.customerID}}
                        </td>
                        <td class="titles">
                            Postal address
                        </td>
                        <td>
                            {{transactionDetail.customerContact}}
                        </td>
                    </tr>
                    <tr>
                        <td class="titles">
                            Permits
                        </td>
                        <td [colSpan]="4">
                            <span *ngFor="let permit of transactionDetail.permits; let i = index;">
                                <span *ngIf="i !== transactionDetail.permits.length - 1">
                                    {{ permit.permitID}} - Class: {{permit.permitClassCode}},
                                </span>
                                <span *ngIf="i === transactionDetail.permits.length - 1">
                                    {{ permit.permitID}} - Class: {{permit.permitClassCode}}
                                </span> 
                            </span>
                        </td>
                    </tr>
                </tbody>
                </table>
            </div>
        </div>
        
    <!-- Section 2 -->


        <div class="row section">
            <div class="table-containers">
                <table class="table table-striped">
                <thead>
                    <tr>
                        <th style = "text-align: left" colspan="4">
                            Permit holder declaration
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="containsLeaseBasedPermit">
                        <td [colSpan]="3"
                        class="titles">
                            <p class="required text">1. Do you as the permit holder have current Public Liability Insurance (PLI) 
                                as required by your Deed of Undertaking and Acknowledgement?</p>
                        </td>
                        <td>
                            <div class="question-container">
                                <div class="question-holder">
                                    <div class="question">
                                        <input 
                                        class="input"
                                        type="radio"
                                        [value]="true"
                                        formControlName="questionOne"
                                        >
                                        <p >Yes</p>
                                        <input 
                                        class="input" 
                                        type="radio"
                                        [value]="false"
                                        formControlName="questionOne"
                                        >
                                        <p >No</p>
                                    </div>
                                </div>
                            </div>
                            <span class="form-control-error" 
                                *ngIf="showErrors && transactionForm.get('questionOne').hasError('required')">
                                    This field is required.
                            </span>
                        </td>
                    </tr>
                    <tr *ngIf="containsLeaseBasedPermit">
                        <td [colSpan]="3"
                        class="titles">
                            <p class="required text">2. Do you understand that as the permit holder, 
                                you are responsible for ensuring that all leases under your permit are 
                                maintained in a tidy condition and marked in accordance with the standards
                                 described in the NSW Oyster Industry Sustainable Aquaculture Strategy (OISAS), 
                                 or conditions of development approval?</p>
                        </td>
                        <td>
                            <div class="question-container">
                                <div class="question-holder">
                                    <div class="question">
                                        <input 
                                        class="input"
                                        type="radio"
                                        [value]="true"
                                        formControlName="questionTwo"
                                        >
                                        <p >Yes</p>
                                        <input 
                                        class="input" 
                                        type="radio"
                                        [value]="false"
                                        formControlName="questionTwo"
                                        >
                                        <p >No</p>
                                    </div>
                                </div>
                            </div>
                            <span class="form-control-error" 
                                *ngIf="showErrors && transactionForm.get('questionTwo').hasError('required')">
                                    This field is required.
                            </span>
                        </td>
                    </tr>
                    <tr *ngIf="containsLeaseBasedPermit">
                        <td [colSpan]="3"
                        class="titles">
                            <p class="required text">3. Do you understand that as the permit holder, 
                                the leases under your permit will be regularly inspected by Fisheries 
                                Officers and if any area is found to be non-compliant with OISAS or conditions of development approval, 
                                a Notice to Comply will be issued under S.162 of the Fisheries 
                                Management Act 1994 and/or clause 55 of the Fisheries 
                                Management (Aquaculture) Regulation 2017, and you will 
                                be responsible for ensuring the lease is brought back to a 
                                compliant state as per the time indicated in that Notice?</p>
                        </td>
                        <td>
                            <div class="question-container">
                                <div class="question-holder">
                                    <div class="question">
                                        <input 
                                        class="input"
                                        type="radio"
                                        [value]="true"
                                        formControlName="questionThree"
                                        >
                                        <p >Yes</p>
                                        <input 
                                        class="input" 
                                        type="radio"
                                        [value]="false"
                                        formControlName="questionThree"
                                        >
                                        <p >No</p>
                                    </div>
                                </div>
                            </div>
                            <span class="form-control-error" 
                                *ngIf="showErrors && transactionForm.get('questionThree').hasError('required')">
                                    This field is required.
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td [colSpan]="3"
                        class="titles">
                            <p *ngIf="containsLeaseBasedPermit" class="required">4. Did you have a Nil Production?</p>
                            <p *ngIf="!containsLeaseBasedPermit" class="required">Did you have a Nil Production?</p>
                        </td>
                        <td>
                            <div class="question-container">
                                <div class="question-holder">
                                    <div class="question">
                                        <input 
                                        class="input"
                                        type="radio"
                                        [value]="true"
                                        formControlName="nilProduction"
                                        >
                                        <p >Yes</p>
                                        <input 
                                        class="input" 
                                        type="radio"
                                        [value]="false"
                                        formControlName="nilProduction"
                                        >
                                        <p >No</p>
                                    </div>
                                </div>
                            </div>
                            <span class="form-control-error" 
                                *ngIf="showErrors && transactionForm.get('nilProduction').hasError('required')">
                                    This field is required.
                            </span>
                        </td>
                    </tr>
                    
                </tbody>
                </table>
            </div>
        </div>
    
    
    
    <ng-container *ngIf="showFields">
        <div class="row section" *ngFor="let section of transactionDetail.sections">

            <!-- Section 3 -->
    
                <ng-container  formGroupName = "sectionThreeFormGroup" *ngIf="section.secID === Section.sectionThree">
                    <div class="col-12">
                        <h3>Oyster Production</h3>
                    </div>
                    <div class="col-12">
                        <h4>Report oyster sold in DOZENS</h4>
                        <p class="comment">Oyster production by grade for human consumption (permit classes A and/or B).</p>
                        <div class="estuary-field" *ngFor="let estuary of section.estuaries">
                            <h5>
                                <label class="col-7 font-weight-bold">{{estuary.name}}</label>
                                <button class="btn btn-link btn-lg float-right" (click)="openSpeciesModal(estuary, section)">
                                    Add species
                                    <fa-icon icon="plus" class="ml-2"></fa-icon>
                                </button>
                            </h5>
                            <div class="table-container" *ngIf="showSectionThreeEntries[+estuary.estuaryID]">
                                <table class="table table-striped">
                                    <thead *ngIf="displayHeading(estuary, section)">
                                        <th>Species</th>
                                        <th class="table-heading">Large
                                            <span class="info-icon" (click)="openInformationModal()">
                                                <fa-icon icon="info-circle"></fa-icon>
                                            </span>
                                        </th>
                                        <th class="table-heading">Medium
                                            <span class="info-icon" (click)="openInformationModal()">
                                                <fa-icon icon="info-circle"></fa-icon>
                                            </span>
                                        </th>
                                        <th class="table-heading">Small
                                            <span class="info-icon" (click)="openInformationModal()">
                                                <fa-icon icon="info-circle"></fa-icon>
                                            </span>
                                        </th>
                                        <th></th>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngFor="let formGroup of sectionThreeAFormControl?.controls; let i = index;">
                                            <tr app-production-return-sections 
                                            [columns]="columns.sectionThreeA"
                                            [currentFormGroupName] = "formGroup"
                                            [showError$]="showError$"
                                            *ngIf="estuary.estuaryID === formGroup.value.estuaryID"
                                            >
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="col-12" *ngIf="showSectionThreeSubsectionsEntries">
                        <h4>Report average farm gate price in dollars (<strong>$</strong>)</h4>
                        <div class="estuary-field">
                            <div class="table-container">
                                <table class="table table-striped">
                                    <thead>
                                        <th>Species</th>
                                        <th class="table-heading">Large</th>
                                        <th class="table-heading">Medium</th>
                                        <th class="table-heading">Small</th>
                                        <th></th>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngFor="let formGroup of sectionThreeBFormControl?.controls; let i = index;">
                                            <tr app-production-return-sections 
                                            [columns]="columns.sectionThreeB"
                                            [currentFormGroupName] = "formGroup"
                                            [showError$]="showError$"
                                            class="table-row"
                                            >
                                            </tr>
                                        </ng-container>  
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="form-control-error requiredFields" 
                            *ngIf="showErrors && sectionThreeControls?.hasError('requiredFields')">
                            <div *ngFor="let err of sectionThreeControls.errors.errorMsg">
                                <span>
                                    {{err}}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-12" *ngIf="showSectionThreeSubsectionsEntries">
                        <h4>Report source of farm stock by percentage (<strong>%</strong>)</h4>
                        <div class="estuary-field">
                            <div class="table-container">
                                <table class="table table-striped percentage">
                                    <thead>
                                        <th>Species</th>
                                        <th class="table-heading">Wild caught</th>
                                        <th class="table-heading">NSW hatchery / Nursery stock</th>
                                        <th class="table-heading">Import from interstate hatchery</th>
                                        <th></th>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngFor="let formGroup of sectionThreeCFormControl?.controls; let i = index;">
                                            <tr app-production-return-sections 
                                            [columns]="columns.sectionThreeC"
                                            [currentFormGroupName] = "formGroup"
                                            [showError$]="showError$"
                                            class="table-row"
                                            >
                                            </tr>
                                            <span class="form-control-error" 
                                                *ngIf="showErrors && formGroup.hasError('InvalidTotalPercentage')">
                                                Each species entries must total 0% or 100%.
                                            </span>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <span class="form-control-error" 
                            *ngIf="showErrors && sectionThreeControls?.hasError('requiredRow')">
                            {{ sectionThreeControls.errors.errorMsg}}
                        </span>
                    </div>
                </ng-container>
                
    
            <!-- Section 4 -->
    
                <ng-container *ngIf="section.secID === Section.sectionFour">
                    <div class="col-12">
                        <h3>Spat Production for Molluscs</h3>
                    </div>
                    <div class="col-12">
                        <h4>Report spat quantity in INDIVIDUALS</h4>
                        <p class="comment">Spat production of oysters & other molluscs (permit classes A, B, C and/or H).</p>
                    </div>
                    <div class="col-12">
                        <h5>
                            <button class="btn btn-link btn-lg float-right" (click)="openSpeciesModal('', section)">
                                Add species
                                <fa-icon icon="plus" class="ml-2"></fa-icon>
                            </button>
                        </h5>
                        <div class="table-container" *ngIf="showSectionFourEntries">
                            <table class="table table-striped">
                                <thead>
                                    <th>Species</th>
                                    <th class="table-heading">
                                        Origin
                                    </th>
                                    <th class="table-heading">
                                        Size
                                        <span class="info-icon" (click)="openInformationModal()">
                                            <fa-icon icon="info-circle"></fa-icon>
                                        </span>
                                    </th>
                                    <th class="table-heading">Total Quantity (<strong>Ind</strong>)
                                    </th>
                                    <th class="table-heading">Total Value (<strong>$</strong>)
                                    </th>
                                    <th class="table-heading">Avg Value (<strong>$</strong>)
                                    </th>
                                    <th></th>
                                </thead>
                                <tbody>
                                    <ng-container *ngFor="let formGroup of sectionFourFormControl?.controls; let i = index;">
                                        <tr app-production-return-sections 
                                        [columns]="columns.sectionFour"
                                        [currentFormGroupName] = "formGroup"
                                        [showError$]="showError$"
                                        >
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="col-12">
                        <span class="form-control-error" 
                        *ngIf="showErrors && transactionForm.get('sectionFourForm').hasError('duplicateOriginSize')">
                            You cannot select same species with same origin and size more than once.
                        </span> 
                    </div>
                </ng-container>
    
    
            <!-- Section 5 -->
    
                <ng-container *ngIf="section.secID === Section.sectionFive">
                    <div class="col-12">
                        <h3>Pearl Oyster Production</h3>
                    </div>
                    <div class="col-12">
                        <h4>Report production in INDIVIDUALS</h4>
                        <p class="comment">Pearl oyster production (permit classes A and/or B).</p>
                    </div>
                    <div class="col-12">
                        <div class="estuary-field" *ngFor="let estuary of section.estuaries">
                            <h5>
                                <label class="col-7 font-weight-bold">{{estuary.name}}</label>
                                <button class="btn btn-link btn-lg float-right" (click)="openSpeciesModal(estuary, section)">
                                    Add species
                                    <fa-icon icon="plus" class="ml-2"></fa-icon>
                                </button>
                            </h5>
                            <div class="table-container" *ngIf="showSectionFiveEntries[+estuary.estuaryID]">
                                <table class="table table-striped">
                                    <thead>
                                        <th>Species</th>
                                        <th class="table-heading">
                                            Product Type
                                        </th>
                                        <th class="table-heading">
                                            Total Quantity (<strong>Ind</strong>)
                                        </th>
                                        <th class="table-heading">
                                            Total Value (<strong>$</strong>)
                                        </th>
                                        <th class="table-heading">
                                            Avg Value (<strong>$</strong>)
                                        </th>
                                        <th></th>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngFor="let formGroup of sectionFiveFormControl?.controls; let i = index;">
                                            <tr app-production-return-sections 
                                            [columns]="columns.sectionFive"
                                            [currentFormGroupName] = "formGroup"
                                            [showError$]="showError$"
                                            *ngIf="estuary.estuaryID === formGroup.value.estuaryID"
                                            >
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </div>
                        </div>    
                    </div>
                    <div class="col-12">
                        <span class="form-control-error" 
                        *ngIf="showErrors && transactionForm.get('sectionFiveForm').hasError('duplicateProductType')">
                            You cannot select same species with same product type for a specific estuary.
                        </span> 
                    </div>
                </ng-container>
    
    
            <!-- Section 6 -->
    
                <ng-container *ngIf="section.secID === Section.sectionSix">
                    <div class="col-12 mb-2">
                        <h3 class="mb-0">Non Oyster Production</h3>
                        <p class="comment">Any other lease-based production excluding oysters.</p>
                    </div>
                    <div class="col-12">
                        <h4>Report production in KILOGRAMS</h4>
                        <p class="comment">Production of non-oyster species on leases (permit classes A and/or B).</p>
                    </div>
                    <div class="col-12">
                        <div class="estuary-field" *ngFor="let estuary of section.estuaries">
                            <h5>
                                <label class="col-7 font-weight-bold">{{estuary.name}}</label>
                                <button class="btn btn-link btn-lg float-right" (click)="openSpeciesModal(estuary, section)">
                                    Add species
                                    <fa-icon icon="plus" class="ml-2"></fa-icon>
                                </button>
                            </h5>
                            <div class="table-container" *ngIf="showSectionSixEntries[+estuary.estuaryID]">
                                <table class="table table-striped">
                                    <thead *ngIf="displayHeading(estuary, section)">
                                        <th>Species</th>
                                        <th class="table-heading">
                                            Total Quantity (<strong>Kg</strong>)
                                        </th>
                                        <th class="table-heading">
                                            Total Value (<strong>$</strong>)
                                        </th>
                                        <th class="table-heading">
                                            Avg Value  (<strong>$</strong>)
                                        </th>
                                        <th></th>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngFor="let formGroup of sectionSixFormControl?.controls; let i = index;">
                                            <tr app-production-return-sections 
                                            [columns]="columns.sectionSix"
                                            [currentFormGroupName] = "formGroup"
                                            [showError$]="showError$"
                                            *ngIf="estuary.estuaryID === formGroup.value.estuaryID"
                                            >
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </div>
                        </div>    
                    </div>
                </ng-container>
    
    
            <!-- Section 7 -->
    
                <ng-container *ngIf="section.secID === Section.sectionSeven">
                    <div class="col-12">
                        <h3>Production under Class C, D, E and / or F Permits</h3>
                    </div>
                    <div class="col-12">
                        <h4>Report production in KILOGRAMS</h4>
                        <p class="comment">Production of any species - exclude anything reported as spat (permit classes C, D, E and/or F).</p>
                        <p class="comment">If you hold a Class F permit AND are catch & release facility ONLY, you do NOT need to complete this section.</p>
                    </div>
                    <div class="col-12">
                        <div class="estuary-field" *ngFor="let permit of section.permits">
                            <h5>
                                <label class="col-7 font-weight-bold">{{permit.permitID}} Class {{permit.permitClassCode}}</label>
                                <button class="btn btn-link btn-lg float-right" (click)="openSpeciesModal('', section, '', permit)">
                                    Add species
                                    <fa-icon icon="plus" class="ml-2"></fa-icon>
                                </button>
                            </h5>
                            <div class="table-container" *ngIf="showSectionSevenEntries[permit.permitID]">
                                <table class="table table-striped">
                                    <thead *ngIf="displayHeading('', section, permit)">
                                        <th>Species</th>
                                        <th class="table-heading">
                                            Product Type
                                        </th>
                                        <th class="table-heading">
                                            Total Quantity (<strong>Kg</strong>)
                                        </th>
                                        <th class="table-heading">
                                            Total Value (<strong>$</strong>)
                                        </th>
                                        <th class="table-heading">
                                            Avg Value (<strong>$</strong>)
                                        </th>
                                        <th></th>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngFor="let formGroup of sectionSevenFormControl?.controls; let i = index;">
                                            <tr app-production-return-sections 
                                            [columns]="columns.sectionSeven"
                                            [currentFormGroupName] = "formGroup"
                                            [showError$]="showError$"
                                            *ngIf="permit.permitID === formGroup.value.permitID"
                                            >
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </div>
                        </div>    
                    </div>
                    <div class="col-12">
                        <span class="form-control-error" 
                        *ngIf="showErrors && transactionForm.get('sectionSevenForm').hasError('duplicateProductType')">
                            You cannot select same species with same product type for a specific permit.
                        </span> 
                    </div>
                </ng-container>
    
    
    
            <!-- Section 8 -->
    
                <ng-container *ngIf="section.secID === Section.sectionEight">
                    <div class="col-12">
                        <h3>Production under Class H Permit</h3>
                    </div>
                    <div class="col-12">
                        <h4>Report production in INDIVIDUALS</h4>
                        <p class="comment">Production of any species - exclude anything reported as spat (permit class H).</p>
                    </div>
                    <div class="col-12">
                        <div class="estuary-field" *ngFor="let permit of section.permits">
                            <h5>
                                <label class="col-7 font-weight-bold">{{permit.permitID}} Class {{permit.permitClassCode}}</label>
                                <button class="btn btn-link btn-lg float-right" (click)="openSpeciesModal('', section, '', permit)">
                                    Add species
                                    <fa-icon icon="plus" class="ml-2"></fa-icon>
                                </button>
                            </h5>
                            <div class="table-container" *ngIf="showSectionEightEntries[permit.permitID]">
                                <table class="table table-striped">
                                    <thead *ngIf="displayHeading('', section, permit)">
                                        <th>Species</th>
                                        <th class="table-heading">
                                            Product Type
                                        </th>
                                        <th class="table-heading">
                                            Total Quantity (<strong>Ind</strong>)
                                        </th>
                                        <th class="table-heading">
                                            Total Value (<strong>$</strong>)
                                        </th>
                                        <th class="table-heading">Avg Value
                                        </th>
                                        <th></th>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngFor="let formGroup of sectionEightFormControl?.controls; let i = index;">
                                            <tr app-production-return-sections 
                                            [columns]="columns.sectionEight"
                                            [currentFormGroupName] = "formGroup"
                                            [showError$]="showError$"
                                            *ngIf="permit.permitID === formGroup.value.permitID"
                                            >
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </div>
                        </div>    
                    </div>
                    <div class="col-12">
                        <span class="form-control-error" 
                        *ngIf="showErrors && transactionForm.get('sectionEightForm').hasError('duplicateProductType')">
                            You cannot select same species with same product type for a specific permit.
                        </span> 
                    </div>
                </ng-container>
    
    
        </div>
    </ng-container>
    
    </ng-container>
    <ngb-alert *ngIf="showErrors" type="danger" [dismissible] = "false">
        Data entry has been saved. Please review data entry errors as this will prevent you from submitting your production return.
    </ngb-alert>
    <ngb-alert *ngIf="saved" type="success" [dismissible]="false">{{ saved }}</ngb-alert>
    <div *ngIf="preventing?.length">
        <ngb-alert *ngFor="let error of preventing" type="danger" [dismissible]="false">
            {{ error.errorDetail }}
        </ngb-alert>
    </div>
    <app-production-return-save-submit class="buttons" (clickListener) = "actionHandler($event)"></app-production-return-save-submit>
    
    
    
    
         