import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as fromHome from './home.reducer';
import { LoadCustomer, CreateAgent, UpdateAgent, ClearErrors } from './home.actions';
import { CreateAgentPayload, UpdateAgentPayload } from './CustomerView';

@Injectable()
export class HomeActionDispatcher {

  constructor(private store: Store<fromHome.State>) {
  }
  clearErrors = () => this.store.dispatch(new ClearErrors());
  loadCustomer = () => this.store.dispatch(new LoadCustomer());
  createAgent = (agentPermissions: CreateAgentPayload) => this.store.dispatch(new CreateAgent(agentPermissions));
  updateAgent = (agentPermissions: UpdateAgentPayload) => this.store.dispatch(new UpdateAgent(agentPermissions));
}
