import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as fromCustomerSearch from './breadcrumb.reducer';
import { AddBreadcrumb, ClearBreadcrumb } from './breadcrumb.actions';
import { Breadcrumb } from './Breadcrumb';

@Injectable()
export class BreadcrumbActionDispatcher {

  constructor(private store: Store<fromCustomerSearch.State>) {
  }

  add = (breadcrumb: Breadcrumb) => this.store.dispatch(new AddBreadcrumb(breadcrumb));

  clear = () => this.store.dispatch(new ClearBreadcrumb());
}
