export enum CustomerStatusID {
  Disqualified = 0,
  Active = 1,
}

export interface CustomerSummary {
  acn: string;
  billingPostcode: string;
  billingState: string;
  billingStreetAddress: string;
  billingTownSuburb: string;
  customerID: string;
  customerName: string;
  customerStatusID: CustomerStatusID;
  customerStatus?: string;
  customerType: string;
  customerTypeID: string;
  documentFolderNumber: string;
  financeCustomerNumber: string;
  mailingPostcode: string;
  mailingState: string;
  mailingStreetAddress1: string;
  mailingStreetAddress2: string;
  mailingTownSuburb: string;
  physicalPostcode: string;
  physicalState: string;
  physicalStreetAddress1: string;
  physicalStreetAddress2: string;
  physicalTownSuburb: string;
  preferredContactName: string;
  preferredContactNumber: string;
}
