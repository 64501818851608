
import { Validators } from '@angular/forms';

import { FieldTypes, FieldNames } from '@aquaonline/ng-models';
import { wholeNumberRegex, maxLengthValidator, positiveNumberValidator, twoDecimalPlacesValidator } from '@aquaonline/ng-shared';

export const columns = {
  sectionThreeA : [
    {
      name: FieldNames.SpeciesName,
      type: FieldTypes.Text,
      defaultValue: null,
      validators: [],
    },
    {
      name: FieldNames.Large,
      type: FieldTypes.Input,
      defaultValue: null,
      validators: [Validators.pattern(wholeNumberRegex), maxLengthValidator, positiveNumberValidator],
    },
    {
      name: FieldNames.Medium,
      type: FieldTypes.Input,
      defaultValue: null,
      validators: [Validators.pattern(wholeNumberRegex), maxLengthValidator, positiveNumberValidator],
    },
    {
      name: FieldNames.Small,
      type: FieldTypes.Input,
      defaultValue: null,
      validators: [Validators.pattern(wholeNumberRegex), maxLengthValidator, positiveNumberValidator],
    },
    {
      name: FieldNames.SpeciesID,
      type: FieldTypes.Info,
      defaultValue: null,
      validators: [],
    },
    {
      name: FieldNames.EstuaryID,
      type: FieldTypes.Info,
      defaultValue: null,
      validators: [],
    },
    {
      name: FieldNames.EstuaryName,
      type: FieldTypes.Info,
      defaultValue: null,
      validators: [],
    },
    {
      name: FieldNames.SecID,
      type: FieldTypes.Info,
      defaultValue: 'OYSTER_PRODUCTION',
      validators: [],
    },
    {
      name: FieldNames.RowNumber,
      type: FieldTypes.Info,
      defaultValue: null,
      validators: [],
    },
    {
      name: FieldNames.Remove,
      type: FieldTypes.Remove,
      defaultValue: null,
      validators: [],
    },
  ],
  sectionThreeB : [
    {
      name: FieldNames.SpeciesName,
      type: FieldTypes.Text,
      defaultValue: null,
      validators: [],
    },
    {
      name: FieldNames.Large,
      type: FieldTypes.Input,
      defaultValue: null,
      validators: [twoDecimalPlacesValidator, maxLengthValidator, positiveNumberValidator],
    },
    {
      name: FieldNames.Medium,
      type: FieldTypes.Input,
      defaultValue: null,
      validators: [twoDecimalPlacesValidator, maxLengthValidator, positiveNumberValidator],
    },
    {
      name: FieldNames.Small,
      type: FieldTypes.Input,
      defaultValue: null,
      validators: [twoDecimalPlacesValidator, maxLengthValidator, positiveNumberValidator],
    },
    {
      name: FieldNames.SpeciesID,
      type: FieldTypes.Info,
      defaultValue: null,
      validators: [],
    },
    {
      name: FieldNames.Remove,
      type: FieldTypes.PlaceHolder,
      defaultValue: null,
      validators: [],
    },
    {
      name: FieldNames.SecID,
      type: FieldTypes.Info,
      defaultValue: 'OYSTER_PRODUCTION',
      validators: [],
    },
  ],
  sectionThreeC : [
    {
      name: FieldNames.SpeciesName,
      type: FieldTypes.Text,
      defaultValue: null,
      validators: [],
    },
    {
      name: FieldNames.WildCaught,
      type: FieldTypes.Input,
      defaultValue: null,
      validators: [Validators.required, Validators.pattern(wholeNumberRegex), maxLengthValidator],
    },
    {
      name: FieldNames.Hatchery,
      type: FieldTypes.Input,
      defaultValue: null,
      validators: [Validators.required, Validators.pattern(wholeNumberRegex), maxLengthValidator],
    },
    {
      name: FieldNames.Import,
      type: FieldTypes.Input,
      defaultValue: null,
      validators: [Validators.required, Validators.pattern(wholeNumberRegex), maxLengthValidator],
    },
    {
      name: FieldNames.SpeciesID,
      type: FieldTypes.Info,
      defaultValue: null,
      validators: [],
    },
    {
      name: FieldNames.Remove,
      type: FieldTypes.PlaceHolder,
      defaultValue: null,
      validators: [],
    },
    {
      name: FieldNames.SecID,
      type: FieldTypes.Info,
      defaultValue: 'OYSTER_PRODUCTION',
      validators: [],
    },
  ],
  sectionFour : [
    {
      name: FieldNames.SpeciesName,
      type: FieldTypes.Text,
      defaultValue: null,
      validators: [],
    },
    {
      name: FieldNames.Origin,
      type: FieldTypes.DropDown,
      defaultValue: null,
      dropDownValues: [
        'Wild caught',
        'NSW hatchery',
        'Interstate hatchery',
        'Nursery',
      ],
      validators: [Validators.required],
    },
    {
      name: FieldNames.Size,
      type: FieldTypes.DropDown,
      defaultValue: null,
      dropDownValues: [
        'Small',
        'Medium',
        'Large',
      ],
      validators: [Validators.required],
    },
    {
      name: FieldNames.Quantity,
      type: FieldTypes.Input,
      defaultValue: null,
      validators: [Validators.required, Validators.pattern(wholeNumberRegex), maxLengthValidator, positiveNumberValidator],
    },
    {
      name: FieldNames.Value,
      type: FieldTypes.Input,
      defaultValue: null,
      validators: [Validators.required, twoDecimalPlacesValidator, maxLengthValidator, positiveNumberValidator],
    },
    {
      name: FieldNames.SpeciesID,
      type: FieldTypes.Info,
      defaultValue: null,
      validators: [],
    },
    {
      name: FieldNames.SecID,
      type: FieldTypes.Info,
      defaultValue: 'SPAT_PRODUCTION',
      validators: [],
    },
    {
      name: FieldNames.RowNumber,
      type: FieldTypes.Info,
      defaultValue: null,
      validators: [],
    },
    {
      name: FieldNames.Average,
      type: FieldTypes.Text,
      unit: '$',
      defaultValue: null,
      validators: [],
    },
    {
      name: FieldNames.Remove,
      type: FieldTypes.Remove,
      defaultValue: null,
      validators: [],
    },
  ],
  sectionFive : [
    {
      name: FieldNames.SpeciesName,
      type: FieldTypes.Text,
      defaultValue: null,
      validators: [],
    },
    {
      name: FieldNames.ProductType,
      type: FieldTypes.DropDown,
      defaultValue: null,
      dropDownValues: [
        'Oyster (meat)',
        'Pearl',
      ],
      validators: [Validators.required],
    },
    {
      name: FieldNames.Quantity,
      type: FieldTypes.Input,
      defaultValue: null,
      validators: [Validators.required, Validators.pattern(wholeNumberRegex), maxLengthValidator, positiveNumberValidator],
    },
    {
      name: FieldNames.Value,
      type: FieldTypes.Input,
      defaultValue: null,
      validators: [Validators.required, twoDecimalPlacesValidator, maxLengthValidator, positiveNumberValidator],
    },
    {
      name: FieldNames.SpeciesID,
      type: FieldTypes.Info,
      defaultValue: null,
      validators: [],
    },
    {
      name: FieldNames.EstuaryID,
      type: FieldTypes.Info,
      defaultValue: null,
      validators: [],
    },
    {
      name: FieldNames.EstuaryName,
      type: FieldTypes.Info,
      defaultValue: null,
      validators: [],
    },
    {
      name: FieldNames.SecID,
      type: FieldTypes.Info,
      defaultValue: 'PEARL_OYSTER_PRODUCTION',
      validators: [],
    },
    {
      name: FieldNames.RowNumber,
      type: FieldTypes.Info,
      defaultValue: null,
      validators: [],
    },
    {
      name: FieldNames.Average,
      type: FieldTypes.Text,
      unit: '$',
      defaultValue: null,
      validators: [],
    },
    {
      name: FieldNames.Remove,
      type: FieldTypes.Remove,
      defaultValue: null,
      validators: [],
    },
  ],
  sectionSix : [
    {
      name: FieldNames.SpeciesName,
      type: FieldTypes.Text,
      defaultValue: null,
      validators: [],
    },
    {
      name: FieldNames.Quantity,
      type: FieldTypes.Input,
      defaultValue: null,
      validators: [Validators.required, Validators.pattern(wholeNumberRegex), maxLengthValidator, positiveNumberValidator],
    },
    {
      name: FieldNames.Value,
      type: FieldTypes.Input,
      defaultValue: null,
      validators: [Validators.required, twoDecimalPlacesValidator, maxLengthValidator, positiveNumberValidator],
    },
    {
      name: FieldNames.SpeciesID,
      type: FieldTypes.Info,
      defaultValue: null,
      validators: [],
    },
    {
      name: FieldNames.EstuaryID,
      type: FieldTypes.Info,
      defaultValue: null,
      validators: [],
    },
    {
      name: FieldNames.EstuaryName,
      type: FieldTypes.Info,
      defaultValue: null,
      validators: [],
    },
    {
      name: FieldNames.SecID,
      type: FieldTypes.Info,
      defaultValue: 'NON_OYSTER_PRODUCTION',
      validators: [],
    },
    {
      name: FieldNames.RowNumber,
      type: FieldTypes.Info,
      defaultValue: null,
      validators: [],
    },
    {
      name: FieldNames.Average,
      type: FieldTypes.Text,
      unit: '$',
      defaultValue: null,
      validators: [],
    },
    {
      name: FieldNames.Remove,
      type: FieldTypes.Remove,
      defaultValue: null,
      validators: [],
    },
  ],
  sectionSeven : [
    {
      name: FieldNames.SpeciesName,
      type: FieldTypes.Text,
      defaultValue: null,
      validators: [],
    },
    {
      name: FieldNames.ProductType,
      type: FieldTypes.DropDown,
      defaultValue: null,
      dropDownValues: [
        'Human consumption',
        'Bait',
        'Other'
      ],
      validators: [Validators.required],
    },
    {
      name: FieldNames.Quantity,
      type: FieldTypes.Input,
      defaultValue: null,
      validators: [Validators.required, Validators.pattern(wholeNumberRegex), maxLengthValidator, positiveNumberValidator],
    },
    {
      name: FieldNames.Value,
      type: FieldTypes.Input,
      defaultValue: null,
      validators: [Validators.required, twoDecimalPlacesValidator, maxLengthValidator, positiveNumberValidator],
    },
    {
      name: FieldNames.SpeciesID,
      type: FieldTypes.Info,
      defaultValue: null,
      validators: [],
    },
    {
      name: FieldNames.PermitID,
      type: FieldTypes.Info,
      defaultValue: null,
      validators: [],
    },
    {
      name: FieldNames.PermitClassCode,
      type: FieldTypes.Info,
      defaultValue: null,
      validators: [],
    },
    {
      name: FieldNames.SecID,
      type: FieldTypes.Info,
      defaultValue: 'PERMIT_NON_H_PRODUCTION',
      validators: [],
    },
    {
      name: FieldNames.RowNumber,
      type: FieldTypes.Info,
      defaultValue: null,
      validators: [],
    },
    {
      name: FieldNames.Average,
      type: FieldTypes.Text,
      unit: '$',
      defaultValue: null,
      validators: [],
    },
    {
      name: FieldNames.Remove,
      type: FieldTypes.Remove,
      defaultValue: null,
      validators: [],
    },
  ],
  sectionEight : [
    {
      name: FieldNames.SpeciesName,
      type: FieldTypes.Text,
      defaultValue: null,
      validators: [],
    },
    {
      name: FieldNames.ProductType,
      type: FieldTypes.DropDown,
      defaultValue: null,
      dropDownValues: [
        'Aquarium',
        'Other',
      ],
      validators: [Validators.required],
    },
    {
      name: FieldNames.Quantity,
      type: FieldTypes.Input,
      defaultValue: null,
      validators: [Validators.required, Validators.pattern(wholeNumberRegex), maxLengthValidator, positiveNumberValidator],
    },
    {
      name: FieldNames.Value,
      type: FieldTypes.Input,
      defaultValue: null,
      validators: [Validators.required, twoDecimalPlacesValidator, maxLengthValidator, positiveNumberValidator],
    },
    {
      name: FieldNames.SpeciesID,
      type: FieldTypes.Info,
      defaultValue: null,
      validators: [],
    },
    {
      name: FieldNames.PermitID,
      type: FieldTypes.Info,
      defaultValue: null,
      validators: [],
    },
    {
      name: FieldNames.PermitClassCode,
      type: FieldTypes.Info,
      defaultValue: null,
      validators: [],
    },
    {
      name: FieldNames.SecID,
      type: FieldTypes.Info,
      defaultValue: 'PERMIT_H_PRODUCTION',
      validators: [],
    },
    {
      name: FieldNames.RowNumber,
      type: FieldTypes.Info,
      defaultValue: null,
      validators: [],
    },
    {
      name: FieldNames.Average,
      type: FieldTypes.Text,
      unit: '$',
      defaultValue: null,
      validators: [],
    },
    {
      name: FieldNames.Remove,
      type: FieldTypes.Remove,
      defaultValue: null,
      validators: [],
    },
  ],
};
