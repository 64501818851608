import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterModule } from '@angular/router';

import { CollapsibleHorizontalComponent } from './collapsible-horizontal.component';
import { SharedModule } from '../shared/shared.module';


const COMPONENTS = [CollapsibleHorizontalComponent];

@NgModule({
  declarations: COMPONENTS,
  exports: COMPONENTS,
  imports: [
    CommonModule,
    SharedModule,
    FontAwesomeModule,
    RouterModule,
  ]
})
export class CollapsibleHorizontalModule { }
