import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CustomerSummaryService {

  constructor(
    private http: HttpClient
  ) { }

  get = (customerID: string): Observable<any> =>
    this.http.get<any>(`${environment.apiUrl}/user/summary?customerID=${customerID}`)
}
