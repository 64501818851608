import { Pipe, PipeTransform } from '@angular/core';

import { isNilOrEmpty } from './ramda-functions';

@Pipe({
  name: 'replaceNewLine'
})
export class ReplaceNewLinePipe implements PipeTransform {
  transform = (text: string, replaceString = '<br />') => isNilOrEmpty(text) ? null : text.replace(new RegExp('\n', 'g'), replaceString);
}
