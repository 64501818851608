import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatGridListModule } from '@angular/material/grid-list';
import { A11yModule } from '@angular/cdk/a11y';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PortalModule } from '@angular/cdk/portal';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatMenuModule } from '@angular/material/menu';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { NgbModule, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxUiLoaderModule, NgxUiLoaderConfig } from 'ngx-ui-loader';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { ToastrModule } from 'ngx-toastr';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';

import { environment } from '../environments/environment';
import { AppEffects } from './app.effects';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { reducers, metaReducers } from './reducers';
import { HeaderModule } from './header/header.module';
import { MenuModule } from './menu/menu.module';
import { HomeModule } from './home/home.module';
import { BreadcrumbModule } from './breadcrumb/breadcrumb.module';
import { AuthModule } from './auth/auth.module';
import { FooterModule } from './footer/footer.module';
import { UserProfileModule } from './user-profile/user-profile.module';
import { NgbDateCustomParserFormatter } from './shared/ngbDateCustomParserFormatter';
import { IECacheInterceptor } from './ie-cache.interceptor';
import { ErrorInterceptor } from './error.interceptor';
import { ngxToastrConfig } from './ngx-toastr-config';
import { LeaseModule } from './lease/lease.module';
import { FarmsModule } from './farms/farms.module';
import { AccountProfileModule } from './account-profile/view-account-profile/account-profile.module';
import { SetPasswordModule } from './auth/update-password/update-password.module';
import { EditAccountProfileModule } from './account-profile/edit-account-profile/edit-account-profile.module';
import { PageHeaderModule } from './page-header/page-header.module';
import { ManageAgentsModule } from './manage-agents/manage-agents.module';
import { AgentSelectorModule } from './agent-selector/agent-selector.module';
import { PermitModule } from './permit/permit.module';
import { ProductionReturnListModule } from './production-return-list/production-return-list.module';
import { ProductionReturnModule } from './production-return/production-return.module';
import { ProductionReturnViewModule } from './production-return-view/production-return-view.module';
import { CanDeactivateGuard } from './CanDeactivateGuard';
import { ChangePasswordModule } from './change-password/change-password.module';
import { PermitScheduleModule } from './permit-schedule/permit-schedule.module';
import { PermitSchedulePrintModule } from './permit-schedule-print/permit-schedule-print.module';
import { NgProductionReturnModule } from '@aquaonline/ng-production-return';

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  pbThickness: 5, // progress bar thickness,
  fgsColor: '#fff',
  pbColor: '#fff',
  text: 'Loading',
};

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    AppRoutingModule,
    StoreModule.forRoot(reducers, { metaReducers, runtimeChecks: { strictStateImmutability: true, strictActionImmutability: true } }),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
    EffectsModule.forRoot([AppEffects]),
    NgbModule,
    HttpClientModule,
    HeaderModule,
    MenuModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    HomeModule,
    BreadcrumbModule,
    AuthModule,
    FooterModule,
    UserProfileModule,
    AgentSelectorModule,
    ToastrModule.forRoot(ngxToastrConfig),
    PermitModule,
    LeaseModule,
    FarmsModule,
    ProductionReturnListModule,
    AccountProfileModule,
    SetPasswordModule,
    EditAccountProfileModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatFormFieldModule,
    MatInputModule,
    MatGridListModule,
    A11yModule,
    CdkStepperModule,
    CdkTableModule,
    CdkTreeModule,
    DragDropModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    PortalModule,
    ScrollingModule,
    PageHeaderModule,
    ManageAgentsModule,
    ProductionReturnModule,
    ProductionReturnViewModule,
    ChangePasswordModule,
    PermitScheduleModule,
    PermitSchedulePrintModule,
    NgProductionReturnModule
  ],
  providers: [
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
    { provide: HTTP_INTERCEPTORS, useClass: IECacheInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    CanDeactivateGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    library: FaIconLibrary,
  ) {
    library.addIconPacks(fas, far);
  }
}
