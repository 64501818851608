import { Router } from '@angular/router';

import { isEncodedString } from './is-encoded-string';

export const getCurrentPathWithoutParams = (router: Router) => {
  const urlTree = router.parseUrl(router.url);
  const url = urlTree.root.children['primary'].segments
    .map(it => it.path)
    .map(path => !isEncodedString(path) ? encodeURIComponent(path) : path)
    .join('/');

  return `/${url}`;
};


