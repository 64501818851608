
<mat-drawer-container class="footer-container" >
    <mat-drawer *ngIf="isMobile" mode="side" closed>
        <div class="version">{{ version }}</div>
    </mat-drawer>
    <mat-drawer *ngIf="loggedIn === loggedInStatus.loggedIn && !isMobile" mode="side" opened>
        <div class="version">{{ version }}</div>
    </mat-drawer>
    <mat-drawer-content class="footer-content">
        <div class="links">
            <a href="http://www.nsw.gov.au/" target="_blank">NSW Government</a>
             | <a href="https://www.regional.nsw.gov.au/about-us/access-to-information" target="_blank">Accessibility</a>
             | <a href="https://www.regional.nsw.gov.au/privacy" target="_blank">Privacy</a>
             | <a href="https://www.nsw.gov.au/copyright" target="_blank">Copyright</a>
             | <a href="https://www.industry.nsw.gov.au/disclaimer" target="_blank">Disclaimer</a>
        </div>
    </mat-drawer-content>
</mat-drawer-container>