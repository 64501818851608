import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { BootstrapApp } from './index';
import * as fromRoot from '../reducers';

@Injectable({
  providedIn: 'root',
})
export class RootDispatcher {

  constructor(private store: Store<fromRoot.State>) {

  }

  bootstrapApp(): void {
    this.store.dispatch(new BootstrapApp());
  }
}
