import { equals } from '../shared/ramda-functions';

export const isLeaseCurrentOrPendingRenewal = (
  { pendingRenewal, leaseStatus }: {
    pendingRenewal: boolean;
    leaseStatus: string;
  }
) => {
  return equals('Current', leaseStatus) || pendingRenewal;
}
