import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ProductionReturnListService } from './production-return-list.service';
import { ProductionReturn } from '.';

@Component({
  selector: 'app-production-return-list',
  templateUrl: './production-return-list.component.html',
  styleUrls: ['./production-return-list.component.scss'],
})
export class ProductionReturnListComponent implements OnInit {
  pendingProductionReturns$: Observable<ProductionReturn[]>;
  submittedProductionReturns$: Observable<ProductionReturn[]>;

  constructor(
    private productionReturnListService: ProductionReturnListService,
  ) { }

  ngOnInit() {
    this.receiveProductionReturns();
  }

  private receiveProductionReturns() {
    const productionReturns = this.productionReturnListService.getProductionReturnList();

    this.pendingProductionReturns$ = productionReturns.pipe(
      map(prs => prs.filter(pr => pr.pending))
    );
    this.submittedProductionReturns$ = productionReturns.pipe(
      map(prs => prs.filter(pr => !pr.pending))
    );
  }
}
