import { Component, OnInit, Input } from '@angular/core';


@Component({
  selector: 'app-customer-view-address',
  templateUrl: './customer-view-address.component.html',
  styleUrls: ['./customer-view-address.component.scss']
})
export class CustomerViewAddressComponent implements OnInit {
  @Input() addresses: any;
  constructor() { }

  ngOnInit() {
  }

}
