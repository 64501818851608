import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'list',
    pathMatch: 'full'
  },
  {
    path: 'list',
    loadChildren: () => import('./permit-list/permit-list.module').then(m => m.PermitListModule)
  },
  {
    path: 'view/:permitID',
    loadChildren: () => import('./permit-view/permit-view.module').then(m => m.PermitViewModule)
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PermitRoutingModule { }
