import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ExitModalComponent } from './exit-modal.component';

@NgModule({
  declarations: [ExitModalComponent],
  exports: [ExitModalComponent],
  entryComponents: [ExitModalComponent],
  imports: [
    CommonModule,
    NgbModule,
    ReactiveFormsModule
  ]
})
export class ExitModalModule {}
