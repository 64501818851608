import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { ProductionReturnComponent } from './production-return.component';
import { PageHeaderModule } from '../page-header/page-header.module';
import { AgentSelectorModule } from '../agent-selector/agent-selector.module';
import * as fromProductionReturn from './production-return.reducer';
import { ProductionReturnEffects } from './production-return.effects';
import { ProductionReturnSectionsModule } from './production-return-sections/production-return-sections.module';
import { SpeciesModalModule } from './species-modal/species-modal.module';
import { InformationModalModule } from './information-modal/information-modal.module';
import { NilProductionModalModule } from './nil-production-modal/nil-production-modal.module';
import { ProductionReturnSaveSubmitModule } from './production-return-save-submit/production-return-save-submit.module';
import { ExitModalModule } from './exit-modal/exit-modal.module';
import { ExitModalComponent } from './exit-modal/exit-modal.component';
import { ProductionReturnRoutingModule } from './production-return-routing.module';
import { SubmitModalModule } from './submit-modal/submit-modal.module';
import { ClearModalModule } from './clear-modal/clear-modal.module';

@NgModule({
  declarations: [ProductionReturnComponent],
  entryComponents: [ExitModalComponent],
  imports: [
    CommonModule,
    PageHeaderModule,
    AgentSelectorModule,
    StoreModule.forFeature('production-return', fromProductionReturn.reducer),
    EffectsModule.forFeature([ProductionReturnEffects]),
    ReactiveFormsModule,
    FontAwesomeModule,
    NgbModule,
    SpeciesModalModule,
    InformationModalModule,
    NilProductionModalModule,
    ExitModalModule,
    SubmitModalModule,
    ProductionReturnSectionsModule,
    ProductionReturnSaveSubmitModule,
    MatButtonModule,
    ProductionReturnRoutingModule,
    ClearModalModule,
  ],
  providers: []
})
export class ProductionReturnModule { }
