import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { AuthActionTypes, Login, LoginFailure, LoginSuccess } from './auth.actions';
import { AuthService } from './auth.service';
import { AuthenticateResult } from './auth';

@Injectable()
export class AuthEffects {

  @Effect()
  login$: Observable<LoginFailure | LoginSuccess> =
    this.actions$
      .pipe(
        ofType<Login>(AuthActionTypes.Login),
        switchMap(({payload}) =>
          this.authService.login(payload)
            .pipe(
              map((auth: AuthenticateResult) => new LoginSuccess(auth)),
              catchError(err => of(new LoginFailure(err))),
            )
        )
    );

  constructor(
    private actions$: Actions,
    private authService: AuthService
  ) {}
}
