import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { map, mapTo } from 'rxjs/operators';

import { UserProfileReady, UserProfileReset } from './user-profile.actions';
import { AuthActionTypes, LoginSuccess } from '../auth/auth.actions';

@Injectable()
export class UserProfileEffects {

  @Effect()
  userProfileReady$: Observable<UserProfileReady> =
    this.actions$
      .pipe(
        ofType<LoginSuccess>(AuthActionTypes.LoginSuccess),
        map(({payload}) => new UserProfileReady(payload))
      );

  @Effect()
  reset$: Observable<UserProfileReset> = this.actions$
    .pipe(
      ofType(AuthActionTypes.Logout),
      mapTo(new UserProfileReset())
    );

  constructor(
    private actions$: Actions
  ) {}
}
