export interface IProductionReturnModalOutput {
    [key: string]: boolean
}

export enum ProductionReturnModalMessages {
    nilProduction = `Please confirm you have had <b>"Nil Production"</b> for the production year you are submitting a return. This will discard any production data you have entered.`,
    notSubmitted = `Are you sure you want to mark this production return as <b>"Not Submitted"</b>?`
}

export enum ProductionReturnModalTitles {
    nilProduction = 'Nil production confirmation',
    notSubmitted = 'Not submitted production confirmation'
}

export enum ProductionReturnModalOutputs {
    nilProduction = 'nilProduction',
    notSubmitted = 'notSubmitted'
}