import { Pipe, PipeTransform } from '@angular/core';

import { equals } from './ramda-functions';
import { round } from './round';

@Pipe({
  name: 'toFixed'
})
export class ToFixedPipe implements PipeTransform {
  transform(num: number | string | any, decimal = 2) {
    if (isNaN(num)) {
      return num;
    }
    let n = num;
    if (equals(typeof num, 'string')) {
      n = parseFloat(num);
    }

    return round(n, decimal).toFixed(decimal);
  }
}
