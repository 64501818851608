import { Permit } from '../production-return';


export enum ConditionType {
  Standard = 'STANDARD',
  Specific = 'SPECIFIC',
  Special = 'SPECIAL',
}

export enum PermitGroup {
  Farm = 'FARM',
  Lease = 'LEASE'
}

export enum PermitStatuses {
  Current = 'CURRENT',
  Processing = 'PROCESSING',
  Suspended = 'SUSPENDED'
}

export enum PermitStatus {
  Current = 'Current',
  Processing = 'Processing',
  Suspended = 'Suspended',
  Cancelled = 'Cancelled'
}

export enum PermitClass {
  Repository = 'Repository (permits)',
}

export interface Condition {
  conditionIDByUI?: number;
  conditionID?: number;
  permitConditionID?: number;
  leaseConditionID?: number;
  condition?: string;
  conditionLabel?: string;
  conditionType?: ConditionType;
  conditionSubject?: string;
  conditionSubGroup?: string;
  conditionLabelShort?: string;
}

export interface Farm {
  farmID?: string;
  selected?: boolean;
  farmStatus?: string;
  location?: string;
  permitFarmLinkID?: number;
  permitID?: string;
  propertyName?: string;
}

export interface FarmDetail {
  summary: FarmSummary;
  location: FarmLocation;
  permits: Array<Permit>;
}

export interface FarmSummary {
  farmID: string;
  farmStatus: string;
  permitHolderCustomerID: string;
  permitHolderCustomerName: string;
  preferredContactName: string;
  preferredContactNumber: string;
}

export interface FarmSummaryPermit {
  farmID: string;
  farmStatus: string;
  permitID?: string;
  propertyName: string;
  species?: Array<string>;
  location?: string;
  permitFarmLinkID?: number;
  permitHolderCustomerID?: string;
}

export interface FarmLocation {
  lotDP: string;
  propertyName: string;
  address1: string;
  address2: string;
  townSuburb: string;
  state: string;
  postcode: string;
  lga: string;
  fisheriesOffice: string;
}

export interface CustomerSummary {
  acn: string;
  billingPostcode: string;
  billingState: string;
  billingStreetAddress: string;
  billingTownSuburb: string;
  customerID: string;
  customerName: string;
  customerStatusID: string;
  customerType: string;
  customerTypeID: string;
  documentFolderNumber: string;
  financeCustomerNumber: string;
  mailingPostcode: string;
  mailingState: string;
  mailingStreetAddress1: string;
  mailingStreetAddress2: string;
  mailingTownSuburb: string;
  physicalPostcode: string;
  physicalState: string;
  physicalStreetAddress1: string;
  physicalStreetAddress2: string;
  physicalTownSuburb: string;
  preferredContactName: string;
  preferredContactNumber: string;
}

export interface CustomerLease {
  leaseID: string;
  leaseClass: string;
  leaseStatus: string;
  trueLeaseArea: number;
  leaseExpiryDate: Date;
  encumbered: boolean;
  estuary: string;
}

export interface CustomerAssociate {
  customerID: string;
  customerName: string;
  customerStatus: string;
  customerType: string;
  preferredContact: string;
}

export interface CustomerFarm {
  farmID: string;
  farmStatus: string;
  propertyName: string;
  location: string;
  fisheriesOffice: string;
  lga: string;
}

export interface CustomerPermit {
  permitID: string;
  permitStatus: string;
  permitClass: string;
  permitClassCode: string;
  permitGroup: string;
  totalAuthorisedArea?: number;
  wspa?: number;
}

export interface CustomerContact {
  contactType: string;
  contact: string;
  contactName: string;
  sms: boolean;
  preferredContact: boolean;
}

export interface ComponentCustomer {
  customerID?: string;
  customerName?: string;
  customerStatus?: string;
  customerType?: string;
  preferredContact?: string;
}

export interface Flag {
  flagID: number;
  flag: string;
  entity?: string;
  setByUsername?: string;
  setDateTime?: Date;
}

export interface Partner extends ComponentCustomer {
  partnerCustomerID?: string;
}

export interface Director extends ComponentCustomer {
  directorCustomerID?: string;
}

export interface Delegate extends ComponentCustomer {
  delegateCustomerID?: string;
}

export interface CustomerDetail {
  summary: CustomerSummary;
  leases: Array<CustomerLease>;
  farms: Array<CustomerFarm>;
  permits: Array<CustomerPermit>;
  contacts: Array<CustomerContact>;
}

export const CONTEXTUAL_MESSAGE = 'This is a summary of your permit holdings and is not an authorised copy of your aquaculture permit certificate.';