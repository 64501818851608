import { removeNilsFromObject, isNilOrEmpty } from '../shared/ramda-functions';
import { TransactionDetail } from '.';

export const transformPayload = (transactionDetail: TransactionDetail) => {
  const {
    commentNew,
    contacts,
    payments,
    paymentOptions,
    lotDPs,
    structures,
    species,
    conditions,
    flags,
    partners,
    directors,
    delegates,
    leases,
    permits,
    securityArrangements,
    candidates,
    subdivisions,
    farms,
    productionReturnData,
    ...detail
  }: any = transactionDetail;

  let comment = commentNew;
  if (isNilOrEmpty(comment)) {
    comment = undefined;
  }

  return {
    detail: removeNilsFromObject({
      ...detail,
      component: JSON.stringify(transactionDetail.component)
    }),
    commentNew: comment,
    contacts,
    payments: payments || [],
    paymentOptions: paymentOptions || [],
    lotDPs,
    structures,
    species,
    conditions,
    flags,
    partners,
    directors,
    delegates,
    leases,
    permits,
    securityArrangements,
    candidates,
    subdivisions,
    farms,
    productionReturnData,
  };
};
