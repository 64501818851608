import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';

import {
  ProductionReturnActionTypes,
  LoadProductionReturn,
  LoadProductionReturnSuccess,
  LoadProductionReturnFailed
} from './production-return.actions';
import { ProductionReturnWebappDirectService } from './production-return-webapp-direct.service';

@Injectable()
export class ProductionReturnEffects {
  @Effect()
  getProductionReturn$: Observable<LoadProductionReturnSuccess | LoadProductionReturnFailed> =
    this.actions$
      .pipe(
        ofType(ProductionReturnActionTypes.LoadProductionReturn),
        switchMap(({payload}) => {
          return this.productionReturnWebappDirectService.getProductionReturn(payload)
            .pipe(
              map((productionReturn) => new LoadProductionReturnSuccess(productionReturn)),
              catchError(error => of(new LoadProductionReturnFailed(error)))
            );
        })
      );

  constructor(
    private actions$: Actions,
    private productionReturnWebappDirectService: ProductionReturnWebappDirectService,
  ) {}

}
