import { Action } from '@ngrx/store';

import { PermitDetail, LogRequest } from '../index.d';

export enum PermitViewActionTypes {
  LoadPermitView = '[PermitView] Load PermitViews',
  LoadPermitViewWithLog = '[PermitView] Load PermitViews With Log',
  LoadPermitViewSuccess = '[PermitView] Load PermitView Success',
  LoadPermitViewFailed = '[PermitView] Load PermitView Failed',
}

export class LoadPermitView implements Action {
  readonly type = PermitViewActionTypes.LoadPermitView;
  constructor(public payload: string) {}
}

export class LoadPermitViewWithLog implements Action {
  readonly type = PermitViewActionTypes.LoadPermitViewWithLog;
  constructor(public payload: LogRequest) {}
}

export class LoadPermitViewSuccess implements Action {
  readonly type = PermitViewActionTypes.LoadPermitViewSuccess;
  constructor(public payload: PermitDetail) { }
}

export class LoadPermitViewFailed implements Action {
  readonly type = PermitViewActionTypes.LoadPermitViewFailed;
  constructor(public payload: string) { }
}

export type PermitViewActions = LoadPermitView
  |LoadPermitViewWithLog
  | LoadPermitViewSuccess
  | LoadPermitViewFailed;
