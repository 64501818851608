<app-page-header pageTitle="Account Profile"></app-page-header>

<ng-container *ngIf="(customerDetail$ | async) as customerDetail">
  <div class="row section">
    <div class="col-12">
      <h3>Customer details</h3>
    </div>
    <div class="col-12 col-md-10 row">
      <div class="col-12 col-md-6 row customer-row">
        <label class="col-5 font-weight-bold required">Customer no:</label>
        <div class="col-7">
          <!-- <input
            type="text"
            class="form-control"
            formControlName="customerID"
            [ngbTypeahead]="lookupCustomer"
            [resultFormatter]="formatter"
            [inputFormatter]="inputFormatter"
            (selectItem)="loadCustomer($event)"
          /> -->
          <!-- <span class="form-control-error" *ngIf="showErrors && transactionForm.get('customerID').hasError('required')">
            Customer no is required.
          </span>
          <span class="form-control-error" *ngIf="showErrors && transactionForm.get('customerID').hasError('invalidFormat')">
            Select customer using typeahead.
          </span> -->
        </div>
      </div>
      
    </div>
  </div>
  <ng-container>
    <div class="row section">
        <div class="col-12 col-md-4 row address-row">
          <div class="col-12">
            <h3>Physical address</h3>
          </div>
          <div class="col-12 row form-group">
            <label class="col-5 font-weight-bold col-form-label">Street address 1:</label>
            <div class="col-7">
              <!-- <input
                type="text"
                class="form-control"
                formControlName="physicalStreetAddress1"
              /> -->
            </div>
          </div>
          <div class="col-12 row form-group">
            <label class="col-5 font-weight-bold col-form-label">Street address 2:</label>
            <div class="col-7">
              <!-- <input
                type="text"
                class="form-control"
                formControlName="physicalStreetAddress2"
              /> -->
            </div>
          </div>
          <div class="col-12 row form-group">
            <label class="col-5 font-weight-bold col-form-label">Town/Suburb:</label>
            <div class="col-7">
              <!-- <input
                type="text"
                class="form-control"
                formControlName="physicalTownSuburb"
              /> -->
            </div>
          </div>
          <div class="col-12 row form-group">
            <label class="col-5 font-weight-bold col-form-label">State:</label>
            <div class="col-7">
              <!-- <input
                type="text"
                class="form-control"
                formControlName="physicalState"
              /> -->
            </div>
          </div>
          <div class="col-12 row form-group">
            <label class="col-5 font-weight-bold col-form-label">Postcode:</label>
            <div class="col-7">
              <!-- <input
                type="text"
                class="form-control"
                formControlName="physicalPostcode"
              /> -->
            </div>
          </div>
        </div>
      <div class="col-12 col-md-4 row address-row">
        <div class="col-12">
          <h3>Mailing address</h3>
        </div>
        <div class="col-12 row form-group">
          <label class="col-5 font-weight-bold col-form-label">Street address 1:</label>
          <div class="col-7">
            <!-- <input
              type="text"
              class="form-control"
              formControlName="mailingStreetAddress1"
            /> -->
          </div>
        </div>
        <div class="col-12 row form-group">
          <label class="col-5 font-weight-bold col-form-label">Street address 2:</label>
          <div class="col-7">
            <!-- <input
              type="text"
              class="form-control"
              formControlName="mailingStreetAddress2"
            /> -->
          </div>
        </div>
        <div class="col-12 row form-group">
          <label class="col-5 font-weight-bold col-form-label">Town/Suburb:</label>
          <div class="col-7">
            <!-- <input
              type="text"
              class="form-control"
              formControlName="mailingTownSuburb"
            /> -->
          </div>
        </div>
        <div class="col-12 row form-group">
          <label class="col-5 font-weight-bold col-form-label">State:</label>
          <div class="col-7">
            <!-- <input
              type="text"
              class="form-control"
              formControlName="mailingState"
            /> -->
          </div>
        </div>
        <div class="col-12 row form-group">
          <label class="col-5 font-weight-bold col-form-label">Postcode:</label>
          <div class="col-7">
            <!-- <input
              type="text"
              class="form-control"
              formControlName="mailingPostcode"
            /> -->
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4 row address-row">
        <div class="col-12">
          <h3>Billing address</h3>
        </div>
        <div class="col-12 row form-group">
          <label class="col-5 font-weight-bold col-form-label required">Street address:</label>
          <div class="col-7">
            <!-- <input
              type="text"
              class="form-control"
              formControlName="billingStreetAddress"
            /> -->
            <!-- <span class="form-control-error" *ngIf="showErrors && transactionForm.get('billingStreetAddress').hasError('required')">
              Street address is required.
            </span> -->
          </div>
        </div>
        <div class="col-12 row form-group">
          <label class="col-5 font-weight-bold col-form-label required">Town/Suburb:</label>
          <div class="col-7">
            <!-- <input
              type="text"
              class="form-control"
              formControlName="billingTownSuburb"
            /> -->
            <!-- <span class="form-control-error" *ngIf="showErrors && transactionForm.get('billingTownSuburb').hasError('required')">
              Town/Suburb is required.
            </span> -->
          </div>
        </div>
        <div class="col-12 row form-group">
          <label class="col-5 font-weight-bold col-form-label required">State:</label>
          <div class="col-7">
            <!-- <input
              type="text"
              class="form-control"
              formControlName="billingState"
            />
            <span class="form-control-error" *ngIf="showErrors && transactionForm.get('billingState').hasError('required')">
              State is required.
            </span> -->
          </div>
        </div>
        <div class="col-12 row form-group">
          <label class="col-5 font-weight-bold col-form-label required">Postcode:</label>
          <div class="col-7">
            <!-- <input
              type="text"
              class="form-control"
              formControlName="billingPostcode"
            />
            <span class="form-control-error" *ngIf="showErrors && transactionForm.get('billingPostcode').hasError('required')">
              Postcode is required.
            </span> -->
          </div>
        </div>
        <div class="col-12 row form-group">
          <label class="col-5 font-weight-bold col-form-label required">Billing name:</label>
          <div class="col-7">
            <!-- <input
              type="text"
              class="form-control"
              formControlName="billingCustomerName"
            />
            <span class="form-control-error" *ngIf="showErrors && transactionForm.get('billingCustomerName').hasError('required')">
              Billing name is required.
            </span> -->
          </div>
        </div>
        <div class="col-12 row form-group">
          <label class="col-5 font-weight-bold col-form-label">Billing name ext:</label>
          <div class="col-7">
            <!-- <input
              type="text"
              class="form-control"
              formControlName="billingCustomerNameExtension"
            /> -->
          </div>
        </div>
      </div>
    </div>

    <!-- <app-customer-amend-contact-detail [customerID]="transactionForm.get('customerID').value?.id">

    </app-customer-amend-contact-detail>

    <app-component-customer
      *ngIf="customerDetail.customerTypeID !== CustomerTypes.Individual"
      [customerTypeID]="customerDetail.customerTypeID"
      [showError]="customerDetail.customerTypeID === CustomerTypes.Company && showErrors && transactionForm.get('directors').hasError('minLength')"
      [readonly]="customerDetail.customerTypeID === CustomerTypes.Partnership"
    ></app-component-customer> -->
  </ng-container>
</ng-container>
