import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as fromAuth from './reducers/auth.reducers';
import * as actions from '../auth/auth.actions';
import { AuthenticatePayload, AuthenticateResult } from './auth';

@Injectable()
export class AuthActionDispatcher {

  constructor(private store: Store<fromAuth.State>) {
  }

  login(authenticate: AuthenticatePayload): void {
    this.store.dispatch(new actions.Login(authenticate));
  }

  loginSuccess(payload: AuthenticateResult): void {
    this.store.dispatch(new actions.LoginSuccess(payload));
  }

  logout(): void {
    this.store.dispatch(new actions.Logout());
  }

}
