import { ActivatedRoute } from '@angular/router';

import { isNil, path } from './ramda-functions';

export const getDataParamForActivatedRoute = (route: ActivatedRoute, dataParam: string): any => {
  const param  = path(['routeConfig', 'data', dataParam], route);
  if (isNil(param) && route.firstChild) {
    return getDataParamForActivatedRoute(route.firstChild, dataParam);
  }

  return param;
};
