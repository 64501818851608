import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { FormGroup, FormBuilder, AbstractControl } from '@angular/forms';
import { map, tap } from 'rxjs/operators';

import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';

import { HomeActionDispatcher } from '../../home/home.dispatcher';
import { HomeStateSelector } from '../../home/home.selector';
import { CustomerSummaryService } from '../customer-summary.service';
import { TypeaheadService } from '../../shared/typeahead.service';
import { ActiveAgent } from '../../home/CustomerView';
import { AgentSelectorStateSelector } from '../../agent-selector/agent-selector.selector';
import { OptionMeta } from '../../search-results';
import { lookup } from '../../typeahead/lookup';
import { isNilOrEmpty, equals } from '../../shared/ramda-functions';
import { ViewStatus } from '../../enum';

@Component({
  selector: 'app-create-agent',
  templateUrl: './create-agent.component.html',
  styleUrls: ['./create-agent.component.scss']
})
export class CreateAgentComponent implements OnInit, OnDestroy {
  agent$: Observable<any>;
  agentPermissionCreateForm: FormGroup;
  showErrors = false;
  loading$$: Subscription;
  activeAgent$$: Subscription;
  selectAllSubscription$$: Subscription;
  inquiriesSubscription$$: Subscription;
  agentManagementSubscription$$: Subscription;
  productionReportsSubscription$$: Subscription;
  error$: Observable<string>;
  inquiriesValue: boolean;
  agentManagementValue: boolean;
  productionReportsValue: boolean;
  selectedAgentName: string;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    private homeActionDispatcher: HomeActionDispatcher,
    private homeStateSelector: HomeStateSelector,
    private formBuilder: FormBuilder,
    private customerSummaryService: CustomerSummaryService,
    protected typeaheadService: TypeaheadService,
    protected ngxLoader: NgxUiLoaderService,
    private agentSelectorStateSelector: AgentSelectorStateSelector,
  ) { }

  ngOnInit() {
    this.error$ = this.homeStateSelector.error();
    this.createForm();
    this.inquiriesSubscription$$ = this.agentPermissionCreateForm.get('inquiries').valueChanges.subscribe(value => {
      this.manageSelectAllStatus(value);
      this.inquiriesValue = value;
      this.isAllSelected();
    });
    this.agentManagementSubscription$$ = this.agentPermissionCreateForm.get('agentManagement').valueChanges.subscribe(value => {
      this.agentManagementValue = value;
      this.manageSelectAllStatus(value);
      this.isAllSelected();
    });
    this.productionReportsSubscription$$ = this.agentPermissionCreateForm.get('productionReports').valueChanges.subscribe(value => {
      this.productionReportsValue = value;
      this.manageSelectAllStatus(value);
      this.isAllSelected();
    });
    this.checkBoxManagement();
    this.listenToActiveAgent();
    // TODO: you need set active agent here to see name page is reloaded, but you need to include name
    // this.agentSelectorActionDispatcher.setActiveAgent(this.route.snapshot.params.customerID);
  }

  lookupCustomer = (text$: Observable<string>) => {
    return lookup(text$, '/typeahead/agent', this.typeaheadService, this.route.snapshot.params.customerID);
  }
  formatter = (option: OptionMeta | string) => typeof option === 'object' ? `${option.label}` : option;
  inputFormatter = (option: OptionMeta) => option.id;
  loadCustomer = (event: NgbTypeaheadSelectItemEvent) => this.agent$ = this.customerSummaryService.get(event.item.id);

  createAgentPermission() {
    if (this.agentPermissionCreateForm.invalid) {
      this.showErrors = true;
      return;
    }
    this.homeActionDispatcher.createAgent({
      ...this.agentPermissionCreateForm.value,
      agentForCustomerID: this.route.snapshot.params.customerID,
      agentCustomerID: this.agentPermissionCreateForm.value.agentCustomerID.id,
    });
    this.listenToViewStatus();
  }

  ngOnDestroy(): void {
    if (this.loading$$) {
      this.loading$$.unsubscribe();
    }
    if (this.activeAgent$$) {
      this.activeAgent$$.unsubscribe();
    }
    this.productionReportsSubscription$$.unsubscribe();
    this.inquiriesSubscription$$.unsubscribe();
    this.agentManagementSubscription$$.unsubscribe();
  }

  private listenToActiveAgent() {
    this.activeAgent$$ = this.agentSelectorStateSelector.activeAgent().subscribe((activeAgent: ActiveAgent) => {
      this.selectedAgentName = activeAgent.agentForName;
    });
  }

  private isCustomerSelected (control: AbstractControl): {[key: string]: any} | null {
    if (isNilOrEmpty(control.value && control.value.id)) {
      return {'invalidCustomer': {value: control.value}};
    } else {
      return null;
    }
  }

  private createForm() {
    this.agentPermissionCreateForm = this.formBuilder.group({
      agentCustomerID: [null, this.isCustomerSelected],
      inquiries: [false],
      agentManagement: [false],
      productionReports: [false],
      selectAll: [false],
    });
  }

  private manageSelectAllStatus(value: any) {
    if (!value) {
      this.selectAllSubscription$$.unsubscribe();
      this.agentPermissionCreateForm.get('selectAll').setValue(value);
      this.checkBoxManagement();
    }
  }

  private isAllSelected = () => {
    if (this.inquiriesValue && this.agentManagementValue && this.productionReportsValue) {
      this.selectAllSubscription$$.unsubscribe();
      this.agentPermissionCreateForm.get('selectAll').setValue(true);
      this.checkBoxManagement();
    }
    if (!this.inquiriesValue && !this.agentManagementValue && !this.productionReportsValue) {
      this.selectAllSubscription$$.unsubscribe();
      this.agentPermissionCreateForm.get('selectAll').setValue(false);
      this.checkBoxManagement();
    }
  }

  private selectAll = () => {
    this.agentPermissionCreateForm.get('inquiries').setValue(true);
    this.agentPermissionCreateForm.get('agentManagement').setValue(true);
    this.agentPermissionCreateForm.get('productionReports').setValue(true);
  }

  private unselectAll = () => {
    this.agentPermissionCreateForm.get('inquiries').setValue(false);
    this.agentPermissionCreateForm.get('agentManagement').setValue(false);
    this.agentPermissionCreateForm.get('productionReports').setValue(false);
  }

  private checkBoxManagement = () => {
    this.selectAllSubscription$$ = this.agentPermissionCreateForm.get('selectAll').valueChanges.subscribe(value => {
      if (value) {
        this.selectAll();
      } else {
        this.unselectAll();
      }
    });
  }

  private listenToViewStatus() {
    this.loading$$ = this.homeStateSelector.viewStatus()
    .pipe(
      tap(searchStatus => {
        if (equals(searchStatus, ViewStatus.LoadSuccess)) {
          this.router.navigate(['manage-agents']);
        }
      }),
      map(searchStatus => equals(searchStatus, ViewStatus.Loading))
    ).subscribe(isLoading => {
      isLoading ? this.ngxLoader.start() : this.ngxLoader.stop();
    });
  }

}
