import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { Agent } from '../home/CustomerView';
import { AgentSelectorState, getAgentForState, getMyDetailState, getActiveAgentState } from './agent-selector.reducers';

@Injectable()
export class AgentSelectorStateSelector {
  constructor(
    private store: Store<AgentSelectorState>,
  ) {}

  customerDetail = () => this.store.select(getMyDetailState).pipe(
    filter(customerDetail => !!customerDetail),
  )
  activeAgent = () => this.store.select(getActiveAgentState).pipe(
    filter(activeAgent => !!activeAgent),
  )
  agentsFor = (filterForPermission) => this.store.select(getAgentForState).pipe(
    filter(agents => !!agents),
    map(agentFor => {
      return agentFor.filter((agent: Agent) => {
        return agent[filterForPermission];
      });
    }),
  )
  isAgentForAnyone = (filterForPermission): Observable<boolean> => this.agentsFor(filterForPermission).pipe(
    map(agentFor => {
      return agentFor.length > 1;
    }),
  )
}
