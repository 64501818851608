import { LeaseStatuses } from '../lease/enum';

export interface RegistrationDetails {
  contacts?: CustomerContact[];
  directors?: Director[];
  delegates?: Delegate[];
  partners?: Partner[];
  myDetail: CustomerSummary;
}

export interface CustomerDetail {
  myDetail: CustomerSummary;
  leases: Array<CustomerLease>;
  farms: Array<CustomerFarm>;
  permits: Array<CustomerPermit>;
  contacts: Array<CustomerContact>;
  myAgents: Array<Agent>;
  agentFor: Array<Agent>;
  summary: CustomerSummary;
}

export interface CreateAgentPayload {
  agentCustomerID: string;
  agentForCustomerID: string;
  inquiries: boolean;
  productionReports: boolean;
  agentManagement: boolean;
}

export interface UpdateAgentPayload {
  agentID: string;
  inquiries: boolean;
  productionReports: boolean;
  agentManagement: boolean;
  loggedInUserID?: string;
  activeAgentCustomerID?: string;
  secondaryAgentID?: string;
}

export interface ActiveAgent {
  agentFor: string;
  agentForName: string;
}

export interface Agent {
  agentID: number;
  roleType: string;
  customerID: string;
  agentName: string;
  agentPreferredContactName: string;
  agentPreferredContactNumber: string;
  agentFor: string;
  agentForName: string;
  agentForPreferredContactNumber: string;
  agentForPreferredContactName: string;
  inquiries: boolean;
  productionReports: boolean;
  agentManagement: boolean;
}

export interface CustomerSummary {
  acn: string;
  billingPostcode: string;
  billingState: string;
  billingStreetAddress: string;
  billingTownSuburb: string;
  customerID: string;
  customerName: string;
  customerStatusID: string;
  customerType: string;
  customerTypeID: string;
  documentFolderNumber: string;
  financeCustomerNumber: string;
  mailingPostcode: string;
  mailingState: string;
  mailingStreetAddress1: string;
  mailingStreetAddress2: string;
  mailingTownSuburb: string;
  physicalPostcode: string;
  physicalState: string;
  physicalStreetAddress1: string;
  physicalStreetAddress2: string;
  physicalTownSuburb: string;
  preferredContactName: string;
  preferredContactNumber: string;
  tradingAs: string;
  customerStatus: string;
  abn: string;
}

export interface CustomerLease {
  customerID: string;
  leaseID: string;
  leaseId: string;
  leaseClass: string;
  leaseStatus: string;
  trueLeaseArea: number;
  leaseExpiryDate: Date;
  encumbered: boolean;
  estuary: string;
  leaseStatusID?: LeaseStatuses;
  pendingRenewal: number;
}

export interface CustomerAssociate {
  customerID: string;
  customerName: string;
  customerStatus: string;
  customerType: string;
  preferredContact: string;
}

export interface CustomerFarm {
  farmID: string;
  farmId: string;
  farmStatus: string;
  propertyName: string;
  location: string;
  fisheriesOffice: string;
  lga: string;
  customerID?: string;
}

export interface CustomerPermit {
  customerID: string;
  permitID: string;
  permitStatus: string;
  permitStatusID?: string;
  permitId: string;
  permitClass: string;
  permitClassCode: string;
  permitGroup: string;
  totalAuthorisedArea?: number;
  wspa?: number;
}

export interface CustomerContact {
  contactType: string;
  contact: string;
  contactName: string;
  sms: boolean;
  preferredContact: boolean;
}

export interface ComponentCustomer {
  customerID?: string;
  customerName?: string;
  customerStatus?: string;
  customerType?: string;
  preferredContact?: string;
}

export interface Partner extends ComponentCustomer {
  partnerCustomerId?: string;
}

export interface Director extends ComponentCustomer {
  directorCustomerId?: string;
}

export interface Delegate extends ComponentCustomer {
  delegateCustomerId?: string;
}

export enum CustomerTypes {
  Individual = 'INDIVIDUAL',
  Company = 'COMPANY',
  Entity = 'ENTITY',
  Partnership = 'PARTNERSHIP',
  Repository = 'REPOSITORY',
}
