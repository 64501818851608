import { Action, createFeatureSelector, createSelector } from '@ngrx/store';

import { ViewStatus } from './../../enum';
import { LeaseViewActions, LeaseViewActionTypes } from './lease-view.actions';
import { LeaseState } from '../lease.reducers';
import { LeaseDetail } from '../index.d';


export interface State {
  leaseDetail?: LeaseDetail;
  viewStatus: ViewStatus;
  errorMessage?: string;
}

export const initialState: State = {
  viewStatus: ViewStatus.Initial,
};

export function reducer(state = initialState, action: LeaseViewActions): State {
  switch (action.type) {

    case LeaseViewActionTypes.LoadLeaseView:
    case LeaseViewActionTypes.LoadLeaseViewWithLog:
      return {
        viewStatus: ViewStatus.Loading,
      };
    case LeaseViewActionTypes.LoadLeaseViewSuccess:
      return {
        viewStatus: ViewStatus.LoadSuccess,
        leaseDetail: action.payload,
      };
    case LeaseViewActionTypes.LoadLeaseViewFailed:
      return {
        viewStatus: ViewStatus.LoadFailed,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
}

const getLeaseState = createFeatureSelector<LeaseState>('lease');

export const getLeaseViewState = createSelector(getLeaseState, state => state.view);

export const getLeaseDetail = createSelector(getLeaseViewState, state => state.leaseDetail);
export const getLeaseViewStatus = createSelector(getLeaseViewState, state => state.viewStatus);
export const getLeaseViewErrorMessage = createSelector(getLeaseViewState, state => state.errorMessage);
