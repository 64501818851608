
import { Injectable } from '@angular/core';
import { FormGroup, FormArray, FormBuilder } from '@angular/forms';

import { ProductionReturnService } from './production-return.service';
import { isNilOrEmpty, isNotNilAndEmpty, propEq, pathEq } from '@aquaonline/ramda';
import { FieldTypes, FieldNames, Section, IEstuary } from '@aquaonline/ng-models';
import { createArrayWithOrAppendValue } from '@aquaonline/ng-shared';
import { columns } from './production-return-columns';
import { NgProductionReturnModule } from './ng-production-return.module';


@Injectable({
  providedIn: NgProductionReturnModule
})
export class ProductionReturnSectionSixService {
  columns = columns;
  constructor(
    protected productionReturnService: ProductionReturnService,
    protected formBuilder: FormBuilder,
  ) { }

  addFromControlsForInput = (input, transactionForm, selectedSpeciesByEstuarySectionSix) => {
    const newColumns = {...this.columns};
    (transactionForm.get('sectionSixForm') as FormArray)
    .push(this.formBuilder.group(this.getFormGroupItemsFromColumnEdit(newColumns.sectionSix, input)));
    if (isNotNilAndEmpty(+input.estuaryID)) {
      this.addInputIfNotExist(selectedSpeciesByEstuarySectionSix, input);
    }
  };

  private addInputIfNotExist(selectedSpeciesByEstuarySectionSix: any, input: any) {
    const newValue = { speciesName: input.speciesName, speciesID: +input.speciesID };
    if (selectedSpeciesByEstuarySectionSix[+input.estuaryID]) {
      const isValueExist = selectedSpeciesByEstuarySectionSix[+input.estuaryID].find(element => propEq('speciesID', +input.speciesID, element));
      if (isNilOrEmpty(isValueExist)) {
        selectedSpeciesByEstuarySectionSix[+input.estuaryID].push(newValue);
      }
    } else {
      selectedSpeciesByEstuarySectionSix[+input.estuaryID] = [newValue];
    }
  }

  getFormGroupItemsFromColumnEdit(columns, receivedEntry) {
    return columns.reduce((formGroupOb, column) => {
      switch (column.name) {
        case FieldNames.SpeciesName: {
          column.defaultValue = receivedEntry.speciesName;
          break;
        }
        case FieldNames.SpeciesID: {
          column.defaultValue = +receivedEntry.speciesID;
          break;
        }
        case FieldNames.EstuaryName: {
          column.defaultValue = receivedEntry.estuaryName;
          break;
        }
        case FieldNames.EstuaryID: {
          column.defaultValue = +receivedEntry.estuaryID;
          break;
        }
        case FieldNames.RowNumber: {
          column.defaultValue = receivedEntry.rowNumber;
          break;
        }
      }
      if (propEq('type', FieldTypes.Input, column) || propEq('type', FieldTypes.DropDown, column)) {
        column.defaultValue = receivedEntry[column.name];
      }
      formGroupOb[column.name] = [column.defaultValue, column.validators];
      return  formGroupOb;
    }, {});
  }

  getFormGroupItemsFromColumn(columns, receivedEntry) {
    return columns.reduce((formGroupOb, column) => {
      switch (column.name) {
        case FieldNames.SpeciesName: {
          column.defaultValue = receivedEntry.speciesName;
          break;
        }
        case FieldNames.SpeciesID: {
          column.defaultValue = +receivedEntry.speciesID;
          break;
        }
        case FieldNames.EstuaryName: {
          column.defaultValue = receivedEntry.estuaryName;
          break;
        }
        case FieldNames.EstuaryID: {
          column.defaultValue = +receivedEntry.estuaryID;
          break;
        }
        case FieldNames.RowNumber: {
          column.defaultValue = receivedEntry.rowNumber;
          break;
        }
      }
      if (propEq('type', FieldTypes.Input, column)) {
        column.defaultValue = null;
      }
      if (propEq('type', FieldTypes.DropDown, column)) {
        column.defaultValue = null;
      }
      formGroupOb[column.name] = [column.defaultValue, column.validators];
      return  formGroupOb;
    }, {});
  }

  createControls = (receivedEntry, transactionForm: FormGroup, selectedSpeciesByEstuarySectionSix) => {
    const newColumns = {...this.columns};
    (transactionForm.get('sectionSixForm') as FormArray).push(this.formBuilder.group(this.getFormGroupItemsFromColumn(newColumns.sectionSix, receivedEntry)));
    return createArrayWithOrAppendValue([receivedEntry.estuaryID], 
      {speciesName: receivedEntry.speciesName, speciesID: receivedEntry.speciesID}, selectedSpeciesByEstuarySectionSix);
  }

  createInputs(sectionSixForm, inputs) {
    if (isNilOrEmpty(sectionSixForm)) { return; }

    sectionSixForm.forEach(entry => {
      inputs.push({secID: Section.sectionSix, speciesID: entry.speciesID, 
        estuaryID: entry.estuaryID, estuaryName: entry.estuaryName, average: +entry.average,
        quantity: entry.quantity, value: entry.value, speciesName: entry.speciesName, rowNumber: entry.rowNumber,
      });
    });
  }

  public openSpeciesModal(modalRef, estuary: IEstuary, selectedSpeciesByEstuarySectionSix) {
    modalRef.componentInstance.species = estuary.species;
    modalRef.componentInstance.estuary = estuary;
    modalRef.componentInstance.selectedSpeciesByEstuarySectionSix = selectedSpeciesByEstuarySectionSix;
    return modalRef.componentInstance.passEntry;
  }

  removeRow = (value, sectionSixFormControl: FormArray, selectedSpeciesByEstuarysSectionSix) => {
    this.removeSectionSixEntry(sectionSixFormControl, value, selectedSpeciesByEstuarysSectionSix);
    const entryForEstuary = sectionSixFormControl.controls.find(formGroup => pathEq(['value', 'estuaryID'], value.estuaryID, formGroup));
    sectionSixFormControl.updateValueAndValidity();
    return isNilOrEmpty(entryForEstuary) ? false : true;
  }

  private removeSectionSixEntry(sectionSixControl: FormArray, value: any, selectedSpeciesByEstuarySectionSix) {
    sectionSixControl.controls = sectionSixControl.controls
      .filter(formGroup => !(pathEq(['value', 'rowNumber'], value.rowNumber, formGroup) && pathEq(['value', 'estuaryID'], value.estuaryID, formGroup)));
    selectedSpeciesByEstuarySectionSix[value.estuaryID] = selectedSpeciesByEstuarySectionSix[value.estuaryID]
      .filter(aSpecies => !propEq('speciesID', value.speciesID, aSpecies));
  }
}
