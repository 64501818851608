import { Pipe, PipeTransform } from '@angular/core';

import { isNil } from './ramda-functions';

@Pipe({
  name: 'applyFn'
})
export class ApplyFnPipe implements PipeTransform {
  transform = (item: any, fn: (item: any) => string) => {
    if (isNil(fn) || isNil(item)) {
      return '';
    }

    return fn(item);
  }
}
