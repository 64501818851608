import { Pipe, PipeTransform  } from '@angular/core';

import { is } from '../shared/ramda-functions';
@Pipe({
  name: 'joinArray'
})
export class JoinArrayPipe implements PipeTransform {

  transform (input: Array<string>, character: string = ''): any {
    if (!is(Array)(input)) {
      return input;
    }
    return input.join(character);
  }
}
