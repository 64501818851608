import { tap, distinctUntilChanged } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

import { AccountProfileActionDispatcher } from './account-profile.dispatcher';
import { AccountProfileStateSelector } from './account-profile.selector';
import { RegistrationDetails, ActiveAgent, CustomerTypes } from '../../home/CustomerView';
import { Permissions } from '../../enum';
import { AgentSelectorStateSelector } from '../../agent-selector/agent-selector.selector';

@Component({
  selector: 'app-account-profile',
  templateUrl: './account-profile.component.html',
  styleUrls: ['./account-profile.component.scss']
})
export class AccountProfileComponent implements OnInit, OnDestroy {
  CustomerTypes = CustomerTypes;
  registrationDetails$: Observable<RegistrationDetails>;
  registrationDetails: RegistrationDetails;
  address = [];
  message: string;
  agentSelectorPermissionRequired = Permissions.inquiries;
  activeAgent$$: Subscription;
  activeAgent: ActiveAgent;
  componentCustomersTabTitle: string;

  constructor(
     private accountProfileActionDispatcher: AccountProfileActionDispatcher,
     private accountProfileStateSelector: AccountProfileStateSelector,
     private agentSelectorStateSelector: AgentSelectorStateSelector,
  ) { }

  ngOnInit() {
    this.activeAgent$$ = this.agentSelectorStateSelector.activeAgent().pipe(distinctUntilChanged()).subscribe((activeAgent: ActiveAgent) => {
      if (!this.activeAgent || activeAgent.agentFor !== this.activeAgent.agentFor) {
        this.activeAgent = activeAgent;
        this.accountProfileActionDispatcher.registrationDetails(activeAgent.agentFor);
      }
      this.registrationDetails$ = this.accountProfileStateSelector.registrationDetails().pipe(
        tap(registrationDetails => {
          this.registrationDetails = registrationDetails;
        })
      );
      this.message = `Should you wish to update your contact details,
      please go to the Application Forms on the top menu to download the form.`;
    });
  }

  // openEdit() {
  //   this.router.navigate(['/edit-account-profile'], {
  //     relativeTo: this.route
  //   });
  // }

  ngOnDestroy(): void {
    if (this.activeAgent$$) {
      this.activeAgent$$.unsubscribe();
    }
  }

}
