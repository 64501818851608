<div *ngIf="allShown; else showMoreTemplate">
  <ul>
    <li *ngIf="itemsToShow.length === 0">&nbsp;</li>
    <li *ngFor="let item of itemsToShow; let i=index" class="item" >
      <a *ngIf="showAsLink" [routerLink]="['/', entity, 'view', encodeURI(item)]">{{ item }}</a>
      <span *ngIf="!showAsLink">{{ item }}</span>
      <span *ngIf="itemsToShow[i+1]">,</span>
    </li>
  </ul>
</div>

<ng-template #showMoreTemplate>
  <ul>
    <li *ngIf="itemsToShow.length === 0">&nbsp;</li>
    <li *ngFor="let item of itemsToShow; let i=index" class="item" >
      <span *ngIf="i < hideOverLength">
        <a *ngIf="showAsLink" [routerLink]="['/', entity, 'view', encodeURI(item)]">{{ item }}</a>
        <span *ngIf="!showAsLink">{{ item }}</span>
        <span *ngIf="(i + 1) !== hideOverLength &&  itemsToShow[i+1]">,</span>
      </span>
    </li>
    <li *ngIf="hideOverLength < itemsToShow.length">
      <span class="show-more link" (click)="showMore()">
        Show more
      </span>
    </li>
  </ul>
</ng-template>

