import { ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {MatButtonModule} from '@angular/material/button';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ManageAgentsRoutingModule } from './manage-agents-routing.module';
import { ManageAgentsComponent } from './manage-agents.component';
import { AgentEditDeleteModalModule } from './delete-modal/agent-edit-delete-modal.module';
import { CreateAgentComponent } from './create-agent/create-agent.component';
import { EditAgentComponent } from './edit-agent/edit-agent.component';
import { CustomerSummaryService } from './customer-summary.service';
import { AquaDirectAgentService } from './aqua-direct-agent.service';
import { EditPermissionsModalModule } from './edit-permissions-modal/edit-permissions-modal.module';
import { AgentSelectorModule } from '../agent-selector/agent-selector.module';
import { AgentsTableModule } from './agents-table/agents-table.module';


@NgModule({
  declarations: [ManageAgentsComponent, CreateAgentComponent, EditAgentComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgbModule,
    ManageAgentsRoutingModule,
    FontAwesomeModule,
    AgentEditDeleteModalModule,
    EditPermissionsModalModule,
    AgentSelectorModule,
    MatButtonModule,
    AgentsTableModule,
  ],
  providers: [CustomerSummaryService, AquaDirectAgentService]
})
export class ManageAgentsModule { }
