import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, noop } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        tap(noop, (error: any) => this.processError(error))
      );
  }

  constructor(
    public router: Router,
    private toastr: ToastrService,
  ) {}

  private processError = (err: HttpErrorResponse) => {
    if (err instanceof HttpErrorResponse) {
      const genericErrorMessage = `AquaDirect was unable to successfully complete the last operation.
       Please try again after a while and contact your system administrator.`;
      switch (err.status) {
        case 401:
        case 403:
        case 409:
        case 412:
        case 423:
          // handled auth interceptor
          break;
        default:
          this.toastr.error(genericErrorMessage, 'Error');
          console.error(err);
          break;
      }
    }
  }
}
