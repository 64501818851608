import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ViewStatus } from '../../enum';
import {
  AccountProfileActions,
  AccountProfileActionTypes,
  LoadRegistrationDetails,
  LoadRegistrationDetailsSuccess,
  LoadRegistrationDetailsFailed,
} from './account-profile.actions';
import { RegistrationDetails } from '../../home/CustomerView';

export interface State {
  registrationDetails?: RegistrationDetails;
  customerViewStatus: ViewStatus;
  customerErrorMessage?: string;
}

export const initialState: State = {
  customerViewStatus: ViewStatus.Initial
};

const loadRegistrationDetails = (state: State, action: LoadRegistrationDetails): State => {
  const { registrationDetails, customerErrorMessage, ...rest} = state;

  return {
    ...rest,
    customerViewStatus: ViewStatus.Loading,
  };
};

const loadRegistrationDetailsSuccess = (state: State, action: LoadRegistrationDetailsSuccess): State => ({
  ...state,
  customerViewStatus: ViewStatus.LoadSuccess,
  registrationDetails: action.payload,
});

const loadRegistrationDetailsFailed = (state: State, action: LoadRegistrationDetailsFailed): State => ({
  ...state,
  customerViewStatus: ViewStatus.LoadFailed,
  customerErrorMessage: action.payload,
});

export function reducer(state = initialState, action: AccountProfileActions): State {
  switch (action.type) {
    case AccountProfileActionTypes.LoadRegistrationDetails:
      return loadRegistrationDetails(state, action);
    case AccountProfileActionTypes.LoadRegistrationDetailsSuccess:
      return loadRegistrationDetailsSuccess(state, action);
    case AccountProfileActionTypes.LoadRegistrationDetailsFailed:
      return loadRegistrationDetailsFailed(state, action);
    default:
      return state;
  }
}

const getAccountProfileState = createFeatureSelector<State>('account-profile');

export const getCustomerDetail = createSelector(getAccountProfileState, state => state.registrationDetails);
export const getCustomerViewStatus = createSelector(getAccountProfileState, state => state.customerViewStatus);
export const getCustomerViewErrorMessage = createSelector(getAccountProfileState, state => state.customerErrorMessage);
