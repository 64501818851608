<div class="row">
  <div class="table-container">
    <table class="table table-striped">
      <caption>Currently appointed agents for {{ captionText }}</caption>
      <thead>
        <tr>
          <th>
            Customer no
          </th>
          <th>
            Name
          </th>
          <th>
            Role
          </th>
          <th>
            <p class="center-aligned">
              View details
            </p>
          </th>
          <th>
            <p class="center-aligned">
              Submit production returns
            </p>
          </th>
          <th>
            <p class="center-aligned">
              Manage agents
            </p>
          </th>
          <th>
            Edit
          </th>
          <th>
            Delete
          </th>
        </tr>
      </thead>
      <tbody *ngIf="agents$ | async as agents">
        <tr *ngFor="let agentRelationship of agents">
          <td>
            {{ agentRelationship.customerID }}
          </td>
          <td>
            {{ agentRelationship.agentName }}
          </td>
          <td>
            Customer Agent
          </td>
          <td class="center-aligned">
            <input disabled type="checkbox" [checked]="agentRelationship.inquiries">
          </td>
          <td class="center-aligned">
            <input disabled type="checkbox" [checked]="agentRelationship.productionReports">
          </td>
          <td class="center-aligned">
            <input disabled class="center-aligned" type="checkbox" [checked]="agentRelationship.agentManagement">
          </td>
          <td>
            <button type="submit" mat-stroked-button color="primary" (click)="edit(agentRelationship.agentID, agentRelationship.customerID)">Edit</button>
          </td>
          <td>
            <button type="submit" mat-stroked-button color="warn" (click)="showDeleteModal(agentRelationship.agentID, agentRelationship.customerID)">Delete</button>
          </td>
        </tr>
        <tr *ngIf="agents.length === 0">
          <td [colSpan]="8">
              No customer agents appointed.
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
