import { Injectable } from '@angular/core';

import { NgProductionReturnModule } from './ng-production-return.module';
import { SubSection, IInputData, Section, IInputStructure, ISpecies, ISection, IPermit } from '@aquaonline/ng-models';
import { isNilOrEmpty, prop, propEq, assoc } from '@aquaonline/ramda';
import { getSpeciesName, addSpeciesAndEstuaryNameToInput, addPermitClassCodeToInput } from '@aquaonline/ng-shared';

@Injectable({
  providedIn: NgProductionReturnModule
})
export class ProductionReturnViewService {
  productionReturnData = [];
  constructor() { }

  public formatInputsForView = (inputs: Array<IInputData>, species: Array<ISpecies>, sections: Array<ISection>, permits: Array<IPermit>, productionReturnData: Array<IInputStructure>) => {
    this.productionReturnData = productionReturnData;
    inputs.forEach(input => this.formatSectionInputs[input.secID](input, species, sections, permits));
    return this.productionReturnData;
  }

  private formatSectionThree = (input: IInputData, species: Array<ISpecies>, sections: Array<ISection>) => {
    const detailedInput = addSpeciesAndEstuaryNameToInput(input, species, sections, Section.sectionThree);

    this.formatSectionThreeSubsections[prop('subsection', detailedInput)](detailedInput);
  }

  private formatSectionFour = (input: IInputData, species: Array<ISpecies>) => {
    const speciesName = getSpeciesName(prop('speciesID', input), species); 
    this.productionReturnData[1].inputs.push(assoc('speciesName', speciesName, input));
  }

  private formatSectionFive = (input: IInputData, species: Array<ISpecies>, sections: Array<ISection>) => {
    const detailedInput = addSpeciesAndEstuaryNameToInput(input, species, sections, Section.sectionFive);
    let result = this.productionReturnData[2].estuaries.find(value => propEq('estuaryName', detailedInput.estuaryName, value));
    if (isNilOrEmpty(result)) {
      this.productionReturnData[2].estuaries.push({'estuaryName': detailedInput.estuaryName, inputs: [detailedInput]});
    } else {
      result.inputs.push(detailedInput);
    }
  }

  private formatSectionSix = (input: IInputData, species: Array<ISpecies>, sections: Array<ISection>) => {
    const detailedInput = addSpeciesAndEstuaryNameToInput(input, species, sections, Section.sectionSix);
    let result = this.productionReturnData[3].estuaries.find(value => propEq('estuaryName', detailedInput.estuaryName, value));
    if (isNilOrEmpty(result)) {
      this.productionReturnData[3].estuaries.push({'estuaryName': detailedInput.estuaryName, inputs: [detailedInput]});
    } else {
      result.inputs.push(detailedInput);
    }
  }

  private formatSectionSeven = (input: IInputData, species: Array<ISpecies>, sections: Array<ISection>, permits: Array<IPermit>) => {
    const detailedInput = addSpeciesAndEstuaryNameToInput(input, species, sections, Section.sectionSeven);
    const detailedInputWithPermitClassCode = addPermitClassCodeToInput(detailedInput, permits);
    let result = this.productionReturnData[4].permits.find(value => propEq('permitID', detailedInputWithPermitClassCode.permitID, value));
      if (isNilOrEmpty(result)) {
      this.productionReturnData[4].permits.push({'permitID': detailedInputWithPermitClassCode.permitID, 'permitClassCode': detailedInputWithPermitClassCode.permitClassCode, inputs: [detailedInputWithPermitClassCode]});
      } else {
      result.inputs.push(detailedInputWithPermitClassCode);
      }
    }

  private formatSectionEight = (input: IInputData, species: Array<ISpecies>, sections: Array<ISection>, permits: Array<IPermit>) => {
    const detailedInput = addSpeciesAndEstuaryNameToInput(input, species, sections, Section.sectionEight);
    const detailedInputWithPermitClassCode = addPermitClassCodeToInput(detailedInput, permits);
    let result = this.productionReturnData[5].permits.find(value => propEq('permitID', detailedInputWithPermitClassCode.permitID, value));
      if (isNilOrEmpty(result)) {
      this.productionReturnData[5].permits.push({'permitID': detailedInputWithPermitClassCode.permitID, 'permitClassCode': detailedInputWithPermitClassCode.permitClassCode, inputs: [detailedInputWithPermitClassCode]});
      } else {
      result.inputs.push(detailedInputWithPermitClassCode);
      }
    }

  public formatSectionInputs = {
    [Section.sectionThree]: (input: IInputData, species: Array<ISpecies>, sections: Array<ISection>) => this.formatSectionThree(input, species, sections),
    [Section.sectionFour]: (input: IInputData, species: Array<ISpecies>) => this.formatSectionFour(input, species),
    [Section.sectionFive]: (input: IInputData, species: Array<ISpecies>, sections: Array<ISection>) => this.formatSectionFive(input, species, sections),
    [Section.sectionSix]: (input: IInputData, species: Array<ISpecies>, sections: Array<ISection>) => this.formatSectionSix(input, species, sections),
    [Section.sectionSeven]: (input: IInputData, species: Array<ISpecies>, sections: Array<ISection>, permits: Array<IPermit>) => this.formatSectionSeven(input, species, sections, permits),
    [Section.sectionEight]: (input: IInputData, species: Array<ISpecies>, sections: Array<ISection>, permits: Array<IPermit>) => this.formatSectionEight(input, species, sections, permits),
  }

  private setSeubsectionFarmStockSrcInputs(input: IInputData) {
    this.productionReturnData[0].subSections[2].species.push(input);
  }

  private setSubsectionFarmGatePriceAvgInputs(input: IInputData) {
    this.productionReturnData[0].subSections[1].species.push(input);
  }

  private setSubsectionOysterSoldInputs(input: IInputData) {
    let result = this.productionReturnData[0].subSections[0].estuaries.find(value => propEq('estuaryName', input.estuaryName, value));
    if (isNilOrEmpty(result)) {
      this.productionReturnData[0].subSections[0].estuaries.push({ 'estuaryName': input.estuaryName, inputs: [input] });
    }
    else {
      result.inputs.push(input);
    }
  }

  private formatSectionThreeSubsections = {
    [SubSection.oysterSold]: (input: IInputData) => this.setSubsectionOysterSoldInputs(input),
    [SubSection.farmGatePriceAvg]: (input: IInputData) => this.setSubsectionFarmGatePriceAvgInputs(input),
    [SubSection.farmStockSrc]: (input: IInputData) => this.setSeubsectionFarmStockSrcInputs(input),
  }
}
