import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';
import { CustomerViewAddressComponent } from './customer-view-address.component';

@NgModule({
  declarations: [CustomerViewAddressComponent],
  exports: [CustomerViewAddressComponent],
  imports: [
    CommonModule,
    SharedModule,
  ]
})
export class CustomerViewAddressModule { }
