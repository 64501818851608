import { Component, OnInit, Input } from '@angular/core';


@Component({
  selector: 'app-customer-view-contacts',
  templateUrl: './customer-view-contacts.component.html',
  styleUrls: ['./customer-view-contacts.component.scss']
})
export class CustomerViewContactsComponent implements OnInit {
  @Input() contacts: Array<any>;
  constructor() { }

  ngOnInit() {
  }

}
