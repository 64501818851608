import { FormControl } from '@angular/forms';

import { OptionMeta } from '../search-results';
import { isNilOrEmpty, prop } from './ramda-functions';

export const validateTypeaheadFormat = (control: FormControl) => {
  const option: OptionMeta = control.value;
  if (isNilOrEmpty(option)) {
    return null;
  }

  return !isNilOrEmpty(prop('id', option)) ? null : {
    invalidFormat: {
      valid: false,
      value: option,
    }
  };
};
