import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';

import {
  ProductionReturnListActionTypes,
  LoadProductionReturnList,
  LoadProductionReturnListSuccess,
  LoadProductionReturnListFailed
} from './production-return-list.actions';
import { ProductionReturn } from '.';
import { ProductionReturnListService } from './production-return-list.service';

@Injectable()
export class ProductionReturnListEffects {
  @Effect()
  getProductionReturn$: Observable<LoadProductionReturnListSuccess | LoadProductionReturnListFailed> =
    this.actions$
      .pipe(
        ofType(ProductionReturnListActionTypes.LoadProductionReturnList),
        switchMap(() => {
          return this.productionReturnListService.getProductionReturnList()
            .pipe(
              map((agentRelationships: ProductionReturn[]) => new LoadProductionReturnListSuccess(agentRelationships)),
              catchError(error => of(new LoadProductionReturnListFailed(error)))
            );
        })
      );

  constructor(
    private actions$: Actions,
    private productionReturnListService: ProductionReturnListService,
  ) {}

}
