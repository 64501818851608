import { Component, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';

import { Action } from '../../production-return/enum';
import { ProductionReturnWebappDirectService } from '../../production-return/production-return-webapp-direct.service';


@Component({
  selector: 'app-production-return-exit',
  templateUrl: './production-return-exit.component.html',
  styleUrls: ['./production-return-exit.component.scss'],
})
export class ProductionReturnExitComponent implements OnInit, OnDestroy {
  @Output() clickListener: EventEmitter<string> = new EventEmitter();
  Action = Action;
  constructor(
    protected productionReturnWebappDirectService: ProductionReturnWebappDirectService,
  ) { }

  ngOnInit() {
  }

  exit = () => {
    this.clickListener.emit(Action.Exit);
  }

  ngOnDestroy(): void {}
}
