import { Action } from '@ngrx/store';

export enum RootActionType {
  BootstrapApp = '[Root] Bootstrap App',
}

export class BootstrapApp implements Action {
  readonly type = RootActionType.BootstrapApp;
}

export type Actions = BootstrapApp;
