import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError } from 'rxjs/operators';

import { LoadRegistrationDetailsSuccess, LoadRegistrationDetailsFailed, AccountProfileActionTypes } from './account-profile.actions';
import { AccountProfileService } from './account-profile.service';
import { RegistrationDetails } from '../../home/CustomerView';

@Injectable()
export class AccountProfileEffects {

  @Effect()
  loadCustomer$: Observable<LoadRegistrationDetailsSuccess | LoadRegistrationDetailsFailed> =
    this.actions$
      .pipe(
        ofType(AccountProfileActionTypes.LoadRegistrationDetails),
        switchMap(({payload: customerID}) => {
          return this.accountProfileService.getUser(customerID).pipe(
            map((result: RegistrationDetails) => new LoadRegistrationDetailsSuccess(result)),
            catchError(error => of(new LoadRegistrationDetailsFailed(error)))
          );
        })
      );

  constructor(
    private actions$: Actions,
    private accountProfileService: AccountProfileService,
  ) {}
}
