import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as fromPermitSchedule from './permit-schedule.reducer';
import { LoadPermitSchedule } from './permit-schedule.actions';

@Injectable()
export class PermitScheduleActionDispatcher {

  constructor(private store: Store<fromPermitSchedule.State>) {
  }

  loadPermitSchedule = (customerID: string) => this.store.dispatch(new LoadPermitSchedule(customerID));
}
