import { FormGroup, FormArray, FormBuilder } from '@angular/forms';
import { Injectable } from '@angular/core';

import { ProductionReturnService } from './production-return.service';
import { isNilOrEmpty, isNotNilAndEmpty, propEq, pathEq } from '@aquaonline/ramda';
import { FieldTypes, FieldNames, Section, IEstuary, SubSection, IInputData } from '@aquaonline/ng-models';
import { createArrayWithOrAppendValue, totalPercentageValidator } from '@aquaonline/ng-shared';
import { columns } from './production-return-columns';
import { NgProductionReturnModule } from './ng-production-return.module';


@Injectable({
  providedIn: NgProductionReturnModule
})
export class ProductionReturnSectionThreeService {
  sectionThreeControls: FormGroup;
  columns = columns;
  constructor(
    protected productionReturnService: ProductionReturnService,
    protected formBuilder: FormBuilder,
  ) { }

  addFormControls = (input, selectedSpeciesByEstuarySectionThree, sectionThreeControls,
    sectionThreeSpeciesFrequency, columnsSubsectionA, columnsSubsectionB, columnsSubsectionC) => {

      this.sectionThreeControls = sectionThreeControls;
      let sectionThreeCFormEdit;
      switch (input.subsection) {
        case SubSection.oysterSold: {
        const sectionThreeAFormEdit = this.formBuilder.group(this.getFormGroupItemsFromColumnEdit(columnsSubsectionA, input));
        (sectionThreeControls.get('sectionThreeAForm') as FormArray).push(sectionThreeAFormEdit);
        if (isNotNilAndEmpty(sectionThreeSpeciesFrequency[+input.speciesID])) {
          sectionThreeSpeciesFrequency[+input.speciesID].push(+input.speciesID);
        } else {
          sectionThreeSpeciesFrequency[+input.speciesID] = [+input.speciesID];
        }
        if (isNotNilAndEmpty(+input.estuaryID)) {
          this.addInputIfNotExist(selectedSpeciesByEstuarySectionThree, input);
        }
        return {name: sectionThreeAFormEdit};
      }
      case SubSection.farmGatePriceAvg: {
        const sectionThreeBFormEdit = this.formBuilder.group(this.getFormGroupItemsFromColumnEdit(columnsSubsectionB, input));
        (sectionThreeControls.get('sectionThreeBForm') as FormArray).push(sectionThreeBFormEdit);
        return {name: sectionThreeBFormEdit};
      }
      case SubSection.farmStockSrc: {
        sectionThreeCFormEdit = this.formBuilder.group(this.getFormGroupItemsFromColumnEdit(columnsSubsectionC, input),
        {validators: [totalPercentageValidator]});
        (sectionThreeControls.get('sectionThreeCForm') as FormArray).push(sectionThreeCFormEdit);
        return {name: sectionThreeCFormEdit};
      }
    }
  }

  private addInputIfNotExist(selectedSpeciesByEstuarySectionThree: any, input: any) {
    const newValue = { speciesName: input.speciesName, speciesID: +input.speciesID };
    if (selectedSpeciesByEstuarySectionThree[+input.estuaryID]) {
      const isValueExist = selectedSpeciesByEstuarySectionThree[+input.estuaryID].find(element => propEq('speciesID', +input.speciesID, element));
      if (isNilOrEmpty(isValueExist)) {
        selectedSpeciesByEstuarySectionThree[+input.estuaryID].push(newValue);
      }
    } else {
      selectedSpeciesByEstuarySectionThree[+input.estuaryID] = [newValue];
    }
  }

  getFormGroupItemsFromColumnEdit(columns, receivedEntry) {
    return columns.reduce((formGroupOb, column) => {
      switch (column.name) {
        case FieldNames.SpeciesName: {
          column.defaultValue = receivedEntry.speciesName;
          break;
        }
        case FieldNames.SpeciesID: {
          column.defaultValue = +receivedEntry.speciesID;
          break;
        }
        case FieldNames.EstuaryName: {
          column.defaultValue = receivedEntry.estuaryName;
          break;
        }
        case FieldNames.EstuaryID: {
          column.defaultValue = +receivedEntry.estuaryID;
          break;
        }
        case FieldNames.RowNumber: {
          column.defaultValue = receivedEntry.rowNumber;
          break;
        }
      }
      if (propEq('type', FieldTypes.Input, column)) {
        column.defaultValue = receivedEntry[column.name];
      }
      formGroupOb[column.name] = [column.defaultValue , column.validators];
      return  formGroupOb;
    }, {});
  }

  getFormGroupItemsFromColumn(columns, receivedEntry) {
    return columns.reduce((formGroupOb, column) => {
      switch (column.name) {
        case FieldNames.SpeciesName: {
          column.defaultValue = receivedEntry.speciesName;
          break;
        }
        case FieldNames.SpeciesID: {
          column.defaultValue = +receivedEntry.speciesID;
          break;
        }
        case FieldNames.EstuaryName: {
          column.defaultValue = receivedEntry.estuaryName;
          break;
        }
        case FieldNames.EstuaryID: {
          column.defaultValue = +receivedEntry.estuaryID;
          break;
        }
        case FieldNames.RowNumber: {
          column.defaultValue = receivedEntry.rowNumber;
          break;
        }
      }
      if (propEq('type', FieldTypes.Input, column)) {
        column.defaultValue = null;
      }
      if (propEq('type', FieldTypes.DropDown, column)) {
        column.defaultValue = null;
      }
      formGroupOb[column.name] = [column.defaultValue, column.validators];
      return  formGroupOb;
    }, {});
  }

  createControls = (receivedEntry, sectionThreeControls: FormGroup, selectedSpeciesByEstuarySectionThree,
     selectedSpeciesSectionThree, sectionThreeSpeciesFrequency) => {
    const newColumns = {...this.columns};
    this.sectionThreeControls = sectionThreeControls;
    const sectionThreeAForm = this.formBuilder.group(this.getFormGroupItemsFromColumn(newColumns.sectionThreeA, receivedEntry));
    const sectionThreeBForm = this.formBuilder.group(this.getFormGroupItemsFromColumn(newColumns.sectionThreeB, receivedEntry));
    (sectionThreeControls.get('sectionThreeAForm') as FormArray).push(sectionThreeAForm);
      this.addEntryIfNotDuplicate(sectionThreeSpeciesFrequency, receivedEntry, selectedSpeciesSectionThree, sectionThreeControls, sectionThreeBForm, newColumns.sectionThreeC);
    return createArrayWithOrAppendValue([receivedEntry.estuaryID], 
        {speciesName: receivedEntry.speciesName, speciesID: receivedEntry.speciesID}, selectedSpeciesByEstuarySectionThree);
  }

  private addEntryIfNotDuplicate(sectionThreeSpeciesFrequency: any, receivedEntry: any, selectedSpeciesSectionThree: any, transactionForm: FormGroup, sectionThreeBForm: FormGroup, columnsSubsectionC: any) {
    let duplicateChecker = [];
    if (sectionThreeSpeciesFrequency[receivedEntry.speciesID]) {
      duplicateChecker = sectionThreeSpeciesFrequency[receivedEntry.speciesID];
    }
    if (duplicateChecker.length <= 1) {
      selectedSpeciesSectionThree.push(receivedEntry.speciesID);
      (transactionForm.get('sectionThreeBForm') as FormArray).push(sectionThreeBForm);
      (transactionForm.get('sectionThreeCForm') as FormArray)
        .push(this.formBuilder.group(this.getFormGroupItemsFromColumn(columnsSubsectionC, receivedEntry),
          { validators: [totalPercentageValidator] }));
    }
  }

  public createInputs(sectionThreeControls, inputs) {
    if (isNilOrEmpty(sectionThreeControls)) { return; }
    
    const {sectionThreeAForm, sectionThreeBForm, sectionThreeCForm} = sectionThreeControls;
    if(isNotNilAndEmpty(sectionThreeAForm)){
      sectionThreeAForm.forEach(entry => {
        inputs.push({secID: Section.sectionThree, subsection: SubSection.oysterSold ,speciesID: entry.speciesID, 
          estuaryID: entry.estuaryID, estuaryName: entry.estuaryName, large: entry.large, 
          medium: entry.medium, small: entry.small, speciesName: entry.speciesName, rowNumber: entry.rowNumber,
        });
      });
    }
    if(isNotNilAndEmpty(sectionThreeBForm)){
      sectionThreeBForm.forEach(entry => {
        inputs.push({secID: Section.sectionThree, subsection: SubSection.farmGatePriceAvg ,speciesID: entry.speciesID, 
          large: entry.large, medium: entry.medium, small: entry.small, speciesName: entry.speciesName
        });
      });
    }
    if(isNotNilAndEmpty(sectionThreeCForm)){
      sectionThreeCForm.forEach(entry => {
        inputs.push({secID: Section.sectionThree, subsection: SubSection.farmStockSrc ,speciesID: entry.speciesID, 
          wildCaught: entry.wildCaught, hatchery: entry.hatchery, import: entry.import, speciesName: entry.speciesName
        });
      });
    }
  }

  public addFromControlsForInput(input: IInputData, index: number, sectionThreeForms, selectedSpeciesByEstuarySectionThree, 
    sectionThreeControls, sectionThreeSpeciesFrequency) {
    const sectionThreeFormsResult = this.addFormControlsToSectionThree(input, selectedSpeciesByEstuarySectionThree, sectionThreeControls, sectionThreeSpeciesFrequency);
    if (sectionThreeForms[sectionThreeFormsResult.name]) {
      sectionThreeForms[sectionThreeFormsResult.name][index] = { ...sectionThreeFormsResult };
    }
    else {
      sectionThreeForms[sectionThreeFormsResult.name] = [{ ...sectionThreeFormsResult }];
    }
  }

  addFormControlsToSectionThree = (input: IInputData, selectedSpeciesByEstuarySectionThree, sectionThreeControls, sectionThreeSpeciesFrequency) => {
    const newColumns = {...this.columns};
    return this.addFormControls(input, selectedSpeciesByEstuarySectionThree,
      sectionThreeControls,  sectionThreeSpeciesFrequency, 
      newColumns.sectionThreeA, newColumns.sectionThreeB, newColumns.sectionThreeC);
  }

  public openSpeciesModal(modalRef, estuary: IEstuary, selectedSpeciesByEstuarySectionThree) {
    modalRef.componentInstance.species = estuary.species;
    modalRef.componentInstance.estuary = estuary;
    modalRef.componentInstance.selectedSpeciesByEstuarySectionThree = selectedSpeciesByEstuarySectionThree;
    return modalRef.componentInstance.passEntry;
  }

  removeRow = (value, sectionThreeAFormControl, sectionThreeBFormControl, sectionThreeCFormControl, 
    selectedSpeciesByEstuarySectionThree, sectionThreeSpeciesFrequency, selectedSpeciesSectionThree,
    showSectionThreeEntries) => {
    this.removeSectionThreeEntry(sectionThreeAFormControl, value, selectedSpeciesByEstuarySectionThree);
    showSectionThreeEntries[value.estuaryID] = this.hideSectionThreeIfNoEntryExist(sectionThreeAFormControl, value);
    this.removeEntryFromSubSectionsBC(value, sectionThreeBFormControl, sectionThreeCFormControl, sectionThreeSpeciesFrequency, selectedSpeciesSectionThree);
    sectionThreeAFormControl.updateValueAndValidity();
    sectionThreeBFormControl.updateValueAndValidity();
    sectionThreeCFormControl.updateValueAndValidity();
    return showSectionThreeEntries[value.estuaryID];
  }

  public removeEntryFromSubSectionsBC(value: any, sectionThreeBControl: FormArray, sectionThreeCControl: FormArray, sectionThreeSpeciesFrequency, selectedSpeciesSectionThree) {
    sectionThreeSpeciesFrequency[value.speciesID].pop();
    if (!sectionThreeSpeciesFrequency[value.speciesID].length) {
      selectedSpeciesSectionThree.splice(selectedSpeciesSectionThree.indexOf(value.speciesID), 1);
      sectionThreeBControl.controls = sectionThreeBControl.controls
        .filter(formGroup => !pathEq(['value', 'speciesID'], value.speciesID, formGroup));
      sectionThreeCControl.controls = sectionThreeCControl.controls
        .filter(formGroup => !pathEq(['value', 'speciesID'], value.speciesID, formGroup));
    }
  }

  private hideSectionThreeIfNoEntryExist(sectionThreeAControl: FormArray, value: any) {
    const entryForEstuary = sectionThreeAControl.controls.find(formGroup => pathEq(['value', 'estuaryID'], value.estuaryID, formGroup));
    return isNilOrEmpty(entryForEstuary) ? false : true;
  }

  private removeSectionThreeEntry(sectionThreeAControl: FormArray, value: any, selectedSpeciesByEstuarySectionThree) {
    sectionThreeAControl.controls = sectionThreeAControl.controls
      .filter(formGroup => !(pathEq(['value', 'rowNumber'], value.rowNumber, formGroup) && pathEq(['value', 'estuaryID'], value.estuaryID, formGroup)));
    selectedSpeciesByEstuarySectionThree[value.estuaryID] = selectedSpeciesByEstuarySectionThree[value.estuaryID]
      .filter(aSpecies => !propEq('speciesID', value.speciesID, aSpecies));
  }
}
