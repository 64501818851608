import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError } from 'rxjs/operators';

import {
  AgentSelectorActionTypes,
  GetAgentRelationshipsForSuccess,
  GetAgentRelationshipsForFailed,
  GetAgentRelationships,
} from './agent-selector.actions';
import { AquaDirectAgentService } from '../manage-agents/aqua-direct-agent.service';
import { Agent, CustomerSummary } from '../home/CustomerView';
import { AgentSelectorStateSelector } from './agent-selector.selector';

@Injectable()
export class AgentsSelectorEffects {

  @Effect()
  getAgentRelationships$: Observable<GetAgentRelationshipsForSuccess | GetAgentRelationshipsForFailed> =
    this.actions$
      .pipe(
        ofType(AgentSelectorActionTypes.GetAgentRelationships),
        switchMap((action: GetAgentRelationships) => {
          return this.agentSelectorStateSelector.customerDetail().pipe(
            map((customerSummary: CustomerSummary) => {
              return customerSummary.customerID;
            })
          );
        }),
        switchMap((customerID) => {
          return this.aquaDirectAgentService.getAgentRelationships(customerID)
            .pipe(
              map((agentRelationships: Agent[]) => new GetAgentRelationshipsForSuccess(agentRelationships)),
              catchError(error => of(new GetAgentRelationshipsForFailed(error)))
            );
        })
      );


  constructor(
    private actions$: Actions,
    private aquaDirectAgentService: AquaDirectAgentService,
    private agentSelectorStateSelector: AgentSelectorStateSelector,
  ) {}
}
