import { Action } from '@ngrx/store';

import { UserProfile } from './UserProfile';

export enum UserProfileActionTypes {
  Ready = '[UserProfile] Ready',
  Reset = '[UserProfile] Reset',
}

export class UserProfileReady implements Action {
  readonly type = UserProfileActionTypes.Ready;

  constructor(public payload: UserProfile) {}
}

export class UserProfileReset implements Action {
  readonly type = UserProfileActionTypes.Reset;
}

export type UserProfileActions = UserProfileReady | UserProfileReset;
