export interface UserProfile {
  lastLogin: Date;
  role?: UserRole;
  username?: string;
  givenName?: string;
  surname?: string;
}

export enum UserRole {
  ReadOnly = 'READ_ONLY',
  Administrator = 'ADMINISTRATOR',
  PROGRAM_LEADER = 'PROGRAM_LEADER',
  FISHERIES_MANAGER = 'FISHERIES_MANAGER',
  FISHERIES_MANAGEMENT_OFFICER = 'FISHERIES_MANAGEMENT_OFFICER',
  VIEW_ONLY = 'VIEW_ONLY',
}
