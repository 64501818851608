import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as fromEditAccountProfile from './edit-account-profile.reducer';
import { LoadCustomer } from './edit-account-profile.actions';

@Injectable()
export class EditAccountProfileActionDispatcher {

  constructor(private store: Store<fromEditAccountProfile.State>) {
  }

  loadCustomer = () => this.store.dispatch(new LoadCustomer());
}
