import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { EditAccountProfileActionDispatcher } from './edit-account-profile.dispatcher';
import { EditAccountProfileStateSelector } from './edit-account-profile.selector';
import { editAccountProfileFields } from './edit-account-profile-fields';
import { CustomerDetail } from '../../home/CustomerView';




@Component({
  selector: 'app-edit-account-profile',
  templateUrl: './edit-account-profile.component.html',
  styleUrls: ['./edit-account-profile.component.scss']
})
export class EditAccountProfileComponent implements OnInit {
  customerDetail$: Observable<CustomerDetail>;
  customerDetail: CustomerDetail;
  editAccountProfileForm: FormGroup;
  constructor(
     private editAccountProfileActionDispatcher: EditAccountProfileActionDispatcher,
     private editAccountProfileStateSelector: EditAccountProfileStateSelector,
     private router: Router,
     private route: ActivatedRoute,
     private formBuilder: FormBuilder,
  ) { }

  ngOnInit() {
    this.createForm();
    this.editAccountProfileActionDispatcher.loadCustomer();
    this.customerDetail$ = this.editAccountProfileStateSelector.customerDetail().pipe(tap(customerDetail => {
      this.customerDetail = customerDetail;
    }));
  }

  createForm() {
    this.editAccountProfileForm = this.formBuilder.group({
      transactionID: [],
      paymentOptions: [],
      payments: [],
    });
    editAccountProfileFields
      .forEach(field => {
        const validators = [];
        if (field.isRequired) {
          validators.push(Validators.required);
        }

        // if (field.isTypeahead) {
        //   validators.push(validateTypeaheadFormat);
        // }

        this.editAccountProfileForm.addControl(field.name, this.formBuilder.control(
          { disabled: field.isReadonly, value: null }, validators
        ));

      });
  }

}
