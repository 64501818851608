import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { withLatestFrom, filter, map } from 'rxjs/operators';

import { ViewStatus } from '../enum';
import { equals } from '../shared/ramda-functions';
import * as fromPermitSchedule from './permit-schedule.reducer';
import { PermitStatuses } from '../lease/enum';

@Injectable()
export class PermitScheduleStateSelector {
  constructor(
    private store: Store<fromPermitSchedule.State>,
  ) {}

  viewStatus = () => this.store.select(fromPermitSchedule.getPermitScheduleViewStatus);

  permitScheduleDetail = (): Observable<any> => this.store.select(fromPermitSchedule.getPermitScheduleDetail)
    .pipe(
      withLatestFrom(this.viewStatus()),
      filter(([_, viewStatus]) => equals(viewStatus, ViewStatus.LoadSuccess)),
      map(([permitScheduleDetail]) => permitScheduleDetail)
    )

  hasPermitSchedule = (): Observable<any> => this.permitScheduleDetail()
    .pipe(
      map(permitScheduleDetail => permitScheduleDetail.permits.length &&
      permitScheduleDetail.permits.some(permit => equals(permit.permitStatusID, PermitStatuses.Current)))
    )

  error = () => this.store.select(fromPermitSchedule.getPermitScheduleViewErrorMessage);
}
